<template>
  <div
    v-if="
      user.photoURL ==
      'https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4'
    "
  >
    <v-avatar
      :size="size"
      :color="getColor()"
      :style="'color: white; font-size:' + initialsSize"
      >{{ getInitials() }}</v-avatar
    >
  </div>
  <div v-else>
    <v-avatar :size="size">
      <v-img :src="user.photoURL"></v-img>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
    size: Number,
  },

  methods: {
    getColor() {
      if (this.user.hasOwnProperty("color")) {
        if (this.user.color == null) {
          return "blue";
        } else {
          return this.user.color;
        }
      } else {
        return "blue";
      }
    },
    getInitials() {
      let text = "";
      if (this.user.hasOwnProperty("displayName")) {
        text = this.user.displayName;
      } else if (this.user.hasOwnProperty("name")) {
        text = this.user.name;
      }

       if(text == null){
        text = ""
       }

      return text
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
  },

  computed: {
    initialsSize() {
      return this.size < 90 ? "110%" : "250%";
    },
  },
};
</script>