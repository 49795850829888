<template>
  <v-row>
    <v-col>
      <v-snackbar
        color="amber"
        top
        left
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
      >
        <div style="color: black">
          <span><v-icon color="black">mdi-alert</v-icon></span
          >{{ snackbar.msg }}
        </div>
      </v-snackbar>
      <v-spacer></v-spacer>
      <v-btn-toggle
        @change="updateFilter"
        v-model="statusfilter"
        tile
        color="blue accent-3"
        group
      >
        <v-btn :value="3">לא רלוונטי</v-btn>
        <v-btn :value="4">לא רלוונטי כרגע</v-btn>
        <v-btn :value="7">פגישה</v-btn>
        <v-btn :value="9">המשך טיפול סוכן</v-btn>
        <v-btn :value="11">סגירה</v-btn>
      </v-btn-toggle>
      <DataTable
        title="שיחות שבוצעו"
        :colList="columns"
        :items="rows"
        height="60vh"
        @onRowClickEvent="rowClicked"
        style="padding-top: 5px"
      ></DataTable>
    </v-col>

    <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
      <v-card>
        <v-toolbar dark flat color="#37474F">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >טלפון:
            {{ convertPhoneFormat(selectedObject.phone) }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <!-- <medium>{{filterComplated}} </medium> -->
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="height: 70vh">
          <v-card-text>
            <v-container>
              <!-- <small style="margin-bottom:10px">פרטים לקוח בסיסים</small> -->
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    disabled
                    v-model="selectedObject.name"
                    label="שם הלקוח"
                    prepend-icon="mdi-account"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    disabled
                    v-model="selectedObject.city"
                    label="עיר"
                    prepend-icon="mdi-city"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    disabled
                    v-model="selectedObject.age"
                    label="גיל"
                    prepend-icon="mdi-account-settings-outline"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    disabled
                    v-model="selectedObject.cId"
                    label="תעודת זהות"
                    prepend-icon="mdi-badge-account-horizontal"
                    disableld
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['employer']"
                    label="מעסיק"
                    prepend-icon="mdi-account-tie"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['phone2']"
                    label="טלפון נוסף"
                    append-outer-icon="mdi-phone"
                    dense
                    style="direction: ltr; margin-left: 15px"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="start" style="margin-bottom: 10px">
                <div v-for="item in phoneCallsAttrs" :key="item">
                  <v-checkbox
                    disabled
                    dense
                    style="margin-left: 10px"
                    v-model="selectedObject.attributes"
                    :label="item"
                    :value="item"
                    height="10"
                  ></v-checkbox>
                </div>
              </v-row>

              <v-row align="center" no-gutters>
                <small>מוצר:</small>
                <v-radio-group
                  disabled
                  dense
                  v-model="selectedObject.productType"
                  :mandatory="false"
                  :column="false"
                  @change="productTypeChanged"
                >
                  <v-radio
                    style="margin-right: 10px"
                    label="פנסיוני"
                    value="פנסיוני"
                  ></v-radio>
                  <v-radio
                    style="margin-right: 10px"
                    label="ביטוח"
                    value="ביטוח"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-card
                style="overflow: auto; max-height: 250px; margin-bottom: 40px"
              >
                <v-col v-if="selectedObject.comments.length > 0">
                  <div v-for="item in selectedObject.comments" :key="item">
                    <div v-if="visableToRep(item)">
                      <v-row no-gutters>
                        <v-col cols="12" md="1">
                          <v-avatar>
                            <img :src="item.photoURL" />
                          </v-avatar>
                        </v-col>
                        <v-col v-col cols="12" md="11">
                          <v-row justify="start" no-gutters>
                            <div
                              v-if="item.insideComment"
                              style="font-weight: 500; color: #ef6c00"
                            >
                              {{
                                item.displayName +
                                " " +
                                timeStampTOdate(item.time) +
                                ":"
                              }}
                            </div>
                            <div
                              v-else
                              style="font-weight: 500; color: #1565c0"
                            >
                              {{
                                item.displayName +
                                " " +
                                timeStampTOdate(item.time) +
                                ":"
                              }}
                            </div>
                          </v-row>
                          <v-row justify="start" no-gutters>
                            <div>{{ item.text }}</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-divider style="margin: 5px"></v-divider>
                    </div>
                  </div>
                </v-col>

                <v-row
                  v-else
                  no-gutters
                  justify="center"
                  align="center"
                  style="height: 80px"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      padding-top: 6px;
                      margin: 0;
                      font-weight: 700;
                      color: #0277bd;
                    "
                  >
                    <v-icon color="#0277BD"
                      >mdi-36px mdi-chat-processing-outline</v-icon
                    >
                    <p>אין תגובות</p>
                  </div>
                </v-row>
              </v-card>

              <v-row no-gutters align="start">
                <v-col cols="12" md="1">
                  <small>פנימי</small>
                  <v-switch
                    color="orange darken-3"
                    inset
                    style="margin: 0; margin-left: 5px"
                    v-model="insideComment"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="10">
                  <v-textarea
                    v-model="selectedObject.note"
                    outlined
                    name="notes"
                    dense
                    label="הוסף תגובה"
                    height="65"
                    :disabled="edit"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="1" align="center">
                  <v-btn style="margin-top: 20px" color="blue" icon
                    ><v-icon
                      size="60"
                      style="transform: rotate(180deg)"
                      @click="sendComments"
                      >mdi-send-circle</v-icon
                    ></v-btn
                  >
                </v-col>
              </v-row>
              <small>תנאי סף לפגישה</small>
              <v-row justify="start" style="margin-bottom: 10px" no-gutters>
                <div v-for="item in getMustAttars" :key="item">
                  <v-checkbox
                    disabled
                    dense
                    style="margin-left: 10px"
                    v-model="selectedObject.mustAttrs"
                    :label="item"
                    :value="item"
                    height="10"
                  ></v-checkbox>
                </div>
              </v-row>

              <small>סטאטוס</small>
              <v-row justify="start">
                <v-radio-group
                  disabled
                  row
                  v-model="selectedObject.status"
                  v-for="item in statusList"
                  :key="item.id"
                >
                  <v-radio
                    :label="item.text"
                    :value="item.id"
                    :color="item.color"
                    v-if="checkMustAttrs(item.id)"
                  ></v-radio>
                </v-radio-group>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="selectedObject.info"
                    dense
                    :items="getInfos"
                    label="פירוט"
                    item-value="text"
                    v-if="getInfos.length > 0 ? true : false"
                  ></v-select>
                </v-col>
              </v-row>
              <small v-if="selectedObject.status == 7">קביעת פגישה</small>
              <v-row
                v-if="selectedObject.status == 7"
                align="center"
                justify="space-between"
              >
                <v-col cols="12" sm="4" md="4">
                  <v-row class="mapContainer">
                    <v-icon>mdi-map-marker</v-icon>
                    <vue-google-autocomplete
                      disabled
                      :key="mapsKey"
                      ref="address"
                      id="map"
                      classname="form-control"
                      placeholder="כתובת"
                      v-model="selectedObject.address.addressText"
                      v-on:placechanged="getAddressData"
                      country="il"
                    ></vue-google-autocomplete>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        disabled
                        v-model="computedDateFormatted"
                        label="תאריך פגישה"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      disabled
                      locale="he"
                      v-model="selectedObject.date"
                      @input="menu2 = false"
                      color="#EF6C00"
                      header-color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-row
                    justify="center"
                    align="center"
                    style="margin-top: 14px"
                  >
                    <small>שעת התחלה</small>
                    <v-select
                      disabled
                      v-model="selectedObject.minutes"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getMinuts"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                    <div style="font-weight: bold">:</div>
                    <v-select
                      disabled
                      v-model="selectedObject.hours"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getHouers"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-row
                    justify="center"
                    align="center"
                    style="margin-top: 14px"
                  >
                    <small>שעת סיום</small>
                    <v-select
                      disabled
                      v-model="selectedObject.minutesTo"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getMinuts"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                    <div style="font-weight: bold">:</div>
                    <v-select
                      disabled
                      v-model="selectedObject.hoursTo"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getHouers"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";

export default {
  data() {
    return {
      dialog: false,
      currentCallIndex: 0,
      statusSelected: 0,
      address: [],
      isLoading: false,
      search: null,
      geoLocation: "",
      mapsKey: 1000,

      snackbar: { show: false, timeout: 3000, msg: "" },

      date: "",
      menu: false,
      modal: false,
      menu2: false,
      insideComment: false,
      statusfilter: -1,
      selectedObject: {
        comments: [],
      },
      columns: [
        { field: "statusText", data: "", type: "text", label: "סטאטוס" },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "name", data: "", type: "text", label: "שם" },
        { field: "city", data: "", type: "text", label: "עיר" },
        { field: "productType", data: "", type: "text", label: "מוצר" },
        { field: "info", data: "", type: "text", label: "פירוט" },
        { field: "cId", data: "", type: "text", label: "תעודת זהות" },
        { field: "selaId", data: "", type: "text", label: "מזהה סלע" },
        { field: "source", data: "", type: "text", label: "מקור" },
        { field: "employer", data: "", type: "text", label: "מעסיק" },
        { field: "active", data: "", type: "text", label: "פעיל" },
        { field: "updateStatus", data: "", type: "text", label: "ס.עודכן ב" },
      ],

      columns2: [
        { field: "relevant", data: "", type: "text", label: "רלוונטי" },
        { field: "statusText", data: "", type: "text", label: "סטאטוס" },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "name", data: "", type: "text", label: "שם" },
        { field: "city", data: "", type: "text", label: "עיר" },
        { field: "productType", data: "", type: "text", label: "מוצר" },
        { field: "selaId", data: "", type: "text", label: "מזהה סלע" },
        { field: "source", data: "", type: "text", label: "מקור" },
        { field: "employer", data: "", type: "text", label: "מעסיק" },
        { field: "active", data: "", type: "text", label: "פעיל" },
      ],

      rows: [],
      rows2: [],

      subStatus: [
        // {status:3,text:"יש סוכן",color:"#607D8B"},
        // {status:3,text:"לא מעוניין כרגע",color:"#607D8B"},
        // {status:3,text:"יתרות נמוכות",color:"#607D8B"},
        // {status:3,text:"תנאים טובים",color:"#607D8B"},
        // {status:3,text:"אין כסף",color:"#607D8B"},
        // {status:3,text:"לקוח זועם-לא להתקשר",color:"#607D8B"},
        // {status:3,text:"נפטר",color:"#607D8B"},
        // {status:3,text:"מספר שגוי",color:"#607D8B"},
      ],

      meetingStatusList: [
        {
          group: 1,
          id: 4,
          text: "ממתין לאישור",
          color: "#ECEFF1",
          textColor: "#000000",
        },
        {
          group: 1,
          id: 4,
          text: "מאושר",
          color: "#4CAF50",
          textColor: "#FFFFFF",
        },
        {
          group: 2,
          id: 4,
          text: "לא מאשור",
          color: "#F44336",
          textColor: "#FFFFFF",
        },
      ],
      listsDocs: {},

      showWeekNumber: false,
      formatAmPm: false,
      enableSeconds: false,
      phoneCallsAttrs: [],
      insuranceAttrs: [],
      financialAttrs: [],
      mustAttrs: [],

      phoneCallForant: {
        selaId: "",
        name: "",
        phone: "",
        city: "",
        address: {
          addressText: "",
          city: "",
          lat: 0,
          lng: 0,
          url: "",
        },
        cMail: "",
        age: "",
        agent: "",
        attributes: [],
        cId: "",
        category: "",
        color: "",
        dataInsertOn: "",
        date: "",
        duration: "",
        end: "",
        hour: "",
        hours: "",
        hoursTo: "",
        info: "",
        meetingClosedBy: "",
        meetingDate: "",
        minutes: "",
        minutesTo: "",
        mustAttrs: "",
        note: "",
        productType: "",
        rank: "",
        source: "",
        start: "",
        status: "",
        statusText: "",
        time: "",
        timed: "",
        comments: [],
        updatedBy: "",
        updatedOn: "",
      },
    };
  },
  components: {
    DataTable,
    VueGoogleAutocomplete,
  },

  async mounted() {
    await this.getData();
  },
  computed: {
    getInfos() {
      var ref = this;
      var list = this.subStatus.filter(function (item) {
        return item.status == ref.selectedObject.status;
      });
      return list;
    },
    format() {
      return this.formatAmPm ? "12" : "24";
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedObject.date);
    },

    filterComplated() {
      var ref = this;
      if (this.rows) {
        var list = this.rows.filter(function (item) {
          return item.status != 1;
        });

        return this.rows.length + " / " + list.length;
      } else {
        return "";
      }
    },

    getMinuts() {
      var array = [];
      for (var i = 0; i < 60; i += 5) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },
    getHouers() {
      var array = [];
      for (var i = 8; i < 22; i++) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },

    getMustAttars() {
      return this.selectedObject.productType == "פנסיוני"
        ? this.financialAttrs
        : this.insuranceAttrs;
    },
  },
  methods: {
    async updateFilter() {
      await this.getData();
    },
    async getData() {
      var ref = this;
      //Notiflix.Loading.Init({ svgColor: "#039BE5" });
      // //Notiflix.Loading.Standard("מעדכן");
      this.$store.state.loading = true;

      // var listsDocs = await db.collection("System").doc("lists").set({statusList:this.statusList},{merge:true})
      // var listsDocs = await db.collection("System").doc("lists").set({meetingStatusList:this.meetingStatusList},{merge:true})

      var listsDocs = await db.collection("System").doc("lists").get();

      this.listsDocs = listsDocs;
      this.financialAttrs = listsDocs.data().financialAttrs;
      this.insuranceAttrs = listsDocs.data().insuranceAttrs;
      this.statusList = listsDocs.data().statusList;
      this.subStatus = listsDocs.data().subStatus;
      this.phoneCallsAttrs = listsDocs.data().phoneCallsAttrs;
      // var phoneCalls = await db.collection('PhoneCalls').where('status','>',-1).where('status','<',7).where('representative','==',ref.$store.state.userObj.user.uid).get();
      var phoneCallOther = await db
        .collection("PhoneCalls")
        .where("representative", "==", ref.$store.state.userObj.user.uid)
        .where("status", "==", this.statusfilter)
        .get();

      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.rows = [];
      this.rows2 = [];
      this.statusList = this.statusList.filter(function (status) {
        return status.id < 8;
      });

      phoneCallOther.forEach((doc) => {
        var data = doc.data();
        data["statusText"] = this.getStatusName(data.status);
        if (data.status != 5 && data.status != 1) {
          this.rows.push(data);
        }
      });

      // this.uploadRecords();
    },
    visableToRep(msg) {
      console.log(msg);
      let res = true;
      let repFalg =
        this.$store.state.userObj.userDoc.data().role == "mRep" ? true : false;
      let forAgentFalg = false;

      if (msg.hasOwnProperty("fromAgent")) {
        msg.fromAgent ? (forAgentFalg = true) : () => {};
      }

      if (repFalg && forAgentFalg) {
        res = false;
      }

      if (msg.hasOwnProperty("visableToRep")) {
        if (msg["visableToRep"] == true) {
          res = true;
        }
      }

      return res;
    },
    convertPhoneFormat(text) {
      if (typeof text !== "undefined") {
        return text.replace("+972", "0");
      }
      return text;
    },
    rowClicked(row) {
      console.log(row);
      var index = this.findePhone(row.phone);
      this.currentCallIndex = index;
      this.selectedObject = this.rows[index];

      if (typeof this.selectedObject.attributes == "string") {
        if (this.selectedObject.attributes == "") {
          this.selectedObject.attributes = [];
        } else {
          this.selectedObject.attributes = [this.selectedObject.attributes];
        }
      }

      this.dialog = true;
    },
    getAddressData(addressData, placeResultData, id) {
      var locationObj = {
        lat: placeResultData.geometry.location.lat(),
        lng: placeResultData.geometry.location.lng(),
        addressText: placeResultData.formatted_address,
        // id:placeResultData.id,
        url: placeResultData.url,
        city: placeResultData.vicinity,
      };
      this.rows[this.currentCallIndex].address = locationObj;
      this.selectedObject.address = locationObj;
    },

    nextLead() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      let valid = this.valudateMeeting();
      if (this.valudateMeeting().flag) {
        this.saveToCallObject(this.rows[this.currentCallIndex]);
        if (this.currentCallIndex < this.rows.length - 1) {
          this.currentCallIndex++;
          this.selectedObject = this.rows[this.currentCallIndex];
          this.mapsKey++;
        }
      } else {
        this.snackbar.msg = valid.msg;
        this.snackbar.show = true;
      }
    },

    prevLead() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      this.saveToCallObject(this.rows[this.currentCallIndex]);
      if (this.currentCallIndex > 0) {
        this.currentCallIndex--;
        this.selectedObject = this.rows[this.currentCallIndex];
        this.mapsKey++;
      }
    },

    async closeAndSave() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      await this.saveToCallObject(this.selectedObject);
      location.reload();
    },

    timeStampTOdate(timestamp) {
      if (typeof timestamp == "undefined") {
        return "";
      } else if (typeof timestamp.getMonth === "function") {
        return moment(timestamp).format("HH:mm, DD/MM/YYYY");
      }

      return moment(timestamp.toDate()).format("HH:mm, DD/MM/YYYY");
    },

    valudateMeeting() {
      let result = { msg: "", flag: true };

      if (this.selectedObject.status == 7) {
        if (this.selectedObject.address.hasOwnProperty("addressText")) {
          this.selectedObject.address.addressText == ""
            ? (result = {
                msg: result.msg + "\n," + "חובה לציין כתובת ",
                flag: false,
              })
            : () => {};
        } else {
          result = {
            msg: result.msg + "\n," + "חובה לציין כתובת ",
            flag: false,
          };
        }
        this.selectedObject.date === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין תאריך ",
              flag: false,
            })
          : () => {};
        this.selectedObject.hours === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין שעת התחלה ",
              flag: false,
            })
          : () => {};
        this.selectedObject.minutes === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין דקות לשעת התחלה ",
              flag: false,
            })
          : () => {};
        this.selectedObject.hoursTo === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין שעת סיום ",
              flag: false,
            })
          : () => {};
        this.selectedObject.minutesTo === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין דקות לשעת סיום ",
              flag: false,
            })
          : () => {};
      }

      return result;
    },

    async saveToCallObject(object) {
      var userObj = this.$store.state.userObj;
      object["updatedOn"] = new Date();
      object["updatedBy"] = userObj.user.uid;
      object["updatedByName"] = userObj.user.displayName;

      var comments = this.addComments();

      if (comments !== "") {
        object.hasOwnProperty("comments")
          ? () => {}
          : (object["comments"] = []);
        object.comments.push(comments);
      }

      if (object.status == 7) {
        var selaId = db.collection("Meetings").doc().id;
        var phoneSelaId = object.selaId + "";
        selaId = selaId + "";
        var endDate = moment(object.date, "YYYY-MM-DD").toDate();
        object["meetingDate"] = moment(object.date, "YYYY-MM-DD").toDate();
        object.meetingDate.setHours(object.hours);
        object.meetingDate.setMinutes(object.minutes);
        endDate.setHours(object.hoursTo);
        endDate.setMinutes(object.minutesTo);
        object["meetingClosedBy"] = userObj.user.uid;
        object["start"] = moment(object.meetingDate).format("YYYY-MM-DD HH:mm");
        object["end"] = moment(endDate).format("YYYY-MM-DD HH:mm");
        object["color"] = "#263238";
        object["timed"] = false;
        object["rank"] = "פגישה רגילה";
        object["meetingStatus"] = "ממתין לאישור";
        object["agent"] = "0000";
        object["category"] = "לא משיוך";
        object["selaId"] = selaId;
        await db
          .collection("Meetings")
          .doc(selaId)
          .set(object, { merge: true });
        object["selaId"] = phoneSelaId;
        db.collection("PhoneCalls")
          .doc(object.phone)
          .set(object, { merge: true });

        var dataDoc = await db
          .collection("PhoneCalls")
          .doc(object.phone)
          .collection("Data")
          .doc("meetings")
          .get();
        if (dataDoc.exists) {
          db.collection("PhoneCalls")
            .doc(object.phone)
            .collection("Data")
            .doc("meetings")
            .update({
              list: firebase.firestore.FieldValue.arrayUnion(selaId),
            });
        } else {
          db.collection("PhoneCalls")
            .doc(object.phone)
            .collection("Data")
            .doc("meetings")
            .set({ list: [selaId] });
        }
      }

      if (object.status != 7) {
        object.address = {};
        object.date = "";
        object.meetingDate = "";
        object.hours = "";
        object.minutes = "";
      }
      if (object.status != 3) {
        object.info = "";
      }

      return db
        .collection("PhoneCalls")
        .doc(object.phone)
        .set(object, { merge: true });
    },
    runCalls() {
      this.selectedObject = this.rows[this.currentCallIndex];
      this.dialog = true;
    },
    findePhone(phone) {
      var ref = this;
      var index = null;
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i].phone == phone) {
          index = i;
          break;
        }
      }
      return index;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatusName(code) {
      var statusObj = this.statusList.filter(function (status) {
        return status.id == code;
      });

      if (typeof statusObj[0] == "undefined") {
        return "בתהליך";
      } else {
        return statusObj[0].text;
      }
    },
    checkMustAttrs(id) {
      var res = false;

      if (id != 7 && id) {
        res = true;
      } else {
        if (this.dialog) {
          if (this.selectedObject.productType == "פנסיוני") {
            res =
              this.selectedObject.mustAttrs.length ==
              this.financialAttrs.length;
          } else {
            res =
              this.selectedObject.mustAttrs.length ==
              this.insuranceAttrs.length;
          }
        }
        if (this.selectedObject.status == 7 && !res) {
          this.selectedObject.status = 1;
        }
      }

      return res;
    },

    productTypeChanged(event) {
      console.log(event);
      this.selectedObject.mustAttrs = [];
    },
    addComments() {
      if (!this.selectedObject.note || 0 === this.selectedObject.note.length) {
        return "";
      } else {
        var comment = {
          displayName: this.$store.state.userObj.user.displayName,
          photoURL: this.$store.state.userObj.user.photoURL,
          uid: this.$store.state.userObj.user.uid,
          time: new Date(),
          text: (" " + this.selectedObject.note).slice(1),
          insideComment: this.insideComment ? true : false,
        };
        this.selectedObject.note = "";
        this.insideComment = false;

        return comment;
      }
    },
    async uploadRecords() {
      for (var i = 0; i < this.newPhone.length; i++) {
        var obj = this.phoneCallForant;
        obj.phone = newPhone[i].phone;
        obj.selaId =
          Math.floor(1000000000 + Math.random() * 9000000000) + "" + i;
        obj.city = this.newPhone[i].city ? this.newPhone[i].city : "";
        obj.name = this.newPhone[i].name ? this.newPhone[i].name : "";
        obj.cMail = this.newPhone[i].cMail ? this.newPhone[i].cMail : "";

        obj.productType = "פנסיוני";
        (obj.mustAttrs = []), (obj["representative"] = "00000");
        obj["createdOn"] = new Date();
        obj.updatedBy = this.$store.state.userObj.user.uid;
        obj.updatedOn = new Date();
        obj["statusText"] = "שיחה ראשונה";
        obj["representativeName"] = "לא משוייך";
        obj.status = 1;
        (obj["mainStatus"] = 0), (obj["reminder"] = false);
        (obj["comments"] = []),
          // var obj= {
          //     phone : phoneFormatter.normalize(this.phoneCallsData[i].phone),
          //     comments:[],
          // }
          db.collection("PhoneCalls").doc(obj.phone).set(obj, { merge: true });
      }
    },
    async sendComments() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      await this.saveToCallObject(this.selectedObject);
    },
  },
};
</script>

<style>
#map {
  margin-right: 10px;
  width: 80%;
  border-bottom: 0.8px solid gray;
  text-align: right;
  font-size: 1rem;
  color: black;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}
#map:disabled {
  color: #bdbdbd;
  border-bottom: 2px dotted #bdbdbd;
}
#map:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #1976d2;
  padding-right: 5px;
}
.mapContainer:disabled {
  color: #eeeeee;
}
.mapContainer:focus-within i {
  color: #1976d2;
}

.timePicker {
  width: 150px;
  height: 50px;
  background-color: brown;
}
.centered-input input {
  text-align: center;
}

.datepicker input {
  border: 0px;
  border-bottom: 1px solid;
  border-radius: 0;
  border-color: cadetblue !important;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  direction: rtl;
}

.datepicker input:focus-within i {
  color: #1976d2;
}
.centered-input div {
  width: 100%;
}
</style>
