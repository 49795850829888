<template>
  <div>
    <br>
    <gmap-map
      :center="center"
      :zoom="16"
      style="width:400px;  height: 200px;"
    >
      <gmap-marker
        position="location"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
   props: {
      location : {
        type:Object
      },
   },
  data() {
    return {
     
    };
  },
};
</script>