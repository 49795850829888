<template>
  <v-row>
    <v-navigation-drawer
      v-model="filter"
      absolute
      temporary
      width="400"
      color="#FAFAFA"
      style="padding: 5px"
    >
      <v-row no-gutters>
        <v-btn icon @click="filter = false"
          ><v-icon color="#01579B"> mdi-close</v-icon></v-btn
        >
      </v-row>
      <v-col style="padding: 15px">
        <v-row justify="center" align="center">
          <v-icon color="#01579B">mdi-filter</v-icon>
          <h4 style="color: #01579b">סינון</h4>
        </v-row>
        <v-divider style="margin: 5px; margin-top: 20px"></v-divider>


        <v-row no-gutters justify="space-between" align="center" style="margin-bottom:1rem">
          <v-col cols="3">
        <!-- <v-switch
          v-model="safeSwitch"
          inset
          label="שליפה חסכונית"
          dense
          
        ></v-switch> -->
          </v-col>
          <v-col cols="6" >
            <!-- <v-text-field v-if="safeSwitch" v-model="limitInput" type="number" label="עד כמה רשומות לשלוף" hide-details outlined dense></v-text-field> -->
          </v-col>
        </v-row>

       


        <v-autocomplete
          v-model="selectedStatusList"
          :items="statusList"
          label="סטאטוס"
          item-text="text"
          return-object
          multiple
          clearable
          dense
          @change="addEvents"
        >
          <template v-slot:prepend>
            <v-btn @click="selectedStatusList = statusList" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selectedStatusList.length === statusList.length
                  ? "כל הפריטים"
                  : item.text
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selectedStatusList.length === statusList.length
                  ? ""
                  : "(+" + (selectedStatusList.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>


        <!-- <v-autocomplete
          v-model="selectedRepresentatives"
          :items="representatives"
          label="מתאם/ת"
          item-text="displayName"
          return-object
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn
              @click="selectedRepresentatives = representatives"
              icon
              color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selectedRepresentatives.length === representatives.length
                  ? "כל הפריטים"
                  : item.displayName
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selectedRepresentatives.length === representatives.length
                  ? ""
                  : "(+" + (selectedRepresentatives.length - 1) + "פריטים)"
              }}</span
            >
          </template>

          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <img
                  :src="data.item.photoURL"
                  style="border: 1px solid #b0bec5"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.displayName"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.group"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete> -->

        <v-autocomplete
          style="margin-top: 40px"
          v-model="selectedSource"
          :items="source"
          label="מקור"
          item-text="text"
          return-object
          multiple
          clearable
          dense
          @change="addEvents"
        >
          <template v-slot:prepend>
            <v-btn @click="selectedSource = source" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selectedSource.length === source.length ? "כל הפריטים" : item
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selectedSource.length === source.length
                  ? ""
                  : "(+" + (selectedSource.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>

        <div style="height: 40px"></div>
        <v-autocomplete
          v-model="selectedCitys"
          :items="citys"
          label="עיר"
          item-text="text"
          return-object
          multiple
          clearable
          dense
          @change="addEvents"
        >
          <template v-slot:prepend>
            <v-btn @click="selectedCitys = citys" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selectedCitys.length === citys.length ? "כל הפריטים" : item
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selectedCitys.length === citys.length
                  ? ""
                  : "(+" + (selectedCitys.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>
        <v-row v-if="checkifAllSelected()" no-gutters align="center" style="margin-top:1rem;margin-right:0.5rem">
          <v-checkbox  v-model="dateFilter"></v-checkbox>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              desne
            >
              <template v-slot:activator="{ on, attrs }">
                <div style="width: 300px">
                  <v-text-field
                    dense
                    v-model="dates"
                    label="תאריך יצירה"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!dateFilter"
                  ></v-text-field>
                </div>
              </template>
              <v-date-picker
                color="#0277BD"
                v-model="dates"
                locale="he"
                range
                no-title
                scrollable
                @change="sortDats"
              >
              
              </v-date-picker>
            </v-menu>
        </v-row>

        <v-row justify="center" no-gutters style="margin-top: 20px">
          <v-btn
            @click="queryPhoneCalls2"
            outlined
            color="#0277BD"
            dense
            style="margin-bottom: 10px; margin-left: 10px"
            >הצג נתונים</v-btn
          >
        </v-row>
        <v-divider
          style="margin: 5px; margin-top: 20px; margin-bottom: 20px"
        ></v-divider>

        <!-- <v-row no-gutters align="center" justify="space-around" style="margin-top:1rem">
          <v-col cols="9">
            <v-text-field
              v-model="onlyPhone"
              label="חיפוש  טלפון"
              dense
              solo
              hide-details
              style="text-algin: left; direction: ltr"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-btn color="blue" dark @click="freeSearchText('phoneNumbers')"
              >חפש</v-btn
            >
          </v-col>
        </v-row> -->

        <!-- <v-row no-gutters align="center" justify="space-around">
                    <v-col cols="9">
                        <v-text-field  v-model="onlyPhone" label="חיפוש טלפון" dense solo hide-details style="text-algin:left;direction: ltr;"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-btn color="blue" dark @click="searchOnlyNumber" >חפש</v-btn>
                    </v-col>
                </v-row> -->

        <!-- <v-row no-gutters align="center" justify="space-around"  style="margin-top:1rem">
          <v-col cols="9">
            <v-text-field
              v-model="onlyName"
              label="חיפוש שם"
              dense
              solo
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn color="blue" dark @click="searchOnlyName">חפש</v-btn>
          </v-col>
        </v-row> -->
      </v-col>
    </v-navigation-drawer>
    <v-col>
      <v-row no-gutters style="padding-left: 30px; margin-top: 1.5rem">
        <v-row no-gutters style="margin-right: 2rem">
          <v-col cols="2">
            <!-- <v-text-field
              v-model="onlyPhone"
              label="חיפוש טלפון"
              dense
              solo
              hide-details
              style="text-algin: left; direction: ltr"
              append-outer-icon="mdi-phone"
            ></v-text-field> -->
    
          </v-col>

          <v-col cols="2" style="freesearchmargin-right: 1rem">
            <!-- <v-btn
              style="margin-right: 1rem"
              color="blue"
              dark
              @click="searchOnlyNumber()"
              >חפש</v-btn
            > -->
          </v-col>
          <v-col cols="2">

          </v-col>

          <v-col cols="2" style="margin-right: 1rem">

          </v-col>
        </v-row>

        <v-btn
          color="#00897B"
          dark
          @click="addNewRecord"
          style="margin-left: 10px"
        >
          <v-row no-gutters justify="center" align="center">
            <v-icon>mdi-plus</v-icon>
            <div>רשומה</div>
          </v-row>
        </v-btn btn>

        <v-btn color="#0277BD" dark @click="filter = true">
          <v-row no-gutters justify="center" align="center">
            <v-icon>mdi-filter</v-icon>
            <div>סינון</div>
          </v-row>
        </v-btn>
      </v-row>
  

      <DataTable
        title="שיחות לביצוע"
        :colList="columns"
        :items="rows"
        height="60vh"
        :selcted="true"
        ref="phoneRecordsTable"
        @onRowClickEvent="rowClicked"
        style="padding-top: 25px"
        @onActionsClickEvent="actionsClicked"
      ></DataTable>
    </v-col>

    <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
      <v-card>
        <v-toolbar dark flat color="#37474F">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="newFlag"
            ><v-text-field
              style="margin-top: 30px"
              v-model="selectedObject.phone"
              outlined
              label="מספר טלפון"
              prepend-icon="mdi-phone"
              dense
            ></v-text-field
          ></v-toolbar-title>
          <v-toolbar-title v-else
            >טלפון:
            {{ convertPhoneFormat(selectedObject.phone) }}</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-autocomplete
            v-if="newFlag"
            style="margin-top: 30px; margin-right: 10px"
            label="מתאם/ת"
            v-model="arepresentative"
            dense
            :items="representatives"
            item-text="displayName"
            outlined
            item-value="uid"
            :disabled="edit"
            return-object
            prepend-icon="mdi-account"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img
                    :src="data.item.photoURL"
                    style="border: 1px solid #b0bec5"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.displayName"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.group"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-if="!newFlag"
            v-model="selectedRepresentativeObject"
            :items="representatives"
            label="מתאם/ת"
            item-text="displayName"
            return-object
            clearable
            dense
            outlined
            style="margin-top: 30px; margin-right: 10px; width: 90px"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img
                    :src="data.item.photoURL"
                    style="border: 1px solid #b0bec5"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.displayName"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.group"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>

          <v-btn v-if="newFlag" @click="saveNewRecord()" text dark>הוספה</v-btn>
          <v-btn v-else @click="closeAndSave()" text dark>שמור</v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-subtitle>
          <v-row
            no-gutters
            justify="space-between"
            style="padding-right: 20px; padding-left: 20px"
          >
          </v-row>
        </v-card-subtitle>
        <v-card-text style="height: 70vh">
          <v-card-text>
            <v-container>
              <small v-if="!newFlag">סטאטוס</small>
              <v-row v-if="!newFlag" justify="space-between">
                <v-radio-group
                  row
                  v-model="selectedObject.status"
                  v-for="item in statusList"
                  :key="item.id"
                >
                  <v-radio
                    :label="item.text"
                    :value="item.id"
                    :color="item.color"
                    v-if="checkMustAttrs(item.id)"
                  ></v-radio>
                </v-radio-group>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="selectedObject.info"
                    dense
                    :items="getInfos"
                    label="פירוט"
                    item-value="text"
                    v-if="getInfos.length > 0 ? true : false"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.name"
                    label="שם הלקוח"
                    prepend-icon="mdi-account"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.city"
                    label="עיר"
                    prepend-icon="mdi-city"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.age"
                    label="גיל"
                    prepend-icon="mdi-account-settings-outline"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.cId"
                    label="תעודת זהות"
                    prepend-icon="mdi-badge-account-horizontal"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-combobox
                    label="מקור"
                    v-model="selectedObject['source']"
                    :items="source"
                    dense
                    prepend-icon="mdi-tag"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['employer']"
                    label="מעסיק"
                    prepend-icon="mdi-account-tie"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['active']"
                    label="פעיל"
                    prepend-icon="mdi-power"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['phone2']"
                    label="טלפון נוסף"
                    append-outer-icon="mdi-phone"
                    dense
                    style="direction: ltr; margin-left: 15px"
                  ></v-text-field>
                </v-col>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    label="שם מוצר"
                    v-model="selectedObject['productName']"
                    dense
                    prepend-icon="mdi-book"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="5">
                  <v-text-field
                    label="guid"
                    v-model="selectedObject['guid']"
                    dense
                    prepend-icon="mdi-counter"
                  ></v-text-field>
                </v-col>
                </v-col>
                </v-col>
              </v-row>

              <v-row justify="start" style="margin-bottom: 10px">
                <div v-for="item in phoneCallsAttrs" :key="item">
                  <v-checkbox
                    dense
                    style="margin-left: 10px"
                    v-model="selectedObject.attributes"
                    :label="item"
                    :value="item"
                    height="10"
                  ></v-checkbox>
                </div>
              </v-row>

              <v-row align="center">
                <small>מוצר:</small>
                <v-radio-group
                  dense
                  v-model="selectedObject.productType"
                  :mandatory="false"
                  :column="false"
                  @change="productTypeChanged"
                >
                  <v-radio
                    style="margin-right: 10px"
                    label="פנסיוני"
                    value="פנסיוני"
                  ></v-radio>
                  <v-radio
                    style="margin-right: 10px"
                    label="ביטוח"
                    value="ביטוח"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-card
                style="overflow: auto; max-height: 250px; margin-bottom: 40px"
              >
                <v-col v-if="selectedObject.comments.length > 0">
                  <div v-for="item in selectedObject.comments" :key="item">
                    <v-row no-gutters align="center">
                      <v-col cols="12" md="1">
                        <v-avatar>
                          <img :src="item.photoURL" />
                        </v-avatar>
                      </v-col>
                      <v-col v-col cols="12" md="9">
                        <v-row justify="start" no-gutters>
                          <div
                            v-if="item.insideComment"
                            style="font-weight: 500; color: #ef6c00"
                          >
                            {{
                              item.displayName +
                              " " +
                              timeStampTOdate(item.time) +
                              ":"
                            }}
                          </div>
                          <div v-else style="font-weight: 500; color: #1565c0">
                            {{
                              item.displayName +
                              " " +
                              timeStampTOdate(item.time) +
                              ":"
                            }}
                          </div>
                        </v-row>
                        <v-row justify="start" no-gutters>
                          <div>{{ item.text }}</div>
                        </v-row>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          icon
                          @click="delteComment(item)"
                          v-if="
                            $store.state.userObj.userDoc.data().role == 'admin'
                          "
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </v-col>
                      <v-col cols="1">
                        <v-checkbox
                          hide-details
                          off-icon="mdi-eye"
                          on-icon="mdi-eye"
                         
                          v-model="item['visableToRep']"
                          style="margin-bottom: 1rem"
                          color="green darken-5"
                          @change="onChangeShowTORep(item)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider style="margin: 5px"></v-divider>
                  </div>
                </v-col>

                <v-row
                  v-else
                  no-gutters
                  justify="center"
                  align="center"
                  style="height: 80px"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      padding-top: 6px;
                      margin: 0;
                      font-weight: 700;
                      color: #0277bd;
                    "
                  >
                    <v-icon color="#0277BD"
                      >mdi-36px mdi-chat-processing-outline</v-icon
                    >
                    <p>אין תגובות</p>
                  </div>
                </v-row>
              </v-card>

              <v-row no-gutters align="start">
                <v-col cols="12" md="1">
                  <small>פנימי</small>
                  <v-switch
                    color="orange darken-3"
                    inset
                    style="margin: 0; margin-left: 5px"
                    v-model="insideComment"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="11">
                  <v-textarea
                    v-model="selectedObject.note"
                    outlined
                    name="notes"
                    dense
                    label="הוסף תגובה"
                    height="65"
                    :disabled="edit"
                  ></v-textarea>
                </v-col>
              </v-row>

              <small  v-if="!newFlag">תנאי סף לפגישה</small>
              <v-row
                v-if="!newFlag"
                justify="start"
                style="margin-bottom: 10px;margin-top:0.3rem"
              >
                <div v-for="item in getMustAttars" :key="item">
                  <v-checkbox
                    dense
                    style="margin-left: 10px"
                    v-model="selectedObject.mustAttrs"
                    :label="item"
                    :value="item"
                    height="10"
                  ></v-checkbox>
                </div>
              </v-row>
              <small>פגישות מקושרות</small>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="meeting in connectedMeetings"
                  :key="meeting.selaId"
                >
                  <v-expansion-panel-header>
                    <v-row no-gutters align="center">
                      <div>
                        תאריך וזמן הפגישה:
                        <span style="margin-right: 1rem; font-weight: bold">{{
                          timeStampTOdate(meeting.meetingDate)
                        }}</span>
                      </div>
                      <div style="
                            margin-right: 1rem;
                            margin-left: 1rem;
                          ">
                        עם הסוכן:
                          
                        {{ meeting.category }}</span
                        >
                      </div>
                      <v-spacer></v-spacer>
                      <v-chip >{{meeting.meetingStatus}}</v-chip>
                      <v-chip style="margin-right:1rem;margin-left:1rem" dark :color="meeting.color">{{meeting.statusText}}</v-chip>
                      <v-btn small elevation="0" style="margin-left:1rem" dark color="blue" @click="jumpTOItem(meeting.selaId)">קפוץ לפגישה</v-btn>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <v-row>
                          <v-col>
                              <v-row align="center">
                                  <div style="margin-right:1rem">עיר: </div>
                                  <v-chip style="margin-right:1rem" dense>{{meeting.city}}</v-chip>
                                  <div style="margin-right:1rem">כתובת: </div>
                                  <v-chip style="margin-right:1rem" dense>{{meeting.address.addressText}}</v-chip>
                                  <div style="margin-right:1rem">נציג: </div>
                                  <v-chip style="margin-right:1rem" dense>{{meeting.representativeName}}</v-chip>
                              </v-row>
                              <v-row align="center" style="margin-top:1.5rem">
                                  <div style="margin-right:1rem">מוצר: </div>
                                  <v-chip style="margin-right:1rem" dense>{{meeting.productType}}</v-chip>
                                  <div style="margin-right:1rem">סיווג פגישה: </div>
                                  <v-chip style="margin-right:1rem" dense>{{meeting.rank}}</v-chip>
                                  <div style="margin-right:1rem">רלוונטיות: </div>
                                  <v-chip style="margin-right:1rem" dense>{{meeting.relevant}}</v-chip>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <small style="font-weight: bold"
            >נוצר ב: {{ timeStampTOdate(selectedObject.createdOn) }}</small
          >
          <v-spacer></v-spacer>
          <small style="font-weight: bold"
            >עודכן ב: {{ timeStampTOdate(selectedObject.updatedOn) }}</small
          >
          <v-spacer></v-spacer>
          <small style="font-weight: bold"
            >על ידי: {{ selectedObject.updatedByName }}</small
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" persistent max-width="400px">
      <v-card>
        <v-toolbar flat color="#607D8B" align="center">
          <v-btn icon color="white" @click="dialog2 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title style="color: white">שינוי רוחבי</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-action>
            <v-switch
              v-model="reloadOnDone"
              inset
              label="רענן בסיום"
              dense
              hide-details
              color="white"
              style="color:white"
            ></v-switch>           
         </v-toolbar-action>
        </v-toolbar>

        <v-progress-linear
          v-if="updating2"
          style="margin-top: 2px"
          indeterminate
          color="#607D8B"
        ></v-progress-linear>
        <div style="padding: 25px">
          <v-row justify="space-between" align="center">
            <v-icon style="margin-left: 6px; margin-bottom: 6px"
              >mdi-face-agent</v-icon
            >
            <v-autocomplete
              label="מתאם/ת"
              v-model="selectedRepresentativesForChange"
              dense
              :items="representatives"
              item-text="displayName"
              item-value="uid"
              :disabled="edit"
              return-object
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <img
                      :src="data.item.photoURL"
                      style="border: 1px solid #b0bec5"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.displayName"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.group"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-icon style="margin-left: 6px; margin-bottom: 6px"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-select
              label="סטאטוס"
              v-model="selectedStatusForChange"
              dense
              :items="filterdStatusList"
              :disabled="edit"
              return-object
            ></v-select>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-icon style="margin-left: 6px; margin-bottom: 6px"
              >mdi-book</v-icon
            >
            <v-autocomplete
              label="מקור"
              v-model="selectedSourceForChange"
              dense
              :items="source"
              :disabled="edit"
              return-object
            ></v-autocomplete>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-icon style="margin-left: 6px; margin-bottom: 6px"
              >mdi-map</v-icon
            >
            <v-autocomplete
              label="עיר"
              v-model="selectedCityForChange"
              dense
              :items="citys"
              :disabled="edit"
              return-object
            ></v-autocomplete>
          </v-row>
          <v-row justify="center">
            <v-btn
              v-if="!updating2"
              @click="massChange"
              elevation="0"
              width="90%"
              color="#607D8B"
              style="color: white"
              :disabled="edit"
              >שמור</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";
import algoliasearch from "algoliasearch";

import { textSearchKeys } from "../firebase";

// import { delete } from 'vue/types/umd';

const Lists = require("../globals/lists");

export default {
  data() {
    return {
      dateFilter: false,
      menu: false,
      dialog: false,
      dialog2: false,
      updating2: false,
      dates: [],
      currentCallIndex: 0,
      statusSelected: 0,
      address: [],
      isLoading: false,
      search: null,
      geoLocation: "",
      newFlag: true,
      mapsKey: 1000,
      limitInput: 1000,
      safeSwitch: true,
      date: "",
      menu: false,
      modal: false,
      menu2: false,
      filter: false,
      insideComment: false,
      reloadOnDone: true,
      connectedMeetings: [],
      selectedObject: {
        comments: [],
      },
           columns: [
        { field: "statusText", data: "", type: "text", label: "סטאטוס" },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "name", data: "", type: "text", label: "שם" },
        { field: "city", data: "", type: "text", label: "עיר" },
        { field: "productType", data: "", type: "text", label: "מוצר" },
        { field: "cId", data: "", type: "text", label: "תעודת זהות" },
        { field: "selaId", data: "", type: "text", label: "מזהה סלע" },
        { field: "source", data: "", type: "text", label: "מקור" },
        { field: "employer", data: "", type: "text", label: "מעסיק" },
        { field: "active", data: "", type: "text", label: "פעיל" },
        { field: "updateStatus", data: "", type: "text", label: "ס.עודכן ב" },
      ],

      rows: [],

      statusList: [
        { id: 1, text: "שיחה ראשונה", color: "#2196F3" },
        { id: 2, text: "לא ענה", color: "#673AB7" },
        { id: 3, text: "לא רלוונטי", color: "#607D8B" },
        { id: 4, text: "לא רלוונטי כרגע", color: "#FFC107" },
        { id: 5, text: "להסיר", color: "#FF5722" },
        { id: 6, text: "שיחת המשך", color: "#00BCD4" },
        { id: 7, text: "פגישה", color: "#4CAF50" },
      ],

      selectedStatusList: [],

      subStatus: [
        // {status:3,text:"יש סוכן",color:"#607D8B"},
        // {status:3,text:"לא מעוניין כרגע",color:"#607D8B"},
        // {status:3,text:"יתרות נמוכות",color:"#607D8B"},
        // {status:3,text:"תנאים טובים",color:"#607D8B"},
        // {status:3,text:"אין כסף",color:"#607D8B"},
        // {status:3,text:"לקוח זועם-לא להתקשר",color:"#607D8B"},
        // {status:3,text:"נפטר",color:"#607D8B"},
        // {status:3,text:"מספר שגוי",color:"#607D8B"},
      ],

      selectedRows: [],
      limitNumber: 200,
      representatives: [],
      selectedRepresentatives: [],
      selectedStatusForChange: {},
      selectedSourceForChange: "",
      selectedCityForChange: "",
      representativesMap: {},
      selectedRepresentativeObject: {},
      listsDocs: {},
      showWeekNumber: false,
      formatAmPm: false,
      enableSeconds: false,
      phoneCallsAttrs: [],
      insuranceAttrs: [],
      financialAttrs: [],
      mustAttrs: [],
      selectedRepresentativesForChange: {},
      citys: [],
      selectedCitys: [],
      source: [],
      selectedSource: [],
      onlyPhone: "",
      freeSearch: "",
      onlyName: "",
      arepresentative: {
        displayName: "לא משוייך",
        uid: "00000",
      },

      selectedSourceForNew: "הוספה ידנית",

      phoneCallForant: {
        selaId: "",
        name: "",
        phone: "",
        city: "",
        address: {
          addressText: "",
          city: "",
          lat: 0,
          lng: 0,
          url: "",
        },
        cMail: "",
        age: "",
        agent: "",
        attributes: [],
        cId: "",
        category: "",
        color: "",
        dataInsertOn: "",
        date: "",
        duration: "",
        end: "",
        hour: "",
        hours: "",
        hoursTo: "",
        info: "",
        meetingClosedBy: "",
        meetingDate: "",
        minutes: "",
        minutesTo: "",
        mustAttrs: "",
        note: "",
        productType: "",
        rank: "",
        source: "",
        start: "",
        status: "",
        statusText: "",
        time: "",
        timed: "",
        comments: [],
        updatedBy: "",
        updatedOn: "",
      },
    };
  },
  components: {
    DataTable,
    VueGoogleAutocomplete,
  },

  async created() {
    var ref = this;
    //Notiflix.Loading.Init({svgColor:"#039BE5",});
    //Notiflix.Loading.Standard('מעדכן');
    this.$store.state.loading = true;

    var listsDocs = await db.collection("System").doc("lists").get();
    var valuesDocs = await db.collection("System").doc("values").get();
    this.listsDocs = listsDocs;
    this.financialAttrs = listsDocs.data().financialAttrs;
    this.insuranceAttrs = listsDocs.data().insuranceAttrs;
    this.statusList = listsDocs.data().statusList;
    this.subStatus = listsDocs.data().subStatus;
    this.phoneCallsAttrs = listsDocs.data().phoneCallsAttrs;
    // this.citys = (await db.collection("System").doc('values').get()).data().citys;
    this.citys = Lists.cytis;
    this.source = valuesDocs.data().sources;
    this.$store.state.loading = false;

    this.rows = [];

    // var representativesPromise = await db.collection("Representatives").get();
    // representativesPromise.forEach((doc) => {
    //   ref.representatives.push(doc.data());
    //   ref.representativesMap[doc.data().uid] = doc.data().displayName;
    // });

    this.representatives.push( this.$store.state.userObj.userDoc.data());
    this.representativesMap[ this.$store.state.userObj.userDoc.data().uid] = this.$store.state.userObj.userDoc.data().displayName

    this.selectedStatusList = this.statusList;
    this.selectedRepresentatives = this.representatives;
    this.selectedCitys = this.citys;
    this.selectedSource = this.source;
  },

  computed: {
    searchPhoneByValue() {
      let result = "";

      if (this.onlyPhone) {
        if (this.onlyPhone.length > 4) {
          result = this.onlyPhone.substr(this.onlyPhone.length - 4);
        }
      }

      return result;
    },
    getInfos() {
      var ref = this;
      var list = this.subStatus.filter(function (item) {
        return item.status == ref.selectedObject.status;
      });
      return list;
    },
    format() {
      return this.formatAmPm ? "12" : "24";
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedObject.date);
    },

    filterComplated() {
      var ref = this;
      if (this.rows) {
        var list = this.rows.filter(function (item) {
          return item.status != 1;
        });

        return this.rows.length + " / " + list.length;
      } else {
        return "";
      }
    },

    getMinuts() {
      var array = [];
      for (var i = 0; i < 60; i += 5) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },
    getHouers() {
      var array = [];
      for (var i = 8; i < 22; i++) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },

    getMustAttars() {
      return this.selectedObject.productType == "פנסיוני"
        ? this.financialAttrs
        : this.insuranceAttrs;
    },

    filterdStatusList() {
      var newList = this.statusList.filter(function (status) {
        return status.id != 7;
      });
      return newList;
    },
    filterdsourceList() {
      var newList = this.statusList.filter(function (status) {
        return status.id != 7;
      });
      return newList;
    },
  },
  methods: {
    checkifAllSelected() {
      return (
        this.selectedStatusList.length == this.statusList.length &&
        this.selectedRepresentatives.length == this.representatives.length &&
        this.selectedSource.length == this.source.length
      );
    },
    async jumpTOItem(selaId) {
      let popObject = await db.collection("Meetings").doc(selaId).get();
      if (popObject.exists) {
        this.$store.state["popObject"] = popObject.data();
        this.$router.push({ path: "/MeetingsCalendar" });
      }
    },
    async getMeetingsForPhone(phoneCallObject) {
      const query = await db
        .collection("Meetings")
        .where("phone", "==", phoneCallObject.phone)
        .get();
      let meetings = [];
      query.forEach((doc) => {
        meetings.push(doc.data());
      });
      return meetings;
    },

    delteComment(item) {
      this.selectedObject.comments = this.selectedObject.comments.filter(
        (comment) => {
          return item != comment;
        }
      );
    },

    onChangeShowTORep(item) {
      //   console.log(item);
    },

    convertPhoneFormat(text) {
      if (typeof text !== "undefined") {
        return text.replace("+972", "0");
      }
      return text;
    },

    convertToDateSrting(obj) {
      if (typeof obj === "undefined") {
        return "";
      } else {
        return moment(obj.toDate()).format("HH:mm DD/MM/YYYY");
      }
    },
    async rowClicked(row) {
      this.newFlag = false;
      this.connectedMeetings = [];
      var index = this.findePhone(row.phone);
      this.currentCallIndex = index;
      this.selectedObject = this.rows[index];
      this.selectedRepresentativeObject = {
        uid: this.selectedObject["representative"],
        displayName: this.selectedObject["representativeName"],
      };
      if (typeof this.selectedObject.attributes == "string") {
        if (this.selectedObject.attributes == "") {
          this.selectedObject.attributes = [];
        } else {
          this.selectedObject.attributes = [this.selectedObject.attributes];
        }
      }
      this.dialog = true;

      this.connectedMeetings = await this.getMeetingsForPhone(
        this.selectedObject
      );
    },

    addNewRecord() {
      this.newFlag = true;
      var index = 0;
      this.currentCallIndex = index;
      this.selectedObject = { ...this.phoneCallForant };
      this.selectedObject["source"] = "הוספה ידנית";
      this.selectedObject["productType"] = "פנסיוני";
      this.selectedObject["employer"] = "";
      this.selectedObject["active"] = "";
      this.selectedRepresentativeObject = {
        uid: this.selectedObject["representative"],
        displayName: this.selectedObject["representativeName"],
      };
      this.dialog = true;
    },
    getAddressData(addressData, placeResultData, id) {
      var locationObj = {
        lat: placeResultData.geometry.location.lat(),
        lng: placeResultData.geometry.location.lng(),
        addressText: placeResultData.formatted_address,
        id: placeResultData.id,
        url: placeResultData.url,
        city: placeResultData.vicinity,
      };
      this.rows[this.currentCallIndex].address = locationObj;
      this.selectedObject.address = locationObj;
    },

    nextLead() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      this.saveToCallObject(this.rows[this.currentCallIndex]);
      if (this.currentCallIndex < this.rows.length - 1) {
        this.currentCallIndex++;
        this.selectedObject = this.rows[this.currentCallIndex];
        this.mapsKey++;
      }
    },

    prevLead() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      this.saveToCallObject(this.rows[this.currentCallIndex]);
      if (this.currentCallIndex > 0) {
        this.currentCallIndex--;
        this.selectedObject = this.rows[this.currentCallIndex];
        this.mapsKey++;
      }
    },

    async closeAndSave() {
      //Notiflix.Loading.Standard('רק רגע');
      this.$store.state.loading = true;

      if (
        this.selectedObject.status == 2 &&
        this.selectedObject?.comments.length > 0
      ) {
        this.selectedObject.comments = [];
      }

      var promise = await this.saveToCallObject(this.selectedObject);
      this.selectedObject["statusText"] = this.getStatusName(
        this.selectedObject.status
      );
      this.dialog = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;
    },

    async saveToCallObject(object) {
      var userObj = this.$store.state.userObj;
      object["updatedOn"] = new Date();
      object["updatedBy"] = userObj.user.uid;
      object["updatedByName"] = userObj.user.displayName;
      object["representative"] = this.selectedRepresentativeObject.uid;
      object["representativeName"] =
        this.selectedRepresentativeObject.displayName;

      if(object.status == 1){
        object["info"] = ""
      }

      var comments = this.addComments();

      if (comments !== "") {
        object.hasOwnProperty("comments")
          ? () => {}
          : (object["comments"] = []);
        object.comments.push(comments);
      }

      var meetingRef = db.collection("Meetings").doc(object.selaId).get();

      if (meetingRef.exists) {
        meetingRef.set(object, { merge: true });
      }

      return db
        .collection("PhoneCalls")
        .doc(object.phone)
        .set(object, { merge: true });
    },
    runCalls() {
      this.selectedObject = this.rows[this.currentCallIndex];
      this.dialog = true;
    },
    findePhone(phone) {
      var ref = this;
      var index = null;
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i].phone == phone) {
          index = i;
          break;
        }
      }
      return index;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatusName(code) {
      if (!code) {
        return "";
      }
      var statusObj = this.statusList.filter(function (status) {
        return status.id == code;
      });
      return statusObj[0].text;
    },
    checkMustAttrs(id) {
      var res = false;
      if (id != 7) {
        res = true;
      } else {
        if (this.dialog) {
          if (this.selectedObject.productType == "פנסיוני") {
            res =
              this.selectedObject.mustAttrs.length ==
              this.financialAttrs.length;
          } else {
            res =
              this.selectedObject.mustAttrs.length ==
              this.insuranceAttrs.length;
          }
        }
        if (this.selectedObject.status == 7 && !res) {
          this.selectedObject.status = 1;
        }
      }

      return res;
    },
    productTypeChanged(event) {
      console.log(event);
      this.selectedObject.mustAttrs = [];
    },
    async queryPhoneCalls() {
      var ref = this;
      this.rows = [];
      var query = db.collection("PhoneCalls");

      var combos = [];
      var querysPromises = [];

      const statusListLength = ref.selectedStatusList.length;
      const representativeListLength = ref.selectedRepresentatives.length;

      for (var i = 0; i < statusListLength; i++) {
        for (var j = 0; j < representativeListLength; j++) {
          for (var x = 0; x < ref.selectedSource.length; x++) {
            combos.push({
              status: ref.selectedStatusList[i],
              representative: ref.selectedRepresentatives[j],
              source: ref.selectedSource[x],
            });
          }
        }
      }

      // console.log(combos)
      //Notiflix.Loading.Standard('מעדכן');
      this.$store.state.loading = true;

      var limit = this.limitInput;
      if (limit > 1000) {
        limit = 1000;
        this.limitInput = 1000;
      }
      if (!this.safeSwitch) {
        limit = 1000;
      }
      this.limitNumber = limit;

      /*-
            status rep	source city
      case 1	!=    !=    ==   
      case 2	!=    ==    !=
      case 3	!=    ==    ==
      case 4	==    !=    !=
      case 5	==    !=    ==
      case 6	==    ==    !=
      case 7	==    ==    ==
      
      -*/

      let cases = [
        {
          status: false,
          rep: false,
          source: false,
          city: true,
        },
        {
          status: false,
          rep: false,
          source: true,
          city: false,
        },
        {
          status: false,
          rep: false,
          source: true,
          city: true,
        },
        {
          status: false,
          rep: true,
          source: false,
          city: false,
        },
        {
          status: false,
          rep: true,
          source: false,
          city: true,
        },
        {
          status: false,
          rep: true,
          source: true,
          city: false,
        },
        {
          status: false,
          rep: true,
          source: true,
          city: true,
        },
        {
          status: true,
          rep: false,
          source: false,
          city: false,
        },
        {
          status: true,
          rep: false,
          source: false,
          city: true,
        },
        {
          status: true,
          rep: false,
          source: true,
          city: false,
        },
        {
          status: true,
          rep: false,
          source: true,
          city: true,
        },
        {
          status: true,
          rep: true,
          source: false,
          city: false,
        },
        {
          status: true,
          rep: true,
          source: false,
          city: true,
        },
        {
          status: true,
          rep: true,
          source: true,
          city: false,
        },
        {
          status: true,
          rep: true,
          source: true,
          city: true,
        },
      ];
      let ops = {
        isEqueal: function (item1, item2) {
          return item1 == item2;
        },
        isNotEqueal: function (item1, item2) {
          return item1 != item2;
        },
      };

      let filtersMaps = {
        all: {
          status: this.statusList,
          rep: this.representatives,
          source: this.sources,
          city: this.citys,
        },
        selected: {
          status: this.selectedStatusList,
          rep: this.selectedRepresentatives,
          source: this.selectedSource,
          city: this.selectedCitys,
        },
      };

      for (const acase of cases) {
        for (const key in object) {
          if (Object.hasOwnProperty.call(object, key)) {
            if (object[key]) {
              ops.isEqueal(filtersMaps.all[key], filtersMaps.selected[key]);
            } else {
              ops.isNotEqueal(filtersMaps.all[key], filtersMaps.selected[key]);
            }
          }
        }
      }

      if (
        this.selectedStatusList.length != this.statusList.length &&
        this.selectedRepresentatives.length != this.representatives.length &&
        this.selectedSource.length == this.source.length
      ) {
        console.log("case 1");
        let combinations = [];
        for (var i = 0; i < statusListLength; i++) {
          for (var j = 0; j < representativeListLength; j++) {
            combinations.push({
              status: ref.selectedStatusList[i],
              representative: ref.selectedRepresentatives[j],
            });
          }
        }
        combinations.forEach((obj) => {
          console.log("combos: " + combinations.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("status", "==", obj.status.id)
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .limit(limit)
              .get()
          );
        });
      } else if (
        this.selectedStatusList.length != this.statusList.length &&
        this.selectedRepresentatives.length == this.representatives.length &&
        this.selectedSource.length != this.source.length
      ) {
        console.log("case 2");
        let combinations = [];
        for (var i = 0; i < statusListLength; i++) {
          for (var x = 0; x < ref.selectedSource.length; x++) {
            combinations.push({
              status: ref.selectedStatusList[i],
              source: ref.selectedSource[x],
            });
          }
        }
        combinations.forEach((obj) => {
          console.log("combos: " + combinations.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("status", "==", obj.status.id)
              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        });
      } else if (
        this.selectedStatusList.length != this.statusList.length &&
        this.selectedRepresentatives.length == this.representatives.length &&
        this.selectedSource.length == this.source.length
      ) {
        console.log("case 3");
        let combinations = [];
        for (var i = 0; i < statusListLength; i++) {
          combinations.push({
            status: ref.selectedStatusList[i],
          });
        }
        combinations.forEach((obj) => {
          console.log("combos: " + combinations.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("status", "==", obj.status.id)
              .limit(limit)
              .get()
          );
        });
      } else if (
        this.selectedStatusList.length == this.statusList.length &&
        this.selectedRepresentatives.length != this.representatives.length &&
        this.selectedSource.length != this.source.length
      ) {
        console.log("case 4");
        let combinations = [];
        for (var j = 0; j < representativeListLength; j++) {
          for (var x = 0; x < ref.selectedSource.length; x++) {
            combinations.push({
              representative: ref.selectedRepresentatives[j],
              source: ref.selectedSource[x],
            });
          }
        }
        combinations.forEach((obj) => {
          console.log("combos: " + combinations.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        });
      } else if (
        this.selectedStatusList.length == this.statusList.length &&
        this.selectedRepresentatives.length != this.representatives.length &&
        this.selectedSource.length == this.source.length
      ) {
        console.log("case 5");
        let combinations = [];
        for (var j = 0; j < representativeListLength; j++) {
          combinations.push({
            representative: ref.selectedRepresentatives[j],
          });
        }
        combinations.forEach((obj) => {
          console.log("combos: " + combinations.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .limit(limit)
              .get()
          );
        });
      } else if (
        this.selectedStatusList.length == this.statusList.length &&
        this.selectedRepresentatives.length == this.representatives.length &&
        this.selectedSource.length != this.source.length
      ) {
        console.log("case 6");
        let combinations = [];
        for (var x = 0; x < ref.selectedSource.length; x++) {
          combinations.push({
            source: ref.selectedSource[x],
          });
        }
        combinations.forEach((obj) => {
          console.log("combos: " + combinations.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        });
      } else if (
        this.selectedStatusList.length == this.statusList.length &&
        this.selectedRepresentatives.length == this.representatives.length &&
        this.selectedSource.length == this.source.length
      ) {
        console.log("case 7");
        let q = db.collection("PhoneCalls");
        if (this.dateFilter && this.dates.length == 2) {
          console.log("case dates");
          q = q
            .where(
              "createdOn",
              ">",
              moment(this.dates[0], "YYYY-MM-DD").toDate()
            )
            .where(
              "createdOn",
              "<",
              moment(this.dates[1], "YYYY-MM-DD").toDate()
            );
        }
        querysPromises.push(q.limit(limit).get());
      } else {
        combos.forEach((obj) => {
          console.log("combos: " + combos.length);
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("status", "==", obj.status.id)
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        });
      }

      console.log("allQuerys");
      var allQuerys = await Promise.all(querysPromises);

      allQuerys.forEach((queryResult) => {
        queryResult.forEach((doc) => {
          var data = doc.data();
          if (typeof data.status == "undefined") {
            console.log(doc.id);
            // db.collection("PhoneCalls").doc(doc.id).delete();
          }
          if (typeof data.age == "number") {
            data.source;
          }
          data["statusText"] = this.getStatusName(data.status);
          data["representativeName"] =
            ref.representativesMap[data.representative];
          if (limit > ref.rows.length) {
            if (ref.selectedCitys.includes(data.city)) {
              ref.rows.push(data);
            }
          }

          // else{
          //     if(ref.selectedCitys.includes(data.city)){
          //         ref.rows.push(data)
          //     }
          // }
        });
      });

      //Notiflix.Loading.Remove()
      this.$store.state.loading = false;
    },

    async queryPhoneCalls2() {
      let ref = this;
      this.rows = [];
      let query = db.collection("PhoneCalls");
      let combos = [];
      let querysPromises = [];
      this.$store.state.loading = true;

      let limit = this.limitInput;
      if (limit > 10000) {
        limit = 10000;
        this.limitInput = 10000;
      }
      if (!this.safeSwitch) {
        limit = 10000;
      }
      this.limitNumber = limit;

      let casesList = [
        {
          status: false,
          representative: false,
          source: false,
          city: true,
        },
        {
          status: false,
          representative: false,
          source: true,
          city: false,
        },
        {
          status: false,
          representative: false,
          source: true,
          city: true,
        },
        {
          status: false,
          representative: true,
          source: false,
          city: false,
        },
        {
          status: false,
          representative: true,
          source: false,
          city: true,
        },
        {
          status: false,
          representative: true,
          source: true,
          city: false,
        },
        {
          status: false,
          representative: true,
          source: true,
          city: true,
        },
        {
          status: true,
          representative: false,
          source: false,
          city: false,
        },
        {
          status: true,
          representative: false,
          source: false,
          city: true,
        },
        {
          status: true,
          representative: false,
          source: true,
          city: false,
        },
        {
          status: true,
          representative: false,
          source: true,
          city: true,
        },
        {
          status: true,
          representative: true,
          source: false,
          city: false,
        },
        {
          status: true,
          representative: true,
          source: false,
          city: true,
        },
        {
          status: true,
          representative: true,
          source: true,
          city: false,
        },
        {
          status: true,
          representative: true,
          source: true,
          city: true,
        },
      ];
      let filtersMaps = {
        all: {
          status: this.statusList,
          reps: this.representatives,
          source: this.source,
          city: this.citys,
        },
        selected: {
          status: this.selectedStatusList,
          reps: this.selectedRepresentatives,
          source: this.selectedSource,
          city: this.selectedCitys,
        },
        selectedSizes: {
          status: this.selectedStatusList.length,
          reps: this.selectedRepresentatives.length,
          source: this.selectedSource.length,
          city: this.selectedCitys.length,
        },
        allSizes: {
          status: this.statusList.length,
          reps: this.representatives.length,
          source: this.source.length,
          city: this.citys.length,
        },
      };

      if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 01";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
            for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
              for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
                combinations.push({
                  status: filtersMaps.selected.status[i],
                  representative: filtersMaps.selected.reps[j],
                  source: filtersMaps.selected.source[x],
                  city: filtersMaps.selected.city[y],
                });
              }
            }
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("status", "==", obj.status.id)
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("source", "==", obj.source)
              .where("city", "==", obj.city)

              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 02";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
            for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
              combinations.push({
                status: filtersMaps.selected.status[i],
                representative: filtersMaps.selected.reps[j],
                source: filtersMaps.selected.source[x],
              });
            }
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("status", "==", obj.status.id)
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 03";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
            for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
              combinations.push({
                status: filtersMaps.selected.status[i],
                representative: filtersMaps.selected.reps[j],
                city: filtersMaps.selected.city[y],
              });
            }
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("status", "==", obj.status.id)
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("city", "==", obj.city)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 04";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
            combinations.push({
              status: filtersMaps.selected.status[i],
              representative: filtersMaps.selected.reps[j],
            });
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("status", "==", obj.status.id)
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 05";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
            for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
              combinations.push({
                status: filtersMaps.selected.status[i],
                source: filtersMaps.selected.source[x],
                city: filtersMaps.selected.city[y],
              });
            }
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("status", "==", obj.status.id)
              .where("source", "==", obj.source)
              .where("city", "==", obj.city)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 06";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
            combinations.push({
              status: filtersMaps.selected.status[i],
              source: filtersMaps.selected.source[x],
            });
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("status", "==", obj.status.id)
              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 07";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
            combinations.push({
              status: filtersMaps.selected.status[i],
              city: filtersMaps.selected.city[y],
            });
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("status", "==", obj.status.id)
              .where("city", "==", obj.city)

              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length != filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 08";
        console.log(caseName);
        let combinations = [];
        for (var i = 0; i < filtersMaps.selectedSizes.status; i++) {
          combinations.push({
            status: filtersMaps.selected.status[i],
          });
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
               .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("status", "==", obj.status.id)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 09";
        console.log(caseName);
        let combinations = [];
        for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
          for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
            for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
              combinations.push({
                representative: filtersMaps.selected.reps[j],
                source: filtersMaps.selected.source[x],
                city: filtersMaps.selected.city[y],
              });
            }
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("source", "==", obj.source)
              .where("city", "==", obj.city)

              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 10";
        console.log(caseName);
        let combinations = [];
        for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
          for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
            combinations.push({
              representative: filtersMaps.selected.reps[j],
              source: filtersMaps.selected.source[x],
            });
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("source", "==", obj.source)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 11";
        console.log(caseName);
        let combinations = [];
        for (var j = 0; j < filtersMaps.selectedSizes.reps; j++) {
          for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
            combinations.push({
              representative: filtersMaps.selected.reps[j],
              city: filtersMaps.selected.city[y],
            });
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("representative", "==", this.$store.state.userObj.user.uid)
              .where("city", "==", obj.city)

              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length != filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 12";
        console.log(caseName);
        let combinations = [];
        for (var x = 0; x < filtersMaps.selectedSizes.reps; x++) {
          combinations.push({
            representative: filtersMaps.selected.reps[x],
          });
        }

        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
              .where("representative", "==", obj.representative.uid)
              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 13";
        console.log(caseName);
        let combinations = [];
        for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
          for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
            combinations.push({
              source: filtersMaps.selected.source[x],
              city: filtersMaps.selected.city[y],
            });
          }
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("source", "==", obj.source)
              .where("city", "==", obj.city)

              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length != filtersMaps.selected.source.length &&
        filtersMaps.all.city.length == filtersMaps.selected.city.length
      ) {
        const caseName = "case 14";
        console.log(caseName);
        let combinations = [];
        for (var x = 0; x < filtersMaps.selectedSizes.source; x++) {
          combinations.push({
            source: filtersMaps.selected.source[x],
          });
        }

        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("source", "==", obj.source)

              .limit(limit)
              .get()
          );
        }
      } else if (
        filtersMaps.all.status.length == filtersMaps.selected.status.length &&
        filtersMaps.all.reps.length == filtersMaps.selected.reps.length &&
        filtersMaps.all.source.length == filtersMaps.selected.source.length &&
        filtersMaps.all.city.length != filtersMaps.selected.city.length
      ) {
        const caseName = "case 15";
        console.log(caseName);
        let combinations = [];
        for (var y = 0; y < filtersMaps.selectedSizes.city; y++) {
          combinations.push({
            city: filtersMaps.selected.city[y],
          });
        }
        console.log("combos:", combinations.length);
        for (const obj of combinations) {
          querysPromises.push(
            db
              .collection("PhoneCalls")
                            .where("representative", "==", this.$store.state.userObj.user.uid)

              .where("city", "==", obj.city)
              .limit(limit)
              .get()
          );
        }
      } else {
        const caseName = "case 16";
        console.log(caseName);
        let q = db.collection("PhoneCalls").where("representative", "==", this.$store.state.userObj.user.uid)

        if (this.dateFilter && this.dates.length == 2) {
          console.log("case dates");
          q = q
            .where(
              "createdOn",
              ">",
              moment(this.dates[0], "YYYY-MM-DD").toDate()
            )
            .where(
              "createdOn",
              "<",
              moment(this.dates[1], "YYYY-MM-DD").toDate()
            );
        }
        querysPromises.push(q.limit(limit).get());
      }

      console.log("allQuerys2");
      let allQuerys = await Promise.all(querysPromises);

      allQuerys.forEach((queryResult) => {
        queryResult.forEach((doc) => {
          var data = doc.data();
          if (typeof data.status == "undefined") {
            console.log(doc.id);
            // db.collection("PhoneCalls").doc(doc.id).delete();
          }
          if (typeof data.age == "number") {
            data.source;
          }
          data["statusText"] = this.getStatusName(data.status);
          data["representativeName"] =
            ref.representativesMap[data.representative];
          if (limit > ref.rows.length) {
            // if (ref.selectedCitys.includes(data.city)) {
            // }
            ref.rows.push(data);
          }

          // else{
          //     if(ref.selectedCitys.includes(data.city)){
          //         ref.rows.push(data)
          //     }
          // }
        });
      });

      this.$store.state.loading = false;
    },

    async searchOnlyNumber() {
      var ref = this;
      this.rows = [];
      //Notiflix.Loading.Standard('מעדכן');
      this.$store.state.loading = true;

      var query = await db
        .collection("PhoneCalls")
        .where("formatedPhone", "==", this.onlyPhone)
        .get();

      query.forEach((doc) => {
        var data = doc.data();
        data["statusText"] = this.getStatusName(data.status);
        data["representativeName"] =
          ref.representativesMap[data.representative];
        ref.rows.push(data);
      });
      //Notiflix.Loading.Remove()
      this.$store.state.loading = false;
    },
    async searchOnlyName() {
      var ref = this;
      this.rows = [];
      //Notiflix.Loading.Standard('מעדכן');
      this.$store.state.loading = true;

      var query = await db
        .collection("PhoneCalls")
        .where("name", "==", this.onlyName)
        .get();

      query.forEach((doc) => {
        var data = doc.data();
        data["statusText"] = this.getStatusName(data.status);
        data["representativeName"] =
          ref.representativesMap[data.representative];
        ref.rows.push(data);
      });
      //Notiflix.Loading.Remove()
      this.$store.state.loading = false;
    },

    actionsClicked(rows) {
      this.selectedRows = [];
      this.dialog2 = true;
      this.selectedRows = rows;
    },

    async massChange() {
      this.updating2 = true;
      var promises = [];
      var ref = this;

      this.selectedRows.forEach((obj) => {
        ref.selectedStatusForChange.hasOwnProperty("id")
          ? (obj.status = ref.selectedStatusForChange.id)
          : () => {};
        ref.selectedRepresentativesForChange.hasOwnProperty("uid")
          ? (obj["representative"] = ref.selectedRepresentativesForChange.uid)
          : () => {};
        ref.selectedRepresentativesForChange.hasOwnProperty("uid")
          ? (obj["representativeName"] =
              ref.selectedRepresentativesForChange.displayName)
          : () => {};
        obj["statusText"] = ref.getStatusName(obj.status);

        if (ref.selectedSourceForChange != "") {
          obj["source"] = ref.selectedSourceForChange;
        }
        if (ref.selectedCityForChange != "") {
          obj["city"] = ref.selectedCityForChange;
        }

        if (obj.status == 2 && obj?.comments.length > 0) {
          obj.comments = [];
        }

        if (typeof obj["representativeName"] == "undefined") {
          obj["representativeName"] =
            this.representativesMap[obj.representative];

          if (typeof obj["representativeName"] == "undefined") {
            (obj["representativeName"] = "לא משוייך"),
              (obj.representative = "0000");
          }
        }
        console.log(obj);

        promises.push(
          db.collection("PhoneCalls").doc(obj.phone).set(obj, { merge: true })
        );
      });

      this.actionsAgent = "";
      this.actionsRank = "";
      var result = await Promise.all(promises);

      this.selectedRows.forEach((row) => {
        ref.rows[ref.findePhone(row.phone)].statusText = ref.getStatusName(
          row.status
        );
        ref.rows[ref.findePhone(row.phone)].representativeName =
          ref.representativesMap[
            ref.rows[ref.findePhone(row.phone)].representative
          ];
      });
      this.updating2 = false;
      this.dialog2 = false;
      this.selectedRows = [];

      this.selectedRepresentativesForChange = {};
      this.selectedRepresentativesForChange = {};
      this.selectedCityForChange = "";
      this.selectedSourceForChange = "";

      if (this.reloadOnDone) {
        this.selectedRows = [];
        this.rows = [];
        this.$refs.phoneRecordsTable.clearSelectedRows();
        this.queryPhoneCalls2();
      }
    },

    addComments() {
      if (!this.selectedObject.note || 0 === this.selectedObject.note.length) {
        return "";
      } else {
        var comment = {
          displayName: this.$store.state.userObj.user.displayName,
          photoURL: this.$store.state.userObj.user.photoURL,
          uid: this.$store.state.userObj.user.uid,
          time: new Date(),
          text: (" " + this.selectedObject.note).slice(1),
          insideComment: this.insideComment ? true : false,
        };
        this.selectedObject.note = "";
        this.insideComment = false;

        return comment;
      }
    },

    timeStampTOdate(timestamp) {
      if (typeof timestamp == "undefined" || timestamp == "") {
        return "";
      } else if (typeof timestamp.getMonth === "function") {
        return moment(timestamp).format("HH:mm, DD/MM/YYYY");
      }

      return moment(timestamp.toDate()).format("HH:mm, DD/MM/YYYY");
    },

    async saveNewRecord() {
      var obj = { ...this.phoneCallForant };
      obj.phone = this.selectedObject.phone;
      obj.selaId = Math.floor(1000000000 + Math.random() * 9000000000) + "";
      obj.city = this.selectedObject.city;
      obj.name = this.selectedObject.name;
      obj.cMail = "";
      obj.age = this.selectedObject.age;
      obj.cId = this.selectedObject.cId;
      obj.productType = this.selectedObject.productType;
      (obj.mustAttrs = []), (obj["representative"] = this.arepresentative.uid);
      obj["createdOn"] = new Date();
      obj.updatedBy = this.$store.state.userObj.user.uid;
      obj.updatedOn = new Date();
      obj["statusText"] = "שיחה ראשונה";
      obj["representativeName"] = this.arepresentative.displayName;
      obj.status = 1;
      (obj["mainStatus"] = 0), (obj["reminder"] = false);
      obj["comments"] = [];
      obj["source"] = this.selectedObject["source"];
      obj["employer"] = this.selectedObject["employer"];
      obj["active"] = this.selectedObject["active"];
      obj["resFlag"] = false;
      //check if exsists
      let record = await db.collection("PhoneCalls").doc(obj.phone).get();
      if (record.exists) {
        Notiflix.Notify.Warning("הרשומה כבר קיימת");
      } else {
        let valueLists = this.source;

        if (!valueLists.includes(obj["source"])) {
          valueLists.push(obj["source"]);
        }
        await db
          .collection("PhoneCalls")
          .doc(obj.phone)
          .set(obj, { merge: true });
        await db
          .collection("System")
          .doc("values")
          .set({ sources: valueLists }, { merge: true });

        Notiflix.Notify.Success("הרשומה נוספה בהצלחה");
        this.dialog = false;

        this.arepresentative = {
          displayName: "לא משוייך",
          uid: "00000",
        };
      }

      // promises.push(db.collection("PhoneCalls").doc(obj.phone).set(obj,{merge:true}));
    },

    async sendComments() {
      // this.updateMeedting(this.updateMeedting(this.selectedObject))
      var promise = await this.saveToCallObject(this.selectedObject);
    },
  },
};
</script>

<style>
#map {
  margin-right: 10px;
  width: 80%;
  border-bottom: 0.8px solid gray;
  text-align: right;
  font-size: 1rem;
  color: black;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}
#map:disabled {
  color: #bdbdbd;
  border-bottom: 2px dotted #bdbdbd;
}
#map:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #1976d2;
  padding-right: 5px;
}
.mapContainer:disabled {
  color: #eeeeee;
}
.mapContainer:focus-within i {
  color: #1976d2;
}

.timePicker {
  width: 150px;
  height: 50px;
  background-color: brown;
}
.centered-input input {
  text-align: center;
}

.datepicker input {
  border: 0px;
  border-bottom: 1px solid;
  border-radius: 0;
  border-color: cadetblue !important;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  direction: rtl;
}

.datepicker input:focus-within i {
  color: #1976d2;
}
.centered-input div {
  width: 100%;
}
</style>