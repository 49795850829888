<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-select
          hide-details
          v-model="uploadOption"
          outlined
          dense
          :items="items"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-file-input hide-details v-model="file" outlined dense></v-file-input>
      </v-col>
      <v-col cols="1">
        <v-btn
          elevation="0"
          color="blue draken-1"
          style="color: white; font-weight: bold"
          @click="loadFile('PhoneCalls', 'phone', 'נייד')"
          :disabled="loadingFile || file == null"
        >
          <v-progress-circular v-if="loadingFile" indeterminate></v-progress-circular>
          <div v-else>טעינה</div> </v-btn>
        
      </v-col>
      <v-col cols="2" style="margin-top:-1rem">
          <v-switch v-model="overrideSwitch">
            <template v-slot:label>דרוס רשומות</template>
          </v-switch>
      </v-col>
      
      <!-- <v-col cols="1">
        <v-btn
          elevation="0"
          color="blue draken-1"
          style="color: white; font-weight: bold"
          @click="updateFormated"
        >
          <v-progress-circular
            v-if="loadingFile"
            indeterminate
          ></v-progress-circular>
          <div v-else>הפעל</div>
        </v-btn>
      </v-col> -->
    </v-row>
    <v-alert text prominent type="error" icon="mdi-alert" v-if="errorList.length > 0">
      <v-row no-gutters justify="space-between">
        <h3>
          נמצאו <span>{{ errorList.length }}</span> שגיאות בקובץ
        </h3>
        <v-btn
          elevation="0"
          color="deep-orange lighten-3"
          @click="uploadData"
          :disabled="uploaudFile"
        >
          <v-progress-circular
            v-if="uploaudFile"
            color="deep-orange"
            indeterminate
          ></v-progress-circular>
          <div v-else>טען רק שורות תקינות</div>
        </v-btn>
      </v-row>
      <v-row
        no-gutters
        v-for="item in errorList"
        :key="'errorLine' + item.row + item.column"
      >
        <div>
          {{
            `בשורה מספר ${item.row} קיימת שגיאה מסוג "${item.error}" בעמודה "${item.column}" `
          }}
        </div>
      </v-row>
    </v-alert>
    <v-alert
      text
      prominent
      type="success"
      icon="mdi-check"
      v-if="errorList.length == 0 && rows.length > 0"
    >
      <v-row no-gutters justify="space-between" align="center">
        <h3>לא נמצאו שגיאות בקובץ</h3>
        <v-btn
          elevation="0"
          color="green lighten-1"
          @click="uploadData"
          :disabled="uploaudFile"
        >
          <v-progress-circular
            v-if="uploaudFile"
            color="green"
            indeterminate
          ></v-progress-circular>
          <div v-else>טען שורות</div>
        </v-btn>
      </v-row>
    </v-alert>
    <DataTable
      title="משתמשים"
      :colList="columns"
      :items="rows"
      height="60vh"
      style="padding-top: 25px"
    ></DataTable>
  </v-container>
</template>

<script>
import DataTable from "../components/DataTable2";
import { db, rootDb } from "../firebase";
import { firebase } from "@firebase/app";
import moment, { calendarFormat, now } from "moment";
import Notiflix from "notiflix";
import readXlsxFile from "read-excel-file";
import { parseExcelDate } from "read-excel-file";
import lists from "../globals/lists";
const phoneFormatter = require("phone-formatter");
const Lists = require("../globals/lists");

export default {
  components: {
    DataTable: DataTable,
  },
  data() {
    return {
      lastVisible: {},
      uploadOption: "phones",
      file: null,
      loadingFile: false,
      uploaudFile: false,
      overrideSwitch: false,
      items: [
        { text: "טלפונים", value: "phones" },
        // {text:'משתמשים',value:'users'},
      ],

      phonesScheme: {
        "שם מלא": {
          prop: "name",
          type: String,
          required: true,
        },
        נייד: {
          prop: "phone",
          type: (value) => {
            const number = this.parsePhoneNumber(value);
            if (!number) {
              throw new Error("invalid");
            }
            return number;
          },
          required: true,
        },
        ישוב: {
          prop: "city",
          type: (value) => {
            // const city = this.cityCheck(value)
            // if (!city) {
            //     throw new Error(`${value} לא נמצא ברישמת הערים`)
            // }
            return value;
          },
          required: false,
        },
        גיל: {
          prop: "age",
          type: Number,
          required: false,
        },
        מייל: {
          prop: "cMail",
          type: String,
          required: false,
        },
        "סיווג 1": {
          prop: "source",
          type: String,
          required: false,
        },
        מעסיק: {
          prop: "employer",
          type: String,
          required: false,
        },
        פעיל: {
          prop: "active",
          type: String,
          required: false,
        },
        הערה: {
          prop: "note",
          type: String,
          required: false,
        },
        "חפ/ת.ז": {
          prop: "cId",
          type: String,
          required: false,
        },
        מוצר: {
          prop: "productName",
          type: String,
          required: false,
        },
        guid: {
          prop: "guid",
          type: String,
          required: false,
        },
        "מקור מיטב": {
          prop: "meitav",
          type: Boolean,
          required: false,
        },
      },

      columns: [
        { field: "index", data: "", type: "number", label: 'מס"ד' },
        { field: "hasErrors", data: "", type: "text", label: "סטאטוס" },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "name", data: "", type: "text", label: "שם" },
        { field: "cId", data: "", type: "text", label: "חפ/ת.ז" },
        { field: "city", data: "", type: "text", label: "ישוב" },
        { field: "cMail", data: "", type: "text", label: 'דוא"ל' },
        { field: "source", data: "", type: "text", label: "סיווג" },
        { field: "employer", data: "", type: "text", label: "מעסיק" },
        { field: "active", data: "", type: "text", label: "פעיל" },
        { field: "age", data: "", type: "text", label: "גיל" },
        { field: "note", data: "", type: "text", label: "הערה" },
        { field: "guid", data: "", type: "text", label: "guid" },
        { field: "productName", data: "", type: "text", label: "מוצר" },
        { field: "meitav", data: "", type: "text", label: "מקור מיטב" },

      ],

      errorList: [],

      rows: [],

      phoneCallForant: {
        selaId: "",
        name: "",
        phone: "",
        city: "",
        address: {
          addressText: "",
          city: "",
          lat: 0,
          lng: 0,
          url: "",
        },
        cMail: "",
        age: "",
        agent: "",
        attributes: [],
        cId: "",
        category: "",
        color: "",
        dataInsertOn: "",
        date: "",
        duration: "",
        end: "",
        hour: "",
        hours: "",
        hoursTo: "",
        info: "",
        meetingClosedBy: "",
        meetingDate: "",
        minutes: "",
        minutesTo: "",
        mustAttrs: "",
        note: "",
        productType: "",
        rank: "",
        source: "",
        start: "",
        status: "",
        statusText: "",
        time: "",
        timed: "",
        comments: [],
        updatedBy: "",
        updatedOn: "",
        employer: "",
        active: "",
        meitav: false,
      },
    };
  },

  methods: {
    async loadFile(collection, field, colText) {
      try {
        const MAX_READ_OPERATIONS = 1600;
        const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

        let ref = this;
        this.errorList = [];
        let file = this.file;
        let schema = this.phonesScheme;
        this.loadingFile = true;
        let result = await readXlsxFile(file, { schema });
        console.log({
          result: result.rows,
          errors: result.errors,
        });

        let celi = Math.ceil(result.rows.length / MAX_READ_OPERATIONS);

        let promisesArray = [];

        this.file = null;
        let index = 2;

        result.rows.forEach((row) => {
          row["index"] = index;
          row["hasErrors"] = "תקין";
          index++;
        });

        let errorsList = [];

        result.errors.forEach((error) => {
          errorsList.push(error);
          result.rows[error.row - 1]["hasErrors"] = "שגיאה";
        });

        this.errorList = errorsList;

        let promises = [];
        let pCount = 0;

        for (let r = 0; r < result.rows.length; r++) {
          let row = result.rows[r];
          try {
            if (row.hasOwnProperty(field)) {
              if (pCount < MAX_READ_OPERATIONS) {
                console.log(row[field]);
                let phoneCell = row[field] + "";
              
                let fp = phoneCell.replace("+972", "0").replace(/\D/g, "");
                if(fp.startsWith("972")){
                 console.log("start with 972");
                 fp =  "0"+fp.slice(3)
                }

                if (!fp.startsWith("0")) {
                  console.log("not start with zero");
                  fp = "0" + fp;
                  row[field] = fp;
                }
                console.log(fp);
                promises.push(db.collection(collection).doc(fp).get());
                pCount++;
              } else {
                console.log("stoping...");
                await sleep(5000);
                pCount = 0;
              }
            }
          } catch (error) {
            console.log(error);
            Notiflix.Notify.Warning("לא ניתן לקרוא את שורה מספר " + " " + row.index);
            ref.errorList.push({
              column: field,
              error: "לא ניתן לקריאה",
              row: row.index,
            });
          }
        }

        // }
        // result.rows.forEach(async (row) => {

        // });

        let docs = await Promise.all(promises);

        docs.forEach((doc) => {
          if (doc.exists && !this.overrideSwitch ) {
            console.log("exists");
            result.rows.forEach((row) => {
              let phoneCell = row[field] + "";
              let fp = phoneCell.replace("+972", "0").replace(/\D/g, "");
              if (!fp.startsWith("0")) {
                fp = "0" + fp;
              }
              if (fp == doc.id) {
                ref.errorList.push({
                  column: colText,
                  error: "exists",
                  row: row.index,
                });
                row.hasErrors = "כבר קיים";
              }
            });
          }
        });

        this.rows = [...result.rows];
        console.log(this.rows);

        // this.checkIfExists("phone", "נייד");
      } catch (error) {
        console.log(error);
        Notiflix.Notify.Failure("שגיאה בקריאת נתונים מהקובץ");
      } finally {
        this.loadingFile = false;
      }
    },
    isMeetingIsInTheLast12months(doc) {
      if(!doc.hasOwnProperty("meetingDate")){
        return false;
      }
      let now = moment();
      let last12 = moment().subtract(12, "months");
      let meetingDate = moment(doc.meetingDate);
      return meetingDate.isBetween(last12, now);
    },

   
     async updateFormated() {
      const phones = await db
        .collection("PhoneCalls")
        .orderBy("updatedOn")
        .startAfter(this.lastVisible)
        .limit(1000)
        .get();
      this.lastVisible = phones.docs[phones.docs.length - 1];
      phones.forEach((doc) => {
        let p = doc.data().phone.replace("+972", "0").replace(/\D/g, "");
        console.log(p + " " + moment(doc.data().updatedOn.toDate()).format("DD/MM/YYYY"));
        db.collection("PhoneCalls")
          .doc(doc.id)
          .set({ formatedPhone: p }, { merge: true });
      });
    },

    
    parsePhoneNumber(value) {
      if (/^05\d([-]{0,1})\d{7}$/.test(value)) {
        //value = value.replace("0", "+972");
        return value;
      } else {
        return value;
      }
    },
    cityCheck(value) {
      if (Lists.cytis.includes(value)) {
        return value;
      } else {
        return null;
      }
    },

    uploadData() {
      this.uploadPhoneRecords();
    },

    async uploadPhoneRecords() {
      let writeBatch = rootDb.batch();
      let j = 0;

      try {
        this.uploaudFile = true;
        let newPhone = [];

        let valueLists = await db.collection("System").doc("values").get();
        valueLists = valueLists.data().sources;

        newPhone = this.rows.filter((row) => {
          return row.hasErrors == "תקין";
        });

        let promises = [];
        let objs = [];
        for (var i = 0; i < newPhone.length; i++) {
          var obj = { ...this.phoneCallForant };
          let fp =  newPhone[i].phone + ""
          fp = fp.replace("+972", "0").replace(/\D/g, "");
          if(fp.startsWith("972")){
            console.log("start with 972");
            fp =  "0"+fp.slice(3)
          }
          if (!fp.startsWith("0")) {
            console.log("not start with zero");
            fp = "0" + fp;
          }

          obj.phone = fp;
          obj.selaId = Math.floor(1000000000 + Math.random() * 9000000000) + "" + i;
          obj.city = newPhone[i].city ? newPhone[i].city : "";
          obj.name = newPhone[i].name ? newPhone[i].name : "";
          obj.age = newPhone[i].age ? newPhone[i].age : "";
          obj.cMail = newPhone[i].cMail ? newPhone[i].cMail : "";
          obj.productType = "פנסיוני";
          (obj.mustAttrs = []),
           (obj["representative"] = "00000");
          obj["createdOn"] = new Date();
          obj.updatedBy = this.$store.state.userObj.user.uid;
          obj.updatedOn = new Date();

          obj["statusText"] = "שיחה ראשונה";
          obj["representativeName"] = "לא משוייך";
          obj.status = 1;
          (obj["mainStatus"] = 0), (obj["reminder"] = false);

          obj["env"] = this.$store.state.environment;

          newPhone[i].hasOwnProperty("source")
            ? (obj["source"] = newPhone[i].source)
            : (obj["source"] = "");
          newPhone[i].hasOwnProperty("cId")
            ? (obj["cId"] = newPhone[i].cId + "")
            : (obj["cId"] = "");
          newPhone[i].hasOwnProperty("employer")
            ? (obj["employer"] = newPhone[i].employer)
            : (obj["employer"] = "");
          newPhone[i].hasOwnProperty("active")
            ? (obj["active"] = newPhone[i].active)
            : (obj["active"] = "");

          newPhone[i].hasOwnProperty("note")
            ? (obj["comments"] = [
                {
                  displayName: "טעינת אקסל",
                  fromAgent: false,
                  photoURL:
                    "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FExcel-icon.png?alt=media&token=b9d7cbe3-8789-451f-ab49-88e16ee788e9",
                  text: newPhone[i].note,
                  time: new Date(),
                  uid: "excelLoad",
                },
              ])
            : (obj["comments"] = []);

          newPhone[i].hasOwnProperty("productName")
            ? (obj["productName"] = newPhone[i].productName)
            : (obj["productName"] = "");

          newPhone[i].hasOwnProperty("guid")
            ? (obj["guid"] = newPhone[i].guid)
            : (obj["guid"] = "");

          newPhone[i].hasOwnProperty("meitav")
            ? (obj["meitav"] = newPhone[i].meitav)
            : (obj["meitav"] = false);

          // promises.push(
          //   db.collection("Test").doc(obj.phone).set(obj, { merge: true })
          // );
          if (!valueLists.includes(newPhone[i].source)) {
            valueLists.push(newPhone[i].source);
          }

          objs.push(obj);

          // try {
          //   writeBatch.set(db.collection("Test").doc(obj.phone), obj, {
          //     merge: true,
          //   });
          //   j++;
          //   if (j > 400) {
          //     // write batch only allows maximum 500 writes per batch
          //     j = 0;
          //     console.log("Intermediate committing of batch operation");
          //     await writeBatch.commit();
          //     writeBatch = rootDb.batch();
          //   }

          //   if (j > 0) {
          //     console.log(
          //       "Firebase batch operation completed. Doing final committing of batch operation."
          //     );
          //     await writeBatch.commit();
          //   } else {
          //     console.log("Firebase batch operation completed.");
          //   }
          // } catch (error) {
          //   console.log(error);
          //   writeBatch = rootDb.batch();
          // }
        }

        // await Promise.all(promises);

        let batches = this.spliceIntoChunks(objs, 400);
        for (var b = 0; b < batches.length; b++) {
          await this.doBatch(batches[b]);
        }

        await db
          .collection("System")
          .doc("values")
          .set({ sources: valueLists }, { merge: true });
        this.errorList = [];
        this.rows = [];
        Notiflix.Notify.Success("הרושמות נטענו");
      } catch (error) {
        console.log(error);
        Notiflix.Notify.Failure("שגיאה ייתכן שיש שורות שלא נטענו");
      } finally {
        this.uploaudFile = false;
      }
    },
    async doBatch(docsToBath) {
      const batch = rootDb.batch();
      docsToBath.forEach((obj) => {
        batch.set(db.collection("PhoneCalls").doc(obj.phone), obj, {
          merge: true,
        });
      });
      console.log("Trying to commit batch");
      return batch.commit();
    },
    spliceIntoChunks(arr, chunkSize) {
      const res = [];
      while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
      }

      return res;
    },
  },


};
</script>

<style></style>
