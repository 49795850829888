<template>
  <v-col>
    <v-row no-gutters style="padding-right: 10px"
      ><v-btn dark color="#01579B" @click="newUser">משתמש חדש</v-btn></v-row
    >
    <DataTable
      :key="tableKey + 'usersTableKey'"
      title="משתמשים"
      :colList="columns"
      :items="rows"
      height="60vh"
      :selcted="true"
      @onRowClickEvent="rowClicked"
      style="padding-top: 25px"
      @onActionsClickEvent="actionsClicked"
    ></DataTable>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar color="#0277BD" dark elevation="0">
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title v-if="!edit">משתמש חדש</v-toolbar-title>
          <v-toolbar-title v-else>עדכון משתמש</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="edit" icon @click="deleteUser"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-toolbar>

        <v-alert
          text
          prominent
          v-if="alert.visable"
          :type="alert.type"
          :icon="alert.icon"
          >{{ alert.text }}</v-alert
        >
        <v-card-text style="padding-top: 10px">
          <v-form ref="form" v-model="valid">
            <v-row no-gutters justify="center">
              <!-- <v-avatar
                style="margin: 20px"
                size="100"
                color="#E3F2FD"
                @click="showImagePicker = true"
              >
                <v-img :src="selectedObject['photoURL']"></v-img>
              </v-avatar> -->
              <user-avatar :size="100" :user="selectedObject"> </user-avatar>
            </v-row>
            <v-row justify="center">
              <v-btn
                style="margin: 1rem; color: #757575"
                x-small
                @click="showImagePicker = true"
                elevation="0"
                color="transparent"
              >
                <v-icon>mdi-pencil</v-icon>
                שינוי תמונה
                <my-upload
                  ref="imageUpload"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                  v-model="showImagePicker"
                  :width="100"
                  :height="100"
                  noSquare
                  :params="params"
                  :headers="headers"
                  langType="he"
                  img-format="png"
                ></my-upload>
              </v-btn>
            </v-row>
            <v-text-field
              v-model="selectedObject.uid"
              :rules="[
                (v) => !!v || 'שדה חובה',
                (v) => (v && v.length >= 9) || 'חייב להיות לפחות 9 ספרות',
              ]"
              label='ת"ז'
              prepend-icon="mdi-card-account-details"
              required
              :disabled="edit"
            ></v-text-field>
            <v-text-field
              v-model="selectedObject.displayName"
              :rules="nameRules"
              label="שם מלא"
              prepend-icon="mdi-account"
              required
              :disabled="edit"
            ></v-text-field>
            <v-text-field
              v-model="selectedObject.email"
              :rules="emailRules"
              label="E-mail"
              prepend-icon="mdi-at"
              required
              :disabled="edit"
            ></v-text-field>
            <v-text-field
              v-model="selectedObject.phone"
              :rules="nameRules"
              label="טלפון"
              prepend-icon="mdi-phone"
              required
            ></v-text-field>

            <v-select
              item-value="key"
              item-text="name"
              v-model="selectedObject.role"
              :items="items"
              :rules="[(v) => !!v || 'שדה חובה']"
              label="סוג משתמש"
              prepend-icon="mdi-key"
              required
            ></v-select>

            <v-select
              item-value="key"
              item-text="name"
              v-model="selectedObject['env']"
              :items="envs"
              :rules="[(v) => !!v || 'שדה חובה']"
              label="מוקד"
              prepend-icon="mdi-face-agent"
              multiple
              required
            ></v-select>

            <v-switch
              v-if="selectedObject.role == 'agent'"
              v-model="selectedObject.meetings"
              label="פגישות"
              inset
            ></v-switch>

            <v-autocomplete
              v-if="selectedObject.role == 'inspector'"
              label="סוכנים"
              multiple
              prepend-icon="mdi-account-group"
              chips
              v-model="selectedObject.agents"
              :items="agentsList"
              item-text="name"
              deletable-chips
              small-chips
              clearable
              return-object
            >
              <template v-slot:prepend>
                <v-btn @click="selectAllAgents()" icon color="#0288D1"
                  ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
                >
              </template>
            </v-autocomplete>

            <v-row no-gutters justify="center">
              <v-btn
                :disabled="!valid"
                color="#0277BD"
                style="color: white"
                class="mr-4"
                v-if="!edit"
                @click="validate"
                >יצירת משתמש</v-btn
              >
              <v-btn
                :disabled="!valid"
                color="#0277BD"
                style="color: white"
                class="mr-4"
                v-else
                @click="validate"
                >שמירה</v-btn
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div id="imgTest"></div>
  </v-col>
</template>


<script>
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { rootDb } from "../firebase";
import { firebase } from "@firebase/app";
import moment from "moment";
import Notiflix from "notiflix";
import ImageInput from "../components/ImageInput";
import myUpload from "vue-image-crop-upload";
import { fbstorage } from "../firebase";
import UserAvatar from "../components/UserAvatar.vue";

import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-initials-sprites";

export default {
  components: {
    DataTable: DataTable,
    ImageInput: ImageInput,
    "my-upload": myUpload,
    UserAvatar,
  },
  data() {
    return {
      tableKey: 1,
      dialog: false,
      edit: false,
      agentsList: [],
      selectedObject: {
        uid: "",
        name: "",
        email: "",
        role: "",
        phone: "",
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4",
        meetings: false,
      },
      currentCallIndex: 0,
      selectedRows: [],
      valid: true,
      avatar: null,
      saving: false,
      saved: false,
      showImagePicker: false,

      nameRules: [(v) => !!v || "שדה חובה"],

      emailRules: [
        (v) => !!v || "שדה חובה",
        (v) => /.+@.+\..+/.test(v) || "כתובת לא חוקית",
      ],
      imgRules: [
        (v) => !v || v.size < 2000000 || "גודל הקובץ עד חייב להיות עד 2MB",
      ],

      select: null,
      items: [
        { key: "admin", name: "אדמין" },
        { key: "agent", name: "סוכן" },
        { key: "mRep", name: "מתאם טלפוני" },
        { key: "oRep", name: "נציג תפעול" },
        { key: "mManager", name: "מנהל תיאום" },
        { key: "oManager", name: "מנהל תפעול" },
        { key: "inspector", name: "מפקח סוכנים" },
      ],

      envs: [
        { key: "Moked1", name: "מעגן" },
        { key: "Moked2", name: "חיפה" },
        { key: "Moked3", name: "יובלים" },
        { key: "Moked4", name: "מני גינדי" },
        { key: "Moked5", name: "מיטב דש" },

      ],

      columns: [
        {
          field: "photoURL",
          data: "",
          type: "text",
          label: "תמונה",
          width: "50px",
        },
        { field: "uid", data: "", type: "text", label: "מזהה" },
        { field: "displayName", data: "", type: "text", label: "שם" },
        { field: "email", data: "", type: "text", label: 'דוא"ל' },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "roleText", data: "", type: "text", label: "סוג משתמש" },
        { field: "envText", data: "", type: "text", label: "מוקדים" },
        { field: "appVersion", data: "", type: "text", label: "גרסת אפליקציה" },
      ],

      rows: [],

      alert: {
        type: "",
        icon: "",
        text: "",
        visable: false,
      },

      //image upload data
      showImagePicker: false,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      imgDataUrl: "", // the datebase64 url of created image
    };
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },

  async mounted() {
    await this.getUsers();
    await this.getAppData();
    await this.getAgetnsForInspector();
    this.tableKey++;
  },

  methods: {
    selectAllAgents() {
      let ref = this;
      console.log("daniel");
      this.selectedObject.agents = this.agentsList;
    },
    getRandColor() {
      let colors = [
        "#9C27B0",
        "#F44336",
        "#2196F3",
        "#3F51B5",
        "#673AB7",
        "#009688",
        "#00BCD4",
        "#03A9F4",
        "#8BC34A",
        "#4CAF50",
        "#FF9800",
        "#FFC107",
        "#607D8B",
        "#795548",
        "#FF5722",
        "#9E9E9E",
      ];
      let rand = Math.floor(Math.random() * (15 - 0 + 1) + 0);
      return colors[rand];
    },
    rowClicked(row) {
      this.alert = {
        type: "",
        icon: "",
        text: "",
        visable: false,
      };
      var index = this.findeUser(row.uid);
      this.currentCallIndex = index;
      this.selectedObject = this.rows[index];
      console.log(this.selectedObject);
      this.avatar = { imageURL: this.selectedObject.photoURL };
      this.edit = true;
      this.dialog = true;
    },
    findeUser(uid) {
      var ref = this;
      var index = null;
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i].uid == uid) {
          index = i;
          break;
        }
      }
      return index;
    },

    actionsClicked(rows) {
      this.selectedRows = [];
      this.selectedRows = rows;
    },

    newUser() {
      this.edit = false;
      this.avatar = {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4",
      };
      (this.selectedObject = {
        uid: "",
        name: "",
        email: "",
        role: "",
        meetings: false,
        storageUrl: "",
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4",
      }),
        (this.dialog = true);
    },

    async deleteUser() {
      var deleteUser = firebase.functions().httpsCallable("deleteUser");
      let result = await deleteUser({ uid: this.selectedObject.uid });
      console.log(result);
      this.dialog = false;
    },

    async validate() {
      let ref = this;

      ref.alert = {
        type: "",
        icon: "",
        text: "",
        visable: false,
      };
      // if(!this.edit){
      //    var photoURL=  await this.uploadImage();
      // }
      if (this.$refs.form.validate()) {
        //Notiflix.Loading.Standard();
        this.$store.state.loading = true;

        if (!this.edit) {
          console.log(this.selectedObject);
          var createUser = firebase.functions().httpsCallable("createUser");
          createUser({
            email: this.selectedObject.email.toLowerCase().trim(),
            uid: this.selectedObject.uid.trim(),
            displayName: this.selectedObject.displayName.trim(),
            photoURL: this.selectedObject.photoURL,
            role: this.selectedObject.role,
            meetings: this.selectedObject.meetings,
            phone: this.selectedObject.phone.trim(),
            env: this.selectedObject.env,
          })
            .then(function (result) {
              console.log(result);
              //Notiflix.Loading.Remove();
              ref.$store.state.loading = false;

              if (result.data.msg == "done") {
                ref.dialog = false;
                ref.getUsers();
                Notiflix.Notify.Success("המשתמש נוצר בהצלחה");
                ref.$refs.form.reset();
              } else {
                var errorMessage = "";
                switch (result.data.info.errorInfo.code) {
                  case "auth/email-already-exists":
                    errorMessage = "קיים משתמש עם כתובת מייל זו";
                    break;
                  case "auth/internal-error":
                    errorMessage = "שגיאת התחברות אנא נסה מאוחר יותר";
                    break;
                  case "auth/invalid-display-name":
                    errorMessage = "השם שהוכנס אינו תקין";
                    break;

                  case "auth/invalid-email":
                    errorMessage = "כתובת המייל אינה תיקנה";
                    break;
                  default:
                    errorMessage =
                      "משהו השתבש אנא צור איתנו קשר או נסה מאוחר יותר";
                    break;
                }

                ref.alert = {
                  type: "warning",
                  icon: "mdi-information-outline",
                  text: errorMessage,
                  visable: true,
                };
              }
            })
            .catch(function (error) {
              var code = error.code;
              var message = error.message;
              var details = error.details;
              //Notiflix.Loading.Remove();
              this.$store.state.loading = false;

              console.log(error);
              Notiflix.Notify.Failure("שגיאה ביצירת משתמש");
            })
            .finally((fin) => {
              this.dialog = false;
              this.$store.state.loading = false;
            });
        } else {
          console.log(this.selectedObject);
          if (typeof this.selectedObject.agents == "undefined") {
            this.selectedObject.agents = [];
          }

          await rootDb.collection("Users").doc(ref.selectedObject.uid).set(
            {
              photoURL: ref.selectedObject.photoURL,
              phone: ref.selectedObject.phone,
              role: ref.selectedObject.role,
              meetings: ref.selectedObject.meetings,
              env: ref.selectedObject.env,
              agents: ref.selectedObject.agents,
            },
            { merge: true }
          );


         if(ref.selectedObject.role =="agent"){
           for (const env of this.selectedObject.env) {
            let agentsInfos = (await rootDb.collection("Environments").doc(env).collection("System").doc("CalendarLists").get()).data().agentsInfos
            let found = agentsInfos.find(ag=>{return ag.id == this.selectedObject.uid})
            if(!found){
              agentsInfos.push({
                color:"blue",
                id:this.selectedObject.uid,
                name:this.selectedObject.displayName,
                photoURL:this.selectedObject.photoURL
              })
              await rootDb.collection("Environments").doc(env).collection("System").doc("CalendarLists").update({agentsInfos:agentsInfos})
            }
            await rootDb.collection("Environments").doc(env).collection("Agents").doc(ref.selectedObject.uid).set(
              {
                photoURL: ref.selectedObject.photoURL,
                phone: ref.selectedObject.phone,
                role: ref.selectedObject.role,
                meetings: ref.selectedObject.meetings,
                env: ref.selectedObject.env,
                agents: ref.selectedObject.agents,
              },
              { merge: true }
            );
          }
         }
         ref.getUsers();
          //Notiflix.Loading.Remove();
          this.$store.state.loading = false;
          Notiflix.Notify.Success("המשתמש עודכן בהצלחה");
          this.dialog = false;
          ref.$refs.form.reset();
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    fileChanged(file) {
      var ref = this;
      if (file) {
        console.log(file);
        this.selectedObject.photo = file;
        this.selectedObject.photoURL = URL.createObjectURL(file);
        console.log(this.selectedObject.photoURL);
        this.selectedObject.photoURL.replace("blob:", "");
      } else {
        this.selectedObject.photo = file;
        this.selectedObject.photoURL =
          "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4";
      }
      console.log(this.selectedObject.photoURL);
    },

    async uploadImage() {
      console.log("uploadImage");
      if (
        this.avatar.imageURL !==
        "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4"
      ) {
        var timstamp = Math.floor(new Date() / 1000) + "0";
        var storageRef = firebase.storage().ref();
        var snapshot = await storageRef
          .child("Avatars/" + timstamp + ".png")
          .put(this.avatar.fileObject);
        return snapshot.ref.getDownloadURL();
      } else {
        return "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4";
      }
    },

    async getUsers() {
      this.$store.state.loading = true;

      var ref = this;
      this.rows = [];
      var usersPromises = await rootDb.collection("Users").get();
      usersPromises.forEach((doc) => {
        var data = doc.data();
        data["roleText"] = this.items.filter((obj) => {
          return obj.key == data.role;
        })[0].name;
        let temp = [];
        if (data.hasOwnProperty("env")) {
          data["env"]?.forEach((txt) => {
            temp.push(ref.envs.find((item) => item.key === txt)?.name);
          });
        }

        data["envText"] = temp;

        ref.rows.push(data);
      });
      this.$store.state.loading = false;
    },
    async getAppData() {
      let docs = await rootDb.collectionGroup("App").get();
      let ref = this;
      docs.forEach((doc) => {
        let uid = doc.ref.parent.parent.id;
        let appVersion = doc.data().app;
        let temp = ref.rows.filter((row) => {
          return row.uid == uid;
        });
        if (temp.length > 0) {
          temp[0]["appVersion"] = appVersion;
        }
      });
    },

    //image upload stuff
    toggleShow() {
      this.show = !this.show;
    },

    async cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      this.selectedObject["photoURL"] = await this.storageUrl(imgDataUrl);
      this.avatar = this.selectedObject["photoURL"];
      console.log(this.selectedObject);
      this.$refs.imageUpload.setStep(1);
    },

    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },

    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },

    async storageUrl(imgDataUrl) {
      var timstamp = Math.floor(new Date() / 1000) + "0";
      var storageRef = firebase.storage().ref();
      var snapshot = await storageRef
        .child("Avatars/" + timstamp + ".png")
        .putString(imgDataUrl, "data_url", { contentType: "image/png" });
      return snapshot.ref.getDownloadURL();
    },

    async getAgetnsForInspector() {
      let list = [];
      let agent = await rootDb
        .collection("Users")
        .where("role", "==", "agent")
        .where("meetings", "==", true)
        .get();

      agent.forEach((doc) => {
        list.push({
          color: doc.data().color,
          id: doc.data().uid,
          name: doc.data().displayName,
          photoURL: doc.data().photoURL,
        });
      });

      this.agentsList = list;
    },
  },
  computed: {
    getPhoto() {
      return this.selectedObject.photoURL;
    },
  },
};
</script>

<style>
.cruser:hover {
  cursor: pointer;
}
</style>