const citys = [
  "",
  `אבו ג'ווייעד (שבט)`,
  `אבו גוש`,
  `אבו סנאן`,
  `אבו סריחאן (שבט)`,
  `אבו עבדון (שבט)`,
  `אבו עמאר (שבט)`,
  `אבו עמרה (שבט)`,
  `אבו קורינאת (יישוב)`,
  `אבו קורינאת (שבט)`,
  `אבו רובייעה (שבט)`,
  `אבו רוקייק (שבט)`,
  `אבו תלול`,
  `אבטין`,
  `אבטליון`,
  `אביאל`,
  `אביבים`,
  `אביגדור`,
  `אביחיל`,
  `אביטל`,
  `אביעזר`,
  `אבירים`,
  `אבן יהודה`,
  `אבן מנחם`,
  `אבן ספיר`,
  `אבן שמואל`,
  `אבני איתן`,
  `אבני חפץ`,
  `אבנת`,
  `אבשלום`,
  `אדורה`,
  `אדירים`,
  `אדמית`,
  `אדרת`,
  `אודים`,
  `אודם`,
  `אוהד`,
  `אום אל-פחם`,
  `אום אל-קוטוף`,
  `אום בטין`,
  `אומן`,
  `אומץ`,
  `אופקים`,
  `אור הגנוז`,
  `אור הנר`,
  `אור יהודה`,
  `אור עקיבא`,
  `אורה`,
  `אורון`,
  `אורות`,
  `אורטל`,
  `אורים`,
  `אורנים`,
  `אורנית`,
  `אושה`,
  `אזור`,
  `אזור אילון מ"א 4`,
  `אזור אילון מ"א 52`,
  `אזור אילון של"ש`,
  `אזור אשדוד מ"א 29`,
  `אזור אשדוד מ"א 33`,
  `אזור אשדוד של"ש`,
  `אזור אשקלון מ"א 36`,
  `אזור אשקלון מ"א 37`,
  `אזור באר שבע מ"א 41`,
  `אזור באר שבע מ"א 51`,
  `אזור באר שבע של"ש`,
  `אזור בשור מ"א 38`,
  `אזור בשור מ"א 39`,
  `אזור בשור מ"א 42`,
  `אזור גלילות מ"א 19`,
  `אזור גרר מ"א 39`,
  `אזור גרר מ"א 41`,
  `אזור גרר מ"א 42`,
  `אזור זכרון יעקב מ"א 15`,
  `אזור זכרון יעקב של"ש`,
  `אזור חדרה מ"א 14`,
  `אזור חדרה מ"א 15`,
  `אזור חדרה מ"א 45`,
  `אזור חדרה של"ש`,
  `אזור חולון של"ש`,
  `אזור חיפה מ"א 12`,
  `אזור חיפה של"ש`,
  `אזור חצור מ"א 1`,
  `אזור חצור מ"א 55`,
  `אזור חצור של"ש`,
  `אזור יחיעם מ"א 2`,
  `אזור יחיעם מ"א 4`,
  `אזור יחיעם מ"א 52`,
  `אזור יחיעם מ"א 56`,
  `אזור יחיעם של"ש`,
  `אזור ים המלח מ"א 51`,
  `אזור יקנעם מ"א 13`,
  `אזור יקנעם מ"א 9`,
  `אזור כינרות מ"א 3`,
  `אזור כנרות מ"א 1`,
  `אזור כנרות מ"א 6`,
  `אזור כנרות של"ש`,
  `אזור כרמיאל מ"א 2`,
  `אזור כרמיאל מ"א 56`,
  `אזור כרמיאל של"ש`,
  `אזור לכיש מ"א 34`,
  `אזור לכיש מ"א 35`,
  `אזור לכיש מ"א 41`,
  `אזור לכיש מ"א 50`,
  `אזור מודיעין מ"א 25`,
  `אזור מודיעין מ"א 30`,
  `אזור מודיעין של"ש`,
  `אזור מלאכי מ"א 33`,
  `אזור מלאכי מ"א 34`,
  `אזור מלאכי מ"א 35`,
  `אזור מלאכי מ"א 50`,
  `אזור נהרייה מ"א 4`,
  `אזור נהרייה של"ש`,
  `אזור עכו מ"א 4`,
  `אזור עכו מ"א 56`,
  `אזור עכו של"ש`,
  `אזור פתח תקווה מ"א 20`,
  `אזור פתח תקווה מ"א 25`,
  `אזור פתח תקווה של"ש`,
  `אזור ראשל"צ מ"א 27`,
  `אזור ראשל"צ של"ש`,
  `אזור רחובות מ"א 28`,
  `אזור רחובות מ"א 29`,
  `אזור רחובות מ"א 30`,
  `אזור רחובות מ"א 31`,
  `אזור רחובות מ"א 32`,
  `אזור רחובות של"ש`,
  `אזור רמלה מ"א 25`,
  `אזור רמלה מ"א 30`,
  `אזור רמלה מ"א 40`,
  `אזור רמלה של"ש`,
  `אזור רמת גן של"ש`,
  `אזור שפרעם מ"א 56`,
  `אזור שפרעם מ"א 9`,
  `אזור שפרעם של"ש`,
  `אזור תל אביב של"ש`,
  `אזור תעסוקה משגב(תרדיון)`,
  `אזור תעשיה אכסאל מ"א 9`,
  `אזור תעשייה אכזיב (מילואות)`,
  `אזור תעשייה נעמן (מילואות)`,
  `אחווה`,
  `אחוזם`,
  `אחוזת ברק`,
  `אחיהוד`,
  `אחיטוב`,
  `אחיסמך`,
  `אחיעזר`,
  `אטרש (שבט)`,
  `איבים`,
  `אייל`,
  `איילת השחר`,
  `אילון`,
  `אילון תבור*`,
  `אילות`,
  `אילנייה`,
  `אילת`,
  `אירוס`,
  `איתמר`,
  `איתן`,
  `איתנים`,
  `אכסאל`,
  `אל -עזי`,
  `אל -עריאן`,
  `אל -רום`,
  `אל סייד`,
  `אלומה`,
  `אלומות`,
  `אלון הגליל`,
  `אלון מורה`,
  `אלון שבות`,
  `אלוני אבא`,
  `אלוני הבשן`,
  `אלוני יצחק`,
  `אלונים`,
  `אלי-עד`,
  `אליאב`,
  `אליכין`,
  `אליפז`,
  `אליפלט`,
  `אליקים`,
  `אלישיב`,
  `אלישמע`,
  `אלמגור`,
  `אלמוג`,
  `אלעד`,
  `אלעזר`,
  `אלפי מנשה`,
  `אלקוש`,
  `אלקנה`,
  `אמונים`,
  `אמירים`,
  `אמנון`,
  `אמציה`,
  `אניעם`,
  `אסד (שבט)`,
  `אספר`,
  `אעבלין`,
  `אעצם (שבט)`,
  `אפיניש (שבט)`,
  `אפיק`,
  `אפיקים`,
  `אפק`,
  `אפרת`,
  `ארבל`,
  `ארגמן`,
  `ארז`,
  `אריאל`,
  `ארסוף`,
  `אשבול`,
  `אשבל`,
  `אשדוד`,
  `אשדות יעקב (איחוד)`,
  `אשדות יעקב (מאוחד)`,
  `אשחר`,
  `אשכולות`,
  `אשל הנשיא`,
  `אשלים`,
  `אשקלון`,
  `אשרת`,
  `אשתאול`,
  `באקה אל-גרביה`,
  `באר אורה`,
  `באר גנים`,
  `באר טוביה`,
  `באר יעקב`,
  `באר מילכה`,
  `באר שבע`,
  `בארות יצחק`,
  `בארותיים`,
  `בארי`,
  `בוסתן הגליל`,
  `בועיינה-נוג'ידאת`,
  `בוקעאתא`,
  `בורגתה`,
  `בחן`,
  `בטחה`,
  `בי"ס אזורי מקיף (אשר)`,
  `ביצרון`,
  `ביר אל-מכסור`,
  `ביר הדאג'`,
  `בירייה`,
  `בית אורן`,
  `בית אל`,
  `בית אלעזרי`,
  `בית אלפא`,
  `בית אריה-עופרים`,
  `בית ברל`,
  `בית ג'ן`,
  `בית גוברין`,
  `בית גמליאל`,
  `בית דגן`,
  `בית הגדי`,
  `בית הלוי`,
  `בית הלל`,
  `בית העמק`,
  `בית הערבה`,
  `בית השיטה`,
  `בית זיד`,
  `בית זית`,
  `בית זרע`,
  `בית חולים פוריה`,
  `בית חורון`,
  `בית חירות`,
  `בית חלקיה`,
  `בית חנן`,
  `בית חנניה`,
  `בית חשמונאי`,
  `בית יהושע`,
  `בית יוסף`,
  `בית ינאי`,
  `בית יצחק-שער חפר`,
  `בית לחם הגלילית`,
  `בית מאיר`,
  `בית נחמיה`,
  `בית ניר`,
  `בית נקופה`,
  `בית עובד`,
  `בית עוזיאל`,
  `בית עזרא`,
  `בית עריף`,
  `בית צבי`,
  `בית קמה`,
  `בית קשת`,
  `בית רבן`,
  `בית רימון`,
  `בית שאן`,
  `בית שמש`,
  `בית שערים`,
  `בית שקמה`,
  `ביתן אהרן`,
  `ביתר עילית`,
  `בלפוריה`,
  `בן זכאי`,
  `בן עמי`,
  `בן שמן (כפר נוער)`,
  `בן שמן (מושב)`,
  `בני ברק`,
  `בני דקלים`,
  `בני דרום`,
  `בני דרור`,
  `בני יהודה`,
  `בני נצרים`,
  `בני עטרות`,
  `בני עי"ש`,
  `בני ציון`,
  `בני ראם`,
  `בניה`,
  `בנימינה-גבעת עדה*`,
  `בסמ"ה`,
  `בסמת טבעון`,
  `בענה`,
  `בצרה`,
  `בצת`,
  `בקוע`,
  `בקעות`,
  `בקעת נטופה מ"א 56`,
  `בקעת תירען מ"א 3`,
  `בר-לב`,
  `בר גיורא`,
  `בר יוחאי`,
  `ברוכין`,
  `ברור חיל`,
  `ברוש`,
  `ברכה`,
  `ברכיה`,
  `ברעם`,
  `ברק`,
  `ברקאי`,
  `ברקן`,
  `ברקת`,
  `בת הדר`,
  `בת חן`,
  `בת חפר`,
  `בת ים`,
  `בת עין`,
  `בת שלמה`,
  `בתי זיקוק - קישון`,
  `ג'דיידה-מכר`,
  `ג'ולס`,
  `ג'לג'וליה`,
  `ג'נאביב (שבט)`,
  `ג'סר א-זרקא`,
  `ג'ש (גוש חלב)`,
  `ג'ת`,
  `גאולי תימן`,
  `גאולים`,
  `גאון הירדן מ"א 3`,
  `גאליה`,
  `גבולות`,
  `גבים`,
  `גבע`,
  `גבע בנימין`,
  `גבע כרמל`,
  `גבעולים`,
  `גבעון החדשה`,
  `גבעות בר`,
  `גבעת אבני`,
  `גבעת אלה`,
  `גבעת ברנר`,
  `גבעת השלושה`,
  `גבעת זאב`,
  `גבעת ח"ן`,
  `גבעת חביבה`,
  `גבעת חיים (איחוד)`,
  `גבעת חיים (מאוחד)`,
  `גבעת יואב`,
  `גבעת יערים`,
  `גבעת ישעיהו`,
  `גבעת כ"ח`,
  `גבעת ניל"י`,
  `גבעת עוז`,
  `גבעת שמואל`,
  `גבעת שמש`,
  `גבעת שפירא`,
  `גבעתי`,
  `גבעתיים`,
  `גברעם`,
  `גבת`,
  `גדות`,
  `גדיש`,
  `גדעונה`,
  `גדרה`,
  `גולן דרומי מ"א 71`,
  `גולן צפוני מ"א 71`,
  `גולן תיכון מ"א 71`,
  `גונן`,
  `גורן`,
  `גורנות הגליל`,
  `גזית`,
  `גזר`,
  `גיאה`,
  `גיבתון`,
  `גיזו`,
  `גילון`,
  `גילת`,
  `גינוסר`,
  `גיניגר`,
  `גינתון`,
  `גיתה`,
  `גיתית`,
  `גלאון`,
  `גלגל`,
  `גליל ים`,
  `גליל עליון מז מ"א 1`,
  `גליל עליון מז מ"א 2`,
  `גליל עליון מז מ"א 55`,
  `גליל עליון מז של"ש`,
  `גליל תחתון מז מ"א 2`,
  `גליל תחתון מז מ"א 3`,
  `גליל תחתון מז מ"א 6`,
  `גליל תחתון מז של"ש`,
  `גלעד (אבן יצחק)`,
  `גמ"ל מחוז דרום`,
  `גמזו`,
  `גן הדרום`,
  `גן השומרון`,
  `גן חיים`,
  `גן יאשיה`,
  `גן יבנה`,
  `גן נר`,
  `גן שורק`,
  `גן שלמה`,
  `גן שמואל`,
  `גנות`,
  `גנות הדר`,
  `גני הדר`,
  `גני טל`,
  `גני יוחנן`,
  `גני מודיעין`,
  `גני עם`,
  `גני תקווה`,
  `געש`,
  `געתון`,
  `גפן`,
  `גרופית`,
  `גשור`,
  `גשר`,
  `גשר הזיו`,
  `גת (קיבוץ)`,
  `גת רימון`,
  `דאלית אל-כרמל`,
  `דבורה`,
  `דבורייה`,
  `דבירה`,
  `דברת`,
  `דגניה א'`,
  `דגניה ב'`,
  `דוב"ב`,
  `דולב`,
  `דור`,
  `דורות`,
  `דחי`,
  `דייר אל-אסד`,
  `דייר חנא`,
  `דייר ראפאת`,
  `דימונה`,
  `דישון`,
  `דלייה`,
  `דלתון`,
  `דלתון`,
  `דמיידה`,
  `דן`,
  `דפנה`,
  `דקל`,
  `דרום השרון מ"א 18`,
  `דרום השרון מ"א 20`,
  `דרום השרון של"ש`,
  `דרום יהודה`,
  `דריג'את`,
  `האון`,
  `הבונים`,
  `הגושרים`,
  `הדר עם`,
  `הוד השרון`,
  `הודיות`,
  `הודייה`,
  `הוואשלה (שבט)`,
  `הוזייל (שבט)`,
  `הושעיה`,
  `הזורע`,
  `הזורעים`,
  `החותרים`,
  `היוגב`,
  `הילה`,
  `המעפיל`,
  `המרכז למחקר-נחל שורק`,
  `הסוללים`,
  `העוגן`,
  `הערבה מ"א 51`,
  `הערבה מ"א 53`,
  `הערבה מ"א 54`,
  `הר אדר`,
  `הר אלכסנדר מ"א 14`,
  `הר אלכסנדר מ"א 45`,
  `הר אלכסנדר של"ש`,
  `הר גילה`,
  `הר הנגב הדרומי מ"א 48`,
  `הר הנגב הדרומי מ"א 53`,
  `הר הנגב הדרומי מ"א 54`,
  `הר הנגב הצפוני מ"א 48`,
  `הר הנגב הצפוני מ"א 51`,
  `הר הנגב הצפוני מ"א 53`,
  `הר הנגב הצפוני מ"א 54`,
  `הר עמשא`,
  `הראל`,
  `הרדוף`,
  `הרי יהודה מ"א 26`,
  `הרי יהודה של"ש`,
  `הרי נצרת-תירען`,
  `הרי נצרת-תירען מ"א 9`,
  `הרצלייה`,
  `הררית`,
  `השומרון`,
  `ורד יריחו`,
  `ורדון`,
  `זבארגה (שבט)`,
  `זבדיאל`,
  `זוהר`,
  `זיקים`,
  `זיתן`,
  `זכרון יעקב`,
  `זכריה`,
  `זמר`,
  `זמרת`,
  `זנוח`,
  `זרועה`,
  `זרזיר`,
  `זרחיה`,
  `ח'ואלד`,
  `ח'ואלד (שבט)`,
  `חבצלת השרון`,
  `חבר`,
  `חגור`,
  `חגי`,
  `חגלה`,
  `חד-נס`,
  `חדיד`,
  `חדרה`,
  `חוג'ייראת (ד'הרה) (שבט)`,
  `חולדה`,
  `חולון`,
  `חולית`,
  `חולתה`,
  `חוסן`,
  `חוסנייה`,
  `חוף הכרמל מ"א 15`,
  `חופית`,
  `חוקוק`,
  `חורה`,
  `חורפיש`,
  `חורשים`,
  `חזון`,
  `חיבת ציון`,
  `חיננית`,
  `חיפה`,
  `חירות`,
  `חלוץ`,
  `חלץ`,
  `חמ"ד`,
  `חמאם`,
  `חמדיה`,
  `חמדת`,
  `חמרה`,
  `חניאל`,
  `חניתה`,
  `חנתון`,
  `חספין`,
  `חפץ חיים`,
  `חפצי-בה`,
  `חצב`,
  `חצבה`,
  `חצור-אשדוד`,
  `חצור הגלילית`,
  `חצרים`,
  `חרב לאת`,
  `חרוצים`,
  `חריש`,
  `חרמון מ"א 71`,
  `חרמש`,
  `חרשים`,
  `חשמונאים`,
  `טבריה`,
  `טובא-זנגרייה`,
  `טורעאן`,
  `טייבה`,
  `טייבה (בעמק)`,
  `טירה`,
  `טירת יהודה`,
  `טירת כרמל`,
  `טירת צבי`,
  `טל-אל`,
  `טל שחר`,
  `טללים`,
  `טלמון`,
  `טמרה`,
  `טמרה (יזרעאל)`,
  `טנא`,
  `טפחות`,
  `יאנוח-ג'ת`,
  `יבול`,
  `יבנאל`,
  `יבנה`,
  `יגור`,
  `יגל`,
  `יד בנימין`,
  `יד השמונה`,
  `יד חנה`,
  `יד מרדכי`,
  `יד נתן`,
  `יד רמב"ם`,
  `ידידה`,
  `יהוד`,
  `יהל`,
  `יובל`,
  `יובלים`,
  `יודפת`,
  `יונתן`,
  `יושיביה`,
  `יזרעאל`,
  `יחיעם`,
  `יטבתה`,
  `ייט"ב`,
  `יכיני`,
  `ים המלח - בתי מלון`,
  `ינוב`,
  `ינון`,
  `יסוד המעלה`,
  `יסודות`,
  `יסעור`,
  `יעד`,
  `יעל`,
  `יעף`,
  `יערה`,
  `יערות גבעת המורה מ"א 9`,
  `יפיע`,
  `יפית`,
  `יפעת`,
  `יפתח`,
  `יצהר`,
  `יציץ`,
  `יקום`,
  `יקיר`,
  `יקנעם (מושבה)`,
  `יקנעם עילית`,
  `יראון`,
  `ירדנה`,
  `ירוחם`,
  `ירושלים`,
  `ירחיב`,
  `ירכא`,
  `ירקונה`,
  `ישע`,
  `ישעי`,
  `ישרש`,
  `יתד`,
  `כאבול`,
  `כאוכב אבו אל-היג'א`,
  `כברי`,
  `כדורי`,
  `כדיתה`,
  `כוכב השחר`,
  `כוכב יאיר`,
  `כוכב יעקב`,
  `כוכב מיכאל`,
  `כורזים`,
  `כחל`,
  `כחלה`,
  `כיסופים`,
  `כישור`,
  `כליל`,
  `כלנית`,
  `כמאנה`,
  `כמהין`,
  `כמון`,
  `כנות`,
  `כנף`,
  `כנרת (מושבה)`,
  `כנרת (קבוצה)`,
  `כסיפה`,
  `כסלון`,
  `כסרא-סמיע`,
  `כעביה-טבאש-חג'אג'רה`,
  `כפר אביב`,
  `כפר אדומים`,
  `כפר אוריה`,
  `כפר אחים`,
  `כפר ביאליק`,
  `כפר ביל"ו`,
  `כפר בלום`,
  `כפר בן נון`,
  `כפר ברא`,
  `כפר ברוך`,
  `כפר גדעון`,
  `כפר גלים`,
  `כפר גליקסון`,
  `כפר גלעדי`,
  `כפר דניאל`,
  `כפר האורנים`,
  `כפר החורש`,
  `כפר המכבי`,
  `כפר הנגיד`,
  `כפר הנוער הדתי`,
  `כפר הנשיא`,
  `כפר הס`,
  `כפר הרא"ה`,
  `כפר הרי"ף`,
  `כפר ויתקין`,
  `כפר ורבורג`,
  `כפר ורדים`,
  `כפר זוהרים`,
  `כפר זיתים`,
  `כפר חב"ד`,
  `כפר חושן`,
  `כפר חיטים`,
  `כפר חיים`,
  `כפר חנניה`,
  `כפר חסידים א'`,
  `כפר חסידים ב'`,
  `כפר חרוב`,
  `כפר טרומן`,
  `כפר יאסיף`,
  `כפר ידידיה`,
  `כפר יהושע`,
  `כפר יונה`,
  `כפר יחזקאל`,
  `כפר יעבץ`,
  `כפר כמא`,
  `כפר כנא`,
  `כפר מונש`,
  `כפר מימון`,
  `כפר מל"ל`,
  `כפר מנדא`,
  `כפר מנחם`,
  `כפר מסריק`,
  `כפר מצר`,
  `כפר מרדכי`,
  `כפר נטר`,
  `כפר סאלד`,
  `כפר סבא`,
  `כפר סילבר`,
  `כפר סירקין`,
  `כפר עבודה`,
  `כפר עזה`,
  `כפר עציון`,
  `כפר פינס`,
  `כפר קאסם`,
  `כפר קיש`,
  `כפר קרע`,
  `כפר ראש הנקרה`,
  `כפר רוזנואלד (זרעית)`,
  `כפר רופין`,
  `כפר רות`,
  `כפר שמאי`,
  `כפר שמואל`,
  `כפר שמריהו`,
  `כפר תבור`,
  `כפר תפוח`,
  `כרכום`,
  `כרם בן זמרה`,
  `כרם בן שמן`,
  `כרם יבנה (ישיבה)`,
  `כרם מהר"ל`,
  `כרם שלום`,
  `כרמי יוסף`,
  `כרמי צור`,
  `כרמי קטיף`,
  `כרמיאל`,
  `כרמייה`,
  `כרמים`,
  `כרמל`,
  `לבון`,
  `לביא`,
  `לבנים`,
  `להב`,
  `להבות הבשן`,
  `להבות חביבה`,
  `להבים`,
  `לוד`,
  `לוזית`,
  `לוחמי הגיטאות`,
  `לוטם`,
  `לוטן`,
  `לימן`,
  `לכיש`,
  `לפיד`,
  `לפידות`,
  `לקיה`,
  `מאור`,
  `מאיר שפיה`,
  `מבוא ביתר`,
  `מבוא דותן`,
  `מבוא חורון`,
  `מבוא חמה`,
  `מבוא מודיעים`,
  `מבואות ים`,
  `מבואות יריחו`,
  `מבועים`,
  `מבטחים`,
  `מבקיעים`,
  `מבשרת ציון`,
  `מג'ד אל-כרום`,
  `מג'דל שמס`,
  `מגאר`,
  `מגדים`,
  `מגדל`,
  `מגדל העמק`,
  `מגדל עוז`,
  `מגדל תפן`,
  `מגדלים`,
  `מגידו`,
  `מגל`,
  `מגן`,
  `מגן שאול`,
  `מגשימים`,
  `מדרך עוז`,
  `מדרשת בן גוריון`,
  `מדרשת רופין`,
  `מודיעין-מכבים-רעות*`,
  `מודיעין עילית`,
  `מולדת`,
  `מוצא עילית`,
  `מוקייבלה`,
  `מורן`,
  `מורשת`,
  `מזור`,
  `מזכרת בתיה`,
  `מזרח השרון מ"א 16`,
  `מזרח השרון מ"א 18`,
  `מזרע`,
  `מזרעה`,
  `מחולה`,
  `מחנה הילה*`,
  `מחנה טלי*`,
  `מחנה יהודית*`,
  `מחנה יוכבד*`,
  `מחנה יפה*`,
  `מחנה יתיר`,
  `מחנה מרים*`,
  `מחנה תל נוף*`,
  `מחניים`,
  `מחסיה`,
  `מטולה`,
  `מטע`,
  `מי עמי`,
  `מיטב`,
  `מייסר`,
  `מיצר`,
  `מירב`,
  `מירון`,
  `מישר`,
  `מיתר`,
  `מכורה`,
  `מכחול`,
  `מכמורת`,
  `מכמנים`,
  `מלאה`,
  `מלילות`,
  `מלכייה`,
  `מלכישוע`,
  `מנוחה`,
  `מנוף`,
  `מנות`,
  `מנחמיה`,
  `מנרה`,
  `מנשית זבדה`,
  `מסד`,
  `מסדה`,
  `מסילות`,
  `מסילת ציון`,
  `מסלול`,
  `מסעדה`,
  `מסעודין אל-עזאזמה (שבט)`,
  `מעברות`,
  `מעגלים`,
  `מעגן`,
  `מעגן מיכאל`,
  `מעוז חיים`,
  `מעון`,
  `מעונה`,
  `מעיין ברוך`,
  `מעיין צבי`,
  `מעיליא`,
  `מעלה אדומים`,
  `מעלה אפרים`,
  `מעלה גלבוע`,
  `מעלה גמלא`,
  `מעלה החמישה`,
  `מעלה לבונה`,
  `מעלה מכמש`,
  `מעלה עירון`,
  `מעלה עמוס`,
  `מעלה שומרון`,
  `מעלות-תרשיחא`,
  `מענית`,
  `מערב השרון מ"א 16`,
  `מערב השרון מ"א 18`,
  `מערב השרון מ"א 19`,
  `מעש`,
  `מפלסים`,
  `מפעלי אבשלו"ם`,
  `מפעלי ברקן`,
  `מפעלי גליל עליון`,
  `מפעלי גרנות`,
  `מפעלי העמק (יזרעאל)`,
  `מפעלי חבל מודיעים`,
  `מפעלי חפר`,
  `מפעלי ים המלח(סדום)`,
  `מפעלי כנות`,
  `מפעלי מישור רותם`,
  `מפעלי מעון`,
  `מפעלי נחם הרטוב`,
  `מפעלי צומת מלאכי`,
  `מפעלי צין - ערבה`,
  `מפעלי צמח`,
  `מפעלי שאן`,
  `מצדות יהודה`,
  `מצובה`,
  `מצליח`,
  `מצפה`,
  `מצפה אבי"ב`,
  `מצפה אילן`,
  `מצפה יריחו`,
  `מצפה נטופה`,
  `מצפה רמון`,
  `מצפה שלם`,
  `מצר`,
  `מקווה ישראל`,
  `מרגליות`,
  `מרום גולן`,
  `מרחב עם`,
  `מרחביה (מושב)`,
  `מרחביה (קיבוץ)`,
  `מרכז אזורי כדורי`,
  `מרכז אזורי מרום הגליל`,
  `מרכז אזורי משגב`,
  `מרכז אזורי שוהם`,
  `מרכז כ"ח`,
  `מרכז מיר"ב*`,
  `מרכז שפירא`,
  `משאבי שדה`,
  `משגב דב`,
  `משגב עם`,
  `משהד`,
  `משואה`,
  `משואות יצחק`,
  `משכיות`,
  `משמר איילון`,
  `משמר דוד`,
  `משמר הירדן`,
  `משמר הנגב`,
  `משמר העמק`,
  `משמר השבעה`,
  `משמר השרון`,
  `משמרות`,
  `משמרת`,
  `משען`,
  `מתן`,
  `מתת`,
  `מתתיהו`,
  `נאות גולן`,
  `נאות הכיכר`,
  `נאות חובב`,
  `נאות מרדכי`,
  `נאות סמדר`,
  `נאעורה`,
  `נבטים`,
  `נגבה`,
  `נגוהות`,
  `נהורה`,
  `נהלל`,
  `נהרייה`,
  `נוב`,
  `נוגה`,
  `נווה`,
  `נווה אבות`,
  `נווה אור`,
  `נווה אטי"ב`,
  `נווה אילן`,
  `נווה אילן*`,
  `נווה איתן`,
  `נווה דניאל`,
  `נווה זוהר`,
  `נווה זיו`,
  `נווה חריף`,
  `נווה ים`,
  `נווה ימין`,
  `נווה ירק`,
  `נווה מבטח`,
  `נווה מיכאל`,
  `נווה צוף`,
  `נווה שלום`,
  `נועם`,
  `נוף איילון`,
  `נוף הגליל`,
  `נופים`,
  `נופית`,
  `נופך`,
  `נוקדים`,
  `נורדייה`,
  `נורית`,
  `נחושה`,
  `נחל יפתחאל מ"א 3`,
  `נחל עוז`,
  `נחל תבור מ"א 8`,
  `נחלה`,
  `נחליאל`,
  `נחלים`,
  `נחם`,
  `נחף`,
  `נחשולים`,
  `נחשון`,
  `נחשונים`,
  `נטועה`,
  `נטור`,
  `נטע`,
  `נטעים`,
  `נטף`,
  `ניין`,
  `ניל"י`,
  `ניצן`,
  `ניצן ב'`,
  `ניצנה (קהילת חינוך)`,
  `ניצני סיני`,
  `ניצני עוז`,
  `ניצנים`,
  `ניר אליהו`,
  `ניר בנים`,
  `ניר גלים`,
  `ניר דוד (תל עמל)`,
  `ניר ח"ן`,
  `ניר יפה`,
  `ניר יצחק`,
  `ניר ישראל`,
  `ניר משה`,
  `ניר עוז`,
  `ניר עם`,
  `ניר עציון`,
  `ניר עקיבא`,
  `ניר צבי`,
  `נירים`,
  `נירית`,
  `נמל תעופה בן-גוריון`,
  `נמרוד`,
  `נס הרים`,
  `נס עמים`,
  `נס ציונה`,
  `נעורים`,
  `נעלה`,
  `נעמ"ה`,
  `נען`,
  `נערן`,
  `נפת בית לחם`,
  `נפת בית לחם מ"א 76`,
  `נפת ג'נין  `,
  `נפת ג'נין  מ"א 72`,
  `נפת חברון`,
  `נפת חברון מ"א 78`,
  `נפת טול כרם`,
  `נפת טול כרם  מ"א 72`,
  `נפת ירדן`,
  `נפת ירדן מ"א 74`,
  `נפת ירדן מ"א 75`,
  `נפת ראמאללה `,
  `נפת ראמאללה מ"א  73`,
  `נפת שכם`,
  `נפת שכם מ"א 72`,
  `נצאצרה (שבט)`,
  `נצר חזני`,
  `נצר סרני`,
  `נצרת`,
  `נשר`,
  `נתיב הגדוד`,
  `נתיב הל"ה`,
  `נתיב העשרה`,
  `נתיב השיירה`,
  `נתיבות`,
  `נתניה`,
  `סאג'ור`,
  `סאסא`,
  `סביון*`,
  `סגולה`,
  `סואעד (חמרייה)*`,
  `סואעד (כמאנה) (שבט)`,
  `סולם`,
  `סוסיה`,
  `סופה`,
  `סח'נין`,
  `סייד (שבט)`,
  `סלמה`,
  `סלעית`,
  `סמר`,
  `סנסנה`,
  `סעד`,
  `סעוה`,
  `סער`,
  `ספיר`,
  `סתרייה`,
  `ע'ג'ר`,
  `עבדון`,
  `עברון`,
  `עגור`,
  `עד הלום`,
  `עדי`,
  `עדנים`,
  `עוזה`,
  `עוזייר`,
  `עולש`,
  `עומר`,
  `עופר`,
  `עוצם`,
  `עוקבי (בנו עוקבה) (שבט)`,
  `עזוז`,
  `עזר`,
  `עזריאל`,
  `עזריה`,
  `עזריקם`,
  `עטאוונה (שבט)`,
  `עטרת`,
  `עידן`,
  `עידן הנגב`,
  `עיילבון`,
  `עיינות`,
  `עילוט`,
  `עין איילה`,
  `עין אל-אסד`,
  `עין גב`,
  `עין גדי`,
  `עין דור`,
  `עין הבשור`,
  `עין הוד`,
  `עין החורש`,
  `עין המפרץ`,
  `עין הנצי"ב`,
  `עין העמק`,
  `עין השופט`,
  `עין השלושה`,
  `עין ורד`,
  `עין זיוון`,
  `עין חוד`,
  `עין חצבה`,
  `עין חרוד (איחוד)`,
  `עין חרוד (מאוחד)`,
  `עין יהב`,
  `עין יעקב`,
  `עין כרם-בי"ס חקלאי`,
  `עין כרמל`,
  `עין מאהל`,
  `עין נקובא`,
  `עין עירון`,
  `עין צורים`,
  `עין קנייא`,
  `עין ראפה`,
  `עין שמר`,
  `עין שריד`,
  `עין תמר`,
  `עינת`,
  `עיר אובות`,
  `עכו`,
  `עלומים`,
  `עלי`,
  `עלי זהב`,
  `עלמה`,
  `עלמון`,
  `עמוקה`,
  `עמיחי`,
  `עמינדב`,
  `עמיעד`,
  `עמיעוז`,
  `עמיקם`,
  `עמיר`,
  `עמנואל`,
  `עמק בית שאן מ"א 7`,
  `עמק בית שאן של"ש`,
  `עמק חולה מ"א 1`,
  `עמק חולה מ"א 55`,
  `עמק חולה של"ש`,
  `עמק חפר מזרח מ"א 16`,
  `עמק חרוד מ"א 8`,
  `עמק חרוד של"ש`,
  `עמק יזרעאל מ"א 13`,
  `עמק יזרעאל מ"א 8`,
  `עמק יזרעאל מ"א 9`,
  `עמקה`,
  `ענב`,
  `עספיא`,
  `עפולה`,
  `עפרה`,
  `עץ אפרים`,
  `עצמון שגב`,
  `עראבה`,
  `עראמשה*`,
  `ערב אל נעים`,
  `ערד`,
  `ערוגות`,
  `ערערה`,
  `ערערה-בנגב`,
  `עשרת`,
  `עתלית`,
  `עתניאל`,
  `פארן`,
  `פארק הירדן מ"א 6`,
  `פארק תעשיות ספירים`,
  `פדואל`,
  `פדויים`,
  `פדיה`,
  `פוריידיס`,
  `פורייה - כפר עבודה`,
  `פורייה - נווה עובד`,
  `פורייה עילית`,
  `פורת`,
  `פטיש`,
  `פלך`,
  `פלמחים`,
  `פני חבר`,
  `פסגות`,
  `פסוטה`,
  `פעמי תש"ז`,
  `פצאל`,
  `פקיעין (בוקייעה)`,
  `פקיעין חדשה`,
  `פרדס חנה-כרכור`,
  `פרדסייה`,
  `פרוד`,
  `פרזון`,
  `פרי גן`,
  `פתח תקווה`,
  `פתחיה`,
  `צאלים`,
  `צביה`,
  `צבעון`,
  `צובה`,
  `צוחר`,
  `צופייה`,
  `צופים`,
  `צופית`,
  `צופר`,
  `צוקי ים`,
  `צוקים`,
  `צור הדסה`,
  `צור יצחק`,
  `צור משה`,
  `צור נתן`,
  `צוריאל`,
  `צורית`,
  `ציפורי`,
  `צלפון`,
  `צנדלה`,
  `צפרייה`,
  `צפרירים`,
  `צפת`,
  `צרופה`,
  `צרעה`,
  `קבועה (שבט)`,
  `קבוצת יבנה`,
  `קדומים`,
  `קדימה-צורן`,
  `קדמה`,
  `קדמת צבי`,
  `קדר`,
  `קדרון`,
  `קדרים`,
  `קודייראת א-צאנע (שבט)`,
  `קוואעין (שבט)`,
  `קוממיות`,
  `קורנית`,
  `קטורה`,
  `קיסריה`,
  `קלחים`,
  `קליה`,
  `קלנסווה`,
  `קלע`,
  `קציר`,
  `קצר א-סר`,
  `קצרין`,
  `קריית אונו`,
  `קריית ארבע`,
  `קריית אתא`,
  `קריית ביאליק`,
  `קריית גת`,
  `קריית חינוך מרחבים*`,
  `קריית טבעון`,
  `קריית ים`,
  `קריית יערים`,
  `קריית יערים (מוסד)`,
  `קריית מוצקין`,
  `קריית מלאכי`,
  `קריית נטפים`,
  `קריית ענבים`,
  `קריית עקרון`,
  `קריית שלמה`,
  `קריית שמונה`,
  `קרית חינוך עזתה`,
  `קרית תעופה`,
  `קרני שומרון`,
  `קשת`,
  `ראמה`,
  `ראס אל-עין`,
  `ראס עלי`,
  `ראש העין`,
  `ראש פינה`,
  `ראש צורים`,
  `ראשון לציון`,
  `רבבה`,
  `רבדים`,
  `רביבים`,
  `רביד`,
  `רגבה`,
  `רגבים`,
  `רהט`,
  `רווחה`,
  `רוויה`,
  `רוח מדבר`,
  `רוחמה`,
  `רומאנה`,
  `רומת הייב`,
  `רועי`,
  `רותם`,
  `רחוב`,
  `רחובות`,
  `רחלים`,
  `ריחאנייה`,
  `ריחן`,
  `ריינה`,
  `רימונים`,
  `רינתיה`,
  `רכסים`,
  `רם-און`,
  `רמות`,
  `רמות השבים`,
  `רמות מאיר`,
  `רמות מנשה`,
  `רמות נפתלי`,
  `רמלה`,
  `רמת גן`,
  `רמת דוד`,
  `רמת הכובש`,
  `רמת השופט`,
  `רמת השרון`,
  `רמת יוחנן`,
  `רמת ישי`,
  `רמת כוכב מ"א 7`,
  `רמת כוכב מ"א 8`,
  `רמת כוכב מ"א 9`,
  `רמת כוכב של"ש`,
  `רמת מגשימים`,
  `רמת מנשה מ"א 13`,
  `רמת מנשה של"ש`,
  `רמת צבי`,
  `רמת רזיאל`,
  `רמת רחל`,
  `רנן`,
  `רעים`,
  `רעננה`,
  `רקפת`,
  `רשפון`,
  `רשפים`,
  `רתמים`,
  `שאר ישוב`,
  `שבי דרום`,
  `שבי ציון`,
  `שבי שומרון`,
  `שבלי - אום אל-גנם`,
  `שגב-שלום`,
  `שדה אילן`,
  `שדה אליהו`,
  `שדה אליעזר`,
  `שדה בוקר`,
  `שדה דוד`,
  `שדה ורבורג`,
  `שדה יואב`,
  `שדה יעקב`,
  `שדה יצחק`,
  `שדה משה`,
  `שדה נחום`,
  `שדה נחמיה`,
  `שדה ניצן`,
  `שדה עוזיהו`,
  `שדה צבי`,
  `שדות ים`,
  `שדות מיכה`,
  `שדי אברהם`,
  `שדי חמד`,
  `שדי תרומות`,
  `שדמה`,
  `שדמות דבורה`,
  `שדמות מחולה`,
  `שדרות`,
  `שואבה`,
  `שובה`,
  `שובל`,
  `שוהם`,
  `שומרה`,
  `שומרייה`,
  `שוקדה`,
  `שורש`,
  `שורשים`,
  `שושנת העמקים`,
  `שזור`,
  `שחר`,
  `שחרות`,
  `שיבולים`,
  `שיטים`,
  `שייח' דנון`,
  `שילה`,
  `שילת`,
  `שכניה`,
  `שלווה`,
  `שלווה במדבר`,
  `שלוחות`,
  `שלומי`,
  `שלומית`,
  `שלומציון`,
  `שמיר`,
  `שמעה`,
  `שמרת`,
  `שמשית`,
  `שני`,
  `שניר`,
  `שעב`,
  `שעל`,
  `שעלבים`,
  `שער אפרים`,
  `שער הגולן`,
  `שער העמקים`,
  `שער מנשה`,
  `שערי תקווה`,
  `שפיים`,
  `שפיר`,
  `שפלת יהודה מ"א 26`,
  `שפלת יהודה של"ש`,
  `שפר`,
  `שפרעם`,
  `שקד`,
  `שקף`,
  `שרונה`,
  `שריגים (לי-און)`,
  `שריד`,
  `שרשרת`,
  `שתולה`,
  `שתולים`,
  `תאשור`,
  `תדהר`,
  `תובל`,
  `תומר`,
  `תושייה`,
  `תימורים`,
  `תירוש`,
  `תל אביב -יפו`,
  `תל חי (מכללה)`,
  `תל יוסף`,
  `תל יצחק`,
  `תל מונד`,
  `תל עדשים`,
  `תל קציר`,
  `תל שבע`,
  `תל תאומים`,
  `תלם`,
  `תלמי אליהו`,
  `תלמי אלעזר`,
  `תלמי ביל"ו`,
  `תלמי יוסף`,
  `תלמי יחיאל`,
  `תלמי יפה`,
  `תלמים`,
  `תמרת`,
  `תנובות`,
  `תעוז`,
  `תעשיון בינימין*`,
  `תעשיון השרון`,
  `תעשיון חבל יבנה`,
  `תעשיון חצב*`,
  `תעשיון מבצע*`,
  `תעשיון מיתרים`,
  `תעשיון צריפין`,
  `תעשיון ראם*`,
  `תעשיון שח"ק`,
  `תעשיות גליל תחתון`,
  `תפרח`,
  `תקומה`,
  `תקוע`,
  `תראבין א-צאנע (שבט)`,
  `תרבין א-צאנע (יישוב)*`,
  `תרום`,
  " בנימינה",
  " ראש העין",
  "אבן יהודה",
  "אבן-יהודה",
  "אור יהודה",
  "אורנית",
  "אורנית ",
  "אזור",
  "אשדוד",
  "אשדוד          ",
  "אשקלון",
  "באר יעקב",
  "באר שבע",
  "בני ברק",
  "בת ים",
  "גבעת שמואל     ",
  "גבעתיים",
  "גני תקווה",
  "הוד השרון",
  "הרצליה",
  "השרון",
  "זכרון יעקב",
  "חדרה",
  "חדרה וישובי עמק חפר",
  "חולון",
  "חיפה",
  "יבנה",
  "יהוד-מונוסון",
  "ירושלים",
  "ירושלים        ",
  "כפר נטר",
  "כפר סבא",
  "כפר תבור",
  "לוד",
  "מודיעין",
  "מזכרת בתיה",
  "מעלות-תרשיחא",
  "מתן",
  "נהרייה",
  "נס ציונה",
  "נתניה",
  "עפולה",
  "עפולה - עמק יזרעאל",
  "פתח תקוה",
  "פתח תקווה",
  "צרופה",
  "קריית אתא",
  "קריית טבעון",
  "קרית אונו",
  "ראש העין",
  "ראשון לציון",
  'ראשל"צ והסביבה',
  "רחובות",
  "רמלה",
  "רמת גן",
  "רמת גן - גבעתיים",
  "רמת השרון",
  "רמת השרון - הרצליה",
  "רמת- השרון",
  "רעננה",
  "שוהם",
  "תל אביב",
  "תל אביב - יפו",
  "תל אביב -יפו",
  "תל אביב יפו",
  "תל אביב-יפו",
  "תל מונד",
  "תל-אביב יפו",
  "חיפה והקריות",
  "עיריית הרצליה",
  "נהריה",
];

module.exports = {
  cytis: citys,
};
