<template>
  <div id="managerDash" style="padding: 1rem">
    <v-row style="padding: 0; margin: 0" align="center" justify="end">
      <v-spacer></v-spacer>
      <div style="width: 300px; margin-left: 10px">
      
        <v-autocomplete
          v-model="selctedSources"
          :items="sourcesList"
          label="מקור"
          item-text="name"
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllSources" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>

          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              dense
              :style="windowWidth > 500 ? 'margin: 2px; font-size: calc(1vw - 0.4em)': 'margin: 2px; font-size: 12px'"
            >
              <span>{{
                sourcesList.length === selctedSources.length
                  ? "כל הפריטים"
                  : item
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                sourcesList.length === selctedSources.length
                  ? ""
                  : "(+" + (selctedSources.length - 1) + "פריטים)"
              }}</span
            >
          </template>
          <v-row
            slot="append-item"
            align="center"
            justify="center"
            style="padding-top: 5px"
          >
            <v-btn
              small
              style="margin: 0.8rem"
              width="80%"
              dense
              outlined
              color="blue"
              @click="updateDashboard"
              >רענן</v-btn
            >
          </v-row>
        </v-autocomplete>
      </div>
      <div style="width: 300px; margin-left: 10px">
        <v-autocomplete
          v-model="selctedAgents"
          :items="$store.state.userObj.userDoc.data().agents"
          label="שם סוכן"
          item-text="name"
          return-object
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllAgent" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              dense
              :style="windowWidth > 500 ? 'margin: 2px; font-size: calc(1vw - 0.4em)': 'margin: 2px; font-size: 12px'"
            >
              <span>{{
                selctedAgents.length ===
                $store.state.userObj.userDoc.data().agents.length
                  ? "כל הפריטים"
                  : item.name
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedAgents.length ===
                $store.state.userObj.userDoc.data().agents.length
                  ? ""
                  : "(+" + (selctedAgents.length - 1) + "פריטים)"
              }}</span
            >
          </template>
          <v-row
            slot="append-item"
            align="center"
            justify="center"
            style="padding-top: 5px"
          >
            <v-btn
              small
              style="margin: 0.8rem"
              width="80%"
              dense
              outlined
              color="blue"
              @click="updateDashboard"
              >רענן</v-btn
            >
          </v-row>
        </v-autocomplete>
      </div>

      <div style="width: 300px; margin-left: 10px">
        <v-autocomplete
          v-model="selctedStatuss"
          :items="listsDocs.statusList"
          label="סטאטוס"
          item-text="text"
          item.value="id"
          multiple
          clearable
          return-object
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllStatuss" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              dense
              :style="windowWidth > 500 ? 'margin: 2px; font-size: calc(1vw - 0.4em)': 'margin: 2px; font-size: 12px'"
            >
              <span>{{
                selctedStatuss.length === listsDocs.statusList.length
                  ? "כל הפריטים"
                  : item.text
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedStatuss.length === listsDocs.statusList.length
                  ? ""
                  : "(+" + (selctedStatuss.length - 1) + "פריטים)"
              }}</span
            >
          </template>
          <v-row
            slot="append-item"
            align="center"
            justify="center"
            style="padding-top: 5px"
          >
            <v-btn
              small
              style="margin: 0.8rem"
              width="80%"
              dense
              outlined
              color="blue"
              @click="updateDashboard"
              >רענן</v-btn
            >
          </v-row>
        </v-autocomplete>
      </div>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        desne
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 300px;">
            <v-text-field
              dense
              v-model="dates"
              label="טווח  תאריכים"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </div>
        </template>
        <v-date-picker
          color="#0277BD"
          v-model="dates"
          locale="he"
          range
          no-title
          scrollable
          @change="sortDats"
        >
          <v-spacer></v-spacer>
          <v-btn text color="#0277BD" @click="$refs.menu.save(date)"
            >סינון</v-btn
          >
        </v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-card>
          <v-card-title>לפי סטאטוס</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="statusChartKey"
            :options="statusChart.chartOptions"
            :series="statusChart.series"
            :width="windowWidth < 500 ? 300 : windowWidth / 5.5"
            :height="windowWidth < 500 ? 300 : windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initStatusChart"
            >
              <v-radio label="הכל" value="all"></v-radio>
              <v-radio label="בתהליך" value="progress"></v-radio>
              <v-radio label="הסתיים" value="done"></v-radio>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card>
          <v-card-title>לפי רלוונטיות</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="relleventChartKey"
            :options="relleventChart.chartOptions"
            :series="relleventChart.series"
            :width="windowWidth < 500 ? 300 : windowWidth / 5.5"
            :height="windowWidth < 500 ? 300 : windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initrelleventChart"
            >
              <div style="color: white">----</div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card>
          <v-card-title>לפי סיווג</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="rankChartKey"
            :options="rankChart.chartOptions"
            :series="rankChart.series"
            :width="windowWidth < 500 ? 300 : windowWidth / 5.5"
            :height="windowWidth < 500 ? 300 : windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initrankChart"
            >
              <div style="color: white">----</div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card>
          <v-card-title>אחוזי סגירה</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="closedChartKey"
            :options="closedChart.chartOptions"
            :series="closedChart.series"
            :width="windowWidth < 500 ? 300 : windowWidth / 5.5"
            :height="windowWidth < 500 ? 300 : windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initClosedChart"
            >
              <div style="color: white">----</div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-card class="agnetData">
        <v-toolbar dark color="#607D8B" elevation="0">
          <v-col
            v-for="item in colsList"
            :key="item.key"
            style="text-align: center"
            v-if="item.basic || windowWidth > 500"
          >
            <v-btn text style="margin-left: 5px" @click="sortBy(item.key)">

              <div>{{ sortMap[item.key].text }}</div>
                            <v-icon v-if="sortMap[item.key].state">mdi-arrow-up</v-icon>
              <v-icon v-else>mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
          <v-col style="text-align: center">פעולות</v-col>
        </v-toolbar>
        <v-list style="height:240px;overflow-y:auto;padding">
          <v-list-item v-for="item in agentsDataList" :key="item.id">
            <v-list-item-content>
              <v-row>
                <v-col style="text-align: center">
                  <v-row no-gutters justify="start" align="center">
                    <v-col v-if="windowWidth>500"
>
                      <!-- <v-avatar size="40" color="gray">
                        <img :src="agentsPhotosMap[item.id]" />
                      </v-avatar> -->
                      <user-avatar
                        style="margin-right: 15px"
                        :size="40"
                        :user="getAgentObj(item.id)"
                      ></user-avatar>
                    </v-col>
                    <v-col v-if="windowWidth>500">
                      {{ item.name }}
                    </v-col>
                    <div v-else  style="padding: 1rem;">{{ item.name }}</div>
                  </v-row>
                </v-col>
                <v-col
                  v-if="windowWidth > 500"
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.meethings }}</v-col
                >
                <v-col
                  v-else
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-right:1rem
                  "
                  >{{ item.meethings }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="item.basic || windowWidth > 500"
                  >{{ item.strong }}</v-col
                >
                <v-col
                  v-if="item.basic || windowWidth > 500"
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.service }}</v-col
                >
                <v-col
                  v-if="item.basic || windowWidth > 500"
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.normal }}</v-col
                >
                <v-col
                  v-if="item.basic || windowWidth > 500"
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.inProgress }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="item.basic || windowWidth > 500"
                  >{{ item.done }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="item.basic || windowWidth > 500"
                  >{{ item.relevant }}</v-col
                >
                <v-col
                  v-if="windowWidth > 500"
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.closed }}</v-col
                >
                <v-col
                  v-else
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right:1rem;
                  "
                  >{{ item.closed }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-row no-gutters justify="space-around">
                    <v-btn
                      small
                      color="blue-grey"
                      dark
                      style="margin: 2px"
                      @click="selectOnlyThisAgnet(item.id)"
                      >סנן</v-btn
                    >
                    <v-btn
                      small
                      color="blue"
                      dark
                      style="margin: 2px"
                      @click="showComments(item.id)"
                      >פירוט</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>

    <v-card height="100" width="0"></v-card>

    <v-dialog v-model="dialog" persistent max-width="90vw">
      <v-card min-height="50vh">
        <v-card-title class="text-h5">
          פירוט פגישות לסוכן
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              :cols="windowWidth < 500 ? '12' : '6'"
              :style="windowWidth < 500 ? 'font-size: 11px' : ''"
            >
              פגישות
              <v-card
                elevation="0"
                style="padding: 0.5rem; overflow-y: auto; overflow-x: hidden"
                height="40vh"
              >
                <v-row
                  no-gutters
                  justify="space-around"
                  align="center"
                  style="text-align: center"
                >
                  <v-col cols="2">שם</v-col>
                  <v-col cols="2">תאריך</v-col>
                  <v-col cols="2">עיר</v-col>
                  <v-col cols="2">סיווג</v-col>
                  <v-col cols="2">רלוונטיות</v-col>
                  <v-col cols="2">סטאטוס</v-col>
                </v-row>
                <v-card
                  v-for="meeting in MeetingOfSelectedAgent"
                  style="margin-top: 0.5rem; padding: 5px"
                  @click="selectMeeting(meeting)"
                >
                  <v-row
                    no-gutters
                    justify="space-around"
                    align="center"
                    style="text-align: center"
                  >
                    <v-col cols="2" style="font-weight: bold">{{
                      meeting.name
                    }}</v-col>
                    <v-col cols="2">{{
                      timeStampTOdate(meeting.meetingDate)
                    }}</v-col>
                    <v-col cols="2">{{ meeting.city }}</v-col>
                    <v-col cols="2">{{ meeting.rank }}</v-col>
                    <v-col cols="1">
                      <v-chip
                        small
                        dark
                        :color="meeting.relevant == 'כן' ? 'green' : 'red'"
                        >{{ meeting.relevant }}</v-chip
                      >
                    </v-col>
                    <v-col cols="2">
                      <v-chip
                        small
                        dark
                        :color="getStatusColor(meeting.status)"
                        >{{ meeting.statusText }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <div style="width: 200px; margin-top: 1rem">
                    <v-text-field
                      type="number"
                      disabled
                      v-model="meeting['closingAmout']"
                      label="סכום סגירה"
                      prepend-icon="mdi-cash"
                      required
                      dense
                    ></v-text-field>
                  </div>
                </v-card>
              </v-card>
            </v-col>
            <v-col :style="windowWidth < 500 ? 'font-size: 12px' : ''">
              תגובות
              <v-card
                elevation="5"
                style="padding: 0.5rem; overflow-y: auto; overflow-x: hidden"
                height="40vh"
              >
                <v-row
                  no-gutters
                  v-for="comment in selectedMeeting.comments"
                  :key="comment"
                >
                  <v-card
                    style="margin: 0.5rem; padding: 5px"
                    elevation="0"
                    color="light-blue lighten-5"
                    width="100%"
                  >
                    <v-col>
                      <div style="font-weight: bold; color: #0277bd">
                        {{
                          comment.displayName +
                          " " +
                          timeStampTOdate(comment.time) +
                          ":"
                        }}
                      </div>
                      {{ comment.text }}
                    </v-col>
                  </v-card>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { db, rootDb } from "../firebase";
import moment from "moment";
import UserAvatar from "../components/UserAvatar.vue";

export default {
  components: {
    apexchart: VueApexCharts,
    UserAvatar,
  },
  data: () => ({
    colors: {
      11: "green",
      10: "#01579B",
      7: "grey",
      3: "black",
      4: "orange",
      9: "#01579B",
    },
    dialog: false,
    selectedAgentID: "",
    commentLoading: false,
    listsDocs: [],
    selctedAgents: [],
    selctedStatuss: [],
    selctedSources: [],
    MeetingOfSelectedAgent: [],
    selectedMeeting: {},
    agentsData: {},
    agentsDataList: [],
    representatives: [],
    bounsTarget: 0,
    calendarLists: {},
    sourcesList: [],
    dates: [],
    menu: false,
    meethings: [],
    statusChartKey: 100,
    rankChartKey: 1000,
    //charts
    chartOptionsRadio: "all",
    statusChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },

    relleventChart: {
      series: [],
      chartOptions: {
        labels: ["רלוונטי", "לא רלוונטי", "טרם נקבע"],
        colors: ["#26A69A", "#EF5350", "#455A64"],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },
    rankChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },
    closedChart: {
      series: [],
      chartOptions: {
        labels: ["נסגר", "לא נסגר"],
        colors: ["#4CAF50", "#B71C1C"],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },

    cols: [
      "name",
      "meethings",
      "strong",
      "service",
      "normal",
      "inProgress",
      "done",
      "relevant",
      "closed",
    ],
    colsList: [
      { basic: true, state: false, text: "סוכן", key: "name" },
      { basic: true, state: false, text: "פגישות", key: "meethings" },
      { basic: false, state: false, text: "חזקות", key: "strong" },
      { basic: false, state: false, text: "שירות", key: "service" },
      { basic: false, state: false, text: "רגילות", key: "normal" },
      { basic: false, state: false, text: "בתהליך", key: "inProgress" },
      { basic: false, state: false, text: "הסתיימו", key: "done" },
      { basic: false, state: false, text: "רלוונטיות", key: "relevant" },
      { basic: true, state: false, text: "נסגרו", key: "closed" },
    ],

    sortMap: {
      name: { state: false, text: "סוכן" },
      meethings: { state: false, text: "פגישות" },
      strong: { state: false, text: "חזקות" },
      service: { state: false, text: "שירות" },
      normal: { state: false, text: "רגילות" },
      inProgress: { state: false, text: "בתהליך" },
      done: { state: false, text: "הסתיימו" },
      relevant: { state: false, text: "רלוונטיות" },
      closed: { state: false, text: "נסגרו" },
    },

    agentsPhotosMap: {},
  }),
  methods: {
    getStatusColor(status) {
      if (this.colors[status]) {
        return this.colors[status];
      }
      return "grey";
    },
    timeStampTOdate(timestamp) {
      if (typeof timestamp == "undefined") {
        return "";
      } else if (typeof timestamp.getMonth === "function") {
        return moment(timestamp).format(" DD/MM/YYYY HH:mm");
      }

      return moment(timestamp.toDate()).format("DD/MM/YYYY HH:mm");
    },
    selectMeeting(meeting) {
      this.selectedMeeting = {};
      this.selectedMeeting = meeting;
    },
    async showComments(agentID) {
      this.MeetingOfSelectedAgent = [];
      this.selectedAgentID = "";
      this.selectedAgentID = agentID;
      this.MeetingOfSelectedAgent = this.agentsData[agentID].meetings;
      this.dialog = true;
    },
    getAgentObj(id) {
      let results = this.$store.state.userObj.userDoc
        .data()
        .agents.filter((obj) => {
          return obj.id == id;
        });
      return results[0];
    },

    initStatusChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
        ref.statusChart.chartOptions.labels.push(status.text),
          ref.statusChart.chartOptions.colors.push(status.color);
      });

      this.meethings.forEach((call) => {
        if (this.chartOptionsRadio == "progress") {
          if (
            call.statusText == "פגישה" ||
            call.statusText == "המשך טיפול סוכן" ||
            call.statusText == "דחיה"
          ) {
            statusMap[call.statusText]++;
          }
        } else if (this.chartOptionsRadio == "done") {
          if (
            call.statusText == "לא רלוונטי" ||
            call.statusText == "סגירה" ||
            call.statusText == "חידוש שיחה"
          ) {
            statusMap[call.statusText]++;
          }
        } else if (
          call.statusText == "פגישה" ||
          call.statusText == "המשך טיפול סוכן" ||
          call.statusText == "דחיה" ||
          call.statusText == "לא רלוונטי" ||
          call.statusText == "סגירה" ||
          call.statusText == "חידוש שיחה"
        ) {
          statusMap[call.statusText]++;
        } else {
          statusMap[call.statusText]++;
        }
      });

      ref.statusChart.series = [];

      for (const status in statusMap) {
        ref.statusChart.series.push(statusMap[status]);
      }

      this.statusChartKey++;
    },

    initrelleventChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      var relevant = 0;
      var notRelevant = 0;
      var notDetermend = 0;

      // this.listsDocs.statusList.forEach(status => {
      //     statusMap[status.text] = 0;
      //     ref.statusChart.chartOptions.labels.push(status.text),
      //     ref.statusChart.chartOptions.colors.push(status.color)
      // });

      this.meethings.forEach((meething) => {
        if (meething.relevant == "כן") {
          relevant++;
        } else if (meething.relevant == "לא") {
          notRelevant++;
        } else {
          notDetermend++;
        }
      });

      ref.relleventChart.series = [relevant, notRelevant, notDetermend];

      this.statusChartKey++;
    },

    initrankChart() {
      var series = [];
      var ref = this;
      var rankMap = {};
      ref.rankChart.chartOptions.labels = [];
      ref.rankChart.chartOptions.colors = [];
      ref.rankChart.series = [];
      this.calendarLists.meetingRank.forEach((rank) => {
        ref.rankChart.chartOptions.labels.push(rank.text);
        if (rank.background == "transparent") {
          rank.background = "#B0BEC5";
        }
        ref.rankChart.chartOptions.colors.push(rank.background);
        rankMap[rank.text] = 0;
      });

      this.meethings.forEach((meething) => {
        rankMap[meething.rank]++;
      });

      for (const key in rankMap) {
        ref.rankChart.series.push(rankMap[key]);
      }

      this.rankChartKey++;
    },

    initClosedChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      var closed = 0;
      var notClosed = 0;

      // this.listsDocs.statusList.forEach(status => {
      //     statusMap[status.text] = 0;
      //     ref.statusChart.chartOptions.labels.push(status.text),
      //     ref.statusChart.chartOptions.colors.push(status.color)
      // });

      this.meethings.forEach((meething) => {
        if (meething.status == 11) {
          closed++;
        } else {
          notClosed++;
        }
      });

      ref.closedChart.series = [closed, notClosed];

      this.$forceUpdate();
    },

    getStatusInitMap() {
      var statusMap = {};
      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
      });
      return statusMap;
    },

    sortDats() {
      this.dates.sort();
      this.intiSources();
      this.updateDashboard();
    },
    async updateDashboard() {
      //Notiflix.Loading.Pulse('מעדכן נתונים');
      this.$store.state.loading = true;

      var ref = this;
      this.meethings = [];
      this.bonusTarget();

      var agents = {};

      ref.representatives = [];

      var listsDocs = await db.collection("System").doc("lists").get();
      var from = new Date(this.dates[0]);
      var to = new Date(this.dates[1]);
      // var meethingsPromises = await db
      //   .collection("Meetings")
      //   .where("meetingDate", ">=", from)
      //   .where("meetingDate", "<=", to)
      //   .get();
      let mokedMeetings = {};
      for (
        var i = 0;
        i <= this.$store.state.userObj.userDoc.data().env.length;
        i++
      ) {
        let meethingsPromises = await rootDb
          .collection("Environments")
          .doc(this.$store.state.userObj.userDoc.data().env[i])
          .collection("Meetings")
          .where("meetingDate", ">=", from)
          .where("meetingDate", "<=", to)
          .get();

        meethingsPromises.forEach((doc) => {
          if (
            ref.findeAgent(doc.data().agent) &&
            ref.findeStatus(doc.data().status)
          ) {
            mokedMeetings[doc.id] = doc.data();
          }
        });
      }

      let meethingsPromises = await rootDb
        .collectionGroup("AgentMeetings")
        .where("meetingDate", ">=", from)
        .where("meetingDate", "<=", to)
        .get();

      meethingsPromises.forEach((doc) => {
        if (!doc.ref.path.includes("Environments")) {
          if (
            ref.findeAgent(doc.data().agent) &&
            ref.findeStatus(doc.data().status) &&
            ref.findeSource(doc.data().source)
          ) {
            let data = doc.data();
            let id = doc.id;
            if (mokedMeetings.hasOwnProperty(id)) {
              data.status = mokedMeetings[id].status;
              data.statusText = mokedMeetings[id].statusText;
              data.relevant = mokedMeetings[id].relevant;
            }
            ref.meethings.push(data);
          }
        }
      });

      var index = 0;

      this.listsDocs = listsDocs.data();

      this.initStatusChart();
      this.initrelleventChart();
      this.initrankChart();
      this.initClosedChart();
      this.updateTable();

      this.$store.state.loading = false;
    },
    async intiSources() {
      let list = [];
      var from = new Date(this.dates[0]);
      var to = new Date(this.dates[1]);
      let mokedMeetings = {};
      for (
        var i = 0;
        i <= this.$store.state.userObj.userDoc.data().env.length;
        i++
      ) {
        let meethingsPromises = await rootDb
          .collection("Environments")
          .doc(this.$store.state.userObj.userDoc.data().env[i])
          .collection("Meetings")
          .where("meetingDate", ">=", from)
          .where("meetingDate", "<=", to)
          .get();

        meethingsPromises.forEach((doc) => {
          if (!list.includes(doc.data().source)) {
            if (this.findeAgent(doc.data().agent)) {
              list.push(doc.data().source);
            }
          }
        });
        this.sourcesList = list;
        this.selctedSources = list;
      }
    },

    bonusTarget() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var count = 0;
      while (date1 <= date2) {
        if (date1.getDay() < 5) {
          count++;
        }
        date1.setDate(date1.getDate() + 1);
      }
      this.bounsTarget = Math.round(count * 2.045);
    },

    findeAgent(id) {
      var flag = false;
      for (var i = 0; i < this.selctedAgents.length; i++) {
        if (this.selctedAgents[i].id == id) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    findeStatus(id) {
      var flag = false;
      for (var i = 0; i < this.selctedStatuss.length; i++) {
        if (this.selctedStatuss[i].id == id) {
          flag = true;
          break;
        }
      }
      return flag;
    },

    findeSource(source) {
      return this.selctedSources.includes(source);
    },

    selectAllAgent() {
      this.selctedAgents = this.$store.state.userObj.userDoc.data().agents;
      // this.selctedAgents.push({
      //   color: "blue",
      //   id: "0000",
      //   name: "לא משוייך",
      //   photoURL: "",
      // });
      this.updateDashboard();
    },
    selectAllStatuss() {
      this.selctedStatuss = this.listsDocs.statusList;
      this.updateDashboard();
    },
    selectAllSources() {
      this.selctedSources = this.sourcesList;
      this.updateDashboard();
    },

    updateTable() {
      this.agentsData = {};
      this.meethings.forEach((doc) => {
        this.agentsData[doc.agent] = {
          name: doc.category,
          id: doc.agent,
          meethings: 0,
          strong: 0,
          service: 0,
          normal: 0,
          inProgress: 0,
          done: 0,
          relevant: 0,
          closed: 0,
          meetings: [],
        };
      });

      this.meethings.forEach((doc) => {
        this.agentsData[doc.agent].meethings++;
        doc.rank == "פגישה חזקה"
          ? this.agentsData[doc.agent].strong++
          : () => {};
        doc.rank == "פגישת שירות"
          ? this.agentsData[doc.agent].service++
          : () => {};
        doc.rank == "פגישה רגילה"
          ? this.agentsData[doc.agent].normal++
          : () => {};

        if (
          doc.statusText == "פגישה" ||
          doc.statusText == "המשך טיפול סוכן" ||
          doc.statusText == "דחיה"
        ) {
          this.agentsData[doc.agent].inProgress++;
        } else if (
          doc.statusText == "לא רלוונטי" ||
          doc.statusText == "סגירה"
        ) {
          this.agentsData[doc.agent].done++;
        }

        doc.relevant == "כן" ? this.agentsData[doc.agent].relevant++ : () => {};
        doc.statusText == "סגירה"
          ? this.agentsData[doc.agent].closed++
          : () => {};

        this.agentsData[doc.agent].meetings.push(doc);
      });

      this.agentsDataListUpdate();
    },
    selectOnlyThisAgnet(id) {
      var list = this.selctedAgents.filter((agent) => {
        return agent.id == id;
      });
      this.selctedAgents = list;
      this.updateDashboard();
    },
    sortBy(field) {
      this.sortMap[field].state = !this.sortMap[field].state;
      this.agentsDataList.sort((a, b) =>
        a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
      );
      this.sortMap[field].state ? this.agentsDataList.reverse() : () => {};
    },

    agentsDataListUpdate() {
      var list = [];
      for (const key in this.agentsData) {
        list.push(this.agentsData[key]);
      }
      this.agentsDataList = list;
    },

    careateAgentPhotosMap() {
      var ref = this;
      this.$store.state.userObj.userDoc.data().agents.forEach((agent) => {
        ref.agentsPhotosMap[agent.id] = agent.photoURL;
      });
    },
  },
  computed: {},
  async created() {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

    const startOfYeserday = moment()
      .startOf("day")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const endOfYeserday = moment()
      .endOf("day")
      .subtract(0, "day")
      .format("YYYY-MM-DD");

    this.dates = [startOfMonth, endOfMonth];

    if (this.$route.query.mode == "y") {
      this.dates = [startOfYeserday, endOfYeserday];
    }

    this.calendarLists = await db
      .collection("System")
      .doc("CalendarLists")
      .get();

    var listsDocs = await db.collection("System").doc("lists").get();
    // let values = await db.collection("System").doc("values").get();
    // this.sourcesList = values.data().sources.sort();
    // this.selctedSources = [...this.sourcesList];
    this.selctedStatuss = listsDocs.data().statusList;
    this.calendarLists = this.calendarLists.data();
    this.selctedAgents = this.$store.state.userObj.userDoc.data().agents;

    // this.selctedAgents.push({
    //   color: "blue",
    //   id: "0000",
    //   name: "לא משוייך",
    //   photoURL: "",
    // });

    this.selectAllAgent;
    this.intiSources();
    this.careateAgentPhotosMap();
    this.updateDashboard();
  },
};
</script>

<style>
/* #managerDash .row{
        width: 70vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    } */

#managerDash .v-input--radio-group--row .v-input--radio-group__input {
  flex-wrap: unset;
}
.agnetData {
  min-width: 100%;
}
.agnetData .col {
  padding: 0;
}
</style>