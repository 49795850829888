<template>
  <div id="managerDash" style="padding: 10px">
    <v-row style="padding: 0; margin: 0" align="center">
      <v-spacer></v-spacer>
      <div style="width: 300px; margin-left: 10px">
        <v-autocomplete
          v-model="selctedAgents"
          :items="calendarLists.agentsInfos"
          label="שם סוכן"
          item-text="name"
          return-object
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllAgent" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              dense
              style="margin: 2px; font-size: calc(1vw - 0.4em)"
            >
              <span>{{
                selctedAgents.length === calendarLists.agentsInfos.length
                  ? "כל הפריטים"
                  : item.name
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedAgents.length === calendarLists.agentsInfos.length
                  ? ""
                  : "(+" + (selctedAgents.length - 1) + "פריטים)"
              }}</span
            >
          </template>
          <v-row
            slot="append-item"
            align="center"
            justify="center"
            style="padding-top: 5px"
          >
            <v-btn
              small
              style="margin: 0.8rem"
              width="80%"
              dense
              outlined
              color="blue"
              @click="updateDashboard"
              >רענן</v-btn
            >
          </v-row>
        </v-autocomplete>
      </div>

      <div style="width: 300px; margin-left: 10px">
        <v-autocomplete
          v-model="selctedStatuss"
          :items="listsDocs.statusList"
          label="סטאטוס"
          item-text="text"
          item.value="id"
          multiple
          clearable
          return-object
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllStatuss" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              dense
              style="margin: 2px; font-size: calc(1vw - 0.4em)"
            >
              <span>{{
                selctedStatuss.length === listsDocs.statusList.length
                  ? "כל הפריטים"
                  : item.text
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedStatuss.length === listsDocs.statusList.length
                  ? ""
                  : "(+" + (selctedStatuss.length - 1) + "פריטים)"
              }}</span
            >
          </template>
          <v-row
            slot="append-item"
            align="center"
            justify="center"
            style="padding-top: 5px"
          >
            <v-btn
              small
              style="margin: 0.8rem"
              width="80%"
              dense
              outlined
              color="blue"
              @click="updateDashboard"
              >רענן</v-btn
            >
          </v-row>
        </v-autocomplete>
      </div>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        desne
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 300px">
            <v-text-field
              dense
              v-model="dates"
              label="טווח  תאריכים"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </div>
        </template>
        <v-date-picker
          color="#0277BD"
          v-model="dates"
          locale="he"
          range
          no-title
          scrollable
          @change="sortDats"
        >
          <v-spacer></v-spacer>
          <v-btn text color="#0277BD" @click="$refs.menu.save(date)"
            >סינון</v-btn
          >
        </v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-card>
          <v-card-title>לפי סטאטוס</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="statusChartKey"
            :options="statusChart.chartOptions"
            :series="statusChart.series"
            :width="windowWidth / 5.5"
            :height="windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initStatusChart"
            >
              <v-radio label="הכל" value="all"></v-radio>
              <v-radio label="בתהליך" value="progress"></v-radio>
              <v-radio label="הסתיים" value="done"></v-radio>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card>
          <v-card-title>לפי רלוונטיות</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="relleventChartKey"
            :options="relleventChart.chartOptions"
            :series="relleventChart.series"
            :width="windowWidth / 5.5"
            :height="windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initrelleventChart"
            >
              <div style="color: white">----</div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card>
          <v-card-title>לפי סיווג</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="rankChartKey"
            :options="rankChart.chartOptions"
            :series="rankChart.series"
            :width="windowWidth / 5.5"
            :height="windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initrankChart"
            >
              <div style="color: white">----</div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col align="center">
        <v-card>
          <v-card-title>אחוזי סגירה</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="closedChartKey"
            :options="closedChart.chartOptions"
            :series="closedChart.series"
            :width="windowWidth / 5.5"
            :height="windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initClosedChart"
            >
              <div style="color: white">----</div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-card class="agnetData">
        <v-toolbar dark color="#607D8B" elevation="0">
          <v-col v-for="item in cols" :key="item" style="text-align: center">
            <v-btn text style="margin-left: 5px" @click="sortBy(item)">
              <v-icon v-if="sortMap[item].state">mdi-arrow-up</v-icon>
              <v-icon v-else>mdi-arrow-down</v-icon>
              <div>{{ sortMap[item].text }}</div>
            </v-btn>
          </v-col>
        </v-toolbar>
        <v-list style="height:200px;overflow-y:auto;padding">
          <v-list-item
            v-for="item in agentsDataList"
            :key="item.id"
            @click="selectOnlyThisAgnet(item.id)"
          >
            <v-list-item-content>
              <v-row>
                <v-col style="text-align: center">
                  <v-row no-gutters justify="start" align="center">
                    <v-col>
                      <!-- <v-avatar size="40" color="gray">
                        <img :src="agentsPhotosMap[item.id]" />
                      </v-avatar> -->
                      <user-avatar
                        :size="40"
                        :user="getAgentObj(item.id)"
                      ></user-avatar>
                    </v-col>
                    <v-col>
                      {{ item.name }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.meethings }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.strong }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.service }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.normal }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.inProgress }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.done }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.relevant }}</v-col
                >
                <v-col
                  style="
                    text-align: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  >{{ item.closed }}</v-col
                >
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>

    <!-- <DataTable
      style="padding-top: 10px"
      height="70vh"
      :key="tableKey"
      title="פגישות"
      :selcted="true"
      :colList="columns"
      :items="getOnlyMeetings()"
      @onActionsClickEvent="actionsClicked"
      @onRowClickEvent="rowClicked"
      v-if="tableMode"
    ></DataTable> -->

    <!-- <v-row style="margin-top:5px">
      
                   <v-card class="agnetData">
                        <v-toolbar dark color="#0277BD" elevation="0" >
                                <v-col style="text-align:center">ממוצע</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                                <v-col style="text-align:center">2</v-col>
                        </v-toolbar>
                    </v-card>
            </v-row> -->
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";
import UserAvatar from "./UserAvatar.vue";

export default {
  components: {
    apexchart: VueApexCharts,
    UserAvatar,
  },
  data: () => ({
    listsDocs: [],
    selctedAgents: [],
    selctedStatuss: [],
    agentsData: {},
    agentsDataList: [],
    representatives: [],
    bounsTarget: 0,
    calendarLists: {},
    dates: [],
    menu: false,
    meethings: [],
    statusChartKey: 100,
    rankChartKey: 1000,
    //charts
    chartOptionsRadio: "all",
    statusChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },

    relleventChart: {
      series: [],
      chartOptions: {
        labels: ["רלוונטי", "לא רלוונטי", "טרם נקבע"],
        colors: ["#26A69A", "#EF5350", "#455A64"],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },
    rankChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },
    closedChart: {
      series: [],
      chartOptions: {
        labels: ["נסגר", "לא נסגר"],
        colors: ["#4CAF50", "#B71C1C"],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל הפגישות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },

    cols: [
      "name",
      "meethings",
      "strong",
      "service",
      "normal",
      "inProgress",
      "done",
      "relevant",
      "closed",
    ],

    sortMap: {
      name: { state: false, text: "סוכן" },
      meethings: { state: false, text: "פגישות" },
      strong: { state: false, text: "חזקות" },
      service: { state: false, text: "שירות" },
      normal: { state: false, text: "רגילות" },
      inProgress: { state: false, text: "בתהליך" },
      done: { state: false, text: "הסתיימו" },
      relevant: { state: false, text: "רלוונטיות" },
      closed: { state: false, text: "נסגרו" },
    },

    agentsPhotosMap: {},
  }),
  methods: {
    getAgentObj(id) {
      let results = this.calendarLists.agentsInfos.filter((obj) => {
        return obj.id == id;
      });
      return results[0];
    },

    initStatusChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
        ref.statusChart.chartOptions.labels.push(status.text),
          ref.statusChart.chartOptions.colors.push(status.color);
      });

      this.meethings.forEach((call) => {
        if (this.chartOptionsRadio == "progress") {
          if (
            call.statusText == "פגישה" ||
            call.statusText == "המשך טיפול סוכן" ||
            call.statusText == "דחיה"
          ) {
            statusMap[call.statusText]++;
          }
        } else if (this.chartOptionsRadio == "done") {
          if (
            call.statusText == "לא רלוונטי" ||
            call.statusText == "סגירה" ||
            call.statusText == "חידוש שיחה"
          ) {
            statusMap[call.statusText]++;
          }
        } else if (
          call.statusText == "פגישה" ||
          call.statusText == "המשך טיפול סוכן" ||
          call.statusText == "דחיה" ||
          call.statusText == "לא רלוונטי" ||
          call.statusText == "סגירה" ||
          call.statusText == "חידוש שיחה"
        ) {
          statusMap[call.statusText]++;
        } else {
          statusMap[call.statusText]++;
        }
      });

      ref.statusChart.series = [];

      for (const status in statusMap) {
        ref.statusChart.series.push(statusMap[status]);
      }

      this.statusChartKey++;
    },

    initrelleventChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      var relevant = 0;
      var notRelevant = 0;
      var notDetermend = 0;

      // this.listsDocs.statusList.forEach(status => {
      //     statusMap[status.text] = 0;
      //     ref.statusChart.chartOptions.labels.push(status.text),
      //     ref.statusChart.chartOptions.colors.push(status.color)
      // });

      this.meethings.forEach((meething) => {
        if (meething.relevant == "כן") {
          relevant++;
        } else if (meething.relevant == "לא") {
          notRelevant++;
        } else {
          notDetermend++;
        }
      });

      ref.relleventChart.series = [relevant, notRelevant, notDetermend];

      this.statusChartKey++;
    },

    initrankChart() {
      var series = [];
      var ref = this;
      var rankMap = {};
      ref.rankChart.chartOptions.labels = [];
      ref.rankChart.chartOptions.colors = [];
      ref.rankChart.series = [];
      this.calendarLists.meetingRank.forEach((rank) => {
        ref.rankChart.chartOptions.labels.push(rank.text);
        if (rank.background == "transparent") {
          rank.background = "#B0BEC5";
        }
        ref.rankChart.chartOptions.colors.push(rank.background);
        rankMap[rank.text] = 0;
      });

      this.meethings.forEach((meething) => {
        rankMap[meething.rank]++;
      });

      for (const key in rankMap) {
        ref.rankChart.series.push(rankMap[key]);
      }

      this.rankChartKey++;
    },

    initClosedChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      var closed = 0;
      var notClosed = 0;

      // this.listsDocs.statusList.forEach(status => {
      //     statusMap[status.text] = 0;
      //     ref.statusChart.chartOptions.labels.push(status.text),
      //     ref.statusChart.chartOptions.colors.push(status.color)
      // });

      this.meethings.forEach((meething) => {
        if (meething.status == 11) {
          closed++;
        } else {
          notClosed++;
        }
      });

      ref.closedChart.series = [closed, notClosed];

      this.$forceUpdate();
    },

    getStatusInitMap() {
      var statusMap = {};
      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
      });
      return statusMap;
    },

    sortDats() {
      this.dates.sort();
      this.updateDashboard();
    },
    async updateDashboard() {
      //Notiflix.Loading.Pulse('מעדכן נתונים');
      this.$store.state.loading = true;

      var ref = this;
      this.meethings = [];
      this.bonusTarget();

      var agents = {};

      ref.representatives = [];

      var listsDocs = await db.collection("System").doc("lists").get();
      var from = new Date(this.dates[0]);
      var to = new Date(this.dates[1]);
      var meethingsPromises = await db
        .collection("Meetings")
        .where("meetingDate", ">=", from)
        .where("meetingDate", "<=", to)
        .get();
      var index = 0;

      meethingsPromises.forEach((doc) => {
        if (
          this.findeAgent(doc.data().agent) &&
          this.findeStatus(doc.data().status)
        ) {
          ref.meethings.push(doc.data());
        }
      });

      this.listsDocs = listsDocs.data();
      this.initStatusChart();
      this.initrelleventChart();
      this.initrankChart();
      this.initClosedChart();

      this.updateTable();

      //Notiflix.Loading.Remove()
      this.$store.state.loading = false;
    },

    bonusTarget() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var count = 0;
      while (date1 <= date2) {
        if (date1.getDay() < 5) {
          count++;
        }
        date1.setDate(date1.getDate() + 1);
      }
      this.bounsTarget = Math.round(count * 2.045);
    },

    findeAgent(id) {
      var flag = false;
      for (var i = 0; i < this.selctedAgents.length; i++) {
        if (this.selctedAgents[i].id == id) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    findeStatus(id) {
      var flag = false;
      for (var i = 0; i < this.selctedStatuss.length; i++) {
        if (this.selctedStatuss[i].id == id) {
          flag = true;
          break;
        }
      }
      return flag;
    },

    selectAllAgent() {
      this.selctedAgents = this.calendarLists.agentsInfos;
      this.updateDashboard();
    },
    selectAllStatuss() {
      this.selctedStatuss = this.listsDocs.statusList;
      this.updateDashboard();
    },

    updateTable() {
      this.agentsData = {};
      this.meethings.forEach((doc) => {
        this.agentsData[doc.agent] = {
          name: doc.category,
          id: doc.agent,
          meethings: 0,
          strong: 0,
          service: 0,
          normal: 0,
          inProgress: 0,
          done: 0,
          relevant: 0,
          closed: 0,
        };
      });

      this.meethings.forEach((doc) => {
        this.agentsData[doc.agent].meethings++;
        doc.rank == "פגישה חזקה"
          ? this.agentsData[doc.agent].strong++
          : () => {};
        doc.rank == "פגישת שירות"
          ? this.agentsData[doc.agent].service++
          : () => {};
        doc.rank == "פגישה רגילה"
          ? this.agentsData[doc.agent].normal++
          : () => {};

        if (
          doc.statusText == "פגישה" ||
          doc.statusText == "המשך טיפול סוכן" ||
          doc.statusText == "דחיה"
        ) {
          this.agentsData[doc.agent].inProgress++;
        } else if (
          doc.statusText == "לא רלוונטי" ||
          doc.statusText == "סגירה"
        ) {
          this.agentsData[doc.agent].done++;
        }

        doc.relevant == "כן" ? this.agentsData[doc.agent].relevant++ : () => {};
        doc.statusText == "סגירה"
          ? this.agentsData[doc.agent].closed++
          : () => {};
      });

      this.agentsDataListUpdate();
    },
    selectOnlyThisAgnet(id) {
      var list = this.selctedAgents.filter((agent) => {
        return agent.id == id;
      });
      this.selctedAgents = list;
      this.updateDashboard();
    },
    sortBy(field) {
      this.sortMap[field].state = !this.sortMap[field].state;
      this.agentsDataList.sort((a, b) =>
        a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
      );
      this.sortMap[field].state ? this.agentsDataList.reverse() : () => {};
    },

    agentsDataListUpdate() {
      var list = [];
      for (const key in this.agentsData) {
        list.push(this.agentsData[key]);
      }
      this.agentsDataList = list;
    },

    careateAgentPhotosMap() {
      var ref = this;
      this.calendarLists.agentsInfos.forEach((agent) => {
        ref.agentsPhotosMap[agent.id] = agent.photoURL;
      });
    },
  },
  computed: {},
  async created() {
    //Notiflix.Loading.Init({svgColor:"#039BE5",});
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

    this.dates = [startOfMonth, endOfMonth];

    this.calendarLists = await db
      .collection("System")
      .doc("CalendarLists")
      .get();

    var listsDocs = await db.collection("System").doc("lists").get();
    this.selctedStatuss = listsDocs.data().statusList;

    this.calendarLists = this.calendarLists.data();
    this.selctedAgents = this.calendarLists.agentsInfos;

    this.careateAgentPhotosMap();
    this.updateDashboard();
  },
};
</script>

<style>
/* #managerDash .row{
        width: 70vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    } */

#managerDash .v-input--radio-group--row .v-input--radio-group__input {
  flex-wrap: unset;
}
.agnetData {
  min-width: 100%;
}
.agnetData .col {
  padding: 0;
}
</style>