<template>
  <v-row>
    <v-col>
      <v-snackbar
        color="amber"
        top
        left
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
      >
        <div style="color: black">
          <span><v-icon color="black">mdi-alert</v-icon></span
          >{{ snackbar.msg }}
        </div>
      </v-snackbar>
      <v-row
        no-gutters
        style="padding-left: 1rem; padding-right: 1rem; margin-top: 1.2rem"
      >
        <v-btn text color="#01579B" @click="runCalls">
          <v-icon>mdi-animation-play</v-icon>
          הרצת שיחות
        </v-btn>
        <h5 style="margin-top: 0.6rem; margin-right: 2rem">
          מוגבל לעד 10,000 רשומות
        </h5>
        <v-spacer></v-spacer>
        <v-btn-toggle
          @change="updateFilter"
          v-model="statusfilter"
          tile
          color="blue accent-3"
          group
        >
          <v-btn :value="1">שיחה ראשונה</v-btn>
          <v-btn :value="6">שיחת המשך</v-btn>
          <v-btn :value="2">לא ענו</v-btn>
          <v-btn :value="8">חידוש שיחה</v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        

        <v-navigation-drawer
          absolute
          temporary
          width="400"
          color="#FAFAFA"
          style="padding: 5px"
          v-model="addRecordDrawer"
        >
          <v-card
            elevation="0"
            min-width="400"
            min-height="200"
            style="padding: 1rem"
          >
            <v-form v-model="newFormValid" ref="newRecordSmallForm">
              <v-text-field
                style="margin-top: 1rem"
                v-model="newPhoneObject['phone']"
                label="טלפון"
                prepend-icon="mdi-phone"
                dense
                required
                :rules="[(v) => !!v || 'חובה']"
              ></v-text-field>
              <v-text-field
                style="margin-top: 1rem"
                v-model="newPhoneObject['name']"
                label="שם הלקוח"
                prepend-icon="mdi-account"
                dense
                required
                :rules="[(v) => !!v || 'חובה']"
              ></v-text-field>
              <v-row align="center" no-gutters>
                <small>מוצר:</small>
                <v-radio-group
                  dense
                  v-model="newPhoneObject['productType']"
                  :mandatory="false"
                  :column="false"
                  @change="productTypeChanged"
                >
                  <v-radio
                    style="margin-right: 10px"
                    label="פנסיוני"
                    value="פנסיוני"
                  ></v-radio>
                  <v-radio
                    style="margin-right: 10px"
                    label="ביטוח"
                    value="ביטוח"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-text-field
                style="margin-top: 1rem"
                v-model="newPhoneObject['city']"
                label="עיר"
                prepend-icon="mdi-city"
                dense
              ></v-text-field>
              <v-text-field
                style="margin-top: 1rem"
                v-model="newPhoneObject['cId']"
                label="תעודת זהות"
                prepend-icon="mdi-badge-account-horizontal"
                dense
              ></v-text-field>
              <v-autocomplete
                style="margin-top: 1rem"
                :items="sources"
                label="מקור"
                v-model="newPhoneObject['source']"
                dense
                prepend-icon="mdi-tag"
              ></v-autocomplete>
              <v-text-field
                style="margin-top: 1rem"
                v-model="newPhoneObject['employer']"
                label="מעסיק"
                prepend-icon="mdi-account-tie"
                dense
              ></v-text-field>
              <v-text-field
                style="margin-top: 1rem"
                v-model="newPhoneObject['active']"
                label="פעיל"
                prepend-icon="mdi-power"
                dense
              ></v-text-field>
              <span>פנימי</span
              ><v-switch
                dense
                color="orange darken-3"
                inset
                style="margin: 0; margin-left: 5px"
                v-model="insideComment2"
              ></v-switch>
              <v-textarea
                v-model="newPhoneObject['note']"
                outlined
                name="notes"
                dense
                label="הערות"
                height="65"
                :disabled="edit"
              ></v-textarea>
              <v-btn
                :loading="uploadLoading"
                :disabled="!newFormValid"
                block
                color="blue"
                elevation="0"
                style="color: white"
                @click="saveNewRecord"
                >הוספה</v-btn
              >
            </v-form>
          </v-card>
        </v-navigation-drawer>

         <v-btn text color="#01579B" dark  @click="addRecordDrawer=true">
                <v-row no-gutters justify="center" align="center">
                  <v-icon>mdi-plus</v-icon>
                  <div>הוספת רשומה</div>
                </v-row>
         </v-btn>

        <!-- <v-btn color="#0277BD" dark @click="filter = true">
          <v-row no-gutters justify="center" align="center">
            <v-icon>mdi-filter</v-icon>
            <div>סינון</div>
          </v-row>
        </v-btn> -->

          <v-menu offset-y :close-on-content-click="false">
            <template lazy-validation v-slot:activator="{ on, attrs }">
             
            </template>

            <v-card min-width="400" min-height="200" style="padding: 1rem">
              <v-form v-model="newFormValid" ref="newRecordSmallForm">
                <v-text-field
                  style="margin-top: 1rem"
                  v-model="newPhoneObject['phone']"
                  label="טלפון"
                  prepend-icon="mdi-phone"
                  dense
                  required
                  :rules="[(v) => !!v || 'חובה']"
                ></v-text-field>
                <v-text-field
                  style="margin-top: 1rem"
                  v-model="newPhoneObject['name']"
                  label="שם הלקוח"
                  prepend-icon="mdi-account"
                  dense
                  required
                  :rules="[(v) => !!v || 'חובה']"
                ></v-text-field>
                <v-row align="center" no-gutters>
                  <small>מוצר:</small>
                  <v-radio-group
                    dense
                    v-model="newPhoneObject['productType']"
                    :mandatory="false"
                    :column="false"
                    @change="productTypeChanged"
                  >
                    <v-radio
                      style="margin-right: 10px"
                      label="פנסיוני"
                      value="פנסיוני"
                    ></v-radio>
                    <v-radio
                      style="margin-right: 10px"
                      label="ביטוח"
                      value="ביטוח"
                    ></v-radio>
                  </v-radio-group>
                </v-row>

                <v-text-field
                  style="margin-top: 1rem"
                  v-model="newPhoneObject['city']"
                  label="עיר"
                  prepend-icon="mdi-city"
                  dense
                ></v-text-field>
                <v-text-field
                  style="margin-top: 1rem"
                  v-model="newPhoneObject['cId']"
                  label="תעודת זהות"
                  prepend-icon="mdi-badge-account-horizontal"
                  dense
                ></v-text-field>
                <v-autocomplete
                  style="margin-top: 1rem"
                  :items="sources"
                  label="מקור"
                  v-model="newPhoneObject['source']"
                  dense
                  prepend-icon="mdi-tag"
                ></v-autocomplete>
                <v-text-field
                  style="margin-top: 1rem"
                  v-model="newPhoneObject['employer']"
                  label="מעסיק"
                  prepend-icon="mdi-account-tie"
                  dense
                ></v-text-field>
                <v-text-field
                  style="margin-top: 1rem"
                  v-model="newPhoneObject['active']"
                  label="פעיל"
                  prepend-icon="mdi-power"
                  dense
                ></v-text-field>
                <span>פנימי</span
                ><v-switch
                  dense
                  color="orange darken-3"
                  inset
                  style="margin: 0; margin-left: 5px"
                  v-model="insideComment2"
                ></v-switch>
                <v-textarea
                  v-model="newPhoneObject['note']"
                  outlined
                  name="notes"
                  dense
                  label="הערות"
                  height="65"
                  :disabled="edit"
                ></v-textarea>
                <v-btn
                  :loading="uploadLoading"
                  :disabled="!newFormValid"
                  block
                  color="blue"
                  elevation="0"
                  style="color: white"
                  @click="saveNewRecord"
                  >הוספה</v-btn
                >
              </v-form>
            </v-card>
          </v-menu>
        </div>
      </v-row>
      <DataTable
        title="שיחות לביצוע"
        :colList="columns"
        :items="rows"
        height="60vh"
        @onRowClickEvent="rowClicked"
        style="padding-top: 5px"
      ></DataTable>
    </v-col>

    <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
      <v-card  >
        <v-toolbar dark flat color="#37474F">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >טלפון:
            {{ convertPhoneFormat(selectedObject.phone) }}</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on"
                ><v-icon>mdi-bell</v-icon></v-btn
              >
            </template>
            <v-list min-width="500">
              <v-row no-gutters>
                <v-col cols="5">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted2"
                        label="תאריך תזכורת"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="he"
                      v-model="reminder.date"
                      @input="menu2 = false"
                      color="#EF6C00"
                      header-color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="11" style="margin-right: 1rem">
                  <v-text-field
                    v-model="reminder.note"
                    label="הערה"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div style="padding: 1rem">
                <v-btn
                  :loading="reminderLoading"
                  :disabled="reminder.date == ''"
                  block
                  color="green"
                  style="color: white; font-weight: bold"
                  @click="saveReminer"
                  >שמור תזכורת</v-btn
                >
              </div>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-divider></v-divider>
         <v-alert v-if="meetingFlag" dense border="left"  type="warning">
        שים לב:פגישה קיימת -  {{meetingObj.text}}
         </v-alert>
        <v-card-text style="height: 70vh">
          <v-card-text>
            <v-container>
              <!-- <small style="margin-bottom:10px">פרטים לקוח בסיסים</small> -->
              
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.name"
                    label="שם הלקוח"
                    prepend-icon="mdi-account"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.city"
                    label="עיר"
                    prepend-icon="mdi-city"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    v-model="selectedObject.age"
                    label="גיל"
                    prepend-icon="mdi-account-settings-outline"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject.cId"
                    label="תעודת זהות"
                    prepend-icon="mdi-badge-account-horizontal"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['employer']"
                    label="מעסיק"
                    prepend-icon="mdi-account-tie"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="selectedObject['phone2']"
                    label="טלפון נוסף"
                    append-outer-icon="mdi-phone"
                    dense
                    style="direction: ltr; margin-left: 15px"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    disabled
                    v-model="selectedObject['productName']"
                    label="שם המוצר"
                    append-outer-icon="mdi-book-variant"
                    dense
                    style="direction: ltr; margin-left: 15px"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="start" style="margin-bottom: 10px">
                <div v-for="item in phoneCallsAttrs" :key="item">
                  <v-checkbox
                    dense
                    style="margin-left: 10px"
                    v-model="selectedObject.attributes"
                    :label="item"
                    :value="item"
                    height="10"
                  ></v-checkbox>
                </div>
              </v-row>

              <v-row align="center" no-gutters>
                <small>מוצר:</small>
                <v-radio-group
                  dense
                  v-model="selectedObject.productType"
                  :mandatory="false"
                  :column="false"
                  @change="productTypeChanged"
                >
                  <v-radio
                    style="margin-right: 10px"
                    label="פנסיוני"
                    value="פנסיוני"
                  ></v-radio>
                  <v-radio
                    style="margin-right: 10px"
                    label="ביטוח"
                    value="ביטוח"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-card
                style="overflow: auto; max-height: 250px; margin-bottom: 40px"
              >
                <v-col v-if="selectedObject.comments.length > 0">
                  <div v-for="item in selectedObject.comments" :key="item">
                    <div v-if="visableToRep(item)">
                      <v-row no-gutters>
                        <v-col cols="12" md="1">
                          <v-avatar>
                            <img :src="item.photoURL" />
                          </v-avatar>
                        </v-col>
                        <v-col v-col cols="12" md="11">
                          <v-row justify="start" no-gutters>
                            <div
                              v-if="item.insideComment"
                              style="font-weight: 500; color: #ef6c00"
                            >
                              {{
                                item.displayName +
                                " " +
                                timeStampTOdate(item.time) +
                                ":"
                              }}
                            </div>
                            <div
                              v-else
                              style="font-weight: 500; color: #1565c0"
                            >
                              {{
                                item.displayName +
                                " " +
                                timeStampTOdate(item.time) +
                                ":"
                              }}
                            </div>
                          </v-row>
                          <v-row justify="start" no-gutters>
                            <div>{{ item.text }}</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-divider style="margin: 5px"></v-divider>
                    </div>
                  </div>
                </v-col>

                <v-row
                  v-else
                  no-gutters
                  justify="center"
                  align="center"
                  style="height: 80px"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      padding-top: 6px;
                      margin: 0;
                      font-weight: 700;
                      color: #0277bd;
                    "
                  >
                    <v-icon color="#0277BD"
                      >mdi-36px mdi-chat-processing-outline</v-icon
                    >
                    <p>אין תגובות</p>
                  </div>
                </v-row>
              </v-card>

              <v-row no-gutters align="start">
                <v-col cols="12" md="1">
                  <small>פנימי</small>
                  <v-switch
                    color="orange darken-3"
                    inset
                    style="margin: 0; margin-left: 5px"
                    v-model="insideComment"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="10">
                  <v-textarea
                    v-model="selectedObject.note"
                    outlined
                    name="notes"
                    dense
                    label="הוסף תגובה"
                    height="65"
                    :disabled="edit"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="1" align="center">
                  <v-btn style="margin-top: 20px" color="blue" icon
                    ><v-icon
                      size="60"
                      style="transform: rotate(180deg)"
                      @click="sendComments"
                      >mdi-send-circle</v-icon
                    ></v-btn
                  >
                </v-col>
              </v-row>
              <small >תנאי סף לפגישה</small>
              <v-row justify="start" style="margin-bottom: 10px" no-gutters>
                <div v-for="item in getMustAttars" :key="item">
                  <v-checkbox
                    dense
                    style="margin-left: 10px"
                    v-model="selectedObject.mustAttrs"
                    :label="item"
                    :value="item"
                    height="10"
                  ></v-checkbox>
                </div>
              </v-row>

              <small>סטאטוס </small>: {{ selectedObject.statusText }}
              <v-row justify="start">
                <v-radio-group
                  row
                  v-model="selectedObject.status"
                  v-for="item in statusList"
                  :key="item.id"
                >
                  
                  <v-radio
                    :disabled ="hideStatusList()"
                    :label="item.text"
                    :value="item.id"
                    :color="item.color"
                    v-if="checkMustAttrs(item.id)"
                  ></v-radio>

                </v-radio-group>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="selectedObject.info"
                    dense
                    :items="getInfos"
                    label="פירוט"
                    item-value="text"
                    v-if="getInfos.length > 0 ? true : false"
                  ></v-select>
                </v-col>
              </v-row>
              <small v-if="selectedObject.status == 7">קביעת פגישה</small>
              <v-row
                v-if="selectedObject.status == 7"
                align="center"
                justify="space-between"
              >
                <v-col cols="12" sm="3" md="3">
                  <v-row class="mapContainer">
                    <v-icon>mdi-map-marker</v-icon>
                    <vue-google-autocomplete
                      :key="mapsKey"
                      ref="address"
                      id="map"
                      classname="form-control"
                      placeholder="כתובת"
                      v-model="selectedObject.address.addressText"
                      v-on:placechanged="getAddressData"
                      country="il"
                      types=""
                    ></vue-google-autocomplete>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="תאריך פגישה"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="he"
                      v-model="selectedObject.date"
                      @input="menu2 = false"
                      color="#EF6C00"
                      header-color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-row
                    justify="center"
                    align="center"
                    style="margin-top: 14px"
                  >
                    <small>שעת התחלה</small>
                    <v-select
                      v-model="selectedObject.minutes"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getMinuts"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                    <div style="font-weight: bold">:</div>
                    <v-select
                      v-model="selectedObject.hours"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getHouers"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-row
                    justify="center"
                    align="center"
                    style="margin-top: 14px"
                  >
                    <small>שעת סיום</small>
                    <v-select
                      v-model="selectedObject.minutesTo"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getMinuts"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                    <div style="font-weight: bold">:</div>
                    <v-select
                      v-model="selectedObject.hoursTo"
                      :menu-props="{ maxHeight: 130 }"
                      append-icon=""
                      :items="getHouers"
                      dense
                      class="centered-input"
                      style="padding: 3px; width: 50px; text-align: center"
                    ></v-select>
                  </v-row>
                </v-col>
                <div cols="12" sm="2" md="2">
                  <v-btn
                    @click="createMeeting"
                    :loading="createMeetingFlag"
                    :disabled="!validateMeeting"
                    color="green lighten-1"
                    elevation="1"
                    solo
                    style="color: white; font-weight: bold"
                    >צור פגישה</v-btn
                  >

                </div>
                 </v-row >

                  
            </v-container>
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!createMeetingFlag">
          <v-btn
            color="#01579B"
            text
            @click="prevLead"
            :disabled="!(currentCallIndex > 0)"
            >הקודם</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="#455A64" text @click="closeAndSave">שמור וסגור</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#01579B"
            text
            @click="nextLead"
            :disabled="!(currentCallIndex < this.rows.length - 1)"
            >הבא</v-btn
          >
        </v-card-actions>
      </v-card>



    </v-dialog>
  </v-row>
</template>

<script>
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";

export default {
  data() {
    return {
      addRecordDrawer: false,
      dialog: false,
      meetingFlag: false,
      meetingObj: {},
      currentCallIndex: 0,
      statusSelected: 0,
      address: [],
      isLoading: false,
      meetingDone: false,
      search: null,
      geoLocation: "",
      mapsKey: 1000,
      filter:false,
      meitavMeeting:false,
      snackbar: { show: false, timeout: 3000, msg: "" },
      createMeetingFlag: false,
      date: "",
      menu: false,
      modal: false,
      menu2: false,
      insideComment: true,
      insideComment2: true,
      statusfilter: 1,

      selectedObject: {
        comments: [],
      },
      columns: [
        { field: "statusText", data: "", type: "text", label: "סטאטוס" },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "name", data: "", type: "text", label: "שם" },
        { field: "city", data: "", type: "text", label: "עיר" },
        { field: "productType", data: "", type: "text", label: "מוצר" },
        { field: "cId", data: "", type: "text", label: "תעודת זהות" },
        { field: "selaId", data: "", type: "text", label: "מזהה סלע" },
        { field: "source", data: "", type: "text", label: "מקור" },
        { field: "employer", data: "", type: "text", label: "מעסיק" },
        { field: "active", data: "", type: "text", label: "פעיל" },
        { field: "updateStatus", data: "", type: "text", label: "ס.עודכן ב" },
      ],

      columns2: [
        { field: "relevant", data: "", type: "text", label: "רלוונטי" },
        { field: "statusText", data: "", type: "text", label: "סטאטוס" },
        { field: "phone", data: "", type: "text", label: "טלפון" },
        { field: "name", data: "", type: "text", label: "שם" },
        { field: "city", data: "", type: "text", label: "עיר" },
        { field: "productType", data: "", type: "text", label: "מוצר" },
        { field: "selaId", data: "", type: "text", label: "מזהה סלע" },
      ],

      rows: [],
      rows2: [],

      statusList: [
        {
          group: 0,
          id: 1,
          text: "שיחה ראשונה",
          color: "#2196F3",
          textColor: "#FFFFFF",
        },
        {
          group: 1,
          id: 2,
          text: "לא ענה",
          color: "#673AB7",
          textColor: "#FFFFFF",
        },
        {
          group: 2,
          id: 3,
          text: "לא רלוונטי",
          color: "#607D8B",
          textColor: "#FFFFFF",
        },
        {
          group: 2,
          id: 4,
          text: "לא רלוונטי כרגע",
          color: "#FFC107",
          textColor: "#FFFFFF",
        },
        {
          group: 2,
          id: 5,
          text: "להסיר",
          color: "#FF5722",
          textColor: "#FFFFFF",
        },
        {
          group: 1,
          id: 6,
          text: "שיחת המשך",
          color: "#00BCD4",
          textColor: "#FFFFFF",
        },
        {
          group: 1,
          id: 7,
          text: "פגישה",
          color: "#69F0AE",
          textColor: "#000000",
        },
        {
          group: 0,
          id: 8,
          text: "חידוש שיחה",
          color: "#E91E63",
          textColor: "#FFFFFF",
        },
        {
          group: 1,
          id: 9,
          text: "דחייה",
          color: "#C62828",
          textColor: "#FFFFFF",
        },
        {
          group: 1,
          id: 10,
          text: "המשך טיפול סוכן",
          color: "#1DE9B6",
          textColor: "#FFFFFF",
        },
        {
          group: 2,
          id: 11,
          text: "סגירה",
          color: "#00E676",
          textColor: "#FFFFFF",
        },
      ],

      subStatus: [
        // {status:3,text:"יש סוכן",color:"#607D8B"},
        // {status:3,text:"לא מעוניין כרגע",color:"#607D8B"},
        // {status:3,text:"יתרות נמוכות",color:"#607D8B"},
        // {status:3,text:"תנאים טובים",color:"#607D8B"},
        // {status:3,text:"אין כסף",color:"#607D8B"},
        // {status:3,text:"לקוח זועם-לא להתקשר",color:"#607D8B"},
        // {status:3,text:"נפטר",color:"#607D8B"},
        // {status:3,text:"מספר שגוי",color:"#607D8B"},
      ],

      meetingStatusList: [
        {
          group: 1,
          id: 4,
          text: "ממתין לאישור",
          color: "#ECEFF1",
          textColor: "#000000",
        },
        {
          group: 1,
          id: 4,
          text: "מאושר",
          color: "#4CAF50",
          textColor: "#FFFFFF",
        },
        {
          group: 2,
          id: 4,
          text: "לא מאשור",
          color: "#F44336",
          textColor: "#FFFFFF",
        },
      ],
      listsDocs: {},

      showWeekNumber: false,
      formatAmPm: false,
      enableSeconds: false,
      phoneCallsAttrs: [],
      insuranceAttrs: [],
      financialAttrs: [],
      mustAttrs: [],

      phoneCallForant: {
        selaId: "",
        name: "",
        phone: "",
        city: "",
        address: {
          addressText: "",
          city: "",
          lat: 0,
          lng: 0,
          url: "",
        },
        cMail: "",
        age: "",
        agent: "",
        attributes: [],
        cId: "",
        category: "",
        color: "",
        dataInsertOn: "",
        date: "",
        duration: "",
        end: "",
        hour: "",
        hours: "",
        hoursTo: "",
        info: "",
        meetingClosedBy: "",
        meetingDate: "",
        minutes: "",
        minutesTo: "",
        mustAttrs: "",
        note: "",
        productType: "",
        rank: "",
        source: "",
        start: "",
        status: "",
        statusText: "",
        time: "",
        timed: "",
        comments: [],
        updatedBy: "",
        updatedOn: "",
      },

      newPhoneObject: {
        phone: "",
        name: "",
        city: "",
        cId: "",
        source: "הוספה ידנית",
        employer: "",
        active: "",
        productType: "פנסיוני",
        note: "",
      },

      reminder: {
        date: "",
        note: "",
        type: "",
        id: "",
        path: "",
        isNew: true,
        visable: true,
        selaId: "",
        phone: "",
        name: "",
      },
      reminderLoading: false,

      newFormValid: true,
      sources: ["הוספה ידנית"],
      uploadLoading: false,
    };
  },
  components: {
    DataTable,
    VueGoogleAutocomplete,
  },

  async mounted() {
    await this.getData();
  },
  computed: {
    validateMeeting() {
      let selectedObject = this.selectedObject;
      return (
        selectedObject.date !== "" &&
        selectedObject.minutesTo !== "" &&
        selectedObject.hoursTo !== "" &&
        selectedObject.minutes !== "" &&
        selectedObject.hours !== ""
      );
    },

    getInfos() {
      var ref = this;
      var list = this.subStatus.filter(function (item) {
        return item.status == ref.selectedObject.status;
      });
      return list;
    },
    format() {
      return this.formatAmPm ? "12" : "24";
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedObject.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.reminder.date);
    },

    filterComplated() {
      var ref = this;
      if (this.rows) {
        var list = this.rows.filter(function (item) {
          return item.status != 1;
        });

        return this.rows.length + " / " + list.length;
      } else {
        return "";
      }
    },

    getMinuts() {
      var array = [];
      for (var i = 0; i < 60; i += 5) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },
    getHouers() {
      var array = [];
      for (var i = 8; i < 22; i++) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },

    getMustAttars() {
      return this.selectedObject.productType == "פנסיוני"
        ? this.financialAttrs
        : this.insuranceAttrs;
    },

    
  },
  methods: {
    checkMeeting() {
      this.meetingFlag = false;
      this.meetingObj = {};
      db.collection("Meetings")
        .where(
          "formatedPhone",
          "==",
          this.selectedObject.phone.replace("+972", "0").replace(/\D/g, "")
        )
        .get()
        .then((docs) => {
          if (!docs.empty) {

            let meetings  = docs.docs.map(doc => doc.data());
            meetings =  meetings.filter(doc=> (
              doc.status == 7 && doc.status == 10 && doc.status == 11
            ));

            if(meetings.length > 0){
              this.meetingObj = {
                status: meetings[0].status,
                text: meetings[0].statusText,
              };
              this.meetingFlag = true;
            }
            else{
            this.meetingObj = {
              status: "במספר סטאטוסים",
              text: "קיימות מספר פגישות על טלפון זה",
             };
             this.meetingFlag = true;

            }

           
          }
        });
    },
    async getData() {
      var ref = this;
      //Notiflix.Loading.Init({ svgColor: "#039BE5" });
      //Notiflix.Loading.Standard('מעדכן');
      this.$store.state.loading = true;

      // //    let res = await db.collection('Meetings').where('status', '==' , 7).where('statusText','!=','פגישה').get()
      //    let res = await db.collection('Meetings').where('status', '==' , 7).get()
      //    res.forEach(doc=>{
      //        if(doc.data().statusText != 'פגישה')
      //       db.collection('Meetings').doc(doc.id).set({statusText:'פגישה'},{merge:true})

      //    })

      // var listsDocs = await db.collection("System").doc("lists").set({statusList:this.statusList},{merge:true})
      // var listsDocs = await db.collection("System").doc("lists").set({meetingStatusList:this.meetingStatusList},{merge:true})

      var listsDocs = await db.collection("System").doc("lists").get();
      var valueDocs = await db.collection("System").doc("values").get();
      this.sources = valueDocs.data().sources;

      this.listsDocs = listsDocs;
      this.financialAttrs = listsDocs.data().financialAttrs;
      this.insuranceAttrs = listsDocs.data().insuranceAttrs;
      this.statusList = listsDocs.data().statusList;
      this.subStatus = listsDocs.data().subStatus;
      this.phoneCallsAttrs = listsDocs.data().phoneCallsAttrs;
      var phoneCalls = await db
        .collection("PhoneCalls")
        .where("status", "==", this.statusfilter)
        .where("representative", "==", ref.$store.state.userObj.user.uid)
        .get();
      // var phoneCalls = await db
      //   .collection("PhoneCalls")
      //   .where("status", ">", -1)
      //   .where("status", "<", 7)
      //   .where("representative", "==", ref.$store.state.userObj.user.uid)
      //   .limit(500)
      //   .get();
      var phoneCallOther = [];
      // var phoneCallOther = await db
      //   .collection("PhoneCalls")
      //   .where("status", ">", 6)
      //   .where("representative", "==", ref.$store.state.userObj.user.uid)
      //   .get();
      // var phoneCallOther = await db
      //   .collection("PhoneCalls")
      //   .where("status", "==", 8)
      //   .where("representative", "==", ref.$store.state.userObj.user.uid)
      //   .get();

      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.rows = [];
      this.rows2 = [];

      this.statusList = this.statusList.filter(function (status) {
        return status.id < 9;
      });

      phoneCalls.forEach((doc) => {
        var data = doc.data();
        data["statusText"] = this.getStatusName(data.status);
        if (
          data.status == 5 ||
          data.status == 4 ||
          data.status == 3 ||
          data.status == 9 ||
          data.status == 10 ||
          data.status == 11
        ) {
          this.rows2.push(data);
        } else {
          this.rows.push(data);
        }
      });

      phoneCallOther.forEach((doc) => {
        var data = doc.data();
        if (
          data.status != 7 &&
          data.status != 9 &&
          data.status != 10 &&
          data.status != 11
        ) {
          data["statusText"] = this.getStatusName(data.status);
          this.rows.push(data);
        }
      });

      if (this.$store.state.hasOwnProperty("popObject")) {
        this.selectedObject = { ...this.$store.state.popObject };
        this.dialog = true;
        delete this.$store.state["popObject"];
      }

      // this.uploadRecords();
    },
    async saveReminer() {
      this.reminderLoading = true;
      let dbref = db
        .collection("Users")
        .doc(this.$store.state.userObj.user.uid)
        .collection("Reminders")
        .doc();
      let objPath = await db
        .collection("PhoneCalls")
        .doc(this.selectedObject.phone)
        .get();
      let date = moment(this.reminder.date, "YYYY-MM-DD")
        .startOf("day")
        .toDate();
      this.reminder.date = date;
      this.reminder.type = "phoneCall";
      this.reminder.path = objPath.ref.path;
      this.reminder.id = objPath.id;
      this.reminder.selaId = dbref.id;

      this.reminder.name = objPath.data().name;
      this.reminder.phone = objPath.data().phone;

      await dbref.set(this.reminder);
      this.reminderLoading = false;

      this.reminder = {
        date: "",
        note: "",
        type: "",
        id: "",
        path: "",
        selaId: "",
        isNew: true,
        visable: true,
        phone: "",
        name: "",
      };
    },
    async updateFilter() {
      await this.getData();
    },

    async saveNewRecord() {
      this.uploadLoading = true;
      this.selectedObject = { ...this.phoneCallForant };
      this.selectedObject["representative"] =
        this.$store.state.userObj.userDoc.data().uid;
      this.selectedObject["representativeName"] =
        this.$store.state.userObj.userDoc.data().displayName;



      var obj = { ...this.phoneCallForant };
      obj.phone = this.newPhoneObject.phone;
      obj.selaId = Math.floor(1000000000 + Math.random() * 9000000000) + "";
      obj.city = this.newPhoneObject.city;
      obj.name = this.newPhoneObject.name;
      obj.cMail = "";
      obj.age = this.newPhoneObject.age;
      obj.cId = this.newPhoneObject.cId;
      obj.productType = this.newPhoneObject.productType;
      (obj.mustAttrs = []), (obj["createdOn"] = new Date());
      obj.updatedBy = this.$store.state.userObj.user.uid;
      obj.updatedOn = new Date();
      obj["statusText"] = "שיחה ראשונה";
      obj.status = 1;
      (obj["mainStatus"] = 0), (obj["reminder"] = false);
      obj["comments"] = [
        {
          displayName: this.selectedObject["representativeName"],
          fromAgent: false,
          insideComment: this.insideComment2,
          photoURL: this.$store.state.userObj.userDoc.data().photoURL,
          text: this.newPhoneObject.note,
          time: new Date(),
          uid: this.selectedObject["representative"],
        },
      ];

      obj["representative"] = this.$store.state.userObj.userDoc.data().uid;
      obj["representativeName"] =
        this.$store.state.userObj.userDoc.data().displayName;

      typeof obj["source"] != "undefined"
        ? (obj["source"] = this.newPhoneObject["source"])
        : (obj["source"] = "");
      typeof obj["employer"] != "undefined"
        ? (obj["employer"] = this.newPhoneObject["employer"])
        : (obj["employer"] = "");
      typeof obj["active"] != "undefined"
        ? (obj["active"] = this.newPhoneObject["active"])
        : (obj["active"] = "");
      typeof obj["age"] != "undefined"
        ? (obj["age"] = this.newPhoneObject["age"])
        : (obj["age"] = "");
      typeof obj["city"] != "undefined"
        ? (obj["city"] = this.newPhoneObject["city"])
        : (obj["city"] = "");
      typeof obj["cId"] != "undefined"
        ? (obj["cId"] = this.newPhoneObject["cId"])
        : (obj["cId"] = "");
      typeof obj["source"] != "undefined"
        ? (obj["source"] = this.newPhoneObject["source"])
        : (obj["source"] = "");
      typeof obj["employer"] != "undefined"
        ? (obj["employer"] = this.newPhoneObject["employer"])
        : (obj["employer"] = "");
      typeof obj["active"] != "undefined"
        ? (obj["active"] = this.newPhoneObject["active"])
        : (obj["active"] = "");
      obj["resFlag"] = false;
      
      //validate thate obj.phone is isralei phone with regex
      let phone = obj.phone;
      let phoneRegex = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
      if (!phoneRegex.test(phone)) {
        Notiflix.Notify.Failure("מספר טלפון לא תקין");
        this.uploadLoading = false;
        return;
      }
    
      //check if exsists
      let record = await db.collection("PhoneCalls").doc(obj.phone).get();
      if (record.exists) {
        Notiflix.Notify.Warning("הרשומה כבר קיימת");
      } else {
        await db
          .collection("PhoneCalls")
          .doc(obj.phone)
          .set(obj, { merge: true });
        Notiflix.Notify.Success("הרשומה נוספה בהצלחה");

        this.newPhoneObject = {
          phone: "",
          name: "",
          city: "",
          cId: "",
          source: "הוספה ידנית",
          employer: "",
          active: "",
          productType: "פנסיוני",
          note: "",
        };
      }

      this.uploadLoading = false;
      this.$refs["newRecordSmallForm"].resetValidation();
    },

    visableToRep(msg) {
      let res = true;
      let repFalg =
        this.$store.state.userObj.userDoc.data().role == "mRep" ? true : false;
      let forAgentFalg = false;

      if (msg.hasOwnProperty("fromAgent")) {
        msg.fromAgent ? (forAgentFalg = true) : () => {};
      }

      if (repFalg && forAgentFalg) {
        res = false;
      }

      if (msg.hasOwnProperty("visableToRep")) {
        if (msg["visableToRep"] == true) {
          res = true;
        }
      }

      return res;
    },

    convertPhoneFormat(text) {
      if (typeof text !== "undefined") {
        return text.replace("+972", "0");
      }
      return text;
    },
    rowClicked(row) {
      if(this.statusfilter != 8){
      var index = this.findePhone(row.phone);
      this.currentCallIndex = index;
      this.selectedObject = this.rows[index];

      if (typeof this.selectedObject.attributes == "string") {
        if (this.selectedObject.attributes == "") {
          this.selectedObject.attributes = [];
        } else {
          this.selectedObject.attributes = [this.selectedObject.attributes];
        }
      }

      this.dialog = true;
      }
    },
    getAddressData(addressData, placeResultData, id) {
      var locationObj = {
        lat: placeResultData.geometry.location.lat(),
        lng: placeResultData.geometry.location.lng(),
        addressText: placeResultData.formatted_address,
        // id:placeResultData.id,
        url: placeResultData.url,
        city: placeResultData.vicinity,
      };
      this.rows[this.currentCallIndex].address = locationObj;
      this.selectedObject.address = locationObj;
    },

    nextLead() {
      this.createMeetingFlag = false;
      this.meetingDone = false;
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      let valid = this.valudateMeeting();
      if (this.valudateMeeting().flag) {
        this.saveToCallObject(this.rows[this.currentCallIndex]);
        if (this.currentCallIndex < this.rows.length - 1) {
          this.currentCallIndex++;
          this.selectedObject = this.rows[this.currentCallIndex];
          this.mapsKey++;
        }
      } else {
        this.snackbar.msg = valid.msg;
        this.snackbar.show = true;
      }

      this.checkMeeting();
    },

    prevLead() {
      this.createMeetingFlag = false;
      this.meetingDone = false;

      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      this.saveToCallObject(this.rows[this.currentCallIndex]);
      if (this.currentCallIndex > 0) {
        this.currentCallIndex--;
        this.selectedObject = this.rows[this.currentCallIndex];
        this.mapsKey++;
      }
      this.checkMeeting();
    },

    async closeAndSave() {
      this.meetingDone = false;
      this.createMeetingFlag = false;

      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      await this.saveToCallObject(this.selectedObject);
      location.reload();
    },

    timeStampTOdate(timestamp) {
      if (typeof timestamp == "undefined") {
        return "";
      } else if (typeof timestamp.getMonth === "function") {
        return moment(timestamp).format("HH:mm, DD/MM/YYYY");
      }

      return moment(timestamp.toDate()).format("HH:mm, DD/MM/YYYY");
    },

    valudateMeeting() {
      let result = { msg: "", flag: true };

      if (this.selectedObject.status == 7) {
        if (this.selectedObject.address.hasOwnProperty("addressText")) {
          this.selectedObject.address.addressText == ""
            ? (result = {
                msg: result.msg + "\n," + "חובה לציין כתובת ",
                flag: false,
              })
            : () => {};
        } else {
          result = {
            msg: result.msg + "\n," + "חובה לציין כתובת ",
            flag: false,
          };
        }
        this.selectedObject.date === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין תאריך ",
              flag: false,
            })
          : () => {};
        this.selectedObject.hours === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין שעת התחלה ",
              flag: false,
            })
          : () => {};
        this.selectedObject.minutes === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין דקות לשעת התחלה ",
              flag: false,
            })
          : () => {};
        this.selectedObject.hoursTo === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין שעת סיום ",
              flag: false,
            })
          : () => {};
        this.selectedObject.minutesTo === ""
          ? (result = {
              msg: result.msg + "\n," + "חובה לציין דקות לשעת סיום ",
              flag: false,
            })
          : () => {};
      }

      return result;
    },

    async createMeeting() {
      try {
        db.collection("MeetingCount").doc(selaId).set({
          representative:this.$store.state.userObj.userDoc.data().uid,
          date:new Date(),
          displayName:this.$store.state.userObj.userDoc.data().displayName,
          })

      } catch (error) {
        conosle.log("cant count it")        
      }
      let object = { ...this.selectedObject };
      var userObj = this.$store.state.userObj;

      const meitavMeeting =object.source?.includes("מיטב")

      object["updatedOn"] = new Date();
      object["updatedBy"] = userObj.user.uid;
      object["updatedByName"] = userObj.user.displayName;
      var comments = this.addComments();

      if (comments !== "") {
        object.hasOwnProperty("comments")
          ? () => {}
          : (object["comments"] = []);
        object.comments.push(comments);
      }

      this.createMeetingFlag = true;
      var selaId = db.collection("Meetings").doc().id;
      var phoneSelaId = object.selaId + "";
      selaId = selaId + "";
      var endDate = moment(object.date, "YYYY-MM-DD").toDate();
      object["meetingDate"] = moment(object.date, "YYYY-MM-DD").toDate();
      object.meetingDate.setHours(object.hours);
      object.meetingDate.setMinutes(object.minutes);
      endDate.setHours(object.hoursTo);
      endDate.setMinutes(object.minutesTo);
      object["meetingClosedBy"] = userObj.user.uid;
      object["start"] = moment(object.meetingDate).format("YYYY-MM-DD HH:mm");
      object["end"] = moment(endDate).format("YYYY-MM-DD HH:mm");
      object["color"] = "#263238";
      object["timed"] = false;
      object["rank"] = "פגישה רגילה";
      object["meetingStatus"] = "ממתין לאישור";
      object["agent"] = meitavMeeting ? "304304304"  : "0000";
      object["category"] =  meitavMeeting ?  "לא משוייך מיטב" : "לא משיוך";
      object["relevant"] = "";
      if (typeof object["age"] == "number") {
        object["age"] = object["age"].toString();
      }
      object["selaId"] = selaId;
      await db.collection("Meetings").doc(selaId).set(object, { merge: true });
      object["selaId"] = phoneSelaId;
      db.collection("PhoneCalls")
        .doc(object.phone)
        .set(object, { merge: true });

      var dataDoc = await db
        .collection("PhoneCalls")
        .doc(object.phone)
        .collection("Data")
        .doc("meetings")
        .get();
      if (dataDoc.exists) {
        await db
          .collection("PhoneCalls")
          .doc(object.phone)
          .collection("Data")
          .doc("meetings")
          .update({
            list: firebase.firestore.FieldValue.arrayUnion(selaId),
          });
      } else {
        await db
          .collection("PhoneCalls")
          .doc(object.phone)
          .collection("Data")
          .doc("meetings")
          .set({ list: [selaId] });
      }
      this.createMeetingFlag = false;
      this.meitavMeeting = false;

      if (this.currentCallIndex < this.rows.length - 1) {
        this.nextLead();
      } else {
        this.closeAndSave();
      }




    },

    async saveToCallObject(object) {
      var userObj = this.$store.state.userObj;
      object["updatedOn"] = new Date();
      object["updatedBy"] = userObj.user.uid;
      object["updatedByName"] = userObj.user.displayName;

      if(object.status == 1){
        object["info"] = ""
      }

      var comments = this.addComments();

      if (comments !== "") {
        object.hasOwnProperty("comments")
          ? () => {}
          : (object["comments"] = []);
        object.comments.push(comments);
      }

      //    if(object.status == 7){
      //         var selaId  = (db.collection('Meetings').doc()).id;
      //         var phoneSelaId = object.selaId + ""
      //         selaId  = selaId + ""
      //         var endDate = moment(object.date, 'YYYY-MM-DD').toDate();
      //         object['meetingDate'] = moment(object.date, 'YYYY-MM-DD').toDate();
      //         object.meetingDate.setHours(object.hours);
      //         object.meetingDate.setMinutes(object.minutes);
      //         endDate.setHours(object.hoursTo);
      //         endDate.setMinutes(object.minutesTo);
      //         object['meetingClosedBy'] = userObj.user.uid;
      //         object['start'] = moment(object.meetingDate).format('YYYY-MM-DD HH:mm');
      //         object['end'] = moment(endDate).format('YYYY-MM-DD HH:mm');
      //         object['color'] ='#263238';
      //         object['timed'] = false ;
      //         object['rank'] = 'פגישה רגילה';
      //         object['meetingStatus'] = 'ממתין לאישור';
      //         object['agent'] = '0000';
      //         object['category'] ='לא משיוך';
      //         object['selaId']  = selaId;
      //         await db.collection('Meetings').doc(selaId).set(object,{merge:true});
      //         object['selaId']  = phoneSelaId;
      //         db.collection('PhoneCalls').doc(object.phone).set(object,{merge:true});

      //         var dataDoc = await db.collection('PhoneCalls').doc(object.phone).collection('Data').doc('meetings').get()
      //         if(dataDoc.exists){
      //             db.collection('PhoneCalls').doc(object.phone).collection('Data').doc('meetings').update({
      //              list: firebase.firestore.FieldValue.arrayUnion(selaId)
      //             });
      //         }else{
      //              db.collection('PhoneCalls').doc(object.phone).collection('Data').doc('meetings').set({list:[selaId]})
      //         }

      //    }

      if (object.status != 7) {
        object.address = {};
        object.date = "";
        object.meetingDate = "";
        object.hours = "";
        object.minutes = "";
      }
      if (object.status != 3) {
        object.info = "";
      }

      return db
        .collection("PhoneCalls")
        .doc(object.phone)
        .set(object, { merge: true });
    },

    runCalls() {
      this.selectedObject = this.rows[this.currentCallIndex];
      this.dialog = true;
    },
    findePhone(phone) {
      var ref = this;
      var index = null;
      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i].phone == phone) {
          index = i;
          break;
        }
      }
      return index;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatusName(code) {
      var statusObj = this.statusList.filter(function (status) {
        return status.id == code;
      });

      if (typeof statusObj[0] == "undefined") {
        return "";
      } else {
        return statusObj[0].text;
      }
    },
    checkMustAttrs(id) {
      var res = false;

      if (id != 7 && id) {
        res = true;
      } else {
        if (this.dialog) {
          if (this.selectedObject.productType == "פנסיוני") {
            res =
              this.selectedObject.mustAttrs.length ==
              this.financialAttrs.length;
          } else {
            res =
              this.selectedObject.mustAttrs.length ==
              this.insuranceAttrs.length;
          }
        }
        if (this.selectedObject.status == 7 && !res) {
          this.selectedObject.status = 1;
        }
      }

      if (id == 8) {
        res = false;
      }

      return res;
    },

    hideStatusList() {
     const isImportentStatus =  (this.selectedObject.status  == 7 ||
      this.selectedObject.status  == 10 ||
      this.selectedObject.status  == 11 );

      const role = this.$store.state.userObj.userDoc.data().role;
      const isNotAdmin = role != "admin" && role != "mManager"

      return isImportentStatus && isNotAdmin;
    },

    productTypeChanged(event) {
      this.selectedObject.mustAttrs = [];
    },
    addComments() {
      if (!this.selectedObject.note || 0 === this.selectedObject.note.length) {
        return "";
      } else {
        var comment = {
          displayName: this.$store.state.userObj.user.displayName,
          photoURL: this.$store.state.userObj.user.photoURL,
          uid: this.$store.state.userObj.user.uid,
          time: new Date(),
          text: (" " + this.selectedObject.note).slice(1),
          insideComment: this.insideComment ? true : false,
        };
        this.selectedObject.note = "";
        this.insideComment = false;

        return comment;
      }
    },
    async uploadRecords() {
      for (var i = 0; i < this.newPhone.length; i++) {
        var obj = this.phoneCallForant;
        obj.phone = newPhone[i].phone;
        obj.selaId =
          Math.floor(1000000000 + Math.random() * 9000000000) + "" + i;

        obj.city = this.newPhone[i].city ? this.newPhone[i].city : "";
        obj.name = this.newPhone[i].name ? this.newPhone[i].name : "";
        obj.cMail = this.newPhone[i].cMail ? this.newPhone[i].cMail : "";

        obj.productType = "פנסיוני";
        (obj.mustAttrs = []), (obj["representative"] = "00000");
        obj["createdOn"] = new Date();
        obj.updatedBy = this.$store.state.userObj.user.uid;
        obj.updatedOn = new Date();
        obj["statusText"] = "שיחה ראשונה";
        obj["representativeName"] = "לא משוייך";
        obj["relevant"] = "";

        obj.status = 1;
        (obj["mainStatus"] = 0), (obj["reminder"] = false);
        (obj["comments"] = []),
          // var obj= {
          //     phone : phoneFormatter.normalize(this.phoneCallsData[i].phone),
          //     comments:[],
          // }
          db.collection("PhoneCalls").doc(obj.phone).set(obj, { merge: true });
      }
    },
    async sendComments() {
      this.rows[this.currentCallIndex].statusText = this.getStatusName(
        this.rows[this.currentCallIndex].status
      );
      await this.saveToCallObject(this.selectedObject);
    },
  },

  watch: {
    dialog(newState, oldState) {
      if (newState) {
      }
      this.checkMeeting();
    },
  },
};
</script>

<style>
#map {
  margin-right: 10px;
  width: 80%;
  border-bottom: 0.8px solid gray;
  text-align: right;
  font-size: 1rem;
  color: black;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}
#map:disabled {
  color: #bdbdbd;
  border-bottom: 2px dotted #bdbdbd;
}
#map:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #1976d2;
  padding-right: 5px;
}
.mapContainer:disabled {
  color: #eeeeee;
}
.mapContainer:focus-within i {
  color: #1976d2;
}

.timePicker {
  width: 150px;
  height: 50px;
  background-color: brown;
}
.centered-input input {
  text-align: center;
}

.datepicker input {
  border: 0px;
  border-bottom: 1px solid;
  border-radius: 0;
  border-color: cadetblue !important;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
  direction: rtl;
}

.datepicker input:focus-within i {
  color: #1976d2;
}
.centered-input div {
  width: 100%;
}
</style>