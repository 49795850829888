<template>
  <v-app v-if="signIn == 2">
    <v-navigation-drawer v-model="drawer" app dark right mini-variant>
      <v-list dense>
        <!-- <div class="menuLogo"></div> -->
        <div
          class="menuLogo"
          style="height: 56px; width: 100%; margin-bottom: 0.5rem"
        ></div>
        <keep-alive> </keep-alive>
        <router-link
          v-for="item in shoeMenu()"
          :key="item.title"
          :to="item.route"
        >
          <v-list-item
            :style="
              item.route == 'Manager' &&
              $router.currentRoute.fullPath.includes('Manager')
                ? 'background-color: coral;'
                : 'background-color: transparent;'
            "
            link
            @click="selectedItem = item.title"
          >
            <v-list-item-action style="height: 50px; margin-bottom: 15px">
              <v-row justify="center">
                <v-icon>{{ item.icon }}</v-icon>
                <small style="width: 100%; text-align: center">{{
                  item.title
                }}</small>
              </v-row>
            </v-list-item-action>
            <v-list-item-content> </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-row>
          <v-col class="navBarItem">
            <div style="color: white; font-size: 0.8rem">v2.0</div>
            <v-icon v-on:click="() => signout()"> mdi-logout</v-icon>
          </v-col>
        </v-row>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :color="
        ($store.state.environment == 'Moked1' || $store.state.environment == 'Moked3' || $store.state.environment == 'Moked4'  ) ?  '#01579B' :  $store.state.environment == 'Moked5'  ? '#1A237E' : 'cyan darken-4' 
      "
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title  v-if="windowWidth > 500" >{{   $store.state.environment == 'Moked3' ? pageTitle + " - יובלים" : $store.state.environment == 'Moked5' ? pageTitle + " - מיטב דש" : $store.state.environment == 'Moked4' ? pageTitle + " - מני גינדי": pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col  v-if="windowWidth > 500" cols="3">
        <v-text-field
          v-model="onlyPhone"
          label="חיפוש טלפון"
          dense
          solo
          hide-details
          style="text-algin: left; direction: ltr"
          append-outer-icon="mdi-phone"
        ></v-text-field>
      </v-col>
      <v-col  v-if="windowWidth > 500"   cols="2" style="freesearchmargin-right: 1rem">
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue"
              @click="searchForItems"
              dark
              v-bind="attrs"
              v-on="on"
            >
              חפש
            </v-btn>
          </template>

          <v-card min-height="300" min-width="200" style="padding: 1rem">
            <v-row
              no-gutters
              justify="center"
              align="center"
              style="height: 200px"
            >
              <v-progress-circular
                v-if="serchLoading == 0"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-card
                v-else-if="serchLoading == 1"
                min-width="500"
                elevation="0"
              >
                <!-- <v-card-title>{{ getNameForSearch() }}</v-card-title> -->
                <div v-for="item in serachRes" :key="item.id">
                  <h4 style="padding-top: 1rem; padding: right">
                    {{ item.data().name }}
                  </h4>
                  <v-row
                    no-gutters
                    style="padding: 1rem; height: 100%"
                    align="center"
                  >
                    <v-avatar
                      style="margin-left: 1rem"
                      size="60"
                      :color="
                        item.type == 'meeting'
                          ? 'teal lighten-4'
                          : 'cyan lighten-4'
                      "
                    >
                      <v-icon large>{{
                        item.type == "meeting" ? "mdi-handshake" : "mdi-phone"
                      }}</v-icon>
                    </v-avatar>
                    <h5 style="margin: 0; margin-left: 1rem">
                      {{ item.type == "meeting" ? "פגישה" : "טלפון" }}
                    </h5>
                    <h5 style="margin: 0; margin-left: 1rem">
                      {{ item.data().statusText }}
                    </h5>
                    <h5
                      v-if="item.type == 'meeting'"
                      style="margin: 0; margin-left: 1rem"
                    >
                      {{
                        parseDateTimeTOString(item.data().meetingDate.toDate())
                      }}
                    </h5>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="openSearch(item)"
                      :color="
                        item.type == 'meeting'
                          ? 'teal lighten-4'
                          : 'cyan lighten-4'
                      "
                      >הצג</v-btn
                    >
                  </v-row>
                </div>
              </v-card>
              <div v-else>
                <v-card min-width="200" elevation="0">
                  <v-row justify="center" height="center">
                    <v-img
                      width="50"
                      src="../src/assets/astronaut.svg"
                      style="margin-top: 1rem"
                    ></v-img>
                  </v-row>
                  <h4 style="margin-top: 1rem; text-align: center">
                    לא נמצאו תוצאות
                  </h4>
                </v-card>
              </div>
            </v-row>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-title>{{user.email}}</v-toolbar-title> -->

      <v-menu
         v-if="windowWidth > 500"
        :v-model="menuVis"
        offset-y
        :close-on-click="false"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :color="newReminders ? 'amber' : 'transparent'"
            left
            overlap
            dot
            ><v-btn icon x-small v-bind="attrs" v-on="on"
              ><v-icon>mdi-bell</v-icon></v-btn
            ></v-badge
          >
        </template>
        <v-card min-width="500" v-if="reminders.length > 0">
          <v-card-title style="padding: 1rem" justify="end">
            <v-btn
              elevation="0"
              color="grey lighten-3"
              style="margin-left: 1rem"
              @click="deleteAll"
              >נקה הכל</v-btn
            >
            <v-btn elevation="0" color="grey lighten-3" @click="seeAll"
              >ראיתי הכל</v-btn
            >

            <v-spacer></v-spacer>

            <v-progress-circular
              indeterminate
              color="primary"
              v-if="jumpLoading"
            ></v-progress-circular>
          </v-card-title>
          <div style="max-height: 600px; overflow-y: auto">
            <v-list-item
              v-for="(item, index) in reminders"
              :key="index"
              style="padding: 1rem; background-color: white"
            >
              <v-row align="center" no-gutters>
                <v-badge
                  :color="item.isNew ? 'amber' : 'transparent'"
                  left
                  overlap
                >
                  <v-avatar
                    size="50"
                    :color="
                      item.type == 'phoneCall'
                        ? 'cyan lighten-4'
                        : 'teal lighten-4'
                    "
                    style="margin-bottom: 1rem; margin-left: 1rem"
                  >
                    <v-icon v-if="item.type == 'phoneCall'">mdi-phone</v-icon>
                    <v-icon v-else>mdi-handshake</v-icon>
                  </v-avatar>
                </v-badge>

                <v-col>
                  <h4>{{ parseDateTOString(item.date.toDate()) }}</h4>
                  <div style="font-size: 1rem; max-width: 350px">
                    {{ item.phone }} ,
                    <span style="font-weight: bold; font-size: 0.9rem">{{
                      item.name
                    }}</span>
                  </div>
                  <!-- <div>{{item.city}}, {{item.statusText}}</div> -->
                  <!-- <p>{{item.note}}</p> -->
                </v-col>

                <v-btn style="margin-left: 1rem" icon @click="jumpTOItem(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn icon @click="deleteReminder(item)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </v-row>
            </v-list-item>
          </div>
        </v-card>
        <v-list v-else>
          <v-col algin="center" justify="center">
            <v-row justify="center" style="margin: 1rem">
              <v-icon x-large>mdi-calendar-clock</v-icon>
            </v-row>
            <p>אין תזכורות כרגע</p>
          </v-col>
        </v-list>
      </v-menu>

      <h5 style="margin: 8px">
        {{ this.$store.state.userObj.user.displayName }}
      </h5>

      <user-avatar :user="this.$store.state.userObj.userDoc.data()" :size="50">
      </user-avatar>
    </v-app-bar>

    <v-content>
      <v-overlay v-model="$store.state.loading">
        <v-progress-circular
          color="blue lighten-3"
          indeterminate
          size="64"
        ></v-progress-circular>
        <h4
          style="
            color: #bbdefb;
            text-align: center;
            margin-top: 1rem;
            margin-right: 5px;
          "
        >
          רק רגע...
        </h4>
      </v-overlay>
      <router-view :key="routerkey"></router-view>
    </v-content>
  </v-app>
  <v-app v-else-if="signIn == 1">
    <Login></Login>
  </v-app>
  <v-app v-else>
    <div class="fullPage">
      <img width="300" src="./assets/fulllogowhite.png" />
      <div style="width: 300px; margin-top: 40px">
        <v-progress-linear
          indeterminate
          rounded
          height="6"
          color="white"
        ></v-progress-linear>

        <v-btn
          style="position: fixed; bottom: 1rem; left: 1rem; font-weight: bold"
          color="blue accent-2"
          dark
          @click="signout"
          >יציאה</v-btn
        >
      </div>
    </div>
  </v-app>
</template>

<script>
import Notiflix from "notiflix";
import firebase from "firebase";
import { db, rootDb, updateDb } from "./firebase";
import Localbase from "localbase";
import Login from "./components/Login";
import moment from "moment";
import UserAvatar from "./components/UserAvatar.vue";

export default {
  components: {
    Login,
    UserAvatar,
  },
  name: "App",
  data: () => ({
    onLine: true,
    signIn: 0,
    emailInp: "",
    serachRes: [],
    drawer: null,
    overlay: true,
    passwordInp: "",
    onlyPhone: "",
    user: null,
    menuVis: false,
    serchLoading: 0,
    routerkey: 0,
    selectedItem: "Home",
    selectedItemClicked: "Home",
    pageTitle: "תיאום פגישות",
    jumpLoading: false,
    items: [
      {
        title: "דשבורד",
        icon: "mdi-view-dashboard",
        color: "#0288D1",
        selected: false,
        route: "/",
      },
      {
        title: "שיחות לביצוע",
        icon: "mdi-phone",
        color: "#0288D1",
        selected: false,
        route: "/PhoneCalls",
      },
      {
        title: "שיחות שבוצעו",
        icon: "mdi-file-phone",
        color: "#0288D1",
        selected: false,
        route: "/PhoneCallsDone",
      },
      {
        title: "יומן פגישות",
        icon: "mdi-calendar",
        color: "#0288D1",
        selected: false,
        route: "/MeetingsCalendar",
      },
      {
        title: "ניהול",
        icon: "mdi-account-tie",
        color: "#0288D1",
        selected: false,
        route: "/Manager",
      },
      {
        title: "רשומות טלפונים",
        icon: "mdi-card-account-phone",
        color: "#0288D1",
        selected: false,
        route: "/PhoneCallsRecords",
      },
      // { title: 'צ׳ט', icon: 'mdi-google-hangouts', color: '#0288D1',selected : false,route: '/chat' },
      {
        title: "הרשאות",
        icon: "mdi-account-group",
        color: "#0288D1",
        selected: false,
        route: "/users",
      },
      {
        title: "טעינת נתונים",
        icon: "mdi-file-upload",
        color: "#0288D1",
        selected: false,
        route: "/loadData",
      },
      {
        title: "סוכנים",
        icon: "mdi-chart-arc",
        color: "#0288D1",
        selected: false,
        route: "/agents_dashboard",
      },

      // { title: 'דוחות', icon: 'mdi-table', color: '#0288D1',selected : false,route: '/settings' },
    ],

    reminders: [],
  }),

  watch: {
    selectedItem: function (newVal, oldval) {
      this.routerkey++;
    },
  },

  computed: {
    getacronym() {
      return this.user.email
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
  },

  created() {
    //Notiflix.Loading.Init({ svgColor: "#039BE5" });
    Notiflix.Notify.Init({});
    Notiflix.Report.Init({});

    let localbase = new Localbase("localbase");
    let ref = this;

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let environment = (
          await rootDb.collection("Users").doc(user.uid).get()
        ).data().env;

        let localenv = await localbase
          .collection("Settings")
          .doc("environment")
          .get();

        this.$store.state.environment = localenv.id;

        if (environment.includes(this.$store.state.environment)) {
          console.log(this.$store.state.environment);
          if (!(this.$store.state.environment == "Moked1")) {
          }
          updateDb(this.$store.state.environment);
        } else {
          this.signout();
          Notiflix.Notify.Failure("אין לך הרשאת גישה למוקד הזה");
        }

        console.log(environment);
        let ref = this;
        var userDoc = await rootDb
          .collection("Users")
          .doc(user.uid + "")
          .get();

        this.signIn = 2;
        this.user = user;

        this.$store.state["userObj"] = { userDoc: userDoc, user: user };
        this.$store.state["project"] = firebase.apps[0].options.projectId;
        console.log("im here");
        console.log(this.$store.state["userObj"]);

        let remidersPromise = await db
          .collection("Users")
          .doc(user.uid)
          .collection("Reminders")
          .where("date", "<=", new Date())
          .where(
            "date",
            ">=",
            moment(new Date()).subtract(3, "months").toDate()
          )
          .where("visable", "==", true)
          .orderBy("date", "desc")
          .get();
        remidersPromise.forEach((doc) => {
          ref.reminders.push(doc.data());
        });
      } else {
        console.log("sign out");
        this.signIn = 1;
      }
    });
  },

  methods: {
    amIOnline(e) {
      this.onLine = e;
    },
    getNameForSearch() {
      return typeof this.serachRes[0] != "undifind"
        ? this.serachRes[0].data().name
        : "";
    },
    signin: function () {
      //Notiflix.Loading.Standard("please wait");
      firebase
        .auth()
        .signInWithEmailAndPassword(this.emailInp, this.passwordInp)
        .then((res) => {
          //Notiflix.Loading.Remove();
        })
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(error.message);
          //Notiflix.Loading.Remove();
          Notiflix.Notify.Failure("mail or password are worng");
        });
    },
    signout: function () {
      //Notiflix.Loading.Standard("Logout");
      firebase
        .auth()
        .signOut()
        .then(function () {
          //Notiflix.Loading.Remove();
        })
        .catch(function (error) {
          Notiflix.Notify.Failure("Failure in logout");
        });
    },
    menuItemClick: function (title) {
      this.pageTitle = title;
    },
    showTile: function (item) {
      var role = this.$store.state.userObj.userDoc.data().role;
      if (item.route == "/Manager") {
        if (role != "admin" && role != "mManager") {
          return false;
        }
      }
      return true;
    },

    seeAll: function () {
      this.reminders.forEach((item) => {
        item["isNew"] = false;
        db.collection("Users")
          .doc(this.$store.state.userObj.user.uid)
          .collection("Reminders")
          .doc(item.selaId)
          .update({ isNew: false });
      });
    },
    deleteAll: function () {
      this.reminders.forEach((item) => {
        db.collection("Users")
          .doc(this.$store.state.userObj.user.uid)
          .collection("Reminders")
          .doc(item.selaId)
          .delete();
      });
      this.reminders = [];
    },

    shoeMenu() {
      let adminMenu = [
        "/",
        "/PhoneCalls",
        "/MeetingsCalendar",
        "/Manager",
        "/users",
        "/loadData",
        "/PhoneCallsDone",
        "/PhoneCallsRecords",
      ];
      let managerMenu = [
        "/",
        "/PhoneCalls",
        "/MeetingsCalendar",
        "/Manager",
        "/PhoneCallsDone",
      ];

      let inspectorMenu = ["/agents_dashboard"];
      let others = ["/", "/PhoneCalls", "/MeetingsCalendar", "/PhoneCallsDone"];
      let results = [];
      var role = this.$store.state.userObj.userDoc.data().role;

      if (role == "admin") {
        this.items.forEach((element) => {
          if (adminMenu.includes(element.route)) {
            results.push(element);
          }
        });
      } else if (role == "mManager") {
        this.items.forEach((element) => {
          if (managerMenu.includes(element.route)) {
            results.push(element);
          }
        });
      } else if (role == "inspector") {
        this.items.forEach((element) => {
          if (inspectorMenu.includes(element.route)) {
            results.push(element);
          }
        });
      } else {
        this.items.forEach((element) => {
          if (others.includes(element.route)) {
            results.push(element);
          }
        });
      }
      return results;
    },
    parseDateTOString(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    parseDateTimeTOString(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    async jumpTOItem(item) {
      this.jumpLoading = true;
      this.reminders.filter((obj) => {
        return item.selaId == obj.selaId;
      })[0].isNew = false;
      db.collection("Users")
        .doc(this.$store.state.userObj.user.uid)
        .collection("Reminders")
        .doc(item.selaId)
        .update({ isNew: false });

      if (
        this.$store.state.userObj.userDoc.data().role == "admin" ||
        this.$store.state.userObj.userDoc.data().role == "mManager"
      ) {
        let admins = await rootDb
          .collection("Users")
          .where("role", "==", "admin")
          .get();
        let mManagers = await rootDb
          .collection("Users")
          .where("role", "==", "mManager")
          .get();

        for (const user of admins.docs) {
          db.collection("Users")
            .doc(user.data().uid)
            .collection("Reminders")
            .doc(item.selaId)
            .update({ isNew: false });
        }

        for (const user of mManagers.docs) {
          db.collection("Users")
            .doc(user.data().uid)
            .collection("Reminders")
            .doc(item.selaId)
            .update({ isNew: false });
        }
      }

      let temp = item.path.split("/");
      let popObject = await rootDb
        .collection(temp[0])
        .doc(temp[1])
        .collection(temp[2])
        .doc(temp[3])
        .get();
      this.jumpLoading = false;

      console.log(this.$router);

      if (popObject.exists) {
        this.$store.state["popObject"] = popObject.data();
        if (item.type == "phoneCall") {
          if (this.$router.currentRoute.path != "/PhoneCalls") {
            this.$router.push({ path: "/PhoneCalls" });
          }
        } else {
          if (this.$router.currentRoute.path != "/MeetingsCalendar") {
            this.$router.push({ path: "/MeetingsCalendar" });
          }
        }
        this.routerkey++;
      }
    },
    async deleteReminder(item) {
      this.reminders = this.reminders.filter((obj) => {
        return item.selaId != obj.selaId;
      });
      db.collection("Users")
        .doc(this.$store.state.userObj.user.uid)
        .collection("Reminders")
        .doc(item.selaId)
        .delete();

      if (
        this.$store.state.userObj.userDoc.data().role == "admin" ||
        this.$store.state.userObj.userDoc.data().role == "mManager"
      ) {
        let admins = await rootDb
          .collection("Users")
          .where("role", "==", "admin")
          .get();
        let mManagers = await rootDb
          .collection("Users")
          .where("role", "==", "mManager")
          .get();

        for (const user of admins.docs) {
          db.collection("Users")
            .doc(user.data().uid)
            .collection("Reminders")
            .doc(item.selaId)
            .delete();
        }

        for (const user of mManagers.docs) {
          db.collection("Users")
            .doc(user.data().uid)
            .collection("Reminders")
            .doc(item.selaId)
            .delete();
        }
      }
    },

    openSearch(item) {
      this.$store.state["popObject"] = item.data();
      if (item.type == "phoneCall") {
        if (this.$router.currentRoute.path != "/PhoneCalls") {
          this.$router.push({ path: "/PhoneCalls" });
        }
      } else {
        if (this.$router.currentRoute.path != "/MeetingsCalendar") {
          this.$router.push({ path: "/MeetingsCalendar" });
        }
      }
      this.routerkey++;
    },

   async restoreRecordFromMeetings(phone){
      const israeliPhoneNumber = phone
      const digitsOnly = israeliPhoneNumber.replace(/\D/g, '').replace(/^972/, '0');
      console.log("not found lets restore it",digitsOnly); // Output: 0541234567
      const meetingRef = db.collection('Meetings').where("formatedPhone","==",phone)
      const meetingSnap = await meetingRef.get()
      const meetingDocs = meetingSnap.docs
      if(meetingDocs.length > 1){
        console.log("more than one meeting")
      }
      else{
        const meetingDoc = meetingDocs[0]
        // restore it 
        const meetingData = meetingDoc.data()
        await db.collection("PhoneCalls").doc(meetingData.formatedPhone).set(meetingData,{merge:true})

    
     }
     
    },

    async searchForItems() {
     const userId =this.$store.state.userObj.userDoc.data().uid
     console.log("user doc ",this.$store.state.userObj.userDoc.data())
      this.serachRes = [];
      try {
        let res = [];
        let p = this.onlyPhone.replace("+972", "0").replace(/\D/g, "");
        this.onlyPhone = p;
        if (this.onlyPhone.length > 0) {
          this.serchLoading = 1;
          let phoneRef = db
            .collection("PhoneCalls")
            .where("phone", "!=", "")
            .where("formatedPhone", "==", p);

          let otherPhones = db
            .collection("PhoneCalls")
            .where("phone2", "==", p);

          if (
            this.$store.state.userObj.userDoc.data().role != "admin" &&
            this.$store.state.userObj.userDoc.data().role != "mManager"
          ) {


            phoneRef.where(
              "representative",
              "==",
              userId
            );
            otherPhones.where(
              "representative",
              "==",
              userId
              );
          }

          let doc = await phoneRef.get();
          let docs2 = await otherPhones.get();
          doc.docs.forEach((element) => {
          if (
            this.$store.state.userObj.userDoc.data().role != "admin" &&
            this.$store.state.userObj.userDoc.data().role != "mManager"
          ){
            if(element.data().representative == userId){
              element["type"] = "phoneCall";
              res.push(element);
            }
          }else{
              element["type"] = "phoneCall";
             res.push(element);
          }
  
          });
          docs2.docs.forEach((element) => {
          if (
            this.$store.state.userObj.userDoc.data().role != "admin" &&
            this.$store.state.userObj.userDoc.data().role != "mManager"
          ){
            if(element.data().representative == userId){
              element["type"] = "phoneCall";
              res.push(element);
            }
          }else{
              element["type"] = "phoneCall";
             res.push(element);
          }
          });

          if (res.length > 0) {
            let meetingsRef = db
              .collection("Meetings")
              .where("phone", "==", res[0].data().phone);
            if (
              this.$store.state.userObj.userDoc.data().role != "admin" &&
              this.$store.state.userObj.userDoc.data().role != "mManager"
            ) {
              meetingsRef.where(
                "representative",
                "==",
                userId
              );
            }

            let meetings = await meetingsRef.get();
            meetings.docs.forEach((element) => {
              if (
                this.$store.state.userObj.userDoc.data().role != "admin" &&
                this.$store.state.userObj.userDoc.data().role != "mManager"
              ){
                if(element.data().representative == userId){
                  element["type"] = "meeting";
                  res.push(element);
                }
              }else{
                  element["type"] = "meeting";
                res.push(element);
              }

            });
          }
          else{
            await this.restoreRecordFromMeetings(p)
            this.searchForItems()
          }

          this.serachRes = [...res];
          this.serchLoading = 1;

          if (this.serachRes.length == 0) {
            this.serchLoading = 2;
          }

          this.onlyPhone = "";
        }

      } catch (error) {
        console.error(error);
        this.serchLoading = 2;
        this.onlyPhone = "";
      }
    },
  },
  computed: {
    newReminders() {
      let res = this.reminders.filter((item) => {
        return item.isNew;
      });

      return res.length > 0;
    },
  },
};
</script>

<style>
/* html ::-webkit-scrollbar {
        display: none;
 } */
</style>

<style scoped>
.fullPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #243949 0%, #517fa4 100%);
}

a {
  text-decoration: none;
}

.navBarItem {
  color: white !important;
  font-size: 30px;
  text-align: center;
  margin: 10px;
  font-size: 30px !important;
}
.navBarItem:hover {
  color: #0288d1 !important;
  cursor: pointer;
}
.avatar {
  color: white !important;
}

.navBarItem:last-child {
  margin-top: auto;
  position: fixed;
  bottom: 0;
}

.logo {
  color: #a9bcd0 !important;
  border-bottom: 1px solid #a9bcd0;
  padding: 10px;
  margin: 5px;
  background-image: url("./assets/logo.png");
  width: 90%;
  height: 70px;
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
}
.megaCard {
  width: 97%;
  height: 96%;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.router-link-exact-active > div {
  background-color: #01579b !important;
}

.v-application a {
  color: white;
}

.loginContainer {
  background-color: rgb(59, 117, 59);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginCard {
  width: 60vh;
  height: 60vh;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#loginButton {
  background-color: rgb(22, 141, 22);
  width: 200px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  margin-top: 50px;
}
#loginButton:hover {
  background-color: rgb(9, 96, 23);
  cursor: pointer;
}

input {
  width: 50%;
  height: 50px;
  border: 1px solid #a9bcd0;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  color: black;
  font-size: 15px;
}

.loginLogo {
  background-size: 100px;
  background-image: url("./assets/logo.png");
  width: 100px;
  height: 100px;
  margin: 30px;
  margin-top: 0;
}

.menuLogo {
  background-size: 35px;
  background-image: url("./assets/onlylogowhite.png");
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-position: 50%;
}

.bigLogo {
  background-size: 300px;
  background-image: url("./assets/logo.png");
  width: 300px;
  height: 300px;
  margin: 30px;
}
.offline {
  background-color: #fc9842;
  background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
}
.online {
  background-color: #00b712;
  background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
}
</style>
