<template>
  <div style="padding: 10px">
    <phonecallsrecords></phonecallsrecords>
  </div>
</template>

<script>
import phonecallsrecords from "../components/PhoneCallsRecords";
import managerDashboardPhones from "../components/ManagerDashboardPhones";
import managerDashboardMeethigns from "../components/ManagerDashboardMeethigns";
import settings from "../components/Settings";

export default {
  components: {
    phonecallsrecords,
    managerDashboardPhones,
    managerDashboardMeethigns,
    settings,
  },

  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style>
</style>