
<template>
  <div class="myDataTable" style="padding: 30px">
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="selaId"
      class="elevation-1"
      ref="dataTable"
      :height="height"
      max-height="100%"
      max-width="100%"
      multi-sort
      fixed-header
      :footer-props="{ 'items-per-page-options': [5, 10, 50, 100, 300, -1] }"
      :items-per-page="10"
      :show-select="selcted"
      @current-items="getFiltered"
      @click:row="rowClick"
      v-model="selctedRows"
    >
      <template v-slot:top>
        <v-row style="padding: 0.8rem; padding-left: 10px">
          <v-spacer></v-spacer>
          <v-btn
            @click="actionsClick"
            color="#01579B"
            width="90"
            text
            style="font-weight: 500"
            v-if="selctedRows.length > 1"
          >
            <v-row
              v-if="$store.state.userObj.userDoc.data().role != 'mRep'"
              align="center"
            >
              <v-icon>mdi-checkbox-multiple-marked</v-icon>
              <div>פעולות</div>
            </v-row>
          </v-btn>

          <v-btn
            @click="excelExport"
            color="#01579B"
            width="90"
            text
            style="font-weselectedobjectight: 500"
          >
            <v-row align="center">
              <v-icon>mdi-file-excel-outline</v-icon>
              <div>Excel</div>
            </v-row>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:body.prepend>
        <tr class="filtersContainer">
          <td class="filterinputCell" v-if="selcted"></td>
          <td v-for="item in colList" :key="item.label" class="filterinputCell">
            <input
              v-model="item.data"
              :type="item.type"
              :placeholder="item.label"
              class="filterInput"
            />
          </td>
        </tr>
        <!-- <tr>
              <td v-for="item in headersMap" :key="item.label" style="background-color: #01579B;padding:7px;">
                <input v-model="item.data" :type="item.type" :label="item.label" class="filterInput">
              </td>
            </tr> -->
      </template>

      <template v-slot:item.phone="{ item }">
        <div style="direction: ltr">{{ item.phone }}</div>
      </template>
      <template v-slot:item.photoURL="{ item }">
        <div style="margin: 0.5rem">
          <user-avatar :user="item" :size="45"></user-avatar>
        </div>
      </template>

      <template v-slot:item.reminder="{ item }">
        {{ item.reminder ? "כן" : "לא" }}
      </template>
      <template v-slot:item.resFlag="{ item }">
        {{ item.resFlag ? "יש" : "אין" }}
      </template>

      <template v-slot:item.date="{ item }">
        {{ item.date.split("-").reverse().join().replaceAll(",", "/") }}
      </template>
      <template v-slot:item.updateStatus="{ item }">
        <!-- {{moment(item.updateStatus.toDate()).format('DD/MM/YYYY')}} -->
        {{ parseDate(item.updateStatus) }}
      </template>
      <template v-slot:item.createdOn="{ item }">
        <!-- {{moment(item.updateStatus.toDate()).format('DD/MM/YYYY')}} -->
        {{ parseDate(item.createdOn) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import UserAvatar from "./UserAvatar.vue";
import xlsx from "json-as-xlsx";

export default {
  name: "data-table",
  props: {
    colList: {
      type: Array,
    },
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    selcted: {
      type: Boolean,
    },
    height: {
      type: String,
    },
  },

  components: {
    UserAvatar,
  },

  data() {
    return {
      name: "",
      phone: "",
      subject: "",
      product: "",
      address: "",
      calendar: "",
      date: "",
      time: "",
      notes: "",
      rowsPerPage: 15,
      abc: [
        "A1",
        "B1",
        "C1",
        "D1",
        "E1",
        "F1",
        "G1",
        "H1",
        "I1",
        "J1",
        "K1",
        "L1",
        "M1",
        "N1",
        "O1",
        "P1",
        "Q1",
        "R1",
        "S1",
        "T1",
        "U1",
        "V1",
        "W1",
        "X1",
        "Y1",
        "Z1",
      ],
      filteredItems: [],
      coloumsArray: [],
      selctedRows: [],

      // headersMap:[
      //     {field:"name",data:"",type:"text",label:"שם"},
      //     {field:"phone",data:"",type:"text",label:"טלפון"},
      //     {field:"subject",data:"",type:"text",label:"נושא"},
      //     {field:"product",data:"",type:"text",label:"מוצר"},
      // ],
    };
  },
  computed: {
    headers() {
      var headersList = [];
      this.coloumsArray = [];
      this.labelsArray = [];
      const ref = this;
      var length = this.colList.length;
      let index = 0;
      this.colList.forEach((item) => {
        ref.coloumsArray.push(item.field);
        ref.labelsArray.push(item.label);
        headersList.push({
          text: item.label,
          align: "right",
          sortable: true,
          value: item.field,
          class: "headTable",
          divider: true,
          width: item.width,
          filterable: true,
          letter: this.abc[index],
          filter: (f) => {
            return (f + "").toLowerCase().includes(item.data.toLowerCase());
          },
        });
        index = index + 1;
      });

      return headersList;
    },
  },
  methods: {
    getInitials(text) {
      return text
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    onexport() {
      const ref = this;
      let temp = [];
      this.filteredItems.forEach((item) => {
        let tempItem = {};
        for (const property in item) {
          if (ref.coloumsArray.includes(property)) {
            tempItem[property] = item[property];
          }
        }
        temp.push(tempItem);
      });
      var ws = XLSX.utils.json_to_sheet(temp);
      var wb = XLSX.utils.book_new();
      let headersArray = [];
      var range = XLSX.utils.decode_range(ws["!ref"]);
      // for (var C = range.s.r; C <= range.e.r; ++C) {
      //   var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      //   if (typeof ws[address] != "undefined") {
      //     if (typeof ws[address].v != "undefined") {
      //     }
      //   }
      // this.headers.forEach((col) => {
      // if (col.value == ws[address].v) {
      //   ws[address].v = col.text;
      //

      // });
      //}

      // make Workbook of Excel
      var now = moment(new Date()).format("DD-MM-YYYY  HH;mm");
      ref.headers.forEach((col) => {
        if (typeof ws[col.letter] != "undefined") {
          if (typeof ws[col.letter].v != "undefined") {
            console.log(ws[col.letter].v);
            ws[col.letter].v = col.text;
          }
        }
      });

      var fileName = this.title + " " + now + ".xlsx";
      XLSX.utils.book_append_sheet(wb, ws, this.title);
      // XLSX.writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },

    excelExport() {
      let columnsMap = [];
      let fileName = `${moment().format("DD-MM-YYYY mm;HH")} דוח crm`;
      let rows = [...this.items];

      for (const cell of rows) {
        if (cell.hasOwnProperty("createdOn")) {
          try {
            cell.createdOn = cell.createdOn.toDate();
          } catch (error) {
            console.log(error);
          }
          // cell.createdOn = cell.createdOn.toDate();
        }
        if (cell.hasOwnProperty("updateStatus")) {
          // cell.updateStatus = cell.updateStatus.toDate();
        }
      }

      this.headers.forEach((header) => {
        columnsMap.push({
          label: header.text,
         value: (row) => { 
            if(
              header.value == "closingAmout"  || header.value == "meetingCharge"
            ){
              try {
                const numberValue = Number(row[header.value]);
                return isNaN(numberValue) ? 0 : numberValue;

              } catch (error) {
                return 0;
              }
            }
            else if(header.value == "resFlag" || header.value == "reminder"){
               return  row[header.value]  ? "יש" : "אין"
            }
            else{
              return row[header.value];
            }
          }
        });
      });


      console.log(columnsMap);
      let data = [
        {
          sheet: "דוח",
          columns: columnsMap,
          content: rows,
        },
      ];
      let settings = {
        fileName: fileName, // Name of the resulting spreadsheet
        extraLength: 4, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings);
    },
    rowClick(row) {
      this.$emit("onRowClickEvent", row);
    },
    actionsClick() {
      this.$emit("onActionsClickEvent", this.selctedRows);
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    parseDate(item) {
      try {
        if (typeof item == "undefined") {
          return "";
        } else {
          return moment(item.toDate()).format("DD/MM/YYYY");
        }
      } catch (error) {
        console.log(item);
      }
    },
    clearSelectedRows() {
      this.selctedRows = [];
    },
  },
};
</script>

<style>
.myDataTable tr:hover {
  cursor: pointer;
  background-color: #bbdefb !important;
  color: #0d47a1 !important;
}
.myDataTable tr:first-child td {
  border: 0.8px solid lightgray !important;
  text-align: center !important;
}

.myDataTable tr:first-child th {
  padding: 5px !important;
  font-size: calc(1vw - 0.15em) !important;
}
.myDataTable .headTable {
  background-color: #01579b !important;
  color: whitesmoke !important;
}
.myDataTable tr td {
  font-size: calc(1vw - 0.15em) !important;
}

.filterInput {
  width: 100%;
  border: 0.8px solid lightgray;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  background-color: white;
  height: 23px;
  font-size: calc(1vw - 0.15em) !important;
}
.filtersContainer {
}
.filterinputCell {
  background-color: #e0e0e0;
}
.myDataTable tbody tr:nth-of-type(odd) {
  background-color: #eceff1;
}
.myDataTable body tr:nth-of-type(even) {
  background-color: #fafafa;
}

.v-data-table__selected {
  background: #e0f7fa !important;
}

.myDataTable thead .text-start {
  background-color: #01579b !important;
  color: whitesmoke !important;
}
.myDataTable thead .text-start i {
  color: whitesmoke !important;
}
</style>