
<template>
  <v-col>
    <v-row
      style="padding-top: 20px; margin-right: 20px"
      no-gutters
      align="center"
    >
      <v-card width="300" elevation="2">
        <v-toolbar elevation="2">
          <v-toolbar-title>יעד בונוס יומי</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined @click="dialogs.dialog1 = true">{{
            bonustaget
          }}</v-btn>
        </v-toolbar>
      </v-card>
      <v-card style="margin-right: 2rem" min-width="300" elevation="2">
        <v-toolbar elevation="2">
          <v-toolbar-title>שליחת תזכורת פידבק לסוכנים</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="pushLoading"
            style="margin-right: 1rem; color: #0967b9"
            color="blue lighten-3"
            elevation="0"
            @click="sendPush"
            >שליחה</v-btn
          >
        </v-toolbar>
      </v-card>
    </v-row>

    <v-row style="padding: 20px" justify="space-around">
      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>סטאטוסים</v-toolbar-title>
            <v-btn disabled fab color="#0288D1" dark absolute left bottom>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in lists.statusList"
                  :key="item.id"
                  @click=""
                >
                  <v-list-item-avatar :color="item.color">
                    <v-row
                      justify="center"
                      :style="'color:' + item.textColor"
                      >{{ item.text.charAt(0) }}</v-row
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>תת-סטאטוס</v-toolbar-title>
            <v-btn
              fab
              color="#0288D1"
              dark
              absolute
              left
              bottom
              @click="dialogs.dialog2 = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in lists.subStatus"
                  :key="item.status"
                  @click=""
                >
                  <v-list-item-avatar :color="item.color"> </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>תנאי סף פיננסי</v-toolbar-title>
            <v-btn
              fab
              color="#0288D1"
              dark
              absolute
              left
              bottom
              @click="dialogs.dialog3 = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in lists.financialAttrs"
                  :key="item"
                  @click=""
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>תנאי סף ביטוח</v-toolbar-title>
            <v-btn
              fab
              color="#0288D1"
              dark
              absolute
              left
              bottom
              @click="dialogs.dialog4 = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in lists.insuranceAttrs"
                  :key="item"
                  @click=""
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="padding: 20px" justify="space-around">
      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>סטאטוס ראשי</v-toolbar-title>
            <v-btn disabled fab color="#0288D1" dark absolute left bottom>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in lists.meetingStatusList"
                  :key="item.id"
                  @click="() => {}"
                >
                  <v-list-item-avatar :color="item.color">
                    <v-row
                      justify="center"
                      :style="'color:' + item.textColor"
                      >{{ item.text.charAt(0) }}</v-row
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>דירוג פגישה</v-toolbar-title>
            <v-btn disabled fab color="#0288D1" dark absolute left bottom>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in calenderList.meetingRank"
                  :key="item.id"
                  @click="() => {}"
                >
                  <v-list-item-avatar :color="item.background">
                    <v-row justify="center" :style="'color:' + item.color">{{
                      item.text.charAt(0)
                    }}</v-row>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>סיבות ללא רלוונטי כרגע</v-toolbar-title>
            <v-btn
              fab
              color="#0288D1"
              dark
              absolute
              left
              bottom
              @click="dialogs.dialog8 = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item v-for="item in agentsAppLists.reasons" :key="item">
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>

      <v-col>
        <v-card width="300" max-height="500">
          <v-toolbar dark color="#455A64" elevation="0">
            <v-toolbar-title>תכונות נוספות</v-toolbar-title>
            <v-btn
              fab
              color="#0288D1"
              dark
              absolute
              left
              bottom
              @click="dialogs.dialog9 = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-content>
            <div style="height: 400px; overflow: Auto">
              <v-list>
                <v-list-item
                  v-for="item in lists.phoneCallsAttrs"
                  :key="item"
                  @click="() => {}"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-content>
        </v-card>
      </v-col>
    </v-row>

    <v-col>
      <v-card width="300" max-height="500">
        <v-toolbar dark color="#455A64" elevation="0">
          <v-toolbar-title>מקורות</v-toolbar-title>
          <v-btn
            fab
            color="#0288D1"
            dark
            absolute
            left
            bottom
            @click="dialogs.dialog10 = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-content>
          <div style="height: 400px; overflow: Auto">
            <v-list>
              <v-list-item
                v-for="item in values.sources"
                :key="item"
                @click="showDeleteSourcePrompt(item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-content>
      </v-card>
    </v-col>
    <v-row> </v-row>
    <v-dialog v-model="dialogs.dialog1" max-width="290">
      <v-card>
        <v-card-title>שינוי בונוס יעד יומי</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              type="number"
              v-model="newbonustaget"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog1 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="updateBonusTarget">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.dialog2" max-width="290">
      <v-card>
        <v-card-title>הוספת תת-סטאטוס</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              label="תת-סטאטוס"
              outlined
              dense
              v-model="newSubStatus"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog2 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="addSubStatus">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.dialog3" max-width="290">
      <v-card>
        <v-card-title>הוספת תנאי סף פיננסי</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              label="שם תנאי סף"
              outlined
              dense
              v-model="newaddFinancialAttr"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog3 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="addFinancialAttrs">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.dialog4" max-width="290">
      <v-card>
        <v-card-title>הוספת תנאי סף ביטוח</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              label="שם תנאי סף"
              outlined
              dense
              v-model="newInsuranceAttrs"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog4 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="addinsuranceAttrs">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.dialog9" max-width="290">
      <v-card>
        <v-card-title>הוספת תכונה </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              label="שם"
              outlined
              dense
              v-model="newPhoneCallsAttrs"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog9 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="addPhoneCallsAttrs">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.dialog8" max-width="290">
      <v-card>
        <v-card-title>הוספת סיבה </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              label="שם"
              outlined
              dense
              v-model="newReasons"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog8 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="addReasons">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.dialog10" max-width="290">
      <v-card>
        <v-card-title>הוספת מקור </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              label="שם"
              outlined
              dense
              v-model="newSource"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog10 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="addSources">שמור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.dialog11" max-width="290">
      <v-card>
        <v-card-title>מחיקת מקור</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-row no-gutters align="center">
              <h4>{{ sourceToDelete }}</h4>
              <v-progress-circular
                style="margin-right: 1rem"
                indeterminate
                color="blue"
                size="20"
                width="3"
                v-if="recordsLoading"
              ></v-progress-circular>
            </v-row>
            <v-progress-linear
              v-if="deleteLoading"
              indeterminate
              color="red"
              height="10"
            ></v-progress-linear>
            <h4>- {{ recordToMove }} רשומות</h4>
            <h4>- {{ meetingsToMove }} פגישות</h4>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogs.dialog11 = false">ביטול</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="red"
            :disabled="!recordToMove == 0"
            @click="deleteSources"
            >למחוק בטוח</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";

export default {
  data: () => ({
    calenderList: {},
    lists: {},
    values: {},
    agentsAppLists: {},
    bonustaget: 0.0,
    newbonustaget: 0.0,
    pushLoading: false,
    sourceToDelete: "---",
    recordToMove: 0,
    meetingsToMove: 0,
    deleteLoading: false,
    recordsLoading: false,
    dialogs: {
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialog6: false,
      dialog7: false,
      dialog8: false,
      dialog9: false,
      dialog10: false,
      dialog11: false,
    },

    newSubStatus: "",
    newaddFinancialAttr: "",
    newInsuranceAttrs: "",
    newPhoneCallsAttrs: "",
    newReasons: "",
    newSource: "",
  }),

  async created() {
    //Notiflix.Loading.Pulse("מייבא נתונים");
    this.$store.state.loading = true;

    this.calenderList = (
      await db.collection("System").doc("CalendarLists").get()
    ).data();
    this.lists = (await db.collection("System").doc("lists").get()).data();
    this.agentsAppLists = (
      await db.collection("System").doc("agentsAppLists").get()
    ).data();
    this.bonustaget = (
      await db.collection("System").doc("values").get()
    ).data().bounsTarget;
    this.newbonustaget = this.bonustaget;
    this.values = (await db.collection("System").doc("values").get()).data();
    //Notiflix.Loading.Remove();
    this.$store.state.loading = false;
  },

  methods: {
    async deleteSources() {
      this.deleteLoading = true;
      let y = this.sourceToDelete;
      this.values.sources = this.values.sources.filter((obj) => {
        return obj != y;
      });
      await db
        .collection("System")
        .doc("values")
        .update("sources", this.values.sources);
      this.sourceToDelete = "---";
      this.deleteLoading = false;
      this.dialogs.dialog11 = false;
    },
    async showDeleteSourcePrompt(item) {
      console.log(item);
      this.sourceToDelete = item;
      this.recordToMove = 0;
      this.meetingsToMove = 0;
      this.dialogs.dialog11 = true;
      this.recordsLoading = true;

      let query = await db
        .collection("PhoneCalls")
        .where("source", "==", item)
        .get();
      let query2 = await db
        .collection("Meetings")
        .where("source", "==", item)
        .get();
      this.recordsLoading = false;
      this.recordToMove = query.size;
      this.meetingsToMove = query2.size;
    },
    async sendPush() {
      this.pushLoading = true;
      let sendPush = firebase.functions().httpsCallable("sendReminderMenulay");
      await sendPush({});
      this.pushLoading = false;
    },
    async updateBonusTarget() {
      //Notiflix.Loading.Pulse("מעדכן");
      this.$store.state.loading = true;

      await db
        .collection("System")
        .doc("values")
        .update("bounsTarget", this.newbonustaget);
      this.bonustaget = (
        await db.collection("System").doc("values").get()
      ).data().bounsTarget;
      this.newbonustaget = this.bonustaget;
      this.dialogs.dialog1 = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;
    },

    async addSubStatus() {
      //Notiflix.Loading.Pulse("מעדכן");
      this.$store.state.loading = true;

      this.lists.subStatus.push({
        color: "#607D8B",
        status: 3,
        text: this.newSubStatus,
      });

      await db
        .collection("System")
        .doc("lists")
        .update("subStatus", this.lists.subStatus);
      this.lists = (await db.collection("System").doc("lists").get()).data();

      this.dialogs.dialog2 = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.newSubStatus = "";
    },

    async addinsuranceAttrs() {
      //Notiflix.Loading.Pulse("מעדכן");
      this.$store.state.loading = true;

      this.lists.insuranceAttrs.push(this.newInsuranceAttrs);
      await db
        .collection("System")
        .doc("lists")
        .update("insuranceAttrs", this.lists.insuranceAttrs);
      this.lists = (await db.collection("System").doc("lists").get()).data();

      this.dialogs.dialog4 = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.newInsuranceAttrs = "";
    },
    async addPhoneCallsAttrs() {
      //Notiflix.Loading.Pulse("מעדכן");
      this.$store.state.loading = true;

      this.lists.phoneCallsAttrs.push(this.newPhoneCallsAttrs);
      await db
        .collection("System")
        .doc("lists")
        .update("phoneCallsAttrs", this.lists.phoneCallsAttrs);
      this.lists = (await db.collection("System").doc("lists").get()).data();

      this.dialogs.dialog9 = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.newInsuranceAttrs = "";
    },
    async addReasons() {
      //Notiflix.Loading.Pulse("מעדכן");
      this.$store.state.loading = true;

      this.agentsAppLists.reasons.push(this.newReasons);
      await db
        .collection("System")
        .doc("agentsAppLists")
        .update("reasons", this.agentsAppLists.reasons);
      this.agentsAppLists = (
        await db.collection("System").doc("agentsAppLists").get()
      ).data();

      this.dialogs.dialog8 = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.newReasons = "";
    },
    async addSources() {
      //Notiflix.Loading.Pulse("מעדכן");
      this.$store.state.loading = true;

      this.values.sources.push(this.newSource);
      await db
        .collection("System")
        .doc("values")
        .update({ sources: this.values.sources });
      this.values = (await db.collection("System").doc("values").get()).data();

      this.dialogs.dialog10 = false;
      //Notiflix.Loading.Remove();
      this.$store.state.loading = false;

      this.newSource = "";
    },
  },
};
</script>

<style>
</style>