import firebase from "firebase/app";
import "firebase/firestore";

const defaultProject = {
  apiKey: "AIzaSyDnwVZSulkYqajkF8G6TanzAMRMpqIjEgY",
  authDomain: "sela-crm-prod.firebaseapp.com",
  databaseURL: "https://sela-crm-prod.firebaseio.com",
  projectId: "sela-crm-prod",
  storageBucket: "sela-crm-prod.appspot.com",
  messagingSenderId: "243541988790",
  appId: "1:243541988790:web:cf3415f6a1b0e2c3c49d17",
  measurementId: "G-Y41WG5FS6B"
};
const secundProject = {
  apiKey: "AIzaSyBXMJ00AaX9GIWJuYpu8hcWZphKzs3W09k",
  authDomain: "sela-crm-prod-haifa.firebaseapp.com",
  projectId: "sela-crm-prod-haifa",
  storageBucket: "sela-crm-prod-haifa.appspot.com",
  messagingSenderId: "589845039580",
  appId: "1:589845039580:web:7562fe8b65ae70d33de719",
  measurementId: "G-8S1MDMFQ3W"
};

const textSearchKeys = {
  defaultProject: { id: "F669L5R95A", key: "1aa5e0ed1c72958ab7b57579f6b17c99" },
  secundProject: { id: "R05QQOH58E", key: "eed4c0796bb3b2119f37ce95354713db" }
};

// const firebaseApp = firebase.initializeApp(defaultProject);
const firebaseApp = firebase.initializeApp(defaultProject);

let db = firebaseApp.firestore();
let rootDb = firebaseApp.firestore();

const rtdb = firebaseApp.database();
const fbstorage = firebaseApp.storage();

const app = firebase;

function updateDb(env) {
  db = firebaseApp
    .firestore()
    .collection("Environments")
    .doc(env);
}

// Export the database for components to use.
export { db };
export { fbstorage };
export { textSearchKeys };
export { rootDb };
export { app };

export { updateDb };
