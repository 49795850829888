<template>
  <div id="managerDash" style="padding: 20px">
    <v-row style="padding: 0; margin: 0">
      <v-spacer></v-spacer>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        desne
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 300px">
            <v-text-field
              dense
              v-model="dates"
              label="טווח  תאריכים"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </div>
        </template>
        <v-date-picker
          color="#0277BD"
          v-model="dates"
          range
          no-title
          scrollable
          @change="sortDats"
        >
          <v-spacer></v-spacer>
          <v-btn text color="#0277BD" @click="$refs.menu.save(date)"
            >סינון</v-btn
          >
        </v-date-picker>
      </v-menu>
    </v-row>

    <v-row>
      <v-col>
        <v-card height="170" style="display: flex">
          <v-row no-gutters align="center" justify="center">
            <v-col align="center" justify="center">
              <h2>שיחה ראשונה</h2>
              <h1>{{ callsTomake[0] }}</h1>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col>
        <v-card height="170" style="display: flex">
          <v-row no-gutters align="center" justify="center">
            <v-col align="center" justify="center">
              <h2>שיחת המשך</h2>
              <h1>{{ callsTomake[1] }}</h1>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col>
        <v-card height="170" style="display: flex">
          <v-row no-gutters align="center" justify="center">
            <v-col align="center" justify="center">
              <h2>לא ענו</h2>
              <h1>{{ callsTomake[2] }}</h1>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="170" style="display: flex">
          <v-row no-gutters align="center" justify="center">
            <v-col align="center" justify="center">
              <h2>חידוש שיחה</h2>
              <h1>{{ callsTomake[3] }}</h1>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col>
        <v-card height="170" style="display: flex">
          <v-row no-gutters align="center" justify="center">
            <v-col align="center" justify="center">
              <h2 style="color: #ffa000">סה"כ</h2>
              <h1>{{ totalCalls }}</h1>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        <v-card>
          <v-card-title>פילוח שיחות לפי סטאטוס</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="statusChartKey"
            :options="statusChart.chartOptions"
            :series="statusChart.series"
            :width="windowWidth / 5.5"
            :height="windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initStatusChart"
            >
              <v-radio label="הכל" value="all"></v-radio>
              <v-radio label="בתהליך" value="progress"></v-radio>
              <v-radio label="הסתיים" value="done"></v-radio>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card v-for="item in representatives" :key="item.index">
          <v-card-title>אחוז פגישות רלוונטיות</v-card-title>
          <v-divider></v-divider>
          <v-row justify="center" no-gutters>
            <apexchart
              type="radialBar"
              :width="windowWidth / 5.5"
              :height="windowWidth / 5.5"
              :options="item.chartOptions"
              :series="item.series"
            ></apexchart>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions style="justify-content: center">
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initStatusChart"
            >
              <div style="font-size: 1em; color: #1e88e5">
                {{ bounsTarget }} / {{ item.relaventCalls }}
              </div>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";


export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    listsDocs: [],
    representatives: [],
    bounsTarget: 0,
    dates: [],
    menu: false,
    phoneCalls: [],
    callsTomake: [0, 0, 0, 0],
    totalCalls: 0,
    statusChartKey: 100,
    repsChartKey: 1000,
    //charts
    chartOptionsRadio: "all",
    statusChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל השיחות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    },
    representiveChart: {
      series: [],
      chartOptions: {
        colors: [],

        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 100,
          colors: ["#fff"],
        },
        title: {},
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },

        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 0,
        },
      },
    },
  }),
  methods: {
    initStatusChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
        ref.statusChart.chartOptions.labels.push(status.text),
          ref.statusChart.chartOptions.colors.push(status.color);
      });

      this.phoneCalls.forEach((call) => {
        if (this.chartOptionsRadio == "progress") {
          if (
            call.statusText == "שיחה ראשונה" ||
            call.statusText == "לא ענה" ||
            call.statusText == "שיחת המשך" ||
            call.statusText == "פגישה" ||
            call.statusText == "חידוש שיחה" ||
            call.statusText == "המשך טיפול סוכן" ||
            call.statusText == "דחיה"
          ) {
            statusMap[call.statusText]++;
          }
        } else if (this.chartOptionsRadio == "done") {
          if (
            call.statusText == "לא רלוונטי" ||
            call.statusText == "לא רלוונטי כרגע" ||
            call.statusText == "להסיר" ||
            call.statusText == "סגירה"
          ) {
            statusMap[call.statusText]++;
          }
        } else {
          statusMap[call.statusText]++;
        }
      });

      ref.statusChart.series = [];

      for (const status in statusMap) {
        ref.statusChart.series.push(statusMap[status]);
      }

      this.statusChartKey++;
    },
    initRepsChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};
      var seriesObjListMap = {};

      ref.representiveChart.chartOptions.colors = [];
      ref.representiveChart.chartOptions.xaxis.categories = [];
      ref.representiveChart.series = [];

      this.listsDocs.statusList.forEach((status) => {
        ref.representiveChart.chartOptions.colors.push(status.color);
        seriesObjListMap[status.text] = { name: status.text, data: [] };
      });

      this.phoneCalls.forEach((call) => {
        statusMap[call.representative] = {
          name: call.representativeName,
          statusList: ref.getStatusInitMap(),
        };
      });

      for (const status in statusMap) {
        ref.representiveChart.chartOptions.xaxis.categories.push(
          statusMap[status].name
        );
      }

      this.phoneCalls.forEach((call) => {
        statusMap[call.representative].statusList[call.statusText]++;
      });

      for (const status in seriesObjListMap) {
        ref.representiveChart.series.push(seriesObjListMap[status]);
      }

      var objs = [];
      var objsMap = {};
      for (const status in statusMap) {
        for (const representative in statusMap[status]) {
          objs.push(statusMap[status]);
        }
      }

      objs.forEach((obj) => {
        objsMap[obj.name] = obj.statusList;
      });

      for (const representative in objsMap) {
        for (const status in objsMap[representative]) {
          seriesObjListMap.hasOwnProperty(status)
            ? seriesObjListMap[status].data.push(
                objsMap[representative][status]
              )
            : () => {};
          // (seriesObjListMap[status]).data.push((objsMap[representative])[status])
        }
      }

      // console.log(objsMap)

      this.repsChartKey++;
    },
    getStatusInitMap() {
      var statusMap = {};
      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
      });
      return statusMap;
    },

    sortDats() {
      this.dates.sort();
      this.updateDashboard();
    },
    async updateDashboard() {
      //Notiflix.Loading.Pulse('מעדכן נתונים');
      this.$store.state.loading = true;
      var ref = this;
      this.phoneCalls = [];
      this.bonusTarget();

      ref.representatives = [];
      console.log(db.path);
      var listsDocs = await db.collection("System").doc("lists").get();

      var from = new Date(this.dates[0]);
      var to = new Date(this.dates[1]);
      var firstCalls = await db
        .collection("PhoneCalls")
        .where("representative", "==", this.$store.state.userObj.user.uid)
        .where("status", "==", 1)
        .get();
      var callAgain = await db
        .collection("PhoneCalls")
        .where("representative", "==", this.$store.state.userObj.user.uid)
        .where("status", "==", 6)
        .get();
      var dontAnswer = await db
        .collection("PhoneCalls")
        .where("representative", "==", this.$store.state.userObj.user.uid)
        .where("status", "==", 2)
        .get();
      var reNew = await db
        .collection("PhoneCalls")
        .where("representative", "==", this.$store.state.userObj.user.uid)
        .where("status", "==", 8)
        .get();

      var phoneCallsPromise = await db
        .collection("PhoneCalls")
        .where("representative", "==", this.$store.state.userObj.user.uid)
        .where("firstCall", "==", false)
        .where("updateStatus", ">=", from)
        .where("updateStatus", "<=", to)
        .get();
      var representativesPromise = await db
        .collection("Representatives")
        .where("uid", "==", this.$store.state.userObj.user.uid)
        .get();
      var index = 0;

      this.callsTomake[0] = firstCalls.size;
      this.callsTomake[1] = callAgain.size;
      this.callsTomake[2] = dontAnswer.size;
      this.callsTomake[3] = reNew.size;

      this.totalCalls =
        this.callsTomake[0] +
        this.callsTomake[1] +
        this.callsTomake[2] +
        this.callsTomake[3];

      representativesPromise.forEach(async (doc) => {
        if (doc.id != "00000") {
          var data = doc.data();
          index = index + 100;
          data["index"] = index;
          data["totalCalls"] = 0;
          data["chekedCalls"] = 0;
          data["bonusTarget"] = 45;
          data["relaventCalls"] = 0;
          data["chekedPrecent"] = 0;
          data["relevantPrecent"] = 0;
          data["series"] = [0];
          data["name"] = data.displayName;

          (data["chartOptions"] = {
            chart: {
              height: 350,
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "60%",
                },
              },
            },
            labels: [data.name],
          }),
            ref.representatives.push(data);
          db.collection("Meetings")
            .where("representative", "==", this.$store.state.userObj.user.uid)
            .where("meetingDate", ">=", from)
            .where("meetingDate", "<=", to)
            .get()
            .then((snapshout) => {
              var cheked = 0;
              var relevant = 0;

              snapshout.forEach((doc) => {
                doc.data().status != 1 ? cheked++ : () => {};
                doc.data().relevant == "כן" ? relevant++ : () => {};
              });
              data.chekedCalls = cheked;
              data["totalCalls"] = snapshout.docs.length;
              data.relaventCalls = relevant;
              data["chekedPrecent"] =
                snapshout.docs.length > 0
                  ? Math.floor((cheked / snapshout.docs.length) * 100)
                  : parseInt("0");
              data["relevantPrecent"] =
                data.bonusTarget > 0
                  ? Math.floor((relevant / data.bonusTarget) * 100)
                  : parseInt("0");

              //data.series[0] =  Math.floor((cheked/snapshout.docs.length)*100);
              data.series = [Math.floor((relevant / ref.bounsTarget) * 100)];
              data.index++;
            });
        }
      });

      phoneCallsPromise.forEach((doc) => {
        ref.phoneCalls.push(doc.data());
      });
      this.listsDocs = listsDocs.data();
      this.initStatusChart();
      this.initRepsChart();
      //Notiflix.Loading.Remove()
      this.$store.state.loading = false;
    },

    bonusTarget() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var count = 0;
      while (date1 <= date2) {
        if (date1.getDay() < 5) {
          count++;
        }
        date1.setDate(date1.getDate() + 1);
      }
      this.bounsTarget = Math.round(count * 2.045);
    },
  },
  computed: {},
  async mounted() {
    //Notiflix.Loading.Init({svgColor:"#039BE5",});
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

    this.dates = [startOfMonth, endOfMonth];

    this.updateDashboard();
  },
};
</script>

<style>
/* #managerDash .row{
        width: 70vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    } */
</style>