import '@babel/polyfill'
// import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueExcelXlsx from "vue-excel-xlsx";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import * as VueGoogleMaps from "vue2-google-maps";
import VueWindowSize from 'vue-window-size';

Vue.use(VueWindowSize);
Vue.use(VueExcelXlsx);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyDnwVZSulkYqajkF8G6TanzAMRMpqIjEgY',
  libraries: "places",
  language: 'he',
  region: 'IL'

  // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDnwVZSulkYqajkF8G6TanzAMRMpqIjEgY",
    libraries: "places",
    language: 'he',
    region: 'IL'
  }
});

Vue.config.productionTip = false

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
