<template>
  <v-col>
    <v-navigation-drawer
      v-model="filter"
      absolute
      temporary
      width="400"
      color="#FAFAFA"
      style="padding: 5px"
    >
      <v-row no-gutters>
        <v-btn icon @click="filter = false"
          ><v-icon color="#01579B"> mdi-close</v-icon></v-btn
        >
      </v-row>
      <v-col style="padding: 15px">
        <v-row justify="center" align="center">
          <v-icon color="#01579B">mdi-filter</v-icon>
          <h4 style="color: #01579b">סינון</h4>
        </v-row>
        <v-divider style="margin: 5px; margin-top: 20px"></v-divider>
        <div style="height: 40px"></div>

        <v-btn color="blue-grey lighten-4" large @click="saveFilterConfig"
          ><span><v-icon>mdi-content-save-cog-outline</v-icon></span
          >שמור מסננים</v-btn
        >

        <div style="height: 40px"></div>
        <v-menu
          v-if="tableMode"
          ref="menu4"
          v-model="menu4"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              style="margin-top: 15px; font-size: calc(1vw - 0.18em)"
              v-model="dates"
              label="סנן תאריכים"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
            ></v-combobox>
          </template>
          <v-date-picker
            color="#0277BD"
            locale="he"
            v-model="dates"
            range
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="filterEventsByDate"
              :disabled="dates.length != 2"
              >סנן</v-btn
            >
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-menu>

        <div style="height: 40px" v-if="tableMode"></div>

        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="selctedAgents"
              :items="calendarLists.agentsInfos"
              label="שם סוכן"
              item-text="name"
              return-object
              multiple
              clearable
              dense
              @input="searchInput = ''"
            >
              <template v-slot:prepend>
                <v-btn @click="selectAllAgent" icon color="#0288D1"
                  ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
                >
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip
                  v-if="index === 0"
                  style="margin: 2px; font-size: calc(1vw - 0.2em)"
                >
                  <span>{{
                    selctedAgents.length === calendarLists.agentsInfos.length
                      ? "כל הפריטים"
                      : item.name
                  }}</span>
                </v-chip>
                <span
                  style="font-size: calc(1vw - 0.2em)"
                  v-if="index === 1"
                  class="grey--text caption"
                  >{{
                    selctedAgents.length === calendarLists.agentsInfos.length
                      ? ""
                      : "(+" + (selctedAgents.length - 1) + "פריטים)"
                  }}</span
                >
              </template>
              <!-- <v-row slot="prepend-item" align="center" justify="center" style="padding-top:5px">
                          <v-text-field label="שם סוכן" dense hide-details style="margin-left:1rem;margin-right:1rem;margin-bottom:2rem;margin-top:1rem"></v-text-field>
                        </v-row> -->
            </v-autocomplete>
          </v-col>
        </v-row>

        <div style="height: 40px"></div>

        <v-autocomplete
          v-model="selectedMainStatus"
          :items="lists.meetingStatusList"
          label="סטאטוס ראשי"
          item-text="text"
          return-object
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllMainStatus" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selectedMainStatus.length === lists.meetingStatusList.length
                  ? "כל הפריטים"
                  : item.text
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selectedMainStatus.length === lists.meetingStatusList.length
                  ? ""
                  : "(+" + (selectedMainStatus.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>

        <div style="height: 40px"></div>

        <v-autocomplete
          :disabled="lockForm"
          v-model="selctedStatus"
          :items="lists.statusList"
          label="סטאטוס"
          item-text="text"
          return-object
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllsStatus" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selctedStatus.length === lists.statusList.length
                  ? "כל הפריטים"
                  : item.text
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedStatus.length === lists.statusList.length
                  ? ""
                  : "(+" + (selctedStatus.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>

        <div style="height: 40px"></div>
        <v-autocomplete
          v-model="selctedMeetingTypes"
          :items="calendarLists.meetingRank"
          label="סיווג פגישה"
          item-text="text"
          return-object
          multiple
          clearable
          dense
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllMeetginsTypes" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selctedMeetingTypes.length === calendarLists.meetingRank.length
                  ? "כל הפריטים"
                  : item.text
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedMeetingTypes.length === calendarLists.meetingRank.length
                  ? ""
                  : "(+" + (selctedMeetingTypes.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>

        <div style="height: 40px"></div>

        <v-btn block dark color="blue" @click="addEvents">החל סינון</v-btn>

        <div>
          <v-divider style="margin: 5px; margin-top: 20px"></v-divider>
        </div>
        <small>לפי הפגישות הקיימות :</small>
        <v-autocomplete
          style="margin-top: 2rem"
          v-model="selctedCitys"
          :items="citys"
          label="הפגישה בעיר"
          multiple
          clearable
          dense
          @change="hidMeetings"
        >
          <template v-slot:prepend>
            <v-btn @click="selectAllCitys" icon color="#0288D1"
              ><v-icon>mdi-checkbox-marked-circle-outline</v-icon></v-btn
            >
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              style="margin: 2px; font-size: calc(1vw - 0.2em)"
            >
              <span>{{
                selctedCitys.length == citys.length ? "כל הפריטים" : item
              }}</span>
            </v-chip>
            <span
              style="font-size: calc(1vw - 0.2em)"
              v-if="index === 1"
              class="grey--text caption"
              >{{
                selctedCitys.length === citys.length
                  ? ""
                  : "(+" + (selctedCitys.length - 1) + "פריטים)"
              }}</span
            >
          </template>
        </v-autocomplete>
      </v-col>
    </v-navigation-drawer>
    <v-row no-gutters style="margin-left: 30px">
      <v-btn v-if="!loading" icon color="#0277BD" @click="updateMeetings"
        ><v-icon>mdi-sync</v-icon></v-btn
      >
      <v-progress-circular
        v-else
        size="30"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-switch
        dense
        v-model="tableMode"
        label="תצוגת טבלה"
        style="margin-right: 10px; margin-top: 3px"
      ></v-switch>
      <v-switch
        v-if="!repMode"
        dense
        v-model="dragMode"
        label="גרירה"
        style="margin-right: 10px; margin-top: 3px"
      ></v-switch>
      <v-switch
       
        @change="updateMeetings"
        dense
        v-model="showConstrains"
        label="אילוצים"
        style="margin-right: 10px; margin-top: 3px"
      ></v-switch>

      <v-spacer></v-spacer>
      <v-btn color="#0277BD" dark @click="filter = true">
        <v-row no-gutters justify="center" align="center">
          <v-icon>mdi-filter</v-icon>
          <div>סינון</div>
        </v-row>
      </v-btn>
    </v-row>
    <DataTable
      style="padding-top: 10px"
      height="70vh"
      :key="tableKey"
      title="פגישות"
      :selcted="true"
      :colList="columns"
      :items="getOnlyMeetings()"
      @onActionsClickEvent="actionsClicked"
      @onRowClickEvent="rowClicked"
      v-if="tableMode"
    ></DataTable>

    <v-row style="padding:20px;padding:-top:2px" v-if="!tableMode">
      <div style="width: 100%" id="calHolder">
        <v-col>
          <v-sheet height="34px">
            <v-toolbar flat color="white" height="30px">
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn dense fab text small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                height="20px"
                style="font-size: calc(1vw - 0.2em)"
                dense
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                היום</v-btn
              >
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-left="0"
                transition="scale-transition"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    height="20px"
                    style="font-size: calc(1vw - 0.2em)"
                    dense
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    >לך לתאריך</v-btn
                  >
                </template>
                <v-date-picker
                  locale="he"
                  v-model="focus"
                  @input="menu3 = false"
                  color="#EF6C00"
                  header-color="primary"
                ></v-date-picker>
              </v-menu>
              <v-toolbar-title
                v-if="$refs.calendar"
                style="
                  margin: 10px;
                  margin-right: 30px;
                  font-size: calc(1vw - 0.1em);
                  font-weight: bold;
                "
              >
                {{ getHebMonth }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-slider
                v-if="type != 'month'"
                style="margin-top: 35px"
                v-model="zoom"
                min="20"
                max="40"
                label="זום"
              ></v-slider>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    height="20px"
                    style="font-size: calc(1vw - 0.2em)"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>יומי</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>שבועי</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>חודשי</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 ימים</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'category'">
                    <v-list-item-title>סוכנים</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="80vh" style="direction: ltr">
            <v-calendar
              id="calendar"
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getStatusColor"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              event-text-color="#FFFFFF"
              event-height="30"
              locale="he"
              :type="type"
              category-show-all
              :categories="categories"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
              @click:event="oneClick"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
              @mouseleave.native="cancelDrag"
              :interval-height="zoom"
              :key="'calendarKey' + calendarKey"
              @contextmenu.native="handler"
              event-more-text="פגישות נוספות"
              category-show-all
              v-scroll.self="onScroll"
            >
              <template
                v-slot:event="{ event }"
                style="width:100%; !impoertent;postion:relative"
              >
                <div
                  v-if="event.eventType != 'constrain'"
                  :id="event.selaId"
                  style="position: relative"
                >
                  <div
                    v-if="event.resFlag"
                    style="
                      font-size: 12px;
                      position: absolute;
                      top: -2px;
                      right: 15px;
                    "
                  >
                    <v-icon color="white" x-small>mdi-f-text</v-icon>
                  </div>
                  <div
                    style="
                      font-size: 10px;
                      position: absolute;
                      top: -2px;
                      right: 30px;
                      font-weight: bold;
                    "
                  >
                    {{ event.city }}
                  </div>
                  <div>
                    <v-icon
                      v-if="checkIfMeitav(event)"
                      size="15"
                      color="#69F0AE"
                      style="position: absolute; top: 0; left: 15px"
                      >mdi-alpha-m-circle</v-icon
                    >
                  </div>
                  <div>
                    <v-icon
                      v-if="checkIFPhoneCallMettings(event)"
                      size="13"
                      color="#EEFF41"
                      style="position: absolute; top: 15px; left: 1px"
                      >mdi-phone-classic</v-icon
                    >
                    <v-icon
                      v-if="event.resFlag"
                      size="10"
                      color="white"
                      style="position: absolute; top: 0; right: 14px"
                      >mdi-message</v-icon
                    >
                    <v-icon
                      v-if="checkIFHaifaMettings(event)"
                      size="13"
                      color="#18FFFF"
                      style="
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        transform: rotate(180deg);
                      "
                      >mdi-alpha-u-box</v-icon
                    >
                  </div>

                  <!-- <div
                    class="agentIndicator"
                    :style="
                      event.agentStatus == 9
                        ? 'background-color:red'
                        : 'background-color:' +
                          getEventColorByStatus(event.agentStatus) +
                          ';'
                    "
                  ></div> -->
                  <div
                    :class="
                      checkForCallAgain(event)
                        ? 'statusIndicator2'
                        : 'statusIndicator'
                    "
                    :style="'background-color:' + getEventColor(event) + ';'"
                    @click="printthis(event)"
                  ></div>

                  <div style="position: absolute; top: 10px; right: -15px">
                    <v-row
                      class="eventTile"
                      justify="start"
                      align="center"
                      :style="
                        'direction:rtl; font-weight:bold;padding-left:15px;padding-right:15px;color:' +
                        getEventTextColor(event) +
                        ';height:100%;border:0px solid ' +
                        getEventRankColor(event) +
                        ';margin:0.3px;margin-otp'
                      "
                    >
                      <div
                        :style="
                          'background-color:' +
                          getEventRankColor(event) +
                          ';padding:2px;color:' +
                          getEventRankTextColor(event)
                        "
                      >
                        <div style="margin-left: 2px; margin-right: 2px">
                          <span v-if="event.agnetResponseDocFlag"
                            ><v-icon small color="white"
                              >mdi-message-alert</v-icon
                            ></span
                          >
                          {{ getTime(event.start) }}
                        </div>
                      </div>

                      <div style="margin-right: 2px">
                        <div>{{ event.name }}</div>
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                </div>

                <div v-else :id="event.selaId" style="position: relative">
                  <div style="position: absolute; top: 10px; right: -15px">
                    <v-row
                      class="eventTile"
                      justify="start"
                      align="center"
                      :style="'direction:rtl; font-weight:bold;padding-left:15px;padding-right:15px;color:black;height:100%;margin:0.3px;margin-otp'"
                    >
                      <div>
                        <div style="margin-left: 2px; margin-right: 2px">
                          <span v-if="event.agnetResponseDocFlag"
                            ><v-icon small color="white"
                              >mdi-message-alert</v-icon
                            ></span
                          >
                          {{ getTime(event.start) }}
                        </div>
                      </div>

                      <div style="margin-right: 2px">
                        <div>{{ event.name }} - {{ event.city }}</div>
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>
                </div>
              </template>
            </v-calendar>
            <!-- right click menu -->
            <v-menu
              v-if="!lockForm"
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
              :close-on-content-click="false"
            >
              <div v-if="!mettingLoading">
                <v-toolbar
                  height="90"
                  elevation="2"
                  color="grey lighten-5"
                  style="margin-bottom: 0px"
                >
                  <user-avatar :size="50" :user="agnetObject"></user-avatar>
                  <v-col>
                    <!-- <div style="margin-right:10px;font-weight:bold">{{selectedObject.category}}</div> -->
                    <div style="width: 250px; height: 30px">
                      <v-row no-gutters>
                        <v-text-field
                          @input="filterAgentsOnMenu(agnetObjectNameForFilter)"
                          :disabled="selectedObject.status != 7"
                          v-model="agnetObjectNameForFilter"
                          single-line
                          hide-details
                          dense
                          label="משויך לסוכן"
                        ></v-text-field>
                        <div
                          :key="'meetingSyncedId' + meetingSyncedId"
                          style="margin-right: 1rem"
                        >
                          <v-icon small v-if="meetingSynced" color="green"
                            >mdi-cellphone-check</v-icon
                          >
                          <v-icon small v-else>mdi-cellphone</v-icon>
                        </div>
                      </v-row>
                    </div>
                    <div
                      style="
                        margin-right: 10px;
                        font-size: 12px;
                        font-weight: bold;
                      "
                    >
                      {{ selectedObject.name }} - {{ selectedObject.rank }}
                    </div>
                    <div
                      v-if="selectedObject['resFlag']"
                      style="
                        font-weight: bold;
                        margin-right: 10px;
                        font-size: 12px;
                      "
                    >
                      <span
                        ><v-icon
                          small
                          :color="
                            getStatusColorById(selectedObject.agentStatus)
                          "
                          >mdi-square-rounded</v-icon
                        ></span
                      >
                      {{ getStatusText(selectedObject.agentStatus) }}
                    </div>
                    <div
                      style="
                        margin-right: 10px;
                        font-size: 12px;
                        font-weight: bold;
                      "
                    >
                      {{ selectedObject.city }}
                      <span style="font-weight: lighter"
                        >{{ selectedObject.hours }}:{{
                          selectedObject.minutes
                        }}-{{ selectedObject.hoursTo }}:{{
                          selectedObject.minutesTo
                        }}
                      </span>
                    </div>
                  </v-col>
                </v-toolbar>
                <div
                  v-if="selectedObject.status == 7"
                  style="
                    background-color: white;
                    min-height: 60px;
                    max-height: 160px;
                    min-width: 300px;
                    overflow: auto;
                  "
                >
                  <v-list-item
                    style="margin-top: 10px; margin-right: 12px"
                    v-for="(item, index) in menuAgentsList"
                    :key="index"
                    @click="selctedAgentFromMenu(item)"
                  >
                    <v-row align="center">
                      <!-- <v-avatar size="50" style="border: 1px solid #546e7a"
                        ><img :src="item.photoURL"
                      /></v-avatar> -->

                      <user-avatar :size="50" :user="item"></user-avatar>
                      <div style="margin-right: 5px; margin-top: 2px">
                        {{ item.name }}
                      </div>
                    </v-row>
                  </v-list-item>
                </div>
                <div
                  v-if="selectedObject.status == 7"
                  style="
                    background-color: white;
                    height: 150;
                    min-width: 300px;
                    overflow: auto;
                  "
                >
                  <v-row no-gutters justify="center" style="padding: 2px">
                    <v-btn-toggle
                      @change="updateMainStatusFromMenu()"
                      v-model="selectedObject.meetingStatus"
                      tile
                      color="blue accent-3"
                      group
                    >
                      <v-btn value="לא מאושר" small>לא מאושר</v-btn>
                      <v-btn value="ממתין לאישור" small>ממתין</v-btn>
                      <v-btn value="אישור מנהלי" small>אישור מנהלי</v-btn>
                      <v-btn value="מאושר" small>מאושר</v-btn>
                    </v-btn-toggle>
                  </v-row>
                </div>
                <div v-else style="background-color: white">
                  <div style="margin-right: 5px; margin-top: 2px">
                    סטאטוס : {{ selectedObject.statusText }}
                  </div>
                  <div style="margin-right: 5px; margin-top: 2px">
                    תגובה מסוכן:
                    {{ selectedObject.resFlag ? "יש תגובה" : "עדיין אין" }}
                  </div>
                  <div style="margin-right: 5px; margin-top: 2px">
                    רלוונטי: {{ selectedObject.relevant }}
                  </div>
                </div>
              </div>
              <div v-else>
                <v-card width="200" height="200">
                  <v-row
                    no-gutters
                    style="height: 100%"
                    justify="center"
                    align="center"
                  >
                    <v-progress-circular
                      color="blue"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </v-card>
              </div>
            </v-menu>
          </v-sheet>
        </v-col>
      </div>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="950" scrollable>
      <v-card >
        <v-toolbar
          flat
          :color="getStatusColor(selectedObject)"
          :style="'color:' + getEventTextColor(selectedObject) + ';'"
        >
          <v-btn
            icon
            dark
            @click="
              () => {
                dialog = false;
                agnetResponseDocFlag = false;
              }
            "
            :style="'color:' + getEventTextColor(selectedObject) + ';'"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ selectedObject.name }} - {{ selectedObject.meetingStatus }} -
            {{ selectedObject.rank }} | סטאטוס:
            {{ selectedObject.statusText }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn v-if="$store.state.userObj.userDoc.data().role =='admin' || $store.state.userObj.userDoc.data().role =='mManager' "  text :color="getEventTextColor(selectedObject)" @click="()=>{edit=false}">ערוך</v-btn> -->
            <small v-if="!lockForm" style="margin-top: 25px; margin-left: 4px"
              >תזכורת שניה:</small
            >
            <v-switch
              v-if="!lockForm"
              inset
              color="yellow accent-2"
              style="margin-top: 22px"
              v-model="selectedObject['secoundReminder']"
            ></v-switch>
            <v-btn
              v-if="!lockForm"
              text
              :color="getEventTextColor(selectedObject)"
              @click="deletePrompt = true"
              >מחיקה</v-btn
            >
            <v-btn
              v-if="!lockForm && deletePrompt"
              color="red"
              dark
              @click="deleteMeeting"
              >בטוח למחוק?</v-btn
            >
            <v-btn
              text
              :color="getEventTextColor(selectedObject)"
              @click="saveChange"
              >שמור</v-btn
            >
          </v-toolbar-items>
        </v-toolbar>

        <v-card-subtitle
          :style="
            'padding:5px; background-color:' +
            getStatusColor(selectedObject) +
            ';' +
            'color:' +
            getEventTextColor(selectedObject) +
            ';'
          "
        >
          <v-row
            no-gutters
            justify="space-between"
            style="padding-right: 20px; padding-left: 20px"
          >
            <div>טלפון: {{ selectedObject.phone }}</div>
            <div>מזהה סלע: {{ selectedObject.selaId }}</div>
            <div>עודכן ב: {{ timeStampTOdate(selectedObject.updatedOn) }}</div>
            <div>עודכן על ידי: {{ selectedObject.updatedByName }}</div>
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small icon v-bind="attrs" v-on="on"
                  ><v-icon color="white" small>mdi-bell</v-icon></v-btn
                >
              </template>
              <v-list min-width="500">
                <v-row no-gutters>
                  <v-col cols="5">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedDateFormatted2"
                          label="תאריך תזכורת"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="he"
                        v-model="reminder.date"
                        @input="menu2 = false"
                        color="#EF6C00"
                        header-color="primary"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="11" style="margin-right: 1rem">
                    <v-text-field
                      v-model="reminder.note"
                      label="הערה"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div style="padding: 1rem">
                  <v-btn
                    :loading="reminderLoading"
                    :disabled="reminder.date == ''"
                    block
                    color="green"
                    style="color: white; font-weight: bold"
                    @click="saveReminer"
                    >שמור תזכורת</v-btn
                  >
                </div>
              </v-list>
            </v-menu>
          </v-row>
        </v-card-subtitle>

        <v-progress-linear
          v-if="updating"
          style="margin-top: 1px"
          indeterminate
          color="#1976D2"
        ></v-progress-linear>

        <v-card-text style="padding: 10px; height: 85vh">
          <div
            style="
              width: 100%;
              margin-top: 1rem;
              margin-right: 0.5rem;
              margin-bottom: 1rem;
            "
          >
            <v-row
              align="center"
              justify="center"
              no-gutters
              style="width: 95%"
            >
              <!-- <v-avatar size="45" style="margin-left: 1rem"
                ><img :src="getAgentPhoto(selectedObject)"
              /></v-avatar> -->

              <user-avatar
                style="margin-left: 1rem"
                :size="45"
                :user="getAgent(selectedObject)"
              ></user-avatar>
              <!-- <v-icon :color="getAgentColor(selectedObject)" style="margin-left:6px;margin-bottom:6px">mdi-account-circle</v-icon>   -->
              <v-autocomplete
                :disabled="lockForm"
                label="משויך לסוכן"
                v-model="selectedObject.agent"
                dense
                :items="calendarLists.agentsInfos"
                item-text="name"
                item-value="id"
                outlined
                hide-details
              ></v-autocomplete>

              <div
                :key="'meetingSyncedId' + meetingSyncedId"
                style="margin-right: 1rem"
              >
                <v-icon v-if="meetingSynced" color="green"
                  >mdi-cellphone-check</v-icon
                >
                <v-icon v-else>mdi-cellphone</v-icon>
              </div>
                            
                            
              <div
                :key="'meetingLockId' "
                style="margin-right: 2rem"
                  v-if="
                  $store.state.userObj.userDoc.data().role == 'admin' ||
                  $store.state.userObj.userDoc.data().role == 'mManager'
                "
              
              >
               
               <v-checkbox  color="red"  v-model="selectedObject.isReplica" off-icon="mdi-lock-open"  on-icon="mdi-lock"   ></v-checkbox>
              </div>
            </v-row>
            <small style="color:white;">{{ selectedObject.agent }}</small>

          </div>
          <v-container>
            <v-row justify="space-around" align="center">
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :disabled="lockForm"
                  label="סטאטוס ראשי"
                  v-model="selectedObject.meetingStatus"
                  dense
                  :items="lists.meetingStatusList"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  label="סטאטוס"
                  v-model="selectedObject.status"
                  dense
                  :disabled="lockForm"
                  :items="lists.statusList"
                  item-text="text"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :disabled="lockForm"
                  label="סיווג פגישה"
                  v-model="selectedObject.rank"
                  dense
                  :items="calendarLists.meetingRank"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="lockForm && selectedObject.status == 8">
              <v-col cols="13" sm="6" md="3">
                <v-select
                  label="*סטאטוס"
                  v-model="selectedObject.status"
                  dense
                  :items="
                    lists.statusList.filter((item) => {
                      return item.id == 7 || item.id == 8;
                    })
                  "
                  item-text="text"
                  item-value="id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="">
                <v-text-field
                  v-model="selectedObject.name"
                  label="שם הלקוח"
                  prepend-icon="mdi-account"
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="selectedObject.city"
                  label="עיר"
                  prepend-icon="mdi-city"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="selectedObject.age"
                  label="גיל"
                  prepend-icon="mdi-account-settings-outline"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  :disabled="lockForm"
                  v-model="selectedObject.cId"
                  label="תעודת זהות"
                  prepend-icon="mdi-badge-account-horizontal"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              justify="start"
              style="margin-bottom: 10px; margin-right: 0.1rem"
            >
              <div v-for="item in lists.phoneCallsAttrs" :key="item">
                <v-checkbox
                  dense
                  style="margin-left: 10px"
                  v-model="selectedObject.attributes"
                  :label="item"
                  :value="item"
                  height="10"
                ></v-checkbox>
              </div>
            </v-row>
            <v-row align="center" justify="space-evenly">
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  disabled
                  v-model="selectedObject.phone"
                  label="טלפון"
                  append-outer-icon="mdi-phone"
                  dense
                  style="direction: ltr; margin-left: 15px"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="selectedObject['phone2']"
                  label="טלפון נוסף"
                  append-outer-icon="mdi-phone"
                  dense
                  style="direction: ltr; margin-left: 15px"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-combobox
                  v-if="!lockForm"
                  :disabled="lockForm"
                  label="מקור"
                  v-model="selectedObject['source']"
                  :items="source"
                  dense
                  prepend-icon="mdi-tag"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row align="center" justify="space-evenly">
              <v-col cols="12" sm="3" md="3">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      label="תאריך פגישה"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="he"
                    v-model="selectedObject.date"
                    @input="menu2 = false"
                    color="#EF6C00"
                    header-color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-row justify="center" align="center" style="margin-top: 14px">
                  <small>שעת התחלה</small>
                  <v-select
                    v-model="selectedObject.minutes"
                    :menu-props="{ maxHeight: 130 }"
                    append-icon=""
                    :items="getMinuts"
                    dense
                    class="centered-input"
                    style="padding: 3px; width: 50px; text-align: center"
                  ></v-select>
                  <div style="font-weight: bold">:</div>
                  <v-select
                    v-model="selectedObject.hours"
                    :menu-props="{ maxHeight: 130 }"
                    append-icon=""
                    :items="getHouers"
                    dense
                    class="centered-input"
                    style="padding: 3px; width: 50px; text-align: center"
                  ></v-select>
                </v-row>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-row justify="center" align="center" style="margin-top: 14px">
                  <small>שעת סיום</small>
                  <v-select
                    v-model="selectedObject.minutesTo"
                    :menu-props="{ maxHeight: 130 }"
                    append-icon=""
                    :items="getMinuts"
                    dense
                    class="centered-input"
                    style="padding: 3px; width: 50px; text-align: center"
                  ></v-select>
                  <div style="font-weight: bold">:</div>
                  <v-select
                    v-model="selectedObject.hoursTo"
                    :menu-props="{ maxHeight: 130 }"
                    append-icon=""
                    :items="getHouers"
                    dense
                    class="centered-input"
                    style="padding: 3px; width: 50px; text-align: center"
                  ></v-select>
                </v-row>
              </v-col>
            </v-row>

            <v-row align="end">
              <v-col cols="12" md="12">
                <v-card>
                  <div style="position: relative">
                    <v-card
                      elevation="2"
                      height="40"
                      width="400"
                      class="serachMap"
                      style="border-radius: 0"
                    >
                      <div style="display: flex; flex-direction: row">
                        <v-icon>mdi-map-marker</v-icon>
                        <vue-google-autocomplete
                          style="width: 92%"
                          :key="mapsKey"
                          ref="address"
                          id="map"
                          classname="form-control"
                          placeholder="כתובת"
                          v-model="getAdressText"
                          v-on:placechanged="getAddressData"
                          :country="['il']"
                          types=""
                        ></vue-google-autocomplete>
                      </div>
                    </v-card>
                    <gmap-map
                      :center="getCenter"
                      :zoom="16"
                      style="width: 100%; height: 250px"
                    >
                      <GmapMarker :position="getCenter" />
                    </gmap-map>

                    <!-- <div class="pac-card" id="pac-card">
                                        <div>
                                          <div id="title">Autocomplete search</div>
                                          <div id="type-selector" class="pac-controls">
                                            <input
                                              type="radio"
                                              name="type"
                                              id="changetype-all"
                                              checked="checked"
                                            />
                                            <label for="changetype-all">All</label>

                                            <input type="radio" name="type" id="changetype-establishment" />
                                            <label for="changetype-establishment">Establishments</label>

                                            <input type="radio" name="type" id="changetype-address" />
                                            <label for="changetype-address">Addresses</label>

                                            <input type="radio" name="type" id="changetype-geocode" />
                                            <label for="changetype-geocode">Geocodes</label>
                                          </div>
                                          <br />
                                          <div id="strict-bounds-selector" class="pac-controls">
                                            <input type="checkbox" id="use-location-bias" value="" checked />
                                            <label for="use-location-bias">Bias to map viewport</label>

                                            <input type="checkbox" id="use-strict-bounds" value="" />
                                            <label for="use-strict-bounds">Strict bounds</label>
                                          </div>
                                        </div>
                                        <div id="pac-container">
                                          <input id="pac-input" type="text" placeholder="Enter a location" />
                                        </div>
                                      </div>
                                      <div id="map"></div>
                                      <div id="infowindow-content">
                                        <span id="place-name" class="title"></span><br />
                                        <span id="place-address"></span>
                                      </div> -->
                  </div>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                md="12"
                v-if="
                  $store.state.userObj.userDoc.data().role == 'admin' ||
                  $store.state.userObj.userDoc.data().role == 'mManager'
                "
              >
                <v-card v-if="agnetResponseDocFlag" width="100%">
                  <v-toolbar
                    :color="getStatusColor(agnetResponseDoc)"
                    elevation="0"
                    height="50"
                    dark
                  >
                    <v-appbar-title
                      >דיווח סוכן - {{ showAgentDates() }}</v-appbar-title
                    >
                  </v-toolbar>
                  <v-row no-gutters style="padding: 5px" align="start">
                    <v-col cols="12">
                      <v-row no-gutters justify="center">
                        <div>סטאטוס:</div>
                        <v-col>
                          <div style="padding-right: 5px; font-weight: bold">
                            {{ agnetResponseDoc.statusText }}
                          </div>
                        </v-col>
                        <v-btn
                          v-if="
                            agnetResponseDoc.status != selectedObject.status
                          "
                          color="#26A69A"
                          dark
                          small
                          width="120"
                          style="font-weight: bold"
                          @click="acceptAgentReport()"
                          >אישור סטאטוס</v-btn
                        >
                      </v-row>

                      <v-row
                        v-if="agnetResponseDoc.meetingDate != selectedObject.meetingDate"
                        no-gutters
                        justify="center"
                        style="margin-top: 10px"
                      >
                        <div>שינוי תאריך פגישה:</div>
                        <v-col>
                          <div style="padding-right: 5px; font-weight: bold">
                            {{ timeStampTOdate(agnetResponseDoc.meetingDate) }}
                          </div>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#26C6DA"
                          dark
                          small
                          width="120"
                          style="font-weight: bold"
                          @click="acceptAgentDate()"
                          >אישור תאריך</v-btn
                        >
                      </v-row>
                      <div
                        v-if="agnetResponseDoc.hasOwnProperty('closingAmout')"
                      >
                        <v-row
                          v-if="
                            agnetResponseDoc.closingAmout != '0' &&
                            agnetResponseDoc.closingAmout != null &&
                            agnetResponseDoc.status == 11
                          "
                          no-gutters
                          justify="center"
                        >
                          <div>סכום סגירה:</div>
                          <v-col>
                            <div style="padding-right: 5px; font-weight: bold">
                              {{ agnetResponseDoc.closingAmout }}
                            </div>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="
                              agnetResponseDoc.closingAmout !=
                              selectedObject['closingAmout']
                            "
                            color="#26A69A"
                            dark
                            small
                            width="120"
                            style="font-weight: boldl;marginTop: 10px"
                            @click="acceptAgentClosing()"
                            >אישור סכום</v-btn
                          >
                        </v-row>
                      </div>

                      <v-row no-gutters>
                        <div>
                          <div style="padding-left: 10px; margin-bottom: 1rem">
                            תנאי סף:
                          </div>
                          <v-col>
                            <div
                              v-for="item in agnetResponseDoc.mustAttrs"
                              :key="item"
                            >
                              <v-row no-gutters align="center">
                                <v-icon
                                  style="margin-left: 5px"
                                  v-if="setCheckboxIcon(item)"
                                  >mdi-checkbox-marked</v-icon
                                >
                                <v-icon style="margin-left: 5px" v-else
                                  >mdi-checkbox-blank-outline</v-icon
                                >
                                <div>{{ item }}</div>
                              </v-row>
                            </div>
                          </v-col>
                        </div>

                        <div
                          style="margin-right: 20px"
                          v-if="agnetResponseDoc.status == 4"
                        >
                          <div style="padding-left: 20px">סיבות:</div>
                          <v-col>
                            <div
                              v-for="item in agnetResponseDoc.reasons"
                              :key="item"
                            >
                              <v-row no-gutters align="center">
                                <v-icon style="margin-left: 5px"
                                  >mdi-alert-outline</v-icon
                                >
                                <div>{{ item }}</div>
                              </v-row>
                            </div>
                          </v-col>
                        </div>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12" md="12">
                <v-card style="overflow: auto; max-height: 250px">
                  <v-col>
                    <div v-for="item in selectedObject.comments" :key="item">
                      <div v-if="visableToRep(item)">
                        <v-row no-gutters align="center">
                          <v-col cols="12" md="1">
                            <!-- <v-avatar>
                              <img :src="item.photoURL" />
                            </v-avatar> -->
                            <user-avatar :size="50" :user="item"></user-avatar>
                          </v-col>
                          <v-col v-col cols="12" md="7">
                            <v-row justify="start" no-gutters>
                              <div
                                v-if="item.insideComment"
                                style="font-weight: 500; color: #ef6c00"
                              >
                                {{
                                  item.displayName +
                                  " " +
                                  timeStampTOdate(item.time) +
                                  ":"
                                }}
                              </div>
                              <div
                                v-else
                                style="font-weight: 500; color: #1565c0"
                              >
                                {{
                                  item.displayName +
                                  " " +
                                  timeStampTOdate(item.time) +
                                  ":"
                                }}
                              </div>
                            </v-row>
                            <v-row justify="start" no-gutters>
                              <div>{{ item.text }}</div>
                            </v-row>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col cols="1">
                            <v-btn
                              icon
                              @click="delteComment(item)"
                              v-if="
                                $store.state.userObj.userDoc.data().role ==
                                'admin'
                              "
                              ><v-icon>mdi-delete</v-icon></v-btn
                            >
                          </v-col>
                          <v-col cols="1">
                            <v-checkbox
                              hide-details
                              off-icon="mdi-eye"
                              on-icon="mdi-eye"
                              color="green darken-5"
                              v-model="item['visableToRep']"
                              style="margin-bottom: 1rem"
                              v-if="
                                $store.state.userObj.userDoc.data().role ==
                                'admin'
                              "
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-divider style="margin: 5px"></v-divider>
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters align="start" style="margin-top: 1rem">
              <v-col cols="12" md="1">
                <small>פנימי</small>
                <v-switch
                  color="orange darken-3"
                  inset
                  style="margin: 0; margin-left: 5px"
                  v-model="insideComment"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="10">
                <v-textarea
                  v-model="selectedObject.note"
                  outlined
                  name="notes"
                  dense
                  label="הוסף תגובה"
                  height="65"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="1" align="center">
                <v-btn style="margin-top: 20px" color="blue" icon
                  ><v-icon
                    size="60"
                    style="transform: rotate(180deg)"
                    @click="sendComments"
                    >mdi-send-circle</v-icon
                  ></v-btn
                >
              </v-col>
            </v-row>

            <v-row align="center">
              <div style="display: flex; align-items: center">
                <small style="margin: 5px">רלוונטית:</small>
                <v-select
                  :disabled="lockForm"
                  v-model="selectedObject.relevant"
                  :items="releaventList"
                  @change="onChangeStatus"
                ></v-select>
              </div>
              <v-spacer></v-spacer>
              <small>מוצר:</small>
              <v-radio-group
                :disabled="lockForm"
                v-model="selectedObject.productType"
                :mandatory="false"
                :column="false"
                dense
                style="margin-left: 30px"
              >
                <v-radio
                  style="margin-right: 10px"
                  label="פנסיוני"
                  value="פנסיוני"
                ></v-radio>
                <v-radio
                  style="margin-right: 10px"
                  label="ביטוח"
                  value="ביטוח"
                ></v-radio>
              </v-radio-group>

              <div
                style="display: flex; align-items: center; margin-left: 30px"
              >
                <small style="margin: 5px">תזכורת:</small>
                <v-switch
                  :disabled="lockForm"
                  v-model="selectedObject.reminder"
                ></v-switch>
              </div>

              <div
                style="display: flex; align-items: center; margin-left: 30px"
              >
                <small style="margin: 5px">הודעה ללקוח:</small>
                <v-switch
                  :disabled="lockForm"
                  v-model="selectedObject['sendMsgToCostuner']"
                ></v-switch>
              </div>
            </v-row>
            <small style="margin-top: 1rem">תנאי סף לפגישה</small>
            <v-row
              justify="start"
              style="margin-bottom: 10px; margin-top: 0.5rem"
            >
              <div v-for="item in selectedObject.mustAttrs" :key="item">
                <v-checkbox
                  :disabled="lockForm"
                  dense
                  style="margin-left: 10px"
                  v-model="selectedObject.mustAttrs"
                  :label="item"
                  :value="item"
                  height="10"
                ></v-checkbox>
              </div>
            </v-row>
            <v-row justify="start" style="margin-bottom: 10px">
              <v-col cols="3">
                <v-text-field
                  type="number"
                  :disabled="lockForm"
                  v-model="selectedObject['closingAmout']"
                  label="סכום סגירה"
                  prepend-icon="mdi-cash"
                  required
                  dense
                  style="margin-top: 10px"
                ></v-text-field>
              </v-col>

              <v-col cols="3"> 
    
                <v-select
                  :disabled="lockForm"
                  v-model="selectedObject['maslekaCharge']"
                  label="חיוב מסלקה לסוכן"
                  prepend-icon="mdi-credit-card-check"
                  :items="['לא', 'כן']"
                  dense
                ></v-select>
               
              </v-col>
                <v-col cols="3">
                <v-select
                  :disabled="lockForm"
                  v-model="selectedObject['maslekaRel']"
                  label="מסלקה רלוונטית למתאם"
                  prepend-icon="mdi-credit-card-check"
                  :items="['לא', 'כן','פגישה פרונטלית','מסלקה מיטב']"
                  dense
                ></v-select>
               
              </v-col>
              <v-spacer></v-spacer>
                <v-col cols="3">
                <v-text-field
                  type="number"
                  :disabled="lockForm"
                  v-model="selectedObject['meetingCharge']"
                  label="חיוב פגישה"
                  prepend-icon="mdi-credit-card-marker"
                  required
                  dense
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>

              <v-btn
                small
                color="teal accent-1"
                elevation="1"
                style="margin-top: 1rem; font-weight: bolder"
                :loading="sendNottiLoading"
                @click="sendReminderForAgentForMeeting"
                >תזכורת לסוכן</v-btn
              >
            </v-row>
            <v-row no-gutters>
              <div style="width: 300px">
                <v-select
                  v-if="
                    $store.state.userObj.userDoc.data().role == 'admin' ||
                    $store.state.userObj.userDoc.data().role == 'mManager'
                  "
                  label="תואם על ידי"
                  :items="repList"
                  item-text="displayName"
                  item-value="uid"
                  @change="onRepChange()"
                  return-object
                  v-model="selectedObject.representative"
                ></v-select>
              </div>
              <v-spacer></v-spacer>

              <div>
                <v-select
                  label="מוקד"
                  :items="['מעגן', 'חיפה']"
                  v-model="selectedObject['callCenter']"
                ></v-select>
              </div>

              <v-spacer></v-spacer>

              <div>
                <v-checkbox
                  v-model="selectedObject['delayReword']"
                  label="תגמול מושעה"
                ></v-checkbox>
              </div>
               <div>
                <v-checkbox
                  v-model="selectedObject['dontTakeFromAgent']"
                  label="לא לקחת מסוכן"
                ></v-checkbox>
              </div>
            </v-row>
          </v-container>
        </v-card-text>  
      </v-card>
     
          </v-dialog>

    <v-dialog v-model="dialog2" persistent max-width="400px">
      <v-card>
        <v-toolbar flat color="#607D8B">
          <v-btn icon color="white" @click="dialog2 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title style="color: white">שינוי רוחבי</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear
          v-if="updating2"
          style="margin-top: 2px"
          indeterminate
          color="#607D8B"
        ></v-progress-linear>
        <div style="padding: 25px">
          <v-row justify="space-between" align="center">
            <v-icon
              :color="getAgentColorByText(actionsAgent)"
              style="margin-left: 6px; margin-bottom: 6px"
              >mdi-account-circle</v-icon
            >
            <v-autocomplete
              label="משיוך לסוכן"
              v-model="actionsAgent"
              dense
              :items="calendarLists.agentsInfos"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-row>
         <v-row justify="space-between" align="center">
            <v-icon
              :color="getAgentColorByText(actionsAgent)"
              style="margin-left: 6px; margin-bottom: 6px"
              >mdi-account-circle</v-icon
            >
            <v-autocomplete
              label="מתאם"
              v-model="actionRep"
              dense
              :items="repList"
              item-text="displayName"
              item-value="uid"
            ></v-autocomplete>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-icon
              :color="actionMainStatus.color"
              style="margin-left: 6px; margin-bottom: 6px"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-select
              label="סטאטוס ראשי"
              v-model="actionMainStatus"
              dense
              :items="lists.meetingStatusList"
              return-object
            ></v-select>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-icon
              :color="actionStatus.color"
              style="margin-left: 6px; margin-bottom: 6px"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-select
              label="סטאטוס"
              v-model="actionStatus"
              dense
              :items="lists.statusList"
              return-object
            ></v-select>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-icon
              :color="getRankColor(actionsRank)"
              style="margin-left: 6px; margin-bottom: 6px"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-select
              label="סיווג פגישה"
              v-model="actionsRank"
              dense
              :items="calendarLists.meetingRank"
            ></v-select>
          </v-row>
          <v-row justify="center" v-if="!lockForm">
            <v-btn
              v-if="!updating2"
              @click="massChange"
              elevation="0"
              width="90%"
              color="#607D8B"
              style="color: white"
              >שמור</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { rootDb } from "../firebase";
import { app } from "../firebase";
import { firebase } from "@firebase/app";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import GoogleMap from "../components/GoogleMap";
import { cytis } from "../globals/lists";
const globals = require("../globals/lists");
import UserAvatar from "../components/UserAvatar.vue";

export default {
  components: {
    DataTable,
    VueGoogleAutocomplete,
    GoogleMap,
    UserAvatar,
  },
  data: () => ({
    repList: [],
    mettingLoading: false,
    scrollPositionsTop: 0,
    scrollPositionsLeft: 0,
    meetingSynced: false,
    meetingSyncedId: 0,
    sendNottiLoading: false,
    monthTitle: "",
    focus: "",
    deletePrompt: false,
    filter: false,
    repMode: false,
    calendarLists: {},
    loading: false,
    lists: {},
    source: [],
    selectedSource: [],
    type: "category",
    menu2: false,
    menu3: false,
    menu4: false,
    typeToLabel: {
      month: "חודשי",
      week: "שבועי",
      day: "יומי",
      "4day": " 4 ימים",
      category: "סוכנים",
    },
    tableMode: false,
    dragMode: false,
    showConstrains: true,
    mapsKey: 1000,
    mode: "column",
    modes: ["stack", "column"],
    categories: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    selectedObject: {},
    dialog: false,
    dialog2: false,
    dialog3: false,
    events: [],
    edit: true,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    zoom: 30,
    menuAgentsList: [],
    citys: [],
    selctedCitys: [],
    selctedAgents: [],
    selctedMeetingTypes: [],
    selctedStatus: [],
    selectedMainStatus: [],
    firsTimeCitysFlag: true,
    allEvents: [],
    actionsAgent: "",
    actionRep: "",
    actionStatus: "",
    actionMainStatus: "",
    actionsRank: "",
    updating: false,
    updating2: false,
    selectedRows: [],
    lockForm: false,
    agentDocUpdatePayload: null,
    clickedEvent: null,
    showMenu: false,
    x: 0,
    y: 0,
    result: [],
    delay: 700,
    clicks: 0,
    timer: null,
    insideComment: false,
    agnetObject: {
      name: "",
      color: "",
      photoURL: "",
    },
    agnetObjectNameForFilter: "",

    itemsLists: [
      { title: "Do Something" },
      { title: "Do Some More" },
      { title: "Do Another" },
      { title: "Do Whatever" },
    ],

    dates: [],

    calendarKey: 0,
    tableKey: 5000,

    popUPs: [],
    columns: [
      { field: "date", data: "", type: "text", label: "תאריך" },
      { field: "selaId", data: "", type: "text", label: "מזהה סלע" },
      { field: "meetingStatus", data: "", type: "text", label: "סטאטוס ר'" },
      { field: "statusText", data: "", type: "text", label: "סטאטוס" },
      { field: "rank", data: "", type: "text", label: "סיווג" },
      { field: "phone", data: "", type: "text", label: "טלפון" },
      { field: "name", data: "", type: "text", label: "שם" },
      { field: "city", data: "", type: "text", label: "עיר" },
      { field: "address.addressText", data: "", type: "text", label: "כתובת" },
      { field: "timeRange", data: "", type: "text", label: "בשעה" },
      { field: "productType", data: "", type: "text", label: "מוצר" },
      { field: "reminder", data: "", type: "text", label: "תזכורת" },
      { field: "relevant", data: "", type: "text", label: "רלוונטי" },
      { field: "category", data: "", type: "text", label: "סוכן" },
      { field: "representativeName", data: "", type: "text", label: "נציג" },
      { field: "resFlag", data: "", type: "text", label: "תגובה" },
      { field: "source", data: "", type: "text", label: "מקור" },
      { field: "cId", data: "", type: "text", label: "ת.ז" },
      { field: "age", data: "", type: "text", label: "גיל" },
      { field: "isPhoneCall", data: "", type: "text", label: "פגישה טלפונית" },
      { field: "isMasleka", data: "", type: "text", label: "מסלקה" },
      { field: "maslekaCharge", data: "", type: "text", label: "חיוב מסלקה לסוכן" },
      { field: "maslekaRel", data: "", type: "text", label: "מסלקה רלוונטית למתאם" },
      { field: "meetingCharge", data: "", type: "text", label: "חיוב פגישה" },
      { field: "callCenter", data: "", type: "text", label: "מוקד" },
      { field: "employer", data: "", type: "text", label: "מעסיק" },
      { field: "closingAmout", data: "", type: "text", label: "סכום סגירה" },
      { field: "updateStatus", data: "", type: "text", label: "ס.עודכן ב" },
      { field: "delayReword", data: "", type: "text", label: "תגמול מושעה" },
      { field: "dontTakeFromAgent", data: "", type: "text", label: "2תגמול מושעה" },

    ],

    rows: [],
    agnetResponseDoc: {
      status: "",
      statusText: "",
      agentFeedback: {
        mustAttrs: [],
        didntSaleReasons: [],
      },
    },

    agnetResponseDocFlag: false,

    releaventList: ["", "לא", "כן"],

    reminder: {
      date: "",
      note: "",
      type: "",
      id: "",
      path: "",
      isNew: true,
      visable: true,
      selaId: "",
      phone: "",
      name: "",
    },
    reminderLoading: false,
  }),
  async mounted() {
    if (this.$store.state.userObj.userDoc.data().role == "mRep") {
      this.lockForm = true;
      this.repMode = true;
      this.type = "week";
    }

    if (!this.tableMode) {
      this.$refs.calendar.checkChange();
      this.monthTitle = this.$refs.calendar.lastEnd.month;
    } else {
      var today = new Date();
      today.setTime(0);
      today.setMinutes(0);
      var treeMonthIntheFuture = new Date();
      treeMonthIntheFuture.setTime(23);
      treeMonthIntheFuture.setMinutes(59);
      treeMonthIntheFuture.setMonth(treeMonthIntheFuture.getMonth() + 3);
      this.getEvents(today, treeMonthIntheFuture);
      this.dates = [
        moment(today).format("YYYY-MM-DD"),
        moment(treeMonthIntheFuture).format("YYYY-MM-DD"),
      ];
    }
    console.log(this.$store.state);
    if (this.$store.state.hasOwnProperty("popObject")) {
      console.log(this.$store.state.popObject);
      this.selectedObject = { ...this.$store.state.popObject };
      this.agnetResponseDoc = db
        .collection("Agents")
        .doc(this.selectedObject.agent)
        .collection("AgentMeetings")
        .doc(this.selectedObject.selaId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log(doc.data());
            var x = doc.data();
            this.agnetResponseDoc = doc.data();
            if (doc.data().status != 7) {
              this.agnetResponseDocFlag = true;
            } else {
              this.agnetResponseDocFlag = false;
            }
          } else {
            this.agnetResponseDocFlag = false;
          }
        });
      this.dialog = true;
      delete this.$store.state["popObject"];
    }

    this.$refs.calendar.next();

    if (
      this.$store.state.userObj.userDoc.data().role == "admin" ||
      this.$store.state.userObj.userDoc.data().role == "mManager"
    ) {
      let reps = await db.collection("Representatives").get();
      let vueInstance = this;
      this.repList = [];
      reps.forEach((doc) => {
        vueInstance.repList.push(doc.data());
      });
    }
  },

  methods: {
    checkIfMeitav(event) {
      if (typeof event != "undefined") {
        if (event.hasOwnProperty("source")) {
          return event.source?.includes("מיטב");
        }
      }

      return false;
    },
    onRepChange() {
      let name = this.selectedObject.representative.displayName;
      let uid = this.selectedObject.representative.uid;
      this.selectedObject.representative = uid;
      this.selectedObject.representativeName = name;
    },
    onScroll(e) {
      this.scrollPositionsLeft = e.target.scrollLeft;
      this.scrollPositionsTop = e.target.scrollTop;
    },
    async getLatsetMeetingData(event) {
      this.mettingLoading = true;
      let doc = await db.collection("Meetings").doc(event.selaId).get();
      let agentDoc = await db
        .collection("Agents")
        .doc(event.agent)
        .collection("AgentMeetings")
        .doc(event.selaId)
        .get();

      let obj = doc.data();
      obj["category"] = this.getAgnetNameByid(obj.agent);
      this.meetingSynced = false;
      if (agentDoc.exists) {
        this.meetingSynced = true;
        let agentMeeting = agentDoc.data();
        if (
          agentMeeting.status != 7 &&
          typeof agentMeeting.status != "undefined"
        ) {
          obj["resFlag"] = true;
          obj["agentStatus"] = agentMeeting.status;
        } else {
          if (typeof agentMeeting.status != "undefined") {
            agentMeeting["status"] = 7;
            agentMeeting["statusText"] = "פגישה";
          }
          obj["resFlag"] = false;
        }
      } else {
        obj["resFlag"] = false;
      }
      this.mettingLoading = false;

      this.updateLastUpdateLocaly(obj);
      return obj;
    },
    async updateLastUpdateLocaly(event) {
      console.log("updateLastUpdateLocaly");
      this.events.forEach((doc) => {
        if (doc.selaId == event.selaId) {
          console.log(doc.selaId);
          doc = event;
        }
      });
      this.allEvents.forEach((doc) => {
        if (doc.selaId == event.selaId) {
          doc = event;
        }
      });

      this.calendarKey++;
    },
    async sendReminderForAgentForMeeting() {
      console.log(this.selectedObject);
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      this.sendNottiLoading = true;
      await sleep(2000);
      const myDate = moment(this.selectedObject.date, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
      console.log(myDate);

      let sendReminer = firebase
        .functions()
        .httpsCallable("sendReminderToAgent");
      let op = await sendReminer({
        meeting: this.selectedObject,
      });
      console.log(op);
      this.sendNottiLoading = false;
    },
    checkIFPhoneCallMettings(event) {
      if (event.attributes) {
        return event.attributes.includes("פגישה טלפונית");
      }
      return false;
    },
    checkIFHaifaMettings(event) {
      if (event.callCenter) {
        return event.callCenter == "חיפה";
      }
      return false;
    },
    getOnlyMeetings() {
      return this.events.filter((event) => {
        return event.name != "אילוץ";
      });
    },

    async saveReminer() {
      this.reminderLoading = true;
      let dbref = db
        .collection("Users")
        .doc(this.$store.state.userObj.user.uid)
        .collection("Reminders")
        .doc();
      let objPath = await db
        .collection("PhoneCalls")
        .doc(this.selectedObject.phone)
        .get();
      let date = moment(this.reminder.date, "YYYY-MM-DD")
        .startOf("day")
        .toDate();
      this.reminder.date = date;
      this.reminder.type = "meeting";
      this.reminder.path = objPath.ref.path;
      this.reminder.id = objPath.id;
      this.reminder.selaId = dbref.id;
      this.reminder.name = objPath.data().name;
      this.reminder.phone = objPath.data().phone;

      await dbref.set(this.reminder);
      this.reminderLoading = false;

      this.reminder = {
        date: "",
        note: "",
        type: "",
        id: "",
        path: "",
        selaId: "",
        isNew: true,
        visable: true,
        phone: "",
        name: "",
      };
    },

    delteComment(item) {
      this.selectedObject.comments = this.selectedObject.comments.filter(
        (comment) => {
          return item != comment;
        }
      );
    },

    visableToRep(msg) {
      let res = true;
      let repFalg =
        this.$store.state.userObj.userDoc.data().role == "mRep" ? true : false;
      let forAgentFalg = false;

      if (msg.hasOwnProperty("fromAgent")) {
        msg.fromAgent ? (forAgentFalg = true) : () => {};
      }

      if (repFalg && forAgentFalg) {
        res = false;
      }

      if (msg.hasOwnProperty("visableToRep")) {
        if (msg["visableToRep"] == true) {
          res = true;
        }
      }

      return res;
    },

    initMap() {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 40.749933, lng: -73.98633 },
        zoom: 13,
      });
      const card = document.getElementById("pac-card");
      const input = document.getElementById("pac-input");
      const biasInputElement = document.getElementById("use-location-bias");
      const strictBoundsInputElement =
        document.getElementById("use-strict-bounds");
      const options = {
        componentRestrictions: { country: "us" },
        fields: ["formatted_address", "geometry", "name"],
        origin: map.getCenter(),
        strictBounds: false,
        types: ["establishment"],
      };
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo("bounds", map);
      const infowindow = new google.maps.InfoWindow();
      const infowindowContent = document.getElementById("infowindow-content");
      infowindow.setContent(infowindowContent);
      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
      });
      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);
        const place = autocomplete.getPlace();
כ
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent =
          place.formatted_address;
        infowindow.open(map, marker);
      });

      // Sets a listener on a radio button to change the filter type on Places
      // Autocomplete.
      function setupClickListener(id, types) {
        const radioButton = document.getElementById(id);
        radioButton.addEventListener("click", () => {
          autocomplete.setTypes(types);
          input.value = "";
        });
      }
      setupClickListener("changetype-all", []);
      setupClickListener("changetype-address", ["address"]);
      setupClickListener("changetype-establishment", ["establishment"]);
      setupClickListener("changetype-geocode", ["geocode"]);
      biasInputElement.addEventListener("change", () => {
        if (biasInputElement.checked) {
          autocomplete.bindTo("bounds", map);
        } else {
          // User wants to turn off location bias, so three things need to happen:
          // 1. Unbind from map
          // 2. Reset the bounds to whole world
          // 3. Uncheck the strict bounds checkbox UI (which also disables strict bounds)
          autocomplete.unbind("bounds");
          autocomplete.setBounds({
            east: 180,
            west: -180,
            north: 90,
            south: -90,
          });
          strictBoundsInputElement.checked = biasInputElement.checked;
        }
        input.value = "";
      });
      strictBoundsInputElement.addEventListener("change", () => {
        autocomplete.setOptions({
          strictBounds: strictBoundsInputElement.checked,
        });

        if (strictBoundsInputElement.checked) {
          biasInputElement.checked = strictBoundsInputElement.checked;
          autocomplete.bindTo("bounds", map);
        }
        input.value = "";
      });
    },

    saveFilterConfig() {
      let ref = this;
      db.collection("UsersConfigs").doc(this.$store.state.userObj.user.uid).set(
        {
          agentFilter: ref.selctedAgents,
          statusFilter: ref.selctedStatus,
          rankFilter: ref.selctedMeetingTypes,
          mainStatusFilter: ref.selectedMainStatus,
          citysFilter: ref.selctedCitys,
          viewType: ref.type,
        },
        { merge: true }
      );
    },

    convertPhoneFormat(text) {
      if (typeof text !== "undefined") {
        return text.replace("+972", "0");
      }
      return text;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getTime(dateString) {
      return moment(dateString).format("HH:mm");
    },
    getEventColor(event) {
      if (this.lists.meetingStatusList) {
        var statusObject = this.lists.meetingStatusList.filter(function (obj) {
          return obj.text == event.meetingStatus;
        });
        return statusObject.length > 0 ? statusObject[0].color : "transparent";
      } else {
        return "transparent";
      }
    },
    getEventColorByStatus(status) {
      if (this.lists.meetingStatusList) {
        var statusObject = this.lists.meetingStatusList.filter(function (obj) {
          return obj.id.toString() == status;
        });

        return statusObject.length > 0 ? statusObject[0].color : "transparent";
      } else {
        return "transparent";
      }
    },

    getEventRankColor(event) {
      if (this.calendarLists.meetingRank) {
        var rankObject = this.calendarLists.meetingRank.filter(function (obj) {
          return obj.text == event.rank;
        });
        return rankObject.length > 0 ? rankObject[0].background : "transparent";
      } else {
        return "transparent";
      }
    },

    getEventRankTextColor(event) {
      if (this.calendarLists.meetingRank) {
        // var rankObject = this.calendarLists.meetingRank.filter(function (obj) {
        //   return obj.text == event.rank;
        // });

        const rankObject = this.calendarLists.meetingRank.find(
          (obj) => obj.text == event.rank
        );

        return rankObject ?  rankObject.color : "white";

      } else {
        return "transparent";
      }
    },

    getEventTextColor(event) {
      if (this.lists.meetingStatusList) {
        var statusObject = this.lists.meetingStatusList.filter(function (obj) {
          return obj.text == event.meetingStatus;
        });
        return statusObject.length > 0
          ? statusObject[0].textColor
          : "transparent";
      } else {
        return "transparent";
      }
    },
    getAgentColor(event) {
      if (this.calendarLists.agentsInfos) {
        var agentsInfoObj = this.calendarLists.agentsInfos.filter(function (
          obj
        ) {
          return obj.id == event.agent;
        });
        return agentsInfoObj.length > 0 ? agentsInfoObj[0].color : "#CFD8DC";
      } else {
        return "#CFD8DC";
      }
    },
    getAgentPhoto(event) {
      if (this.calendarLists.agentsInfos) {
        var agentsInfoObj = this.calendarLists.agentsInfos.filter(function (
          obj
        ) {
          return obj.id == event.agent;
        });
        return agentsInfoObj.length > 0
          ? agentsInfoObj[0].photoURL
          : "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4";
      } else {
        return "https://firebasestorage.googleapis.com/v0/b/sela-crm-prod.appspot.com/o/Avatars%2FAVATAR.png?alt=media&token=77511039-bd97-48c0-8658-acee182cd5d4";
      }
    },
    getAgent(event) {
      if (this.calendarLists.agentsInfos) {
        var agentsInfoObj = this.calendarLists.agentsInfos.filter(function (
          obj
        ) {
          return obj.id == event.agent;
        });
        return agentsInfoObj.length > 0 ? agentsInfoObj[0] : {};
      } else {
        return {};
      }
    },
    getRankColor(text) {
      if (this.calendarLists.meetingRank) {
        var colorObj = this.calendarLists.meetingRank.filter(function (obj) {
          return obj.text == text;
        });
        return colorObj.length > 0 ? colorObj[0].background : "#CFD8DC";
      } else {
        return "#CFD8DC";
      }
    },
    getStatusColor(event) {
      if (this.lists.statusList) {
        var statusObj = this.lists.statusList.filter(function (obj) {
          return obj.id == event.status;
        });
        return statusObj.length > 0 ? statusObj[0].color : "#CFD8DC";
      } else {
        return "#CFD8DC";
      }
    },

    getAgentColorByText(agentid) {
      if (this.calendarLists.agentsInfos) {
        var colorObj = this.calendarLists.agentsInfos.filter(function (obj) {
          return obj.id == agentid;
        });
        return colorObj.length > 0 ? colorObj[0].color : "#CFD8DC";
      } else {
        return "#CFD8DC";
      }
    },

    setCheckboxIcon(item) {
      if (typeof this.agnetResponseDoc.agentMustAttrs == "undefined") {
        return false;
      }
      return this.agnetResponseDoc.agentMustAttrs.includes(item);
    },

    setToday() {
      this.focus = "";
    },
    setDate() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      let vueInstance = this;
      const open = async () => {
        // this.selectedEvent = event
        // this.selectedElement = nativeEvent.target

        //updateMeetingonUpdRW
        this.updating = true;
        event = await this.getLatsetMeetingData(event);
        this.updating = false;

        this.selectedObject = event;
        this.edit = true;
        this.selectedObject.note = "";
        this.deletePrompt = false;

        if (typeof this.selectedObject.relevant == "undefined") {
          this.selectedObject.relevant = "";
        }
        if (typeof this.selectedObject.attributes == "string") {
          if (this.selectedObject.attributes == "") {
            this.selectedObject.attributes = [];
          } else {
            this.selectedObject.attributes = [this.selectedObject.attributes];
          }
        }
        this.agnetResponseDoc = rootDb
          .collection("Agents")
          .doc(event.agent)
          .collection("AgentMeetings")
          .doc(event.selaId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log(doc.data());
              var x = doc.data();
              this.agnetResponseDoc = doc.data();


              if (doc.data().status != 7) {
                this.agnetResponseDocFlag = true;
              } else {
                this.agnetResponseDocFlag = false;
              }
            } else {
              this.agnetResponseDocFlag = false;
            }
          });

        setTimeout(() => (this.dialog = true), 10);

        let element = document.querySelector("#calendar");
        console.log(Math.floor(vueInstance.scrollPositionsLeft));
        element.scrollTo(
          Math.floor(vueInstance.scrollPositionsLeft),
          Math.floor(vueInstance.scrollPositionsTop)
        );
      };

      if (this.dialog) {
        this.dialog = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getMeetingColor() {},
    printthis(any) {
      console.log(any);
    },

    checkForCallAgain(event) {
      let res = false;
      if (event.hasOwnProperty("secoundReminder")) {
        res = event.secoundReminder;
      }
      return res;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    startDrag({ event, timed }) {
      if (
        event &&
        !this.lockForm &&
        this.dragMode &&
        event.eventType != "constrain"
      ) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        var startDate = new Date(start);

        this.dragTime = Math.abs(mouse - startDate);
        var zibi = this.dragTime;
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const mstart = new Date(start);
        const mend = new Date(end);

        const duration = Math.abs(mend - mstart);
        const newStartTime = mouse - this.dragTime;

        // var newStart = this.roundTime(newStartTime)
        var newEndDate = new Date(this.roundTime(newStartTime));

        const newStart = moment(this.roundTime(newStartTime)).format(
          "YYYY-MM-DD HH:mm"
        );
        // const newEnd = newStart + duration
        const newEnd = moment(new Date(newStart))
          .add(duration)
          .format("YYYY-MM-DD HH:mm");

        // this.dragEvent.meetingDate = new date(newStart);
        this.dragEvent["date"] = moment(newStart).format("YYYY-MM-DD");

        this.dragEvent.hours = parseInt(moment(newStart).format("H"));
        this.dragEvent.minutes = parseInt(moment(newStart).format("m"));
        this.dragEvent.hoursTo = parseInt(moment(newEnd).format("H"));
        this.dragEvent.minutesTo = parseInt(moment(newEnd).format("m"));

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      }
    },
    endDrag() {
      if (!this.showMenu) {
        if (this.dragEvent) {
          this.updateMeedting(this.dragEvent);
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    async updateRange({ start, end }) {
      this.monthTitle = start.month;
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);
      this.dates = [
        moment(min).format("YYYY-MM-DD"),
        moment(max).format("YYYY-MM-DD"),
      ];

      this.getEvents(min, max);
    },
    async getEvents(min, max) {
      const events = [];
      this.loading = true;
      let referance = this;

      this.citys = [];
      var constarins;
      let tempCitys = this.citys;
      var meetings = await db
        .collection("Meetings")
        .where("meetingDate", ">=", min)
        .where("meetingDate", "<=", max)
        .get();

      try {
        var constarins = await rootDb
          .collectionGroup("Constrains")
          .where("from", ">=", min)
          .where("from", "<=", max)
          // .where("environment", "==", this.$store.state.environment)
          .get();
      } catch (error) {
        console.log("no constarins permission");
        console.log(error);
      }

      let constrainTemplate = {
        info: "",
        timeRange: "",
        relevant: "",
        meetingStatus: "ממתין לאישור",
        reminder: false,
        mustAttrs: [],
        cMail: "",
        representativeName: "",
        color: "",
        category: "",
        agent: "",
        source: "",
        minutesTo: 0,
        dataInsertOn: "",
        hour: "",
        productType: "פנסיוני",
        age: "",
        comments: [],
        createdOn: {},
        representative: "",
        hours: 0,
        note: "",
        attributes: [],
        meetingClosedBy: "",
        updatedBy: "",
        minutes: 0,
        address: {},
        end: "",
        hoursTo: 0,
        duration: 0,
        date: "",
        updatedByName: "",
        time: "",
        timed: false,
        resFlag: false,
        phone: "",
        selaId: "",
        mainStatus: 0,
        updatedOn: {},
        status: 100,
        name: "אילוץ",
        statusText: "אילוץ",
        start: "",
        rank: "",
        meetingDate: {},
        cId: "",
        city: "",
      };
      let constrainsObjs = [];

      meetings.forEach((doc) => {
        tempCitys.push(
          typeof doc.data().address.city != "undefined"
            ? doc.data().address.city
            : ""
        );
      });
      2;

      tempCitys = [...new Set(tempCitys)];
      tempCitys.sort();

      this.citys = tempCitys;

      this.selctedCitys = tempCitys;
      var agentsMeetings;
      // if (this.$store.state.environment == "Moked2") {
      //   agentsMeetings = await rootDb
      //     .collectionGroup("AgentMeetings")
      //     .where("meetingDate", ">=", min)
      //     .where("meetingDate", "<=", max)
      //     .where("environment", "==", this.$store.state.environment)
      //     .get();
      // } else {
      //   agentsMeetings = await rootDb
      //     .collectionGroup("AgentMeetings")
      //     .where("meetingDate", ">=", min)
      //     .where("meetingDate", "<=", max)
      //     .get();
      // }

      agentsMeetings = await rootDb
        .collectionGroup("AgentMeetings")
        .where("meetingDate", ">=", min)
        .where("meetingDate", "<=", max)
        .where("environment", "==", this.$store.state.environment)
        .get();

      let agentsMeetingsDocs = [];
      agentsMeetings.forEach((doc) => {
        agentsMeetingsDocs.push(doc.data());
      });

      var ref = this;
      meetings.forEach((doc) => {
        var obj = doc.data();
        // ref.popUPs[obj.selaId] =  false;
        obj["category"] = ref.getAgnetNameByid(obj.agent);
        if (ref.lockForm) {
          this.$store.state.userObj.user.uid == obj.representative
            ? events.push(obj)
            : () => {};
        } else {
          events.push(obj);
        }
        // ref.citys.push(obj.address.city);

        let agentMeeting = agentsMeetingsDocs.filter((meeting) => {
          return meeting.selaId == doc.id;
        })[0];
        //obj['agentMeeting'] = agentMeeting;
        if (agentMeeting) {
          if (
            agentMeeting.status != 7 &&
            typeof agentMeeting.status != "undefined"
          ) {
           
            obj["resFlag"] = true;
            obj["agentStatus"] = agentMeeting.status;
          } else {
            if (typeof agentMeeting.status != "undefined") {
              agentMeeting["status"] = 7;
              agentMeeting["statusText"] = "פגישה";
            }
            obj["resFlag"] = false;
          }
        } else {
          obj["resFlag"] = false;
        }
      });

      try {
        if (this.showConstrains && this.type == "category") {
          constarins.forEach((doc) => {
            let obj = { ...constrainTemplate };
            obj["category"] = referance.getAgnetNameByid(doc.data().agent);
            obj.start = moment(doc.data().from.toDate()).format(
              "YYYY-MM-DD HH:mm"
            );
            obj.end = moment(doc.data().to.toDate()).format("YYYY-MM-DD HH:mm");
            obj.agent = doc.data().agent;
            obj["name"] = "אילוץ";

            if (doc.data().hasOwnProperty("city")) {
              obj.city = doc.data().city;
            }

            obj.color = "#B71C1C";
            obj["eventType"] = "constrain";
            obj.selaId = doc.data().selaId;
            events.push(obj);
          });
        }
      } catch (error) {
        console.log(error);
      }

      this.allEvents = events;

      this.addEvents();

      this.loading = false;
    },

    async addEvents() {
      var ref = this;
      var filterdEvents = [];

      let constarins = this.allEvents.filter((event) => {
        return event.name == "אילוץ";
      });

      // this.allEvents = [... this.allEvents]
      filterdEvents = this.allEvents;

      filterdEvents = [...filterdEvents];

      let mainStatussCodes = [];
      this.selectedMainStatus.forEach((obj) => {
        mainStatussCodes.push(obj.text);
      });
      let meetingStatusCodes = [];
      this.selctedStatus.forEach((obj) => {
        meetingStatusCodes.push(obj.id);
      });
      let mettingTypes = [];
      this.selctedMeetingTypes.forEach((obj) => {
        mettingTypes.push(obj.text);
      });

      let agetnsIds = [];
      this.categories = [];

      this.selctedAgents = this.selctedAgents.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      this.selctedAgents.forEach((obj) => {
        if (obj.id == "0000") {
          agetnsIds.push("0000");
          ref.categories.push("לא משוייך");
        }
      });

      this.selctedAgents.forEach((obj) => {
        if (obj.id != "0000") {
          agetnsIds.push(obj.id);
          ref.categories.push(obj.name);
        }
      });

      filterdEvents = filterdEvents.filter((anEvent) => {
        return agetnsIds.includes(anEvent.agent);
      });

      filterdEvents = filterdEvents.filter((anEvent) => {
        return ref.selctedCitys.includes(anEvent.address.city);
      });

      filterdEvents = filterdEvents.filter((anEvent) => {
        return mettingTypes.includes(anEvent.rank);
      });
      filterdEvents = filterdEvents.filter((anEvent) => {
        return mainStatussCodes.includes(anEvent.meetingStatus);
      });
      filterdEvents = filterdEvents.filter((anEvent) => {
        return meetingStatusCodes.includes(anEvent.status);
      });

      this.categories = [...new Set(this.categories)];

      constarins.forEach((event) => {
        filterdEvents.push(event);
      });

      filterdEvents.forEach((event) => {
        event["isPhoneCall"] = event.attributes?.includes("פגישה טלפונית") ? "כן" : "";
        event["isMasleka"] = event.attributes?.includes("מסלקה") ? "כן" : "";
      });

      this.events = filterdEvents;
    },

    getAddressData(addressData, placeResultData, id) {
      var locationObj = {
        lat: placeResultData.geometry.location.lat(),
        lng: placeResultData.geometry.location.lng(),
        addressText: placeResultData.formatted_address,
        // id:placeResultData.id,
        url: placeResultData.url,
        city: placeResultData.vicinity,
      };
      this.selectedObject["address"] = locationObj;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async updateMeedting(selectedObject) {
      this.selectedObject["category"] = this.getAgnetNameByid(
        this.selectedObject.agent
      );
      var object = selectedObject;
      var userObj = this.$store.state.userObj;
      object["updatedOn"] = new Date();
      object["updatedBy"] = userObj.user.uid;
      object["meetingDate"] = moment(object.date, "YYYY-MM-DD").toDate();
      var endDate = moment(object.date, "YYYY-MM-DD").toDate();
      object.meetingDate.setHours(object.hours);
      object.meetingDate.setMinutes(object.minutes);
      endDate.setHours(object.hoursTo);
      endDate.setMinutes(object.minutesTo);

      object["start"] = moment(object.meetingDate).format("YYYY-MM-DD HH:mm");
      object["end"] = moment(endDate).format("YYYY-MM-DD HH:mm");
      var minuteStart =
        object.minutes < 10 ? "0" + object.minutes : object.minutes;
      var minuteEnd =
        object.minutesTo < 10 ? "0" + object.minutesTo : object.minutesTo;

      var hourStart = object.hours < 10 ? "0" + object.hours : object.hours;
      var hourEnd = object.hoursTo < 10 ? "0" + object.hoursTo : object.hoursTo;

      object["timeRange"] =
        hourStart + ":" + minuteStart + " - " + hourEnd + ":" + minuteEnd;
      object["duration"] = Math.abs(
        new Date(object["end"]) - new Date(object["start"])
      );

      var comments = this.addComments();

      if (comments !== "") {
        object.hasOwnProperty("comments")
          ? () => {}
          : (object["comments"] = []);
        object.comments.push(comments);
      }
      object["isReplica"] = false;

      object["statusText"] = this.getStatusText(object.status);
      await db
        .collection("PhoneCalls")
        .doc(String(object.phone))
        .set(object, { merge: true });
      return db
        .collection("Meetings")
        .doc(String(object.selaId))
        .set(object, { merge: true });
    },

    addComments() {
      if (!this.selectedObject.note || 0 === this.selectedObject.note.length) {
        return "";
      } else {
        var comment = {
          displayName: this.$store.state.userObj.user.displayName,
          photoURL: this.$store.state.userObj.user.photoURL,
          uid: this.$store.state.userObj.user.uid,
          time: new Date(),
          text: (" " + this.selectedObject.note).slice(1),
          insideComment: this.insideComment ? true : false,
        };
        this.selectedObject.note = "";
        this.insideComment = false;

        return comment;
      }
    },

    async saveChange() {
      this.mapsKey++;
      this.updating = true;
      let rep = this.selectedObject.representative;
      console.log(rep);
      // this.selectedObject.representative = rep.uid;
      // this.selectedObject.representativeName = rep.displayName;

      await this.updateMeedting(this.selectedObject);
      await this.updateLastUpdateLocaly(this.selectedObject);
      try {
        if(this.agentDocUpdatePayload !== null){
          console.log("agentDocUpdatePayload",this.agentDocUpdatePayload);
          let x =  await db.collection("Agents").doc(this.selectedObject.agent).collection("AgentMeetings").doc(this.selectedObject.selaId).update(this.agentDocUpdatePayload);
          console.log("x",x);
         }
        this.agentDo
        this.agentDocUpdatePayload = null;cUpdatePayload = null;

      } catch (error) {
      }

      this.agentDocUpdatePayload = null;
      this.$forceUpdate();

      this.updating = false;
      this.dialog = false;
    },

    hidMeetings() {
      this.addEvents();
    },

    async deleteMeeting() {
      this.mapsKey++;
      this.updating = true;

      await db.collection("Meetings").doc(this.selectedObject.selaId).delete(),
        this.updateMeetings();
      this.updating = false;
      this.dialog = false;
      this.deletePrompt = false;
    },

    getAgnetNameByid(id) {
      var agents = this.calendarLists.agentsInfos.filter(function (obj) {
        return obj.id == id;
      });
      return agents.length > 0 ? agents[0].name : "לא משוייך";
    },
    getRepNameByid(uid) {
      const list = this.repList 
      const rep = list.find((obj) => obj.uid == uid);
      return rep ? rep.displayName : "";
    },

    getStatusText(id) {
      var status = this.lists.statusList.filter(function (obj) {
        return obj.id == id;
      });
      return status.length > 0 ? status[0].text : "";
    },
    getStatusColorById(id) {
      var status = this.lists.statusList.filter(function (obj) {
        return obj.id == id;
      });
      return status.length > 0 ? status[0].color : "";
    },
    rowClicked(row) {
      console.log(row);
      var index = this.findeMeeting(row.selaId);
      this.selectedObject = this.events[index];
      this.dialog = true;
      this.edit = true;
      this.agnetResponseDocFlag = row.resFlag;
      if (typeof this.selectedObject.relevant == "undefined") {
        this.selectedObject.relevant = "";
      }
      if (typeof this.selectedObject.attributes == "string") {
        if (this.selectedObject.attributes == "") {
          this.selectedObject.attributes = [];
        } else {
          this.selectedObject.attributes = [this.selectedObject.attributes];
        }
      }
      this.agnetResponseDoc = db
        .collection("Agents")
        .doc(row.agent)
        .collection("AgentMeetings")
        .doc(row.selaId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log(doc.data());
            var x = doc.data();
            this.agnetResponseDoc = doc.data();

            if (doc.data().status != 7) {
              this.agnetResponseDocFlag = true;
            } else {
              this.agnetResponseDocFlag = false;
            }
          } else {
            this.agnetResponseDocFlag = false;
          }
        });
    },
    eventHover(event) {
      // console.log(event);
      // this.dialog3 = false;
    },

    onClickOutside() {
      this.dialog3 = false;
    },
    findeMeeting(id) {
      var ref = this;
      var index = null;
      for (var i = 0; i < this.events.length; i++) {
        if (this.events[i].selaId == id) {
          index = i;
          break;
        }
      }
      return index;
    },

    actionsClicked(rows) {
      this.selectedRows = [];
      this.dialog2 = true;
      this.selectedRows = rows;
    },
    async massChange() {
      this.updating2 = true;
      var promises = [];
      var ref = this;

      this.selectedRows.forEach((obj) => {
        ref.actionStatus != "" ? (obj.status = ref.actionStatus.id) : () => {};
        ref.actionStatus != ""
          ? (obj.statusText = ref.actionStatus.text)
          : () => {};
        ref.actionMainStatus != ""
          ? (obj.meetingStatus = ref.actionMainStatus.text)
          : () => {};

        ref.actionsRank != "" ? (obj.rank = ref.actionsRank) : () => {};
        ref.actionsAgent != ""
          ? (obj.agent = String(ref.actionsAgent))
          : () => {};
        ref.actionsAgent != ""
          ? (obj.category = ref.getAgnetNameByid(obj.agent))
          : () => {};

        //representative - zibi here
         ref.actionRep != ""
          ? (obj.representative = String(ref.actionRep))
          : () => {};
        ref.actionRep != ""
          ? (obj.representativeName = ref.getRepNameByid(String(ref.actionRep)))
          : () => {};

        var p = ref.updateMeedting(obj);
        promises.push(p);
      });

      this.actionsAgent = "";
      this.actionsRank = "";
      this.actionStatus = "";
      this.actionMainStatus = "";

      var result = await Promise.all(promises);
      this.updating2 = false;
      this.dialog2 = false;
      this.selectedRows = [];
    },
    filterEventsByDate() {
      this.dates.sort();
      var min = new Date(this.dates[0]);
      var max = new Date(this.dates[1]);

      min.setHours(0);
      min.setMinutes(0);

      max.setHours(23);
      max.setMinutes(59);

      this.getEvents(min, max);
    },
    selectAllAgent() {
      this.selctedAgents = this.calendarLists.agentsInfos;
    },
    selectAllMeetginsTypes() {
      this.selctedMeetingTypes = this.calendarLists.meetingRank;
    },
    selectAllsStatus() {
      this.selctedStatus = this.lists.statusList;
    },
    selectAllMainStatus() {
      this.selectedMainStatus = this.lists.meetingStatusList;
    },
    selectAllCitys() {
      this.selctedCitys = this.citys;
      this.addEvents();
    },

    timeStampTOdate(timestamp) {
      if (typeof timestamp == "undefined") {
        return "";
      } else if (typeof timestamp.getMonth === "function") {
        return moment(timestamp).format("HH:mm, DD/MM/YYYY");
      }
      if (typeof timestamp == "string") {
        return timestamp;
      }
      if(timestamp.hasOwnProperty("_seconds")){
        return moment(new Date(this.agnetResponseDoc.updatedOn._seconds * 1000)).format("HH:mm, DD/MM/YYYY");
      }

      return moment(timestamp.toDate()).format("HH:mm, DD/MM/YYYY");
    },

    acceptAgentReport() {
      this.selectedObject.statusText = this.agnetResponseDoc.statusText;
      this.selectedObject.status = this.agnetResponseDoc.status;

      if (this.selectedObject.status == 11) {
        this.selectedObject.relevant = "כן";
      } else if (
        this.selectedObject.status == 3 ||
        this.selectedObject.status == 4
      ) {
        this.selectedObject.relevant = "לא";
      }

      if (this.selectedObject.status == 9) {
        this.selectedObject.relevant = "";
        this.selectedObject.mainStatus = 1;
        this.selectedObject.meetingStatus = "ממתין לאישור";
        this.selectedObject.status = 8;
        this.selectedObject.statusText = "חידוש שיחה";
        this.selectedObject.agent = "0000";
        this.selectedObject.categsory = "לא משוייך";
        this.$forceUpdate();
      }
    },
    acceptAgentDate() {
      this.selectedObject.meetingDate = this.agnetResponseDoc.meetingDate;
      this.selectedObject.date = this.agnetResponseDoc.date;
      this.selectedObject.start = this.agnetResponseDoc.start;
      this.selectedObject.end = this.agnetResponseDoc.end;
      this.selectedObject.hours = this.agnetResponseDoc.hours;
      this.selectedObject.hoursTo = this.agnetResponseDoc.hoursTo;
      this.selectedObject.minutes = this.agnetResponseDoc.minutes;
      this.selectedObject.minutesTo = this.agnetResponseDoc.minutesTo;
      this.selectedObject.duration = this.agnetResponseDoc.duration;
      this.selectedObject.status = 7;
      this.selectedObject.statusText = "פגישה";


      const agentDocUpdatePayload = 
       {
          metingDate: this.agnetResponseDoc.meetingDate,
          date: this.agnetResponseDoc.date,
          start: this.agnetResponseDoc.start,
          end: this.agnetResponseDoc.end,
          hours: this.agnetResponseDoc.hours,
          hoursTo: this.agnetResponseDoc.hoursTo,
          minutes: this.agnetResponseDoc.minutes,
          minutesTo: this.agnetResponseDoc.minutesTo,
          duration: this.agnetResponseDoc.duration,
          status: this.selectedObject.status,
          statusText: this.selectedObject.statusText,
        
        }

      if(this.agentDocUpdatePayload == null){
        this.agentDocUpdatePayload = {...agentDocUpdatePayload}
      }

      console.log(
       agentDocUpdatePayload
      );
    
      this.$forceUpdate();
    },
    acceptAgentClosing() {
      try {
        this.selectedObject["closingAmout"] =
          this.agnetResponseDoc.closingAmout;
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },

    showAgentDates() {
      console.log(this.agnetResponseDoc)
      let resultDate = moment().format(
        "DD/MM/YYYY"
      );
      if(this.agnetResponseDoc.updatedOn.hasOwnProperty("_seconds")){
       resultDate = new Date(this.agnetResponseDoc.updatedOn._seconds * 1000)
      }
      else{
         resultDate = moment(this.agnetResponseDoc.updatedOn?.toDate()).format(
          "DD/MM/YYYY HH:mm"
        );
      }
      return resultDate;
    },
    moveAgentLeft(event) {},
    moveAgentRight(event) {},

    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    oneClick(event) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.result.push(event.type);
          this.clicks = 0;
          console.log("click");
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.result.push("dblclick");
        console.log("db click");
        this.clicks = 0;
        this.showEvent(event);
      }
    },
    handler: function (e) {
      let vueInstance = this;
      console.log(e);
      e.preventDefault();
      console.log("handler");
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;

      e.path.forEach(async (element) => {
        if (typeof element.classList != "undefined") {
          if (element.classList[0] == "v-event-timed") {
            console.log(element.firstChild.id);

            let meeting = this.allEvents.filter((doc) => {
              return doc.selaId == element.firstChild.id;
            });

            meeting.length > 0 ? (meeting = meeting[0]) : (meeting = null);

            if (meeting) {
              console.log(meeting);
              this.showMenu = true;
              this.selectedObject = {};
              this.selectedObject = await this.getLatsetMeetingData(meeting);
              let element = document.querySelector("#calendar");
              element.scrollTo(
                Math.floor(vueInstance.scrollPositionsLeft),
                Math.floor(vueInstance.scrollPositionsTop)
              );

              let agentObj = (this.agnetObject =
                this.calendarLists.agentsInfos.filter((obj) => {
                  return obj.id == meeting.agent;
                }));

              agentObj.length > 0
                ? (agentObj = agentObj[0])
                : (agentObj = null);

              if (agentObj) {
                this.agnetObject = agentObj;
              } else {
                this.agnetObject = this.calendarLists.agentsInfos[0];
              }

              this.agnetObjectNameForFilter = this.selectedObject.category;
              this.menuAgentsList = [...this.calendarLists.agentsInfos];
            } else {
              this.selectedObject = {};
            }
          }
        }
      });
    },

    selctedAgentFromMenu(item) {
      console.log(item);
      this.selectedObject.agent = item.id;
      this.agnetObject = item;
      this.agnetObjectNameForFilter = item.name;
      this.updateMeedting(this.selectedObject);
    },
    filterAgentsOnMenu(text) {
      this.menuAgentsList = this.calendarLists.agentsInfos.filter((obj) => {
        return obj.name.includes(text);
      });
    },
    updateMainStatusFromMenu() {
      this.updateMeedting(this.selectedObject);
    },
    updateMeetings() {
      this.allEvents = [];
      const startOfMonth = moment()
        .clone()
        .startOf("month")
        .subtract(1, "months");
      const endOfMonth = moment().clone().endOf("month").add(1, "months");
      this.getEvents(startOfMonth.toDate(), endOfMonth.toDate());
      this.dates = [
        moment(startOfMonth.toDate()).format("YYYY-MM-DD"),
        moment(endOfMonth.toDate()).format("YYYY-MM-DD"),
      ];
      this.$forceUpdate();
    },

    sendComments() {
      this.updateMeedting(this.selectedObject);
    },

    onChangeStatus() {
      if (this.selectedObject.relevant == "לא") {
        this.selectedObject.status = 3;
        this.selectedObject.statusText = "לא רלוונטי";
      } else if (this.selectedObject.relevant == "כן") {
        if ((this.selectedObject.status = 3)) {
          this.selectedObject.status = 7;
          this.selectedObject.statusText = "פגישה";
        }
      } else {
        if ((this.selectedObject.status = 3)) {
          this.selectedObject.status = 7;
          this.selectedObject.statusText = "פגישה";
        }
      }
    },
  },

  computed: {

    getCenter() {
      return this.selectedObject.address
        ? {
            lat: this.selectedObject.address.lat,
            lng: this.selectedObject.address.lng,
          }
        : { lat: 0, lng: 0 };
    },
    getMinuts() {
      var array = [];
      for (var i = 0; i < 60; i += 5) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },
    getHouers() {
      var array = [];
      for (var i = 8; i < 22; i++) {
        var text = i < 10 ? "0" + i : "" + i;
        array.push({ text: text, value: i });
      }
      return array;
    },
    computedDateFormatted() {
      return this.formatDate(this.selectedObject.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.reminder.date);
    },
    getHebMonth() {
      var months = {
        1: "ינואר",
        2: "פברואר",
        3: "מרץ",
        4: "אפריל",
        5: "מאי",
        6: "יוני",
        7: "יולי",
        8: "אוגוסט",
        9: "ספטמבר",
        10: "אוקטובר",
        11: "נובמבר",
        12: "דצמבר",
      };

      return months[this.monthTitle] + " - " + this.$refs.calendar.lastEnd.year;
    },
    getMustAttars() {
      return this.selectedObject.productType == "פנסיוני"
        ? this.selectedObject.financialAttrs
        : this.selectedObject.insuranceAttrs;
    },
    getAdressText() {
      if (this.selectedObject.address) {
        return this.selectedObject.address.addressText;
      } else {
        return " ";
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  async created() {
    var meetingRank = [
      { background: "#3F51B5", color: "#FFFFFF", text: "פגישה חזקה" },
      { background: "#2196F3", color: "#FFFFFF", text: "המשך טיפול" },
      { background: "#00BCD4", color: "#FFFFFF", text: "פגישת שירות" },
      { background: "#FF9800", color: "#FFFFFF", text: "תזכורת נוספת" },
      { background: "#4CAF50", color: "#FFFFFF", text: "חידוש פגישה" },
      { background: "#ECEFF1", color: "#000000", text: "ממתין לאישור" },
      { background: "#795548", color: "#FFFFFF", text: "פגישה מאושרת" },
      { background: "#FFEA00", color: "#000000", text: "לא מאושר" },
      { background: "#607D8B", color: "#FFFFFF", text: "לא רלוונטית" },
      { background: "#E91E63", color: "#FFFFFF", text: "לקוח נסגר" },
      { background: "#9E9E9E", color: "#FFFFFF", text: "סטטוס מאושר" },
    ];

    console.log("created");
    // var agentsInfos = [
    //   {name:'לא משוייך',color:'#ECEFF1',id:'304861412'},
    //   {name:'דניאל לוזגרטן',color:'#FFC107',id:'304861412'},
    //   {name:'איתי נחום',color:'#26A69A',id:'123456789'},
    //   {name:'ירון מועלם',color:'#1565C0',id:'98765431'},
    // ]
    // await db.collection('System').doc('CalendarLists').set({agentsInfos:agentsInfos},{merge:true})
    var calendarListsDoc = await db
      .collection("System")
      .doc("CalendarLists")
      .get();

    var userConfigDoc = await db
      .collection("UsersConfigs")
      .doc(this.$store.state.userObj.user.uid)
      .get();
      

     
    this.calendarLists = calendarListsDoc.data();
    var ref = this;

    this.calendarLists.agentsInfos.forEach((agent) => {
      // ref.categories.push(agent.name)
      ref.menuAgentsList.push(agent);
    });

    var lists = await db.collection("System").doc("lists").get();
    var valuesDocs = await db.collection("System").doc("values").get();

    this.lists = lists.data();
    this.selctedMeetingTypes = this.calendarLists.meetingRank;
    this.selectedMainStatus = this.lists.meetingStatusList;
    this.selctedStatus = this.lists.statusList;

    this.source = valuesDocs.data().sources;

    if (userConfigDoc.exists) {
      console.log("userConfigDoc");
      if (userConfigDoc.data().hasOwnProperty("agentFilter")) {
        this.selctedAgents = userConfigDoc.data().agentFilter;
      }
      if (userConfigDoc.data().hasOwnProperty("statusFilter")) {
        this.selctedStatus = userConfigDoc.data().statusFilter;
      }
      if (userConfigDoc.data().hasOwnProperty("rankFilter")) {
        this.selctedMeetingTypes = userConfigDoc.data().rankFilter;
      }
      if (userConfigDoc.data().hasOwnProperty("mainStatusFilter")) {
        this.selectedMainStatus = userConfigDoc.data().mainStatusFilter;
      }
      if (userConfigDoc.data().hasOwnProperty("citysFilter")) {
        this.selctedCitys = userConfigDoc.data().citysFilter;
      }
      if (userConfigDoc.data().hasOwnProperty("viewType")) {
        this.type = userConfigDoc.data().viewType;
      }
    } else {
      this.selctedAgents = this.calendarLists.agentsInfos;
      this.selctedMeetingTypes = this.calendarLists.meetingRank;
      this.selectedMainStatus = this.lists.meetingStatusList;
      this.selctedStatus = this.lists.statusList;
    }
    this.calendarKey++;
  },
};
</script>

<style>
.pl-1 {
  font-weight: bold;
}
.pl-1 strong {
  margin-right: 5px;
  margin-left: 5px;
  color: #ffffff !important;
}
.v-event {
  width: 100% !important;
}
.agentIndicator {
  width: calc(1vw) !important;
  height: calc(1vw) !important;
  max-height: 12px;
  max-width: 12px;
  margin: 1px;
  border-radius: 12px;
  border: 1.5px solid #37474f;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.statusIndicator {
  width: calc(1vw) !important;
  height: calc(1vw) !important;
  max-height: 12px;
  max-width: 12px;
  margin: 1px;
  border: 1.6px solid #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.statusIndicator2 {
  width: calc(1vw) !important;
  height: calc(1vw) !important;
  max-height: 12px;
  max-width: 12px;
  margin: 1px;
  border: 3px solid #ffff00;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.serachMap {
  position: absolute;
  top: 10px;
  right: 70px;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  border-radius: 0;
}

.v-calendar-weekly__day-label .v-btn {
  font-size: calc(1vw - 0.3em) !important;
}
.v-calendar-daily_head-day-label {
  height: auto;
}
#calendar .v-btn__content {
  font-size: calc(1vw - 0.3em) !important;
}

#calendar .v-btn--fab.v-size--default {
  height: 30px !important;
  width: 30px !important;
}

.v-btn--fab.v-size--small {
  height: calc(2vw) !important;
  width: calc(2vw) !important;
}
#calendar .v-calendar-category__column-header {
  font-size: calc(1vw - 0.1em) !important;
}

#calendar .v-calendar-daily__interval {
  background-color: white !important;
}

#calendar .v-calendar-daily__intervals-head {
  background-color: white !important;
}

#calendar .v-calendar-category__column-header {
  background-color: white !important;
}
#calendar .v-calendar-daily_head-day {
  background-color: white !important;
}

.eventTile {
  position: relative;
}
</style>
