<template>
  <div class="home">
    <div class="loginContainer">
      <div class="loginForm"  :style="windowWidth <=500  ? 'width:100%' : 'width:40%'">
        <div  v-if="windowWidth >=500" class="loginLogo"></div>
        <div  v-else class="loginLogo" style="width:200px"></div>

        <v-form ref="form" v-model="valid" lazy-validation style="width: 90%">
          <div>
            <v-text-field
              v-model="email"
              type="mail"
              label='דוא"ל'
              prepend-icon="mdi-email"
              dense
              outlined
              filled
              background-color="white"
              required
              :rules="getRules('emailRules')"
            ></v-text-field>
          </div>

          <div>
            <v-text-field
              v-model="password"
              type="password"
              label="סיסמה"
              prepend-icon="mdi-form-textbox-password"
              dense
              outlined
              filled
              background-color="white"
              required
              :rules="getRules('passWordRules')"
            ></v-text-field>
          </div>

          <div>
            <v-select
              v-model="environment"
              label="מוקד"
              prepend-icon="mdi-face-agent"
              dense
              outlined
              filled
              background-color="white"
              :items="environmentsList"
              item-value="id"
              item-text="name"
              required
              :rules="getRules('envRules')"
            ></v-select>
          </div>

          <v-row justify="center">
            <v-btn
              dark
              color="#0D47A1"
              width="auto"
              v-if="signInMode"
              @click="validate"
            >
              <v-row justify="center" align="center">
                <div style="margin: 5px">Create account</div>
              </v-row>
            </v-btn>

            <v-btn
              dark
              color="#263238"
              width="150"
              style="margin: 10px"
              v-if="!signInMode"
              @click="validate"
            >
              <v-row justify="center" align="center">
                <div style="margin: 5px">התחברות</div>
              </v-row>
            </v-btn>
          </v-row>

          <div style="width: 140px; margin: 0 auto" v-if="loading">
            <v-progress-linear
              style="margin-top: 10px"
              color="#03A9F4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>

          <v-row justify="center" v-if="!signInMode">
            <v-btn
              tile
              large
              color="blue"
              style="font-size: 12px; margin-right: 15px; padding: 5px"
              icon
              width="auto"
              @click="restPassword"
              >איפוס סיסמה</v-btn
            >
          </v-row>
        </v-form>
      </div>
      <div v-if="windowWidth >=500" class="loginGraphic">
        <div class="coverTint"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Notiflix from "notiflix";
import firebase from "firebase";
import Localbase from "localbase";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      valid: false,
      signInMode: false,
      name: "",
      email: "",
      password: "",
      password2: "",
      environment: "",
      localbase: null,
      environmentsList: [
        {
          id: "Moked1",
          location: "מעגן",
          name: "מעגן",
        },
        {
          id: "Moked2",
          location: "חיפה",
          name: "חיפה",
        },
        {
          id: "Moked3",
          location: "יובלים",
          name: "יובלים",
        },
         {
          id: "Moked4",
          location: "מני גינדי",
          name: "מני גינדי",
        },
         {
          id: "Moked5",
          location: "מיטב דש",
          name: "מיטב דש",
        },
      ],
      registerd: false,
      loading: false,
    };
  },
  mounted() {
    Notiflix.Notify.Init({
      width: "35%",
      position: "center-top",
      timeout: 5000,
    });
  },
  created() {
    Notiflix.Notify.Init({
      width: "35%",
      position: "center-top",
      timeout: 5000,
    });
  },
  beforeMount() {
    this.localbase = new Localbase("localbase");
  },
  computed: {},
  methods: {
    async validate() {

      var x = await this.$refs.form.validate();
      if (x) {
        this.loading = true;
        if (this.signInMode) {
          this.creatWithEmailandPassword();
        } else {
          this.loginWithMailAndPass();

        }
      }
    },

    async creatWithEmailandPassword() {
      var ref = this;
      var createUser = firebase.functions().httpsCallable("createUser");
      createUser({
        email: ref.email,
        password: ref.password,
        displayName: ref.name,
      })
        .then(async function (result) {
          var signout = await firebase.auth().signOut();
          var sginin = await firebase
            .auth()
            .signInWithEmailAndPassword(ref.email, ref.password);
          var user = firebase.auth().currentUser;
          console.log("sending mail");
          user
            .sendEmailVerification()
            .then(function () {
              ref.signInMode = false;
              ref.loading = false;
              ref.registerd = true;
            })
            .catch(function (error) {
              console.error(error);
              ref.loading = false;
            });
        })
        .catch(function (error) {
          var code = error.code;
          var message = error.message;
          var details = error.details;
          ref.loading = false;
        });
    },

    async loginWithMailAndPass() {
      console.log("loginWithMailAndPass");
      var ref = this;
      var signout = await firebase.auth().signOut();
      try {
        var sginin = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        ref.registerd = false;
        ref.loading = false;
        console.log("saving to local db");
        this.localbase.collection("Settings").doc("environment").set({
          id: ref.environment,
          name: ref.environment,
        });
        console.log(Localbase);

        this.$store.state["environment"] = ref.environment;
        if(this.$router.currentRoute.path !== "/"){
        let url = window.location.href
         url = (url.split("/#")[1])
          this.$router.push({ path: url });
        }
        else{
        this.$router.push({ path: "/" });
        }
      } catch (error) {
        ref.loading = false;
        if (error.code === "auth/user-not-found") {
          Notiflix.Notify.Failure("שם משתמש ו/או סיסמה לא נכונים");
        } else if (error.code === "auth/user-disabled") {
          Notiflix.Notify.Failure("המשתמש בוטל");
        } else {
          Notiflix.Notify.Failure(error.code);
        }
      }
    },

    loginWithGoogle() {
      var ref = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        display: "popup",
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          var token = result.credential.accessToken;
          var user = result.user;
          ref.loading = false;
        })
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
          Notiflix.Notify.Failure(errorMessage);
          ref.loading = false;
        });
    },

    loginWithFaceBook() {
      var ref = this;
      var provider = new firebase.auth.FacebookAuthProvider();
      provider.setCustomParameters({
        display: "popup",
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          var token = result.credential.accessToken;
          var user = result.user;
          ref.loading = false;
        })
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
          Notiflix.Notify.Failure(errorMessage);
          ref.loading = false;
        });
    },

    async sendAgain() {
      var user = await firebase.auth().currentUser;
      user
        .sendEmailVerification()
        .then(function () {
          Notiflix.Notify.Success("Email sent");
        })
        .catch(function (error) {
          // An error happened.
        });
    },

    restPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(function () {
          Notiflix.Notify.Success("Email sent");
        })
        .catch(function (error) {
          // An error happened.
        });
    },

    getRules(field) {
      var rules = {
        emailRules: [
          (v) => !!v || 'חובה למלא כתובת דו"אל',
          (v) => /.+@.+\..+/.test(v) || 'כתובת דוא"ל חייבת להיות חוקית',
        ],

        nameRules: [(v) => !!v || "Name is required"],

        passWordRules: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 6) || "חובה לשים סיסמה",
        ],

        envRules: [(v) => !!v || "יש לבחור מוקד"],

        passWord2Rules: [
          (v) => !!v || "Inset your password again",
          (v) => v === this.password || "סיסמאות לא תואמות",
        ],
      };
      return rules[field];
    },
  },
};
</script>

<style>
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #243949 0%, #517fa4 100%);
}
.loginContainer {
  background-color: white;
  height: 80vh;
  width: 80vw;
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
.loginGraphic {
  height: 100%;
  width: 60%;
  background: #0f2027; /* fallback for old browsers */
  background-image: url("../assets/coverlogo.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  border-radius: 10px 0px 0px 10px;
}

.loginForm {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loginLogo {
  width: 300px;
  height: 100px;
  background-image: url("../assets/fulllogo.png");
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 10px;
}
.flexitem {
  flex: 1;
}
.loginButton {
  width: 200px;
  font-size: 0.65rem !important;
  font-weight: bold !important;
  margin: 10px;
}
.coverTint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background: linear-gradient(
    109.6deg,
    rgba(44, 83, 131, 0.8) 18.9%,
    rgba(95, 175, 201, 0.8) 70.1%
  );
}
h2 {
  margin-bottom: 40px;
  color: #0288d1;
}
.googlelogo {
  width: 20px;
  height: 20px;
  /* background-image: url("../assets/googlelogo.svg") ; */
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 15px;
}
.orLine {
  width: 90%;
  height: 1px;
  margin-bottom: 30px;
  background-color: #cfd8dc;
  position: relative;
}
.orLine div {
  position: absolute;
  top: -10px;
  left: 45%;
  background-color: white;
  width: 40px;
  text-align: center;
  border-radius: 100px;
  color: #90a4ae;
}
</style>