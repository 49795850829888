<template>
  <div id="managerDash" style="padding: 10px">
    <v-row style="padding: 0; margin: 0">
      <v-spacer></v-spacer>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        desne
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 300px">
            <v-text-field
              dense
              v-model="dates"
              label="טווח  תאריכים"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </div>
        </template>
        <v-date-picker
          color="#0277BD"
          v-model="dates"
          range
          no-title
          locale="he"
          scrollable
          @change="sortDats"
        >
          <v-spacer></v-spacer>
          <v-btn text color="#0277BD" @click="$refs.menu.save(date)"
            >סינון</v-btn
          >
        </v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-card>
          <v-card-title>פילוח שיחות לפי סטאטוס</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="statusChartKey"
            :options="statusChart.chartOptions"
            :series="statusChart.series"
            :width="windowWidth / 5.5"
            :height="windowWidth / 5.5"
          ></apexchart>
          <v-divider></v-divider>
          <v-card-actions>
            <v-radio-group
              justify="center"
              v-model="chartOptionsRadio"
              row
              dense
              @change="initStatusChart"
            >
              <v-radio label="הכל" value="all"></v-radio>
              <v-radio label="בתהליך" value="progress"></v-radio>
              <v-radio label="הסתיים" value="done"></v-radio>
            </v-radio-group>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>פילוח שיחות לפי מתאם</v-card-title>
          <v-divider></v-divider>
          <apexchart
            :key="repsChartKey"
            style="direction: ltr; padding: 10px"
            type="bar"
            :width="windowWidth / 2"
            :height="windowWidth / 4.9"
            :options="representiveChart.chartOptions"
            :series="representiveChart.series"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <div style="margin-top:1rem;">
          פגישות שנקבעו

    </div>
    <v-row align="center">
      <div v-for="item in representatives" :key="item.index">
        <v-card v-if="getMeetingsAmount(item.uid)>0" width="200" style="margin: 20px">
          <v-card-title>
               {{item.name}}
          </v-card-title>
          <v-row justify="center" align="center">
             
            <v-col style="padding: 0">
              <!-- <apexchart
                type="radialBar"
                height="200"
                :options="item.chartOptions"
                :series="item.series"
              ></apexchart> -->
            
            </v-col>
          </v-row>
          <v-row justify="center" align="center" style="padding: 0">
            <div style="font-size: 1.2em; color: #1e88e5">
              <!-- {{ bounsTarget }} / {{ item.relaventCalls }} -->
              
                {{getMeetingsAmount(item.uid)}}
            </div>
          </v-row>
        </v-card>
      </div>
   
     


    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DataTable from "../components/DataTable2";
import { db } from "../firebase";
import { firebase } from "@firebase/app";
import phoneFormatter from "phone-formatter";
import moment from "moment";
import Notiflix from "notiflix";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    listsDocs: [],
    representatives: [],
    bounsTarget: 0,
    dates: [],
    menu: false,
    phoneCalls: [],
    statusChartKey: 100,
    repsChartKey: 1000,
    //charts
    chartOptionsRadio: "all",
    statusChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: [],
        size: 220,
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(parseInt(val)) + "%";
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  label: "כלל השיחות",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },  },
              },
            },
          },
        },
      },
    },
    representiveChart: {
      series: [],
      chartOptions: {
        colors: [],

        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {},
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },

        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 0,
        },
      },
    },

    meetingsCount:[],

    statusList: [
      {
        group: 0,
        id: 1,
        text: "שיחה ראשונה",
        color: "#2196F3",
        textColor: "#FFFFFF",
      },
      {
        group: 1,
        id: 2,
        text: "לא ענה",
        color: "#673AB7",
        textColor: "#FFFFFF",
      },
      {
        group: 2,
        id: 3,
        text: "לא רלוונטי",
        color: "#607D8B",
        textColor: "#FFFFFF",
      },
      {
        group: 2,
        id: 4,
        text: "לא רלוונטי כרגע",
        color: "#FFC107",
        textColor: "#FFFFFF",
      },
      {
        group: 2,
        id: 5,
        text: "להסיר",
        color: "#FF5722",
        textColor: "#FFFFFF",
      },
      {
        group: 1,
        id: 6,
        text: "שיחת המשך",
        color: "#00BCD4",
        textColor: "#FFFFFF",
      },
      {
        group: 1,
        id: 7,
        text: "פגישה",
        color: "#69F0AE",
        textColor: "#000000",
      },
      {
        group: 0,
        id: 8,
        text: "חידוש שיחה",
        color: "#E91E63",
        textColor: "#FFFFFF",
      },
      {
        group: 1,
        id: 9,
        text: "דחייה",
        color: "#C62828",
        textColor: "#FFFFFF",
      },
      {
        group: 1,
        id: 10,
        text: "המשך טיפול סוכן",
        color: "#1DE9B6",
        textColor: "#FFFFFF",
      },
      {
        group: 2,
        id: 11,
        text: "סגירה",
        color: "#00E676",
        textColor: "#FFFFFF",
      },
    ],
  }),
  methods: {
    initStatusChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};

      this.listsDocs.statusList.forEach((status) => {
        console.log(status.text);
        statusMap[status.text] = 0;
        ref.statusChart.chartOptions.labels.push(status.text),
          ref.statusChart.chartOptions.colors.push(status.color);
      });

      if (statusMap.hasOwnProperty("")) {
        delete statusMap[""];
      }

      console.log(statusMap);

      this.phoneCalls.forEach((call) => {
        if (this.chartOptionsRadio == "progress") {
          if (
            call.statusText == "שיחה ראשונה" ||
            call.statusText == "לא ענה" ||
            call.statusText == "שיחת המשך" ||
            call.statusText == "פגישה" ||
            call.statusText == "חידוש שיחה" ||
            call.statusText == "המשך טיפול סוכן" ||
            call.statusText == "דחיה"
          ) {
            statusMap[call.statusText]++;
          }
        } else if (this.chartOptionsRadio == "done") {
          if (
            call.statusText == "לא רלוונטי" ||
            call.statusText == "לא רלוונטי כרגע" ||
            call.statusText == "להסיר" ||
            call.statusText == "סגירה"
          ) {
            statusMap[call.statusText]++;
          }
        } else {
          if (typeof call.statusText == "undefined") {
            console.log(call);
          }
          statusMap[call.statusText]++;
        }
      });

      if (statusMap.hasOwnProperty("")) {
        delete statusMap[""];
      }

      ref.statusChart.series = [];

      for (const status in statusMap) {
        if (Number.isNaN(statusMap[status])) {
          statusMap[status] = 0;
        }
        ref.statusChart.series.push(statusMap[status]);
      }

      this.statusChartKey++;
    },

    initRepsChart() {
      var lables = [];
      var colors = [];
      var ref = this;
      var statusMap = {};
      var seriesObjListMap = {};

      ref.representiveChart.chartOptions.colors = [];
      ref.representiveChart.chartOptions.xaxis.categories = [];
      ref.representiveChart.series = [];

      this.listsDocs.statusList.forEach((status) => {
        ref.representiveChart.chartOptions.colors.push(status.color);
        seriesObjListMap[status.text] = { name: status.text, data: [] };
      });

      this.phoneCalls.forEach((call) => {
        statusMap[call.representative] = {
          name: call.representativeName,
          statusList: ref.getStatusInitMap(),
        };
      });

      for (const status in statusMap) {
        ref.representiveChart.chartOptions.xaxis.categories.push(
          statusMap[status].name
        );
      }

      this.phoneCalls.forEach((call) => {
        statusMap[call.representative].statusList[call.statusText]++;
      });

      for (const status in seriesObjListMap) {
        ref.representiveChart.series.push(seriesObjListMap[status]);
      }

      var objs = [];
      var objsMap = {};
      for (const status in statusMap) {
        for (const representative in statusMap[status]) {
          objs.push(statusMap[status]);
        }
      }

      objs.forEach((obj) => {
        objsMap[obj.name] = obj.statusList;
      });

      for (const representative in objsMap) {
        for (const status in objsMap[representative]) {
          seriesObjListMap.hasOwnProperty(status)
            ? seriesObjListMap[status].data.push(
                objsMap[representative][status]
              )
            : () => {};
          // (seriesObjListMap[status]).data.push((objsMap[representative])[status])
        }
      }

      // console.log(objsMap)

      this.repsChartKey++;
    },
    getStatusInitMap() {
      var statusMap = {};
      this.listsDocs.statusList.forEach((status) => {
        statusMap[status.text] = 0;
      });
      return statusMap;
    },

    sortDats() {
      this.dates.sort();
      this.updateDashboard();
    },
    getMeetingsAmount(uid){
      let founds = this.meetingsCount.filter((doc)=>{return doc.representative === uid})
      return founds.length
    },
    async updateDashboard() {
      // //Notiflix.Loading.Pulse('מעדכן נתונים');
      this.$store.state.loading = true;
      this.meetingsCount =[]
      let temp =[]

      var ref = this;
      this.phoneCalls = [];
      this.bonusTarget();

      ref.representatives = [];

      var listsDocs = await db.collection("System").doc("lists").get();
      var from = new Date(this.dates[0]);
      var to = new Date(this.dates[1]);
      var phoneCallsPromise = await db
        .collection("PhoneCalls")
        .where("firstCall", "==", false)
        .where("updateStatus", ">=", from)
        .where("updateStatus", "<=", to)
        .get();
      var representativesPromise = await db.collection("Representatives").get();
      let meetingCountQuery = await db
        .collection("MeetingCount")
        .where("date", ">=", from)
        .where("date", "<=", to)
        .get();

       meetingCountQuery.forEach(doc=>{
        temp.push(doc.data())
       }) 
       this.meetingsCount = temp
      var index = 0;

      representativesPromise.forEach(async (doc) => {
        if (doc.id != "00000" && doc.id != "304861412") {
          var data = doc.data();
          index = index + 100;
          data["index"] = index;
          data["totalCalls"] = 0;
          data["chekedCalls"] = 0;
          data["bonusTarget"] = 45;
          data["relaventCalls"] = 0;
          data["chekedPrecent"] = 0;
          data["relevantPrecent"] = 0;
          data["series"] = [0];
          data["name"] = data.displayName;

          (data["chartOptions"] = {
            chart: {
              height: 350,
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "60%",
                },
              },
            },
            labels: [data.name],
          }),
            ref.representatives.push(data);
          db.collection("Meetings")
            .where("representative", "==", data.uid)
            .where("meetingDate", ">=", from)
            .where("meetingDate", "<=", to)
            .get()
            .then((snapshout) => {
              var cheked = 0;
              var relevant = 0;
              snapshout.forEach((doc) => {
                doc.data().status != 1 ? cheked++ : () => {};
                doc.data().relevant == "כן" ? relevant++ : () => {};
              });
              data.chekedCalls = cheked;
              data["totalCalls"] = snapshout.docs.length;
              data.relaventCalls = relevant;
              data["chekedPrecent"] =
                snapshout.docs.length > 0
                  ? Math.floor((cheked / snapshout.docs.length) * 100)
                  : parseInt("0");
              data["relevantPrecent"] =
                data.bonusTarget > 0
                  ? Math.floor((relevant / data.bonusTarget) * 100)
                  : parseInt("0");

              //data.series[0] =  Math.floor((cheked/snapshout.docs.length)*100);
              data.series = [Math.floor((relevant / ref.bounsTarget) * 100)];
              data.index++;
            });
        }
      });

      phoneCallsPromise.forEach((doc) => {
        ref.phoneCalls.push(doc.data());
      });
      this.listsDocs = listsDocs.data();
      this.initStatusChart();
      this.initRepsChart();
      //Notiflix.Loading.Remove()
      this.$store.state.loading = false;
    
    },

    bonusTarget() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var count = 0;
      while (date1 <= date2) {
        if (date1.getDay() < 5) {
          count++;
        }
        date1.setDate(date1.getDate() + 1);
      }
      this.bounsTarget = Math.round(count * 2.045);
    },
  },
  computed: {},
  created() {
    //Notiflix.Loading.Init({svgColor:"#039BE5",});
    const startOfMonth = moment().startOf("day").format("YYYY-MM-DD");
    const endOfMonth = moment()
      .endOf("day")
      .add("days", 1)
      .format("YYYY-MM-DD");
    // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    // const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');

    this.dates = [startOfMonth, endOfMonth];

    this.updateDashboard();
  },
};
</script>

<style>
/* #managerDash .row{
        width: 70vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    } */
</style>