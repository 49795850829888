import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import PhoneCalls from "../views/PhoneCalls.vue";
import PhoneCalls2 from "../views/PhoneCalls2.vue";
import MeetingsQuery from "../views/MeetingsQuery.vue";
import PhoneCallsDone from "../views/PhoneCallsDone.vue";

import MeetingsCalendar from "../views/MeetingsCalendar.vue";
import Manager from "../views/Manager.vue";
import Chat from "../views/Chat.vue";
import Users from "../views/Users.vue";
import LoadData from "../views/LoadData.vue";
import PhoneCallsRecordsView from "../views/PhoneCallsRecordsView";

import managerDashboardPhones from "../components/ManagerDashboardPhones";
import managerDashboardMeethigns from "../components/ManagerDashboardMeethigns";
import settings from "../components/Settings";
import phonecallsrecords from "../components/PhoneCallsRecords";
import PhoneCallsRecordsMeitav from "../components/PhoneCallsRecordsMeitav";
import InspectorDashboardMeethigns from "../views/InspectorDashboardMeethigns.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard
  },
  {
    path: "/PhoneCalls",
    name: "PhoneCalls",
    component: PhoneCalls
  },
  {
    path: "/PhoneCalls2",
    name: "PhoneCalls",
    component: PhoneCalls2
  },
  {
path: "/MeetingsQuery",
    name: "MeetingsQuery",
    component: MeetingsQuery
  },
  {
    path: "/PhoneCallsDone",
    name: "PhoneCallsDone",
    component: PhoneCallsDone
  },
  {
    path: "/MeetingsCalendar",
    name: "MeetingsCalendar",
    component: MeetingsCalendar
  },
  {
    path: "/PhoneCallsRecords",
    name: "PhoneCallsRecords",
    component: PhoneCallsRecordsView
  },
  {
    path: "/PhoneCallsRecordsMeitav",
    name: "PhoneCallsRecordsMeitav",
    component: PhoneCallsRecordsMeitav
  },
  {
    path: "/Manager",
    name: "Manager",
    component: Manager,
    children: [
      {
        path: "dashboardPhoneCalls",
        component: managerDashboardPhones
      },
      {
        path: "dashboardMeetings",
        component: managerDashboardMeethigns
      },
      {
        path: "phonecallsrecords",
        component: phonecallsrecords
      },
      {
        path: "settings",
        component: settings
      }
    ]
  },
  {
    path: "/Chat",
    name: "chat",
    component: Chat
  },
  {
    path: "/loadData",
    name: "LoadData",
    component: LoadData
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  },
  {
    path: "/agents_dashboard",
    name: "Agents_dashboard",
    component: InspectorDashboardMeethigns
  }
];

const router = new VueRouter({
  routes
});

export default router;
